import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import { buyers } from 'src/pages/monitoring/Monitoring.config';
import { DataFormMonitoringStats } from 'src/pages/monitoring/Monitoring.helpers';
import CheckBoxPopover from 'src/pages/monitoring/checkbox/CheckBoxPopover.component';
import {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
} from 'react';
import { Buyers } from 'src/services/monitoring/monitoring.types';

type BuyersPopoverProps = {
  buyersList?: Buyers[];
  submitHandler?: MouseEventHandler | undefined;
  setData: Dispatch<SetStateAction<Buyers[] | undefined>>;
  initialData?: Buyers[];
  dataFiltered: DataFormMonitoringStats;
  onCloseHandler?: () => void;
};
export const BuyersPopover: React.FC<BuyersPopoverProps> = ({
  buyersList,
  submitHandler,
  setData,
  initialData,
  dataFiltered,
  onCloseHandler,
}) => {
  const onChangeBuyersHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newList = buyersList?.filter(({ Name }) =>
      Name.toLowerCase().includes(value.toLowerCase()),
    );
    setData(value?.length > 0 ? newList : initialData);
  };
  const count =
    dataFiltered.buyers.length > 0 ? dataFiltered.buyers.length : undefined;
  return (
    <SelectPopover
      onChangeHandler={onChangeBuyersHandler}
      submitHandler={submitHandler}
      label="lbl.customers"
      countFilters={count}
      onCloseHandler={onCloseHandler}
    >
      {buyersList?.map(({ Name, Id }) => (
        <CheckBoxPopover
          key={Id}
          fieldName={buyers}
          label={Name}
          id={Id.toString()}
        />
      ))}
    </SelectPopover>
  );
};

export default BuyersPopover;
