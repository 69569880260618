import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';

export const cardIsExpired = (card?: PaymentMethodResult | null) => {
  const d = new Date();
  const currentAbsoluteMonth = d.getFullYear() * 12 + d.getMonth() + 1;
  return card?.Details
    ? currentAbsoluteMonth -
        (card?.Details.ExpYear! * 12 + card?.Details?.ExpMonth!) >
        0
    : false;
};
