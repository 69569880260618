/// insert in component  ///
// const {urlRedirectToFlow} = useRedirectToFlow();

import { useLocation, useParams } from 'react-router';
import { RoutePath } from 'src/routers/routers.config';
import { CategoryCodes, ProductCodes } from 'src/utils/types/common.types';
import { pageFlows } from './CreateLinkFlow.helper';
import { useProfileStore } from 'src/store/store';

interface Result {
  urlRedirectToFlow: string;
  step: number;
  stepMax: number;
}

export function useRedirectToFlow() {
  const { categoryCode, productCode } = useParams<{
    categoryCode?: CategoryCodes;
    productCode?: ProductCodes;
  }>();
  const hasOrders = useProfileStore((state) => state.HasOrders);

  const result: Result = Object.create(null);

  const currentPage = useLocation()
    .pathname.replace(RoutePath.createLink, '')
    .replace(`${productCode}/`, '')
    .replace(`${categoryCode}/`, '');

  const flow = productCode
    ? pageFlows[productCode.includes('CST') ? 'CST' : productCode]
    : undefined;

  if (flow === undefined) {
    result.urlRedirectToFlow = '';
    return result;
  }

  if (currentPage) {
    const currentPageIndex = flow.findIndex(
      (obj) => obj?.path === (currentPage as never),
    );
    if (currentPageIndex !== -1 && currentPageIndex + 1 < flow.length) {
      result.urlRedirectToFlow = `${
        RoutePath.createLink
      }${categoryCode}/${productCode}/${flow[currentPageIndex + 1].path}`;
      result.step = hasOrders
        ? flow[currentPageIndex].step
        : flow[currentPageIndex].step - 1;
      result.stepMax = hasOrders
        ? flow[currentPageIndex].stepMax
        : flow[currentPageIndex].stepMax - 1;
    }
  }

  return result;
}
