import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import StepperRemodulation from 'src/components/Stepper/StepperRemodulation.component';
import { Installment } from 'src/utils/types/common.types';

const MonitoringDetailModalBody = ({ data }: { data: Installment[] }) => {
  return (
    <ModalBody>
      <StepperRemodulation
        amountRemodulation={data.reduce(
          (acc, installment) => acc + installment.Amount,
          0,
        )}
        dataStepper={data}
      />
    </ModalBody>
  );
};

export default MonitoringDetailModalBody;
