export function maskPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  const lastThreeDigits = phoneNumber.slice(-3);
  const maskedPart = '*'.repeat(phoneNumber.length - 3);

  return maskedPart + lastThreeDigits;
}
