import { SelectedItem } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  canGiveCreditForOrderCreation,
  createAdvanceRequest,
  editPlan,
  flagCreditAssignment,
  generateQrCode,
  getAvailableProducts,
  getCompanyInfo,
  getCompanySearchCriteria,
  getEditPlanRequests,
  getEligibleOrders,
  getRatePlans,
  getVariantsWithPlan,
  initOrderCreate,
  linkWrong,
  orderGiveCredit,
  orderImport,
  orderRenew,
  payOrderLinkEndPoint,
  performAdvanceRequest,
  performEvaluateBuyer,
  performOrderCreation,
  searchCompanyInfo,
  sendLinkToBuyer,
  setBuyerFlagCredit,
  setInvoiceDateAndFile,
  setOrderStatus,
  UpdateInvoice,
} from './orderLink.config';
import {
  CanGiveCreditData,
  CanGiveCreditResponse,
  createAdvanceRequestResult,
  CurrentOrder,
  editPlanData,
  EligibleOrder,
  GetCompanyInfoData,
  GetCompanyInfoResponse,
  GetCompanySearchCriteriaData,
  GetCompanySearchCriteriaResponse,
  getEditPlanResult,
  GetRatePlansData,
  getVariantsResponse,
  InitOrderCreateData,
  InitOrderCreateResponse,
  OrderCreationResponse,
  orderGiveCreditData,
  orderGiveCreditResponse,
  PerformAdvanceRequestData,
  PerformAdvanceRequestResult,
  PerformOrderCreationData,
  Product,
  SearchCompanyInfoData,
  SearchCompanyInfoResponse,
  SendLinkToBuyerData,
  setBuyerFlagCreditData,
  setInvoiceDateAndFileResponse,
  updateInvoiceData,
} from './orderLink.types';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { DataFormEligibleOrders } from 'src/pages/AdvPaymentFlow/partials/ChooseLinkPage/partials/SearchCriteriaForm.helpers';

export const payOrderLinkMethodApi = async (data: any) => {
  const requestConfig = makeEndpointApiConfig({
    url: payOrderLinkEndPoint,
    method: 'POST',
    data: {
      guid: data.guid,
    },
  });

  return await apiRequest.request<{ ResultSet: CurrentOrder }>(requestConfig);
};

export const getCompanyInfoMethodApi = async (data: GetCompanyInfoData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getCompanyInfo,
    method: 'GET',
    params: data,
  });

  return await apiRequest.request<GetCompanyInfoResponse>(requestConfig);
};
export const searchCompanyInfoMethodApi = async (
  data: SearchCompanyInfoData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: searchCompanyInfo,
    method: 'POST',
    data,
    authenticated: true,
  });

  return await apiRequest.request<SearchCompanyInfoResponse>(requestConfig);
};
type dataQr = { idOrdine: number };
export const generateQrCodeMethodApi = async (data: dataQr) => {
  const requestConfig = makeEndpointApiConfig({
    url: generateQrCode,
    method: 'POST',
    data,
    authenticated: true,
  });

  return await apiRequest.request<{ ResultSet: string }>(requestConfig);
};

export const sendLinkToBuyerMethodApi = async (data: SendLinkToBuyerData) => {
  const requestConfig = makeEndpointApiConfig({
    url: sendLinkToBuyer,
    method: 'POST',
    data,
    authenticated: true,
  });

  return await apiRequest.request(requestConfig);
};

export const setOrderStatusMethodApi = async (values: {
  OrderGuid: string;
  Status: StatusPyByLinkValues;
}) => {
  const { OrderGuid, Status } = values;
  const requestConfig = makeEndpointApiConfig({
    url: setOrderStatus,
    method: 'POST',
    data: {
      OrderGuid,
      TargetStatus:
        Status === PayByLinkStatus.createdLink
          ? PayByLinkStatus.cancelledLink
          : PayByLinkStatus.interrupted,
    },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};

export const linkWrongMethodApi = async (guid: string) => {
  const requestConfig = makeEndpointApiConfig({
    url: linkWrong,
    method: 'POST',
    data: {
      guid,
    },
  });
  return await apiRequest.request(requestConfig);
};

export const getRatePlansMethodApi = async (data: GetRatePlansData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getRatePlans,
    method: 'POST',
    data: {
      flagCessioneCredito: data[flagCreditAssignment],
      importo: data[orderImport],
    },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};

//Products Methods
export const getAvailableProductsMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getAvailableProducts,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: Product[] }>(requestConfig);
};

export type getVariantsData = {
  code: string;
  amount: number;
  firstInstallmentDate?: string;
  isDeferred?: boolean;
};

export const getVariantsWithPlanMethodApi = async (data: getVariantsData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getVariantsWithPlan,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<getVariantsResponse>(requestConfig);
};

export const setInvoiceDateAndFileMethodApi = async (data: {
  formData: FormData;
  orderCreationSessionGuid: string;
}) => {
  const requestConfig = makeEndpointApiConfig({
    url: setInvoiceDateAndFile,
    method: 'POST',
    data: data?.formData,
    params: { orderCreationSessionGuid: data?.orderCreationSessionGuid },
    authenticated: true,
  });
  return await apiRequest.request<setInvoiceDateAndFileResponse>(requestConfig);
};

export const getEligibleOrdersMethodApi = async (data: {
  selectedLinks: SelectedItem[];
  filtersLinks: DataFormEligibleOrders;
}) => {
  const requestConfig = makeEndpointApiConfig({
    url: getEligibleOrders,
    method: 'GET',
    params: { selected: data.selectedLinks, ...data.filtersLinks },
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: EligibleOrder[] }>(
    requestConfig,
  );
};

export const createAdvanceRequestMethodApi = async (data: SelectedItem[]) => {
  const requestConfig = makeEndpointApiConfig({
    url: createAdvanceRequest,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<createAdvanceRequestResult>(requestConfig);
};
export const performAdvanceRequestMethodApi = async (
  data: PerformAdvanceRequestData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: performAdvanceRequest,
    method: 'POST',
    params: data,
    authenticated: true,
  });
  return await apiRequest.request<PerformAdvanceRequestResult>(requestConfig);
};
export const orderGiveCreditApi = async (data: orderGiveCreditData) => {
  const requestConfig = makeEndpointApiConfig({
    url: orderGiveCredit,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<orderGiveCreditResponse>(requestConfig);
};
export const setBuyerFlagCreditMethodApi = async (
  data: setBuyerFlagCreditData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: setBuyerFlagCredit,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
export const getEditPlanRequestsMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getEditPlanRequests,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<getEditPlanResult>(requestConfig);
};
export const editPlanMethodApi = async (data: editPlanData) => {
  const requestConfig = makeEndpointApiConfig({
    url: editPlan,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};

export const orderRenewMethodApi = async (OrderId: number) => {
  const requestConfig = makeEndpointApiConfig({
    url: orderRenew,
    method: 'POST',
    data: { OrderId },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
export const updateInvoiceApi = async (data: updateInvoiceData) => {
  const requestConfig = makeEndpointApiConfig({
    url: UpdateInvoice,
    method: 'POST',
    data: data.params,
    params: {
      orderGuid: data.orderGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<orderGiveCreditResponse>(requestConfig);
};
export const canGiveCreditForOrderCreationMethodApi = async (
  data: CanGiveCreditData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: canGiveCreditForOrderCreation,
    method: 'POST',
    data: data,
    params: {
      orderCreationSessionGuid: data.orderCreationSessionGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<CanGiveCreditResponse>(requestConfig);
};
export const performOrderCreationMethodApi = async (
  data: PerformOrderCreationData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: performOrderCreation,
    method: 'POST',
    data,
    params: {
      orderCreationSessionGuid: data.orderCreationSessionGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: OrderCreationResponse }>(
    requestConfig,
  );
};
export const initOrderCreateMethodApi = async (data: InitOrderCreateData) => {
  const requestConfig = makeEndpointApiConfig({
    url: initOrderCreate,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<InitOrderCreateResponse>(requestConfig);
};
export const getCompanySearchCriteriaMethodApi = async (
  data: GetCompanySearchCriteriaData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getCompanySearchCriteria,
    method: 'GET',
    params: data,
  });

  return await apiRequest.request<GetCompanySearchCriteriaResponse>(
    requestConfig,
  );
};

export const evaluateBuyer = async (orderGuid: string) => {
  const requestConfig = makeEndpointApiConfig({
    url: performEvaluateBuyer(orderGuid),
    method: 'POST',
    authenticated: true,
  });

  return await apiRequest.request<any>(requestConfig);
};
