import Icon from 'src/components/Icon/Icon.component';
import RadioBox from 'src/components/RadioBox/RadioBox.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as InstallmentCustom } from 'src/images/icons/installment-custom.svg';
import { SVGProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { ProductVariantsCodes, VariantCardArray } from './PlanCard.helper';

type Props = {
  value: string;
  name: string;
  rateAmount?: number;
  rateDate?: string;
  firstInstallmentDate?: string;
  rateNumber?: number;
  productCode: ProductVariantsCodes;
};

const PlanCard = ({
  name,
  value,
  rateAmount,
  rateDate,
  rateNumber,
  productCode,
  firstInstallmentDate,
}: Props) => {
  const { icon1, icon2, subtitle1, subtitle2, label, id } =
    VariantCardArray[productCode];
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const { watch } = useFormContext();
  const checkedValue = watch(name);
  return (
    <div
      data-component="plan-card"
      className='rounded-lg border border-transparent bg-white px-3 py-4 text-pgrey shadow-md data-[checked="true"]:border-pblack-400 data-[checked="true"]:text-pblack-400 lg:p-4 lg:pb-6'
      data-checked={checkedValue === value}
    >
      <div className="mb-6 flex">
        <RadioBox
          className="text-lg font-bold lg:text-2xl"
          label={label}
          name={name}
          i18n
          value={value}
          id={id}
          large={false}
        />
        {rateNumber && (
          <div className="whitespace-nowrap rounded-md bg-pblack-400 px-2 text-lg font-bold text-white">
            <Translate
              id="lbl.installment"
              data={{
                rateNumber,
              }}
            />
          </div>
        )}
      </div>
      <div className="grid gap-1 lg:gap-2">
        {icon1 && subtitle1 && (
          <FlexInfoCard icon={icon1} label={subtitle1}>
            <P className="ml-auto font-bold">
              {rateAmount && formatCurrency(rateAmount)}
              {rateDate && refactorFormatDate(rateDate)}
            </P>
          </FlexInfoCard>
        )}
        {icon2 && subtitle2 && (
          <FlexInfoCard icon={icon2} label={subtitle2}>
            <P className="font-bold">
              <Translate id="lbl.thirtyDays" />
            </P>
          </FlexInfoCard>
        )}
        {firstInstallmentDate && firstInstallmentDate.length > 0 && (
          <FlexInfoCard
            icon={InstallmentCustom}
            label="lbl.startInstallmentPlan"
          >
            <P className="font-bold">
              {refactorFormatDate(firstInstallmentDate, 'DD MMMM YYYY')}
            </P>
          </FlexInfoCard>
        )}
      </div>
    </div>
  );
};

type InfoProps = {
  icon: React.FC<SVGProps<SVGSVGElement>>;
  label: string;
};
const FlexInfoCard: React.FC<React.PropsWithChildren<InfoProps>> = ({
  icon,
  label,
  children,
}) => {
  return (
    <div className="flex items-center justify-between bg-bblue px-2 text-pgrey">
      <div className="flex">
        <Icon
          svgIconComponent={icon}
          iconHeight="2.4rem"
          iconSize="2.4rem"
          marginRight="0.9rem"
        />
        <P>
          <Translate id={label} />
        </P>
      </div>
      {children}
    </div>
  );
};

export default PlanCard;
