import { RoutePath } from 'src/routers/routers.config';

export const pblDetailPageArray = {
  true: {
    successCanceled: {
      path: RoutePath.linkOnline,
    },
    info: {
      backButton: 'lbl.orders',
      numberLink: 'lbl.idEcommerce',
      expiration: 'lbl.paymentDate',
      tab1: {
        paragraphDate: 'lbl.paymentDate',
      },
    },
    clientCard: {
      name: 'lbl.idEcommerce',
    },
  },
  false: {
    successCanceled: {
      path: RoutePath.linkDiPagamento,
    },
    info: {
      backButton: 'text.paymentLink',
      numberLink: 'lbl.linkNumber',
      expiration: 'lbl.expirationDateLink',
      tab1: {
        paragraphDate: 'text.linkCreationDate',
      },
    },
    clientCard: {
      name: 'text.client',
    },
  },
};
