import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import PlanCard from 'src/components/PlanCard/PlanCard.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { usePayByLinkStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import {
  InstallmentConfigForm,
  variantRadioBox,
} from './StepInstallmentPlan.helpers';

function StepInstallmentPlan() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const firstInstallmentDate = usePayByLinkStore(
    (state) => state.firstInstallmentDate,
  );
  const variants = usePayByLinkStore((state) => state.variants);
  const customInstallments = usePayByLinkStore(
    (state) => state.customInstallments,
  );
  const { initialValues, resolver } = InstallmentConfigForm(
    customInstallments?.Code,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    setValue(
      'customInstallments',
      variants?.find((item: any) => item.Code === data[variantRadioBox]),
    );
    setValue('variantCode', data[variantRadioBox]);
    navigate(urlRedirectToFlow);
  };
  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="text.rataPlanning"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.pblInstallmentInfo" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form
              className="grid gap-6"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {variants?.map((plan, index: number) => (
                <PlanCard
                  key={index}
                  name={variantRadioBox}
                  value={plan.Code}
                  rateAmount={plan.InstallmentAmount}
                  rateNumber={plan.NumRate}
                  productCode={plan.Code}
                  firstInstallmentDate={firstInstallmentDate}
                />
              ))}
              <ButtonContainer>
                <SubmitButton
                  disabled={!methods.formState.isValid}
                  minWidth="100%"
                  id={IDs.btnProceed}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
      {window.innerWidth < 1024 && <div style={{ height: '15vh' }}></div>}
    </>
  );
}

export default StepInstallmentPlan;
