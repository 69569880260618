import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  addProductTemplate,
  deleteProductTemplate,
  getPlansFromTemplates,
  getProductTemplates,
} from './template.config';
import apiRequest from 'src/services/api/api.config';
import {
  addProductTemplateData,
  deleteProductTemplateData,
  getPlansFromTemplatesData,
  getPlansFromTemplatesResponse,
  getProductTemplatesResponse,
} from './template.types';

export const addProductTemplateMethodApi = async (
  data: addProductTemplateData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: addProductTemplate,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request(requestConfig);
};

export const deleteProductTemplateMethodApi = async (
  params: deleteProductTemplateData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: deleteProductTemplate,
    method: 'DELETE',
    authenticated: true,
    params,
  });
  return await apiRequest.request(requestConfig);
};

export const getProductTemplatesMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getProductTemplates,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<getProductTemplatesResponse>(requestConfig);
};

export const getPlansFromTemplatesMethodApi = async (
  params: getPlansFromTemplatesData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getPlansFromTemplates,
    method: 'GET',
    authenticated: true,
    params,
  });

  return await apiRequest.request<getPlansFromTemplatesResponse>(requestConfig);
};
