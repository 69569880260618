import FormPassword from 'src/pages/registration/partials/FormPassword/FormPassword.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationGuestStepPassword: React.FC = () => {
  return (
    <>
      <FormPassword
        nextPath={RoutePath.registrationGuest + RoutePath.flagStep}
        step={6}
        stepMax={7}
      />
    </>
  );
};

export default RegistrationGuestStepPassword;
