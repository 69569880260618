import { Button } from 'src/components/Button/Button.component';
import { ModalHelperProps } from 'src/components/Modal/Modal.types';
import Translate from 'src/components/Translate/Translate.component';
import { ButtonContainerFooter } from './ChoosePlanModal.style';

export type ChooseModalFooterProps = ModalHelperProps & {
  handleClose: () => void;
  applyForm: () => void;
};

const ChoosePlanModalFooter: React.FC<ChooseModalFooterProps> = ({
  applyForm,
  handleClose,
  buttonTitle,
  ...otherProps
}) => {
  return (
    <ButtonContainerFooter>
      <Button variant="Primary" {...otherProps} onClick={applyForm}>
        <Translate id={buttonTitle} />
      </Button>
      <Button
        onClick={handleClose}
        variant="Tertiary"
        i18n
        translatedText="lbl.close"
      />
    </ButtonContainerFooter>
  );
};

export default ChoosePlanModalFooter;
