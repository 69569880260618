import FormOtpEmail from 'src/pages/registration/partials/FormOtpEmail/FormOtpEmail.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationStepOtpEmail: React.FC = () => {
  return (
    <FormOtpEmail
      nextPath={RoutePath.registrationMerchant + RoutePath.phoneStep}
      step={5}
      stepMax={9}
    />
  );
};

export default RegistrationStepOtpEmail;
