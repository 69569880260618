import React from 'react';
import { countryCodesAvailable } from 'src/services/geo/availableCountries';
import getCountries from 'src/utils/functions/getCountries';
import { SupportedLanguageCode } from 'src/services/i18n/i18n.types';

export function useCreateOptions(
  language: SupportedLanguageCode,
): ListboxOption[] {
  const countries = getCountries(language);
  return React.useMemo(() => {
    const output: ListboxOption[] = [];
    const filteredCountries = countries.filter((country) =>
      countryCodesAvailable.includes(country.code),
    );
    filteredCountries.map((item) => {
      const prefixEntry: ListboxOption = {
        optionId: item.prefix + item.code,
        label: `${item.country_name} (+${item.prefix})`,
      };
      output.push(prefixEntry);
    });

    return output;
  }, [countries]);
}
