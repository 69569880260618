import AmountColumn from './partialsTable/AmountColumn.component';
import FeeColumn from './partialsTable/FeeColumn.component';
import InvoiceDateColumn from './partialsTable/InvoiceDateColumn.component';
import OrderIdColumn from './partialsTable/OrderIdColumn.component';
import StatusColumn from './partialsTable/StatusColumn.componen';

export const RequestsResultsTableColumnConfig = [
  {
    id: 'order-id',
    header: 'advPlanRequestsHeaderTable.OrderId',
    RenderComponent: OrderIdColumn,
    rowCellConfig: {
      w: '100%',
      minWDesktop: '37.4rem',
    },
  },
  {
    id: 'creation',
    header: 'advPlanRequestsHeaderTable.InvoiceDate',
    RenderComponent: InvoiceDateColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'amount',
    header: 'advPlanRequestsHeaderTable.Amount',
    RenderComponent: AmountColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'fee',
    header: 'advPlanRequestsHeaderTable.Fee',
    RenderComponent: FeeColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'status',
    header: 'advPlanRequestsHeaderTable.Status',
    RenderComponent: StatusColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
];

export enum AdvPlanRequestsStatus {
  approved = 4,
  unsuitable = 2,
}
