import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const FilteredContainer = styled(Flex)`
  gap: 1.2rem;
  flex-wrap: no-wrap;
  cursor: grab;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  justify-content: flex-start;
  margin: 1.2rem -5.2rem;
  padding: 0 5.2rem;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
    border: 4px solid red;
  }
  position: static;
  ${media.tablet`flex-wrap: wrap;`}
`;

export const FilteredButtonContainer = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  cursor: pointer;
  align-items: center;
  min-width: max-content;
  margin-bottom: 0.1rem;
  background-color: ${colors.textColorBlack};
  color: white;
  [data-disabled='true'] {
    pointer-events: none;
  }
  ${media.tablet`
    font-size: 1.4rem;
    line-height: 2.2rem;
  `}
`;
