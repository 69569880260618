import { SelectProps } from 'src/components/Select/Select.types';
import SelectField from 'src/components/SelectField/SelectField.component';
import React from 'react';
// import { ThemeProvider } from 'styled-components';
// import { modifyTheme } from './PhonePrefixField.helpers';
import { useCreateOptions } from './PhonePrefixField.hooks';
import NativeSelect from 'src/components/NativeSelect/NativeSelect.component';
import { useLanguage } from 'src/services/i18n/i18n.context';

export type PhonePrefixFieldProps = Omit<
  SelectProps,
  'component' | 'iconComponent' | 'options' | 'value' | 'onChange' | 'onBlur'
> & { name: string };

const PhonePrefixField: React.FC<PhonePrefixFieldProps> =
  function PhonePrefixField(props) {
    const { language } = useLanguage();
    const options = useCreateOptions(language);
    return (
      // <ThemeProvider theme={modifyTheme}>
      <SelectField
        data-component="phone-prefix-field"
        {...props}
        i18n={false}
        enableModal={true}
        options={options}
        component={NativeSelect}
      />
      // </ThemeProvider>
    );
  };

export default PhonePrefixField;
