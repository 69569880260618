import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { memo } from 'react';
import { useNavigate } from 'react-router';

type Props = {
  label?: string;
  url?: string;
  noLabel?: boolean;
};
const GoBackButton: React.FC<Props> = memo(function GoBackButton({
  label = 'lbl.back',
  noLabel = false,
  url,
}) {
  const navigate = useNavigate();
  const clickHandler = url ? () => navigate(url) : () => navigate(-1);

  return (
    <button
      data-component="go-back-button"
      className="flex cursor-pointer items-center py-4 text-pblack-400 hover:text-pblack-700"
      onClick={clickHandler}
    >
      <Back className="h-8 w-8 lg:h-12 lg:w-12" />
      {noLabel === false && (
        <span className="text-sm lg:text-base">
          <Translate id={label} />
        </span>
      )}
    </button>
  );
});

export default GoBackButton;
