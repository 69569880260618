import { Button } from 'src/components/Button/Button.component';
import { ButtonProps } from 'src/components/Button/Button.types';
import ModalHumanChatLoadable from 'src/components/ModalHumanChat/ModalHumanChat.loadable';
import React, { useEffect, useState } from 'react';
import { COOKIEHUB_GLOBAL_VAR, INDIGO_GLOBAL_VAR } from '../../constants';

type NeedHelpButtonProps = ButtonProps & {
  BaseComponent?: React.ComponentType<any>;
};

const NeedHelpBase = React.memo(function InputField({
  id,
  className,
  BaseComponent = Button,
  disabled,
  ...otherProps
}: NeedHelpButtonProps) {
  const [modal, setModal] = useState(false);
  const [marketingConsented, setMarketingConsented] = useState(
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.hasInitialised &&
      (window as any)[COOKIEHUB_GLOBAL_VAR]?.hasConsented('marketing'),
  );

  const showCookieBanner = () => {
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.openDialog();
  };

  const showHumanChat = () => {
    if ((window as any)[INDIGO_GLOBAL_VAR]) {
      (window as any)[INDIGO_GLOBAL_VAR].setOpen(true);
    } else {
      document.dispatchEvent(
        new CustomEvent('opyn-init-indigo', {
          bubbles: true,
        }),
      );
    }
    setModal(false);
  };

  useEffect(() => {
    document.addEventListener('cookiehub-onmarketingchange', (event: any) => {
      setMarketingConsented(event.detail?.status);
    });

    return () => (window as any)[INDIGO_GLOBAL_VAR]?.setOpen(false);
  }, []);

  return (
    <>
      <BaseComponent {...otherProps} onClick={() => setModal(true)} />
      {modal && (
        <ModalHumanChatLoadable
          i18n
          modalTitle="supportModal.Title"
          handleClose={() => setModal(false)}
          canChat={marketingConsented}
          showHumanChat={showHumanChat}
          showCookieBanner={showCookieBanner}
        />
      )}
    </>
  );
});

export default NeedHelpBase;
