import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const MovementsDetailCardContainer = styled(Flex)`
  background-color: ${colors.white};
  box-shadow: 0rem 0.2rem 0.4rem 0.1rem ${colors.cardShadowBlue};
  border-radius: 0.8rem;
  gap: 2rem;
  line-height: 2.8rem;
  color: ${colors.textColorBlack};
  ${Flex} {
    min-width: 0;
  }
  ${P} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
