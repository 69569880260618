import { Button } from 'src/components/Button/Button.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Toast from 'src/components/Toast/Toast.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getCookie } from 'src/utils/functions/cookie';
import { OrderDetailContextType } from '../OrderDetail.component';
import { TabsOrder } from '../tabs/TabsOrder.component';
import ResponsiveOrderDetailCard from './ResponsiveOrderDetailCard/ResponsiveOrderDetailCard.component';
import PendingBalanceInfoModalLoadable from 'src/components/PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';
import { useState } from 'react';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';

const OrderDetailInfo: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { orderData, orderGuid } = useOutletContext<OrderDetailContextType>();
  const changed = getCookie('changed');

  return (
    <>
      <div className="bg-white">
        {changed === 'true' && (
          <Toast
            variant="success"
            title="text.changeMethodAlertTitle"
            subTitle="text.youAreChangingPaymentSubtitle"
            timeout={5000}
          />
        )}
        <div className="container">
          <GoBackButton label="lbl.back" />
          {orderData?.ResultSet.InstallmentRatios.Processing
            ? orderData.ResultSet.InstallmentRatios.Processing > 0 && (
                <>
                  <AlertComponent
                    variant="informative"
                    descriptionLbl="lbl.PBAlertBuyer"
                    hyperlinkLbl="lbl.whatsthemeaning"
                    onClick={() => setModalOpen(true)}
                  />
                  {modalOpen && (
                    <PendingBalanceInfoModalLoadable
                      modalTitle="lbl.PBModalTitle"
                      modalBodyType="buyer"
                      i18n
                      handleClose={() => setModalOpen(false)}
                    />
                  )}
                </>
              )
            : null}
          <ResponsiveOrderDetailCard
            incomeRatios={orderData?.ResultSet.InstallmentRatios!}
            amount={orderData?.ResultSet.Amount}
            creationDate={orderData?.ResultSet.Created}
            paymentMethod={orderData?.ResultSet.PaymentMethod}
            backupPaymentMethod={orderData?.ResultSet.BackupPaymentMethod}
            merchantName={orderData?.ResultSet.MerchantName}
            status={orderData?.ResultSet.Status}
          />
        </div>
      </div>
      <div className="container">
        <Button
          variant="Tertiary"
          display="block"
          margin="2.4rem auto 3.6rem"
          sizeOnDesktop="medium"
          i18n
          minWidth="fit-content"
          minWidthMobile="100%"
          translatedText="text.whatCanIDoOrder"
          onClick={() => navigate(`${RoutePath.orderDetail}${orderGuid}/help`)}
        />
        <TabsOrder order={orderData} />
      </div>
    </>
  );
};

export default OrderDetailInfo;
