import media from 'src/style-utils/media-queries';
import { Input } from 'src/style/styleInput.style';
import styled from 'styled-components';

export const InputOtp = styled(Input)`
  width: 4.4rem;
  height: 4.4rem;
  text-align: center;
  padding: 0;
  font-weight: bold;
  font-size: 2.4rem;
  ${media.tablet`
    width: 5.2rem;
    height: 5.2rem;
  `}
`;
