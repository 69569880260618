import Icon from 'src/components/Icon/Icon.component';
import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import StatusPayByLink from 'src/components/StatusPayByLink/StatusPayByLink.component';
import { InstallmentsStatusMap } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Tabs from 'src/components/Tabs/Tabs.component';
import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ClockIcon } from 'src/images/icons/clock.svg';
import { ContainerWrapper } from 'src/pages/PayByLinkDetailPage/partials/PayByLinkDetailInfo/style/PayByLinkDetailInfo.style';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  BuyerOrderStatus,
  InstallmentStatus,
} from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import type { Installment } from 'src/services/installments/installments.types';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { Hr } from 'src/style-utils/hr';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { OrdersTabsProps, statusCard } from './TabsOrder.types';
import { OrderDetailContextType } from '../OrderDetail.component';

export const TabsOrder: React.FC<OrdersTabsProps> = ({ order }) => {
  const navigate = useNavigate();
  const { orderData } = useOutletContext<OrderDetailContextType>();
  const setCurrentTransaction = useCurrentTransactionStore(
    (state) => state.setTransactions,
  );
  const setCurrentOrder = useProfileStore((state) => state.setCurrentOrder);

  const {
    formatters: { formatCurrency },
  } = useI18n();
  const onClickHandler = (item: Installment) => {
    setCurrentTransaction({
      orderGuid: item?.OrderGuid,
      transactionsGuid: [item?.Guid],
      amount: item?.Amount,
      paymentMethodId: item?.OrderPaymentMethodId,
      showPaymentSetupPage: false,
      orderPayment: true,
      type: 'pay',
      canPayWithSDD: orderData?.ResultSet.CanPayWithSDD,
      ChangedMethod: undefined,
      successData: undefined,
    });
    setCurrentOrder({});
    item?.CanPay
      ? navigate(`${RoutePath.paymentFlow}${RoutePath.payWithOpyn}`, {
          state: { showBack: true },
        })
      : navigate(
          `${RoutePath.orderDetail}${item?.OrderGuid}/${RoutePath.info}`,
        );
  };
  const formattedCreationDate = {
    date: refactorFormatDate(order?.ResultSet.Created ?? '', 'DD MMMM YYYY'),
    time: refactorFormatDate(order?.ResultSet.Created ?? '', 'HH:mm'),
  };
  const { subtitle } =
    InstallmentsStatusMap[order?.ResultSet.Status as BuyerOrderStatus];

  const TabsInstallments: ObjectTab[] = [
    {
      tabTitle: 'menu.installments',
      content: (
        <Flex
          gap="1.6rem"
          flexDirection="column"
          padding="2.4rem 1.2rem"
          backgroundStyle="#fff"
          className="rounded-2xl"
        >
          {order?.ResultSet.Installments.map((item) => (
            <InstallmentCard
              key={item.Guid}
              name={item.MerchantName}
              date={item.Expiration}
              amount={item.Amount}
              NumRata={item.INo}
              status={statusCard[item.Status]}
              itemStatus={item.Status}
              orderStatus={item.OrderStatus}
              onClickHandler={
                item.CanPay && item.Status === InstallmentStatus.expired
                  ? () => onClickHandler(item)
                  : undefined
              }
              arrowStatus={statusCard[item.Status]}
            />
          ))}
        </Flex>
      ),
    },
    {
      tabTitle: 'lbl.detail',
      content: (
        <>
          <ContainerWrapper>
            <div>
              <P bold>
                <Translate id="lbl.linkStatus" />
              </P>
              <Flex align="center" gap="1rem">
                <StatusPayByLink
                  statusLink={order?.ResultSet.Status ?? 1}
                  typeAccount="BUYER"
                />
                <PSmall>
                  <Translate id={subtitle} />
                </PSmall>
              </Flex>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.creationOrderDate" />
              </P>
              <Flex align="center">
                <P colorBlack>{formattedCreationDate.date}</P>
                <Icon
                  iconSize="1.6rem"
                  iconHeight="1.6rem"
                  marginRight="0.4rem"
                  marginLeft="1.2rem"
                  svgIconComponent={ClockIcon}
                />
                <P colorBlack>{formattedCreationDate.time}</P>
              </Flex>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="text.rataCount" />
              </P>
              <P colorBlack>{order?.ResultSet.Installments.length}</P>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.amountOrder" />
              </P>
              <P colorBlack>{formatCurrency(order?.ResultSet.Amount ?? 0)}</P>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.supplier" />
              </P>
              <P colorBlack>{order?.ResultSet.MerchantName}</P>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.description" />
              </P>
              <P colorBlack>{order?.ResultSet.Description}</P>
            </div>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.orderNumber" />
              </P>
              <P colorBlack>
                {order?.ResultSet.Id.toString().padStart(5, '0')}
              </P>
            </div>
          </ContainerWrapper>
        </>
      ),
    },
  ];

  return (
    <div className="mx-auto w-full max-w-oxl pb-8">
      <Tabs
        fullWidth
        margin="0 auto 0.8rem"
        tabs={TabsInstallments}
        variant="Primary"
      />
    </div>
  );
};
