import ResponsiveAccordion from 'src/components/ResponsiveAccordion/ResponsiveAccordion.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CollaboratorsHeroImgage } from 'src/images/hero/collaborators_hero.svg';
import HomeStepper from 'src/pages/privateArea/partials/merchant/partials/homeStepper/HomeStepper.component';
import {
  ButtonCustom,
  CustomGrid,
  CustomHomeH2,
  CustomSeparator,
} from 'src/pages/privateArea/partials/merchant/style/MerchantArea.style';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { H3 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  collabListItems,
  collabStepperArray,
} from './CollaboratorsInfoPage.config';

export const CollaboratorsInfoPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white py-10">
        <CustomGrid>
          <CollaboratorsHeroImgage width="100%" height="auto" />
          <Flex fluid flexDirection="column" gap="2rem">
            <CustomHomeH2>
              <Translate id="text.collabTitle" />
            </CustomHomeH2>
            <ButtonCustom
              variant="Primary"
              type="button"
              onClick={() => navigate(RoutePath.addCollaborator)}
              i18n
              translatedText="lbl.addCollaborator"
            />
          </Flex>
        </CustomGrid>
      </div>

      <CustomSeparator />

      <div className="container py-16">
        <H3 light className="mb-8 text-center lg:mb-14">
          <Translate id="text.collabAccordionTitle" />
        </H3>
        <ResponsiveAccordion listItems={collabListItems} />
      </div>

      <div className="container py-16">
        <H3 light className="mb-8 lg:mb-14">
          <Translate id="text.collabStepperTitle" />
        </H3>
        <HomeStepper stepperArray={collabStepperArray} />
      </div>
    </>
  );
};

export default CollaboratorsInfoPage;
