import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const LandingPageContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 70ch;
  text-align: center;
`;

export const LandingPageIcon = styled.img`
  margin: 0 auto;
  height: 19rem;
  ${media.tablet`
    height: 28rem;
  `}
`;
