import { RoutePath } from 'src/routers/routers.config';
import { Product } from 'src/services/orderLink/orderLink.types';
import {
  CategoryCodes,
  ProductCodes,
  ProductsEnum,
} from 'src/utils/types/common.types';

export type LinkFlowContext = {
  availableProducts: Product[];
  products: {
    [key in CategoryCodes]: {
      [key in ProductCodes]: {};
    };
  };
  categories: CategoryCodes[];
};

const {
  stepCustomer,
  stepCf,
  stepCheckData,
  stepImport,
  stepInstallmentPlan,
  stepInstallmentDelay,
  stepDescription,
  stepEmail,
  stepResume,
  stepSendLink,
  successSendLink,
  // payByLinkFlow,
  stepCustomerValidation,
  stepInstallmentNumber,
  stepInstallmentAmount,
  stepCustomInstallmentForm,
  stepCountry,
  stepNamePostalCode,
  stepVAT,
  stepName,
  dateInstallmentPlan,
  startInstallmentPlan,
} = RoutePath;

type pageFlowInterface = {
  [key in ProductCodes]: {
    path: string;
    step: number;
    stepMax: number;
  }[];
};

export const pageFlows: pageFlowInterface = {
  [ProductsEnum.advancePayment]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 10,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 10,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 10,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 10,
    },
    {
      path: stepDescription,
      step: 6,
      stepMax: 10,
    },
    {
      path: stepEmail,
      step: 7,
      stepMax: 10,
    },
    {
      path: stepResume,
      step: 8,
      stepMax: 10,
    },
    {
      path: stepCustomerValidation,
      step: 9,
      stepMax: 10,
    },
    {
      path: stepSendLink,
      step: 10,
      stepMax: 10,
    },
    {
      path: successSendLink,
      step: 10,
      stepMax: 10,
    },
  ],
  [ProductsEnum.singleInstant]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 10,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 10,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 10,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 10,
    },
    {
      path: stepDescription,
      step: 6,
      stepMax: 10,
    },
    {
      path: stepEmail,
      step: 7,
      stepMax: 10,
    },
    {
      path: stepResume,
      step: 8,
      stepMax: 10,
    },
    {
      path: stepCustomerValidation,
      step: 9,
      stepMax: 10,
    },
    {
      path: stepSendLink,
      step: 10,
      stepMax: 10,
    },
    {
      path: successSendLink,
      step: 10,
      stepMax: 10,
    },
  ],
  [ProductsEnum.singleDelayed]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 11,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 11,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 11,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 11,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 11,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 11,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 11,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 11,
    },
    {
      path: stepInstallmentDelay,
      step: 6,
      stepMax: 11,
    },
    {
      path: stepDescription,
      step: 7,
      stepMax: 11,
    },
    {
      path: stepEmail,
      step: 8,
      stepMax: 11,
    },
    {
      path: stepResume,
      step: 9,
      stepMax: 11,
    },
    {
      path: stepCustomerValidation,
      step: 10,
      stepMax: 11,
    },
    {
      path: stepSendLink,
      step: 11,
      stepMax: 11,
    },
    {
      path: successSendLink,
      step: 11,
      stepMax: 11,
    },
  ],
  [ProductsEnum.flexEqual]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 12,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 12,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 12,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 12,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 12,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 12,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 12,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 12,
    },
    {
      path: startInstallmentPlan,
      step: 6,
      stepMax: 12,
    },
    {
      path: dateInstallmentPlan,
      step: 6,
      stepMax: 12,
    },
    {
      path: stepInstallmentPlan,
      step: 7,
      stepMax: 12,
    },
    {
      path: stepDescription,
      step: 8,
      stepMax: 12,
    },
    {
      path: stepEmail,
      step: 9,
      stepMax: 12,
    },
    {
      path: stepResume,
      step: 10,
      stepMax: 12,
    },
    {
      path: stepCustomerValidation,
      step: 11,
      stepMax: 12,
    },
    {
      path: stepSendLink,
      step: 12,
      stepMax: 12,
    },
    {
      path: successSendLink,
      step: 12,
      stepMax: 12,
    },
  ],
  [ProductsEnum.flexCustom]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 13,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 8,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 13,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 13,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 13,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 13,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 13,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 13,
    },
    {
      path: stepInstallmentNumber,
      step: 6,
      stepMax: 13,
    },
    {
      path: stepInstallmentAmount,
      step: 7,
      stepMax: 13,
    },
    {
      path: stepCustomInstallmentForm,
      step: 8,
      stepMax: 13,
    },
    {
      path: stepDescription,
      step: 9,
      stepMax: 13,
    },
    {
      path: stepEmail,
      step: 10,
      stepMax: 13,
    },
    {
      path: stepResume,
      step: 11,
      stepMax: 13,
    },
    {
      path: stepCustomerValidation,
      step: 12,
      stepMax: 13,
    },
    {
      path: stepSendLink,
      step: 13,
      stepMax: 13,
    },
    {
      path: successSendLink,
      step: 13,
      stepMax: 13,
    },
  ],
  [ProductsEnum.custom]: [
    {
      path: stepCustomer,
      step: 1,
      stepMax: 10,
    },
    {
      path: stepCountry,
      step: 2,
      stepMax: 10,
    },
    {
      path: stepCf,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepVAT,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepNamePostalCode,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepName,
      step: 3,
      stepMax: 10,
    },
    {
      path: stepCheckData,
      step: 4,
      stepMax: 10,
    },
    {
      path: stepImport,
      step: 5,
      stepMax: 10,
    },
    {
      path: stepDescription,
      step: 6,
      stepMax: 10,
    },
    {
      path: stepEmail,
      step: 7,
      stepMax: 10,
    },
    {
      path: stepResume,
      step: 8,
      stepMax: 10,
    },
    {
      path: stepCustomerValidation,
      step: 9,
      stepMax: 10,
    },
    {
      path: stepSendLink,
      step: 10,
      stepMax: 10,
    },
    {
      path: successSendLink,
      step: 10,
      stepMax: 10,
    },
  ],
  [ProductsEnum.remodulation]: [
    {
      path: stepImport,
      step: 1,
      stepMax: 5,
    },
    {
      path: stepInstallmentNumber,
      step: 2,
      stepMax: 5,
    },
    {
      path: stepInstallmentAmount,
      step: 3,
      stepMax: 5,
    },
    {
      path: stepCustomInstallmentForm,
      step: 4,
      stepMax: 5,
    },
    {
      path: stepResume,
      step: 5,
      stepMax: 5,
    },
    {
      path: successSendLink,
      step: 5,
      stepMax: 5,
    },
  ],
};
