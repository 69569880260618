import FormPhone from 'src/pages/registration/partials/FormPhone/FormPhone.component';
import { RoutePath } from 'src/routers/routers.config';
import { useProfileStore } from 'src/store/store';

function RegistrationLightStepPhone() {
  const BuyerRegistrationStatus = useProfileStore(
    (state) => state.currentOrder?.BuyerRegistrationStatus,
  );
  return (
    <FormPhone
      nextPath={RoutePath.registrationLight + RoutePath.otpConfirmPhoneStep}
      step={1}
      stepMax={6}
      login={BuyerRegistrationStatus === 'guest'}
      privacy
    />
  );
}

export default RegistrationLightStepPhone;
