import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const CloseIcon = styled.button`
  color: ${colors.textP};
  background-color: white;
  outline: 1px solid currentColor;
  width: 2.4rem;
  height: 2.4rem;
  top: 0;
  right: 0;
  position: absolute;
  transform: translate3d(50%, -50%, 0);
  padding: 0.4rem;
  border-radius: 99rem;
  cursor: pointer;
  :hover {
    background-color: aliceblue;
  }
`;

export const ToastContainer = styled.div`
  align-self: stretch;
  flex: 1;
  max-width: 44.8rem;
  text-align: left;
  background-color: white;
  border-radius: 0.8rem;
  overflow: visible;
  gap: 1.2rem;
  outline: 1px solid;
  outline-color: currentColor;
  display: flex;
  align-items: center;
  ${media.tablet`
    padding: 2.4rem;
  `}
  padding: 1.6rem 1.2rem;
  button {
    float: right;
  }

  &[data-type='primary'] {
    outline-color: ${colors.greyBorder};
    color: ${colors.textColorBlack};
  }
  &[data-type='success'] {
    color: ${colors.greenStatus};
  }
  &[data-type='warning'] {
    color: ${colors.yellowStatus};
  }
  &[data-type='info'] {
    color: ${colors.lightBlueBorder};
  }
  &[data-type='error'] {
    color: ${colors.redStatus};
  }
`;
