import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

// interface SelectErrorProps {
//   isError?: boolean;
// }

export const SelectRootWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  position: relative;
  margin: 0;
  padding: 0;
  background: ${colors.white};
  /* Use this property to trigger fluid behaviour inside a flex container. */
  flex: ${({ theme }) => theme.select.root.flex};
  width: ${({ theme }) => theme.select.root.width};
  ${({ disabled }) =>
    disabled && `pointer-events: none; background : ${colors.bgDisabledInput}`};

  & > * {
    flex: 1 1 auto;
  }
`;

export const StyledNativeSelect = styled.select<{
  isError?: boolean;
}>`
  color: ${colors.textInputColor};
  border-radius: 0.4rem;
  padding: 0.8rem 3.6rem 0.8rem 1.2rem;
  width: 100%;
  border: none;
  outline: 1px solid ${colors.inputBorderColor};
  font-weight: 400;
  line-height: 1.857;
  vertical-align: middle;
  display: inline-block;
  font-size: 1.4rem;
  background: ${colors.white};
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Hides the credentials indicator in safari. */
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  min-width: 89px;
  ~ div {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    right: 0.4rem;
    transition: rotate 150ms ease-in-out;
  }
  &:focus {
    ~ div {
      rotate: -180deg;
    }
    z-index: 1;
    outline-color: ${colors.inputBorderColorFocus};
    box-shadow: none;
  }
  &[disabled] {
    background: ${colors.bgDisabledInput};
  }
  &::placeholder {
    color: ${colors.placeholder};
  }
  ${media.tablet`
    padding: 1.2rem 3.6rem 1.2rem 1.2rem;
    ~ div {
      right: 0.8rem;
    }
  `}
  ${({ isError }) => {
    if (isError) return `outline-color: ${colors.redInput}!important`;
    else return null;
  }};
`;

export const FlagIcon = styled(Icon)`
  position: absolute;
  right: 2.6rem;
  z-index: 2;
`;

export const MessageErrorSelect = styled.span<{
  isError?: boolean;
}>`
  position: absolute;
  display: none;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.4rem;
  ${({ isError }) => {
    if (isError) {
      return `color: ${colors.redInput};
        }
      `;
    }
  }};
`;
