import { ReactComponent as imgMegaphone } from 'src/images/icons/img-megaphone.svg';
import { ReactComponent as imgPsw } from 'src/images/icons/img-psw.svg';
import { ReactComponent as imgSetting } from 'src/images/icons/img-setting.svg';
import { ReactComponent as imgSignDoc } from 'src/images/icons/img-sign-doc.svg';
import { ReactComponent as imgUsers } from 'src/images/icons/img-users.svg';
import { RoleEnum } from 'src/utils/types/common.types';

type AccountPageArrayType = {
  title: string;
  path: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  tipoAccount: string[];
  divider: boolean;
}[];

export const AccountPageArray: AccountPageArrayType = [
  {
    title: 'lbl.companyData',
    path: '/personal-data',
    icon: imgUsers,
    tipoAccount: ['BUYER', 'MERCHANT', RoleEnum.owner, RoleEnum.collaborator],
    divider: false,
  },
  {
    title: 'lbl.accessData',
    path: '/access',
    icon: imgPsw,
    tipoAccount: ['BUYER', 'MERCHANT', RoleEnum.owner, RoleEnum.collaborator],
    divider: true,
  },
  {
    title: 'lbl.usageSetting',
    path: '/usage-setting',
    icon: imgSetting,
    tipoAccount: ['MERCHANT', RoleEnum.owner, RoleEnum.collaborator],
    divider: true,
  },
  {
    title: 'lbl.contract',
    path: '/documents',
    icon: imgSignDoc,
    tipoAccount: ['MERCHANT', RoleEnum.owner],
    divider: true,
  },
  {
    title: 'lbl.marketingChoice',
    path: '/marketing-choice',
    icon: imgMegaphone,
    tipoAccount: ['BUYER', 'MERCHANT', RoleEnum.owner, RoleEnum.collaborator],
    divider: false,
  },
];
