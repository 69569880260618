import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { linksFilterInitialDataEndPoint } from './linksFilterInitialData.config';

export const getLinkFilterInitialDataMethodApi = async ({
  madeFromPlugin,
}: {
  madeFromPlugin: boolean;
}) => {
  const requestConfig = makeEndpointApiConfig({
    url: linksFilterInitialDataEndPoint,
    method: 'GET',
    params: {
      madeFromPlugin,
    },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
