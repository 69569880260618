import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  refreshAuthOtpForDataChangeMethodApi,
  verifyOtpForDataChangeMethodApi,
} from 'src/services/dataChange/dataChange.request';
import { useAccountStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { AuthOtpArray, DataChangeContext } from '../../ChangeAccessData.types';
import { formConfigChangeOtp } from './Otp.helpers';

function OtpAuth() {
  const mobileNumber = useAccountStore((state) => state.Phone);
  const prefix = useAccountStore((state) => state.PhonePrefix).slice(0, -2);
  const Email = useAccountStore((state) => state.Email);
  const { sessionGuid, type } = useOutletContext<DataChangeContext>();
  const [errorMessage, setErrorMessage] = useState('');
  const { redirect } = AuthOtpArray[type];
  const { initialValues, resolver } = formConfigChangeOtp();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation(verifyOtpForDataChangeMethodApi, {
    onSuccess: (res) => {
      res.data.ResultSet
        ? navigate(redirect + `?sessionGuid=${sessionGuid}`, {
            state: {
              type,
            },
          })
        : setErrorMessage('error.otpNotMatching');
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const { mutate: mutateRefresh, isLoading: isLoadingRefresh } = useMutation(
    refreshAuthOtpForDataChangeMethodApi,
    {
      onError: () => {
        navigate(RoutePath.oops);
      },
    },
  );
  if (isLoadingRefresh) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const dataOtp = {
      sessionGuid,
      AuthOtp: Object.values(data).join(''),
    };
    mutate({ ...dataOtp });
  };
  return (
    <>
      <H4>
        <Translate id="text.insertSixCode" />
      </H4>
      <P marginBottom="2rem">
        <Translate id="text.recoveryOtp" />
      </P>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <OtpForm
            sendAgainHandler={() => mutateRefresh({ sessionGuid })}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            email={type === 'phone' ? Email : undefined}
            prefix={type === 'phone' ? undefined : prefix}
            mobile={type === 'phone' ? undefined : mobileNumber}
            isLoadingRefresh={isLoadingRefresh}
          />
          <ButtonContainer>
            <SubmitButton minWidth="100%" disabled={isLoading}>
              <Translate id="text.continue"></Translate>
            </SubmitButton>
          </ButtonContainer>
        </form>
      </FormProvider>
    </>
  );
}

export default OtpAuth;
