import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getBuyerHPData, getBuyerInstallments } from './installments.config';
import apiRequest from 'src/services/api/api.config';
import {
  GetBuyerInstallmentsResponse,
  GetHPDataBuyerResponse,
} from './installments.types';

export const getInstallmentsBuyerApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getBuyerInstallments,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<GetBuyerInstallmentsResponse>(requestConfig);
};
export const getHPDataBuyerApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getBuyerHPData,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<GetHPDataBuyerResponse>(requestConfig);
};
