import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getAdvanceRequests } from './advanceRequests.config';

type GetADVRequestsData = {
  groupGuid?: string;
};
type AdvanceRequest = {
  OrderId: number;
  BuyerName: string;
  Creation: string;
  InvoiceDate: string;
  Amount: number;
  Fee: number;
  Status: number;
};

type GetADVRequestsResult = {
  ResultSet: {
    AdvanceRequests: AdvanceRequest[];
    AdvanceRequestsCount: number;
  };
};

export const getAdvanceRequestsMethodApi = async (
  data?: GetADVRequestsData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getAdvanceRequests,
    method: 'GET',
    params: data,
    authenticated: true,
  });
  return await apiRequest.request<GetADVRequestsResult>(requestConfig);
};
