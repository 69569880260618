import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Close } from 'src/images/icons/circled_X.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { H5, P } from 'src/style-utils/typographic';
import { Grid } from 'src/style/Container.style';
import {
  CloseIcon,
  DoubleChoiceOverlay,
  LeaveStayBox,
} from './style/DoubleChoicePopUp.style';
import DisableWindowScroll from 'src/components/DisableWindowScroll/DisableWindowScroll.component';

interface DoubleChoicePopUpProps {
  handleClose: () => void;
  handleSecondAction?: () => void;
  mainText?: string;
  modalTitle?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  firstButtonLabel?: string;
  lastButtonLabel?: string;
  iconColor?: string;
}

const DoubleChoicePopUp: React.FC<DoubleChoicePopUpProps> = ({
  handleClose,
  handleSecondAction,
  firstButtonLabel,
  modalTitle,
  lastButtonLabel,
  mainText,
  icon,
  iconColor,
}) => {
  return (
    <DoubleChoiceOverlay
      data-component="double-choice-pop-up"
      justify="center"
      align="center"
    >
      <LeaveStayBox>
        <Grid gap="3.2rem" gapOnDesktop="4rem">
          <CloseIcon
            iconHeight="3.2rem"
            svgIconComponent={Close}
            onClick={handleClose}
          />
          <Grid gap="0.8rem" gapOnDesktop="1.6rem">
            <Icon
              svgIconComponent={icon}
              iconSize="4.8rem"
              margin="0 auto"
              color={iconColor}
            />
            {modalTitle && (
              <H5 textAlign="center">
                <Translate id={modalTitle} />
              </H5>
            )}
            {mainText && (
              <P textAlign="center" colorBlack>
                <Translate id={mainText} />
              </P>
            )}
          </Grid>
          <Grid gap="1.6rem">
            {firstButtonLabel && handleSecondAction && (
              <Button
                sizeOnDesktop="medium"
                sizeOnMobile="medium"
                variant="Primary"
                margin="0 auto"
                minWidthMobile="100%"
                onClick={handleSecondAction}
              >
                <Translate id={firstButtonLabel} />
              </Button>
            )}
            {lastButtonLabel && handleClose && (
              <Button
                sizeOnDesktop="medium"
                sizeOnMobile="medium"
                minWidthMobile="100%"
                margin="0 auto"
                variant="Tertiary"
                onClick={handleClose}
              >
                <Translate id={lastButtonLabel} />
              </Button>
            )}
          </Grid>
        </Grid>
      </LeaveStayBox>
      <DisableWindowScroll />
    </DoubleChoiceOverlay>
  );
};

export default DoubleChoicePopUp;
