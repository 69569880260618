import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as InternalErrorIcon } from 'src/images/pageStatus/internal_error.svg';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';
import { ErrorPagePar } from '../style/failedPage.style';

const SignGenericError: React.FC = () => {
  return (
    <>
      <Icon
        svgIconComponent={InternalErrorIcon}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="lbl.internalError" />
      </H4>
      <ErrorPagePar>
        <Translate id="text.internalError" />
      </ErrorPagePar>
    </>
  );
};

export default SignGenericError;
