import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import required from 'src/utils/validation/required';
import { Resolver } from 'react-hook-form';
import email from 'src/utils/validation/email';
import {
  emailField,
  passwordField,
  rememberMe,
} from 'src/services/login/login.config';

export const formConfig = (rememberData: {
  email?: string;
  flag?: boolean;
}) => {
  const initialValues = {
    [passwordField]: '',
    [emailField]: rememberData?.email ?? '',
    [rememberMe]: true,
  };

  const validate = validateFormSync({
    [emailField]: validateFieldSync(required, email),
    [passwordField]: validateFieldSync(required),
  });
  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
