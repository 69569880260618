import FormOtpEmail from 'src/pages/registration/partials/FormOtpEmail/FormOtpEmail.component';
import { RoutePath } from 'src/routers/routers.config';

const OtpConfirmEmailStepRegistration: React.FC = () => {
  return (
    <>
      <FormOtpEmail
        nextPath={RoutePath.registrationBuyer + RoutePath.passwordStep}
        step={4}
        stepMax={6}
      />
    </>
  );
};

export default OtpConfirmEmailStepRegistration;
