import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import UploadedContractImage from 'src/images/pageStatus/contract.svg?url';
import NamirialSuccess from 'src/images/pageStatus/signed_file.svg?url';
import { FindOutMoreEnum } from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.config';
import FindOutMoreModalLoadable from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.loadable';
import {
  ResultPageContainer,
  ResultPageIcon,
} from 'src/pages/onboardingFlow/variants/style/OnboardingFlow.style';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { merchantGetInfo } from 'src/services/user/user.config';
import { merchantGetInfoMethodApi } from 'src/services/user/user.request';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { navigationStatusMappings } from 'src/utils/config/stateAccount';

function SuccessNamirial() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const setUser = useProfileStore((state) => state.setUser);
  const setValue = useProfileStore((state) => state.setValue);
  const MerchantRoles = useAccountStore((state) => state.MerchantRoles);
  const { isLoading, data } = useQuery(
    merchantGetInfo,
    merchantGetInfoMethodApi,
    {
      onSuccess: (res) => {
        setUser(res.data.ResultSet);
        setValue('AccountType', 'MERCHANT');
        setValue('Role', MerchantRoles && MerchantRoles[0].Role);
        const navigationPath =
          navigationStatusMappings[res.data.ResultSet.Status]?.['MERCHANT'];
        if (navigationPath) {
          navigate(navigationPath);
        }
      },
      onError: () => {
        navigate(RoutePath.internalError);
      },
    },
  );
  const isIta = isItalian(data?.data.ResultSet.Country);
  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  return (
    <>
      <ResultPageContainer
        flex="1"
        flexDirection="column"
        padding="4.8rem 2.4rem"
        gap="1.6rem"
      >
        <ResultPageIcon src={isIta ? NamirialSuccess : UploadedContractImage} />
        <H4>
          <Translate
            id={
              isIta
                ? 'text.successNamirialTitle'
                : 'text.successNamirialTitleExt'
            }
          />
        </H4>
        <P>
          <Translate
            id={
              isIta
                ? 'text.successNamirialSubtitle'
                : 'text.successNamirialSubtitleExt'
            }
          />
        </P>
        <ButtonContainer>
          <Button variant="Tertiary" onClick={() => setShowModal(true)}>
            <Translate id="link.findOutMoreStripe" />
          </Button>
        </ButtonContainer>
      </ResultPageContainer>
      {showModal && (
        <FindOutMoreModalLoadable
          buttonTitle="lbl.close"
          modalTitle="text.findOutMoreStripeTitlePage"
          type={FindOutMoreEnum.stripe}
          handleClose={() => setShowModal(false)}
          i18n
        />
      )}
    </>
  );
}

export default SuccessNamirial;
