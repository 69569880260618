import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const ResponsiveFunnelWrapper = styled.div`
  margin: 3.6rem auto;
  width: min(52rem, 100% - 6.4rem);

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const FormSaveTemplate = styled.form`
  ${media.tablet`
  padding: 2rem 4rem;`}
`;
