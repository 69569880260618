import { useState } from 'react';
import {
  Buyers,
  CollaboratorsStats,
} from 'src/services/monitoring/monitoring.types';
import { DataFormMonitoringStats } from '../../Monitoring.helpers';
import FilteredButtonBooleanMonitoring from './partials/FilteredButtonBooelanMonitoring.component';
import FilteredButtonMonitoring from './partials/FilteredButtonMonitoring.component';
import { FilteredContainer } from './style/FilteredList.style';
import { Button } from 'src/components/Button/Button.component';
import { useFormContext } from 'react-hook-form';
import { link, plugin } from 'src/pages/monitoring/Monitoring.config';
import Translate from 'src/components/Translate/Translate.component';

type BuyerNamesType = { Name: string; Id: string };
type CollaboratorsNamesType = { Description: string; RoleId: string };
// type StatusArray = { status: PayByLinkStatus; name: string };

type Props = {
  buyers: Buyers[];
  collaborators: CollaboratorsStats[];
  dataForm: DataFormMonitoringStats;
  initialData: DataFormMonitoringStats;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
};

const FilteredListMonitoring = ({
  buyers,
  dataForm,
  handleClick,
  setDataFilter,
  initialData,
  collaborators,
}: Props) => {
  const { reset } = useFormContext();
  const buyersNames: BuyerNamesType[] = [];
  const collaboratorsNames: CollaboratorsNamesType[] = [];
  const booleanArray: {
    label: string;
    fieldName: typeof link | typeof plugin;
  }[] = [];

  if (dataForm) {
    dataForm.buyers.forEach((buyerId) => {
      const client = buyers.find(({ Id }) => Id.toString() === buyerId);
      buyersNames.push({ Name: client!.Name, Id: client!.Id.toString() });
    });
    dataForm.collaborators.forEach((collaboratorId) => {
      const client = collaborators.find(
        ({ RoleId }) => RoleId.toString() === collaboratorId,
      );
      collaboratorsNames.push({
        Description: client!.Description,
        RoleId: client!.RoleId.toString(),
      });
    });

    if (dataForm.link) {
      booleanArray.push({
        label: 'advPlanRequestsHeaderTable.OrderId',
        fieldName: link,
      });
    }
    dataForm.plugin &&
      booleanArray.push({
        label: 'menu.onlineShop',
        fieldName: plugin,
      });
  }

  //Carousel behaviour
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX;
    if (walk > 20 || walk < -20) {
      if (!isScrolling) setIsScrolling(true);
    }
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const handleLeave = () => {
    setIsDragging(false);
    setIsScrolling(false);
  };

  const resetFilter = () => {
    reset();
    handleClick(initialData);
  };

  if (
    buyersNames.length === 0 &&
    collaboratorsNames.length === 0 &&
    booleanArray.length === 0 &&
    !(dataForm.startDate || dataForm.endDate)
  )
    return <></>;

  return (
    <FilteredContainer
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleLeave}
      onMouseLeave={handleLeave}
    >
      {(dataForm.startDate || dataForm.endDate) && (
        <FilteredButtonMonitoring
          disabled={isScrolling}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          fromDate={dataForm.startDate}
          toDate={dataForm.endDate}
        />
      )}
      {buyersNames.map(({ Name, Id }) => (
        <FilteredButtonMonitoring
          disabled={isScrolling}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          key={Id}
          filteredName={Name}
          valueId={Id}
          fieldName={'buyers'}
        />
      ))}
      {collaboratorsNames.map(({ Description, RoleId }) => (
        <FilteredButtonMonitoring
          disabled={isScrolling}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          key={RoleId}
          filteredName={Description}
          valueId={RoleId}
          fieldName={'collaborators'}
        />
      ))}
      {booleanArray.map(({ label, fieldName }, i) => (
        <FilteredButtonBooleanMonitoring
          disabled={isScrolling}
          label={label}
          fieldName={fieldName}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          key={i}
        />
      ))}
      <Button
        minWidthMobile="max-content"
        minWidth="max-content"
        variant="TextButton"
        height="3.6rem"
        onClick={resetFilter}
      >
        <Translate id="lbl.deleteAll" />
      </Button>
    </FilteredContainer>
  );
};

export default FilteredListMonitoring;
