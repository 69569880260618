import { Button } from 'src/components/Button/Button.component';
import { IconButton } from 'src/components/Button/IconButton.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { BuyerStats } from 'src/services/monitoring/monitoring.types';
import { PSmall } from 'src/style-utils/typographic';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Star } from 'src/images/icons/star.svg';
import { Flex } from 'src/style/flexbox.style';

type field = keyof BuyerStats;
const ValueRenderer = (field: field) => {
  return function ValueRendered(props: BuyerStats) {
    return <PSmall colorBlack>{props[field]}</PSmall>;
  };
};

export const buyersTableColumnConfig = [
  {
    id: 'Name',
    header: 'lbl.businessName',
    RenderComponent: ValueRenderer('Name'),
    rowCellConfig: {
      w: '100%',
      minWDesktop: '33%',
    },
  },
  {
    id: 'TotalAmount',
    header: 'lbl.totalSales',
    RenderComponent: (props: BuyerStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return <PSmall colorBlack>{formatCurrency(props.TotalAmount)}</PSmall>;
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'InstallmentsCreated',
    header: 'text.toPay',
    RenderComponent: (props: BuyerStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <>
          <Flex>
            <PSmall colorBlack>
              {formatCurrency(props.InstallmentsCreated)}
            </PSmall>

            {props.InstallmentsProcessing > 0 && (
              <Icon
                svgIconComponent={Star}
                margin="auto 0"
                iconSize="1.6rem"
                iconHeight="1.6rem"
                marginLeft="0.4rem"
                color="black"
              />
            )}
          </Flex>
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'InstallmentsOverdue',
    header: 'lbl.overdue',
    RenderComponent: (props: BuyerStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <PSmall className={props.InstallmentsOverdue > 0 ? 'text-danger' : ''}>
          {formatCurrency(props.InstallmentsOverdue)}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'OrdersCount',
    header: 'lbl.orders',
    RenderComponent: ValueRenderer('OrdersCount'),
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'cta',
    RenderComponent: (props: BuyerStats) => {
      const navigate = useNavigate();
      return (
        <>
          <IconButton
            onClick={() => navigate(RoutePath.monitoringDetail + props.Guid)}
            className="hideOnMobile"
            IconSrc={ArrowRight}
            variant="LinkPrimary"
            sizeType="medium"
          />
          <Button
            onClick={() => navigate(RoutePath.monitoringDetail + props.Guid)}
            className="hideOnDesktop"
            variant="Tertiary"
            minWidthMobile="100%"
            margin="1.6rem 0 0 0"
            i18n
            translatedText="lbl.buyerDetail"
          />
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
      maxWDesktop: '68px',
    },
  },
];
