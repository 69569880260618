import Translate from 'src/components/Translate/Translate.component';
import { ListTableRemodulationRequests } from 'src/pages/remodulationRequests/RemodulationRequests.types';
import React from 'react';
import colors from 'src/style-utils/colors';
import { P, PSmall } from 'src/style-utils/typographic';

const OrderIdColumn: React.FC<ListTableRemodulationRequests> = ({
  OrderId,
  BuyerName,
}) => {
  return (
    <div>
      <PSmall textColor={colors.textTableLightBody}>
        <Translate id="remodulationRequestTableColumn.OrderId" />
        {OrderId}
      </PSmall>
      <P textColor={colors.textTableBody}>{BuyerName}</P>
    </div>
  );
};

export default OrderIdColumn;
