import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import { MethodsContextType } from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { ChangeMethodFormData } from 'src/services/payments/payments.types';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

function SetBackupMethodPage() {
  const {
    paymentMethods,
    backupMethod,
    setBackupHandler,
    goToStripeFormHandler,
  } = useOutletContext<MethodsContextType>();

  const methods = useForm({
    defaultValues: {
      paymentMethodId: backupMethod ? backupMethod.Id : '',
    } as ChangeMethodFormData,
    mode: 'onSubmit',
  });

  const onSubmit = (values: ChangeMethodFormData) => {
    setBackupHandler({ BackupPaymentMethodId: +values.paymentMethodId });
  };

  const filteredPaymentMethods = paymentMethods.filter(
    (item) => item.Type === 'card',
  );

  if (filteredPaymentMethods.length === 0) {
    goToStripeFormHandler(true);
  }

  return (
    <div className="container pb-8">
      <GoBackButton />
      <div className="mx-auto max-w-oxl">
        <div className="mx-auto grid max-w-olg gap-4 lg:text-center">
          <H4>
            <Translate id="text.changePaymentMethod" />
          </H4>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex
              flexDirection="column"
              align="center"
              gap="2.4rem"
              padding="2.4rem 0"
            >
              {filteredPaymentMethods &&
                filteredPaymentMethods.map((payCard, i: number) => {
                  return <PaymentMethodElement key={i} {...payCard} />;
                })}
            </Flex>
            <DividerOr />
            <ButtonCard
              createPaymentIntentHandler={() => goToStripeFormHandler(true)}
              type={'change'}
            />
            <ButtonContainer marginTop="4rem">
              <Button
                variant="Primary"
                sizeOnDesktop="medium"
                type="button"
                minWidth="100%"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={!methods.formState.isValid}
              >
                <Translate id={'lbl.confirm'} />
              </Button>
            </ButtonContainer>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default SetBackupMethodPage;
