import { Resolver } from 'react-hook-form';
import { minNumber } from 'src/utils/validation/numberInRange';
import { requiredCustomMessage } from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

import {
  flexAmount,
  flexDate,
} from 'src/components/FlexInstallmentCard/FlexInstallmentCard.config';
import { validateFlexDate } from 'src/utils/validation/date';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

export const formConfigFlexInstallmentPayByLink = (
  numRate: number,
  ArrayInstallments: any,
  remodulationFlow: boolean,
) => {
  const initialValues: { [k: string]: any } = {};
  if (ArrayInstallments) {
    for (let i = 0; i < ArrayInstallments.length; i++) {
      const keyAmount = flexAmount + `-${i}`;
      const keyDate = flexDate + `-${i}`;
      initialValues[keyAmount] = ArrayInstallments[i].Amount;
      initialValues[keyDate] = refactorFormatDate(
        ArrayInstallments[i].Expiration,
        'yyyy-MM-DD',
      );
    }
  } else {
    for (let i = 0; i < numRate; i++) {
      const keyAmount = flexAmount + `-${i}`;
      const keyDate = flexDate + `-${i}`;
      initialValues[keyAmount] = '';
      initialValues[keyDate] = '';
    }
  }

  const validate = (values: any) => {
    const validateValues: { [k: string]: any } = {};
    for (let i = 0; i < numRate; i++) {
      const keyAmount = flexAmount + `-${i}`;
      const keyDate = flexDate + `-${i}`;
      validateValues[keyAmount] = validateFieldSync(
        requiredCustomMessage,
        minNumber({ min: 5 }),
      );
      validateValues[keyDate] = validateFieldSync(
        requiredCustomMessage,
        validateFlexDate(
          i > 0 ? values[`${flexDate}-${i - 1}`] : null,
          remodulationFlow,
        ),
      );
    }
    return validateFormSync(validateValues)(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};

export function findMinMax(
  arrayTransazioni: {
    Expiration: string;
    Amount: number;
  }[],
) {
  let min = arrayTransazioni[0].Amount;
  let max = arrayTransazioni[0].Amount;
  for (let i = 1; i < arrayTransazioni.length; i++) {
    const num = arrayTransazioni[i].Amount;
    if (num < min) {
      min = num;
    }
    if (num > max) {
      max = num;
    }
  }
  return { min, max };
}
