import { DataFormBalanceMovements } from 'src/pages/balanceMovementsPage/BalanceMovementsPage.types';
import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { movementsForMerchantEndPoint } from './movementsForMerchant.config';

type MovementsType = {
  Date: string;
  Type: string;
  Amount: number;
  BuyerName: string;
  Id: number;
  OrderGuid: string;
  INo: number;
  Advance: boolean;
};

export type ResultMovementsType = {
  Movements: MovementsType[];
  MovementsCount: number;
  Incassi: number;
  Costi: number;
  Prelievi: number;
};

export const movementsForMerchantMethodApi = async (
  data: DataFormBalanceMovements,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: movementsForMerchantEndPoint,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: ResultMovementsType }>(
    requestConfig,
  );
};
