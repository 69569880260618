import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  fidoEndPoint,
  guidField,
  guidOrderField,
  ipAddressField,
} from './fido.config';

export const fidoMethodApi = async (data: {
  guid: string;
  guidOrder: string;
  ipAddress: string;
}) => {
  const requestConfig = makeEndpointApiConfig({
    url: fidoEndPoint,
    method: 'POST',
    data: {
      guid: data[guidField],
      guidOrder: data[guidOrderField],
      ipAddress: data[ipAddressField],
    },
  });
  return await apiRequest.request<{ ResultSet: boolean }>(requestConfig);
};
