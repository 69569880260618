import Translate from 'src/components/Translate/Translate.component';
import { H3, H5 } from 'src/style-utils/typographic';
import {
  CircleHomeStepper,
  IconHomeStepper,
  RowHomeStepper,
} from './style/homeStepper.style';

interface Props {
  stepperArray: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    title: string;
    subTitle: string;
  }[];
}

const HomeStepper: React.FC<Props> = ({ stepperArray }: Props) => {
  return (
    <div>
      {stepperArray.map(({ title, subTitle, icon }, index) => (
        <RowHomeStepper key={index}>
          <CircleHomeStepper>
            <div>{index + 1}</div>
          </CircleHomeStepper>
          <IconHomeStepper svgIconComponent={icon} />
          <H3>
            <Translate id={title} />
          </H3>
          <H5 light colorGray>
            <Translate id={subTitle} />
          </H5>
        </RowHomeStepper>
      ))}
    </div>
  );
};

export default HomeStepper;
