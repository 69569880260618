import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { updateNewValueForDataChangeMethodApi } from 'src/services/dataChange/dataChange.request';
import { emailField } from 'src/services/registration/registration.config';
import { H4, P } from 'src/style-utils/typographic';
import { FormStyle } from 'src/style/styleInput.style';
import { DataChangeContext } from '../../ChangeAccessData.types';
import { formConfigMail } from './ChangeMail.helpers';

function ChangeEmail() {
  const { initialValues, resolver } = formConfigMail();
  const { sessionGuid, type } = useOutletContext<DataChangeContext>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { mutate, isLoading } = useMutation(
    updateNewValueForDataChangeMethodApi,
    {
      onSuccess: (res, variables) => {
        if (res.data.ResultSet)
          navigate(
            RoutePath.changeAccessData +
              RoutePath.performOtp +
              `?sessionGuid=${sessionGuid}`,
            {
              state: {
                type,
                value: variables.NewValue,
              },
            },
          );
      },
      onError: () => {
        setErrorMessage('error.emailAlreadyExists');
      },
    },
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = async (data: any) => {
    const dataEmail = {
      NewValue: data[emailField],
      sessionGuid,
    };
    mutate({ ...dataEmail });
  };
  return (
    <>
      <H4>
        <Translate id="text.changeEmail" />
      </H4>
      <P marginBottom="2rem">
        <Translate id="lbl.newEmailAddress" />
      </P>
      <FormProvider {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
          <InputField label="lbl.emailAddress" name={emailField} />
          <GenericErrorForm isPresentMessage errorMessageID={errorMessage} />
          <ButtonContainer>
            <SubmitButton minWidth="100%" disabled={isLoading}>
              <Translate id="text.continue" />
            </SubmitButton>
          </ButtonContainer>
        </FormStyle>
      </FormProvider>
    </>
  );
}

export default ChangeEmail;
