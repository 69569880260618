import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const LinkUrlGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 0fr;
  border-radius: 0.4rem;
  outline: 1px solid ${colors.lightGrey};
  overflow: hidden;
  align-items: center;
  margin: 0.8rem 0;
  height: 5.2rem;
  > div {
    padding: 1.6rem;
    background: white;
    height: 100%;
    border-right: 1px solid ${colors.lightGrey};
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
