import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { CheckContainer } from './style/MoveDetailCheckboxBoolean.style';

type MoveDetailCheckBooleanType = {
  formFieldName: string;
  titleId: string;
  subtitleId: string;
  fullWidth?: boolean;
};

const MoveDetailCheckboxBoolean = ({
  formFieldName,
  titleId,
  subtitleId,
  fullWidth = false,
}: MoveDetailCheckBooleanType) => {
  const { setValue, getValues } = useFormContext();
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    setChecked(!checked);
    setValue(formFieldName, !checked);
  };
  const value = getValues(formFieldName);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <CheckContainer
      data-component="move-detail-checkbox-boolean"
      onClick={handleClick}
      checked={checked}
      fullWidth={fullWidth}
    >
      <Flex flexDirection="column">
        <P bold className={checked ? 'text-pblack-400' : ''}>
          <Translate id={titleId} />
        </P>
        <PSmall>
          <Translate id={subtitleId} />
        </PSmall>
      </Flex>
    </CheckContainer>
  );
};

export default MoveDetailCheckboxBoolean;
