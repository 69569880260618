import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SelectCountry from 'src/components/SelectCountry/SelectCountry.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { getCompanySearchCriteriaMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';

function StepCountry() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const country = usePayByLinkStore((state) => state.country);
  const hasOrders = useProfileStore((state) => state.HasOrders);
  const { categoryCode, productCode } = useParams();
  const methods = useForm({
    defaultValues: {
      country: country?.length > 0 ? country.toUpperCase() : 'IT',
    },
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(getCompanySearchCriteriaMethodApi, {
    onSuccess: (res) => {
      const { country, name, vatNumber, postalCode } = res.data.ResultSet;
      const vatPrefix = res.data.ResultSet?.vatPrefix;
      setValue('country', country);
      setValue('validation', { name, vatNumber, postalCode, vatPrefix });
      setValue('buyerFiscalCode', '');
      setValue('postalCode', '');
      setValue('buyerName', '');
      setValue('results', []);

      if (vatNumber.isAvailable) {
        navigate(
          `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepVAT}`,
        );
        return;
      }
      if (postalCode.isAvailable) {
        navigate(
          `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepNamePostalCode}`,
        );
        return;
      }
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepName}`,
      );
    },
  });

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    if (isItalian(data['country'])) {
      setValue('country', data['country']);
      navigate(urlRedirectToFlow);
      return;
    }
    mutate({ country: data['country'] });
  };
  return (
    <>
      <PayByLinkHeader
        stepBack
        stepBackHandler={() => navigate(hasOrders ? -1 : -2)}
        step={step}
        stepMax={stepMax}
        title="lbl.customerNationality"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.customerNationality" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <SelectCountry />
              <ButtonContainer>
                <Button i18n translatedText="lbl.next" />
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
}

export default StepCountry;
