import FormFlag from 'src/pages/registration/partials/FormFlag/FormFlag.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationStepFlag: React.FC = () => {
  return (
    <FormFlag
      typeAccount="MERCHANT"
      nextPath={RoutePath.continueRegistration}
      step={9}
      stepMax={9}
    />
  );
};

export default RegistrationStepFlag;
