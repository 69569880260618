import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const CustomCard = styled.div`
  border-radius: 1.6rem;
  box-shadow: 0px 2px 4px 1px ${colors.cardShadowBlue};
  overflow: hidden;
  background-color: #fff;
  padding: 0;
  margin-top: 1.6rem;
  .cardFooter {
    display: flex;
    padding: 1.6rem 0;
    height: fit-content;
    background-color: #f5faff;
  }
`;

export const ContractCounter = styled.div`
  color: ${colors.textP};
  font-size: 2.5rem;
  font-weight: 400;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 99rem;
  outline: 1.5px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
`;
