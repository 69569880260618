import { Resolver } from 'react-hook-form';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import { emailField } from 'src/services/registration/registration.config';
import email from 'src/utils/validation/email';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigAddCollaborator = () => {
  const initialValues = {
    [emailField]: '',
    [descriptionField]: '',
  };

  const validate = validateFormSync({
    [emailField]: validateFieldSync(required, email),
    [descriptionField]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
