import { Resolver } from 'react-hook-form';
import required from 'src/utils/validation/required';
import { validateFormSync } from 'src/utils/validation/validate-generators';
import { templateNameField } from './SaveTemplate.component';

export const formConfigSaveTemplate = () => {
  const initialValues = {
    [templateNameField]: '',
  };

  const validate = validateFormSync({
    [templateNameField]: required,
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
