import { cva } from 'class-variance-authority';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Check } from 'src/images/icons/check_icon.svg';
import { memo } from 'react';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H5, PSmall } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

type ContentProps = {
  NumRata: number | string;
  date: string;
  amount: number;
  checked: boolean;
};

type Props = {
  index: number;
  checkedId: number;
  hoveredId: number;
  onMouseEnter?: () => void;
  onClick?: () => void;
  NumRata: number | string;
  date: string;
  amount: number;
};

const cardVariants = cva(
  'group relative z-0 flex cursor-pointer items-center justify-between overflow-hidden rounded-lg bg-white p-4 shadow-md outline outline-1  transition-shadow duration-500 after:absolute after:right-0  after:top-1/2 after:-z-10 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out data-[checked=true]:outline-pgrey lg:data-[hovered=true]:shadow-lg lg:data-[hovered=true]:after:w-[210%]',
  {
    variants: {
      status: {
        checked: 'text-pblack-400 outline-pgrey',
        disabled: 'cursor-not-allowed text-pgreyoutlined outline-pgrey',
        default: 'text-pgreyoutlined outline-pgreyoutlined',
      },
    },
    defaultVariants: {
      status: 'default',
    },
  },
);

const CardContent = memo(function CardContent({
  NumRata,
  date,
  amount,
  checked,
}: ContentProps) {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <>
      <div className="flex grow items-center gap-2">
        <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full border-[0.5rem] border-pgreen-400 bg-white lg:h-14 lg:w-14">
          <H5>{NumRata}</H5>
        </div>
        <div className="flex flex-col gap-1">
          <PSmall>
            <Translate id="text.dueDate" />{' '}
          </PSmall>
          <H5 className="line-clamp-1 break-all">
            {refactorFormatDate(date, 'DD MMMM YYYY')}
          </H5>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-2 lg:gap-4">
        <H5>{formatCurrency(amount)}</H5>
        <div
          className={
            'm-2 h-4 w-4 rounded border border-current transition-colors lg:m-3 lg:h-6 lg:w-6 lg:border-2 ' +
            (checked ? 'bg-current' : 'bg-white')
          }
        >
          <Check className="text-white" />
        </div>
      </div>
    </>
  );
});

const InstallmentCardCheckbox: React.FC<Props> = ({
  index,
  checkedId,
  hoveredId,
  onMouseEnter,
  onClick,
  NumRata,
  date,
  amount,
}) => {
  const checked = index <= checkedId;
  const hovered = index <= hoveredId;
  const disabled = index === 0;
  return (
    <div
      data-component="installment-card-checkbox"
      className="pb-4 lg:pb-6"
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div
        className={cardVariants({
          status: disabled ? 'disabled' : checked ? 'checked' : 'default',
        })}
        data-hovered={hovered && !disabled}
      >
        <CardContent
          NumRata={NumRata}
          date={date}
          amount={amount}
          checked={checked}
        />
      </div>
    </div>
  );
};

export default InstallmentCardCheckbox;
