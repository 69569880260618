import React, { memo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
// import { formErrorKey } from 'src/services/forms/forms.config';
import Translate from 'src/components/Translate/Translate.component';
import { LabelInput } from 'src/style/styleInput.style';

import { onlyNumber } from 'src/utils/validation/number';
import {
  // OtpFormValue,
  // otpInput1,
  // otpInput2,
  // otpInput3,
  // otpInput4,
  otpInputString,
} from './Otp.config';
import SingleInput from './OtpSingleInput.component';

export interface OTPInputProps {
  label?: string;
  length: number;
  // onChangeOTP: (otp: string) => any;
  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
}

export function Otp(props: OTPInputProps) {
  const { length, autoFocus, isNumberInput, disabled, label } = props;
  const { getValues, setValue, trigger } = useFormContext();

  // const hasFormError = errors && errors[formErrorKey];

  const [activeInput, setActiveInput] = useState(0);

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;
      if (!isNumberInput) {
        return changedValue;
      }
      return !changedValue || /\d/.test(changedValue) ? changedValue : '';
    },
    [isNumberInput],
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      setValue(`oi-${activeInput + 1}` as otpInputString, str[0] || '');
    },
    [activeInput, setValue],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      if (inputIndex === length) {
        setActiveInput(0);
        return;
      }
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!onlyNumber.test(e.currentTarget.value)) {
        e.currentTarget.value = '';
        return;
      }
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
      trigger();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Auto submit on 4 values
  // useEffect(() => {
  //   if (
  //     getValues(otpInput1) !== '' &&
  //     getValues(otpInput2) !== '' &&
  //     getValues(otpInput3) !== '' &&
  //     getValues(otpInput4) !== ''
  //   ) {
  //     focusInput(-1);
  //   }
  // }, [focusInput,  getValues()]);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (getValues(`oi-${activeInput + 1}` as otpInputString)) {
            changeCodeAtFocus('');
            focusPrevInput();
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, getValues],
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        Object.keys(getValues()).forEach((val: string, index: number) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || '');
            if (changedValue) {
              nextFocusIndex = index;
              setValue(`oi-${index + 1}` as otpInputString, changedValue);
            }
          }
        });
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
        trigger();
      }
    },
    [activeInput, getRightValue, length, setValue, getValues],
  );

  return (
    // TODO fix hasFormError in isFormErrorPresent
    <>
      {label && (
        <LabelInput>
          <Translate id={label} />
        </LabelInput>
      )}
      <div
        data-component="otp"
        className="flex gap-2 text-[2.6rem]"
        data-error={false}
      >
        {Array(length)
          .fill('')
          .map((_, index) => (
            <SingleInput
              placeholder="000"
              name={`oi-${index + 1}`}
              id={`oi-${index + 1}`}
              key={`SingleInput-${index}`}
              focus={activeInput === index}
              value={getValues(`oi-${index + 1}` as otpInputString)}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              disabled={disabled}
              {...props}
            />
          ))}
      </div>
    </>
  );
}

const OTPInput = memo(Otp);
export default OTPInput;
