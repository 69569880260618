import Icon from 'src/components/Icon/Icon.component';
import StatusPayByLink from 'src/components/StatusPayByLink/StatusPayByLink.component';
import type { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right_white_bg.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { H5, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  ClientInfoCardBody,
  ClientInfoCardContainer,
  ClientInfoCardHeader,
} from './style/ClientInfoCard.style';
import { RoutePath } from 'src/routers/routers.config';
import { pblDetailPageArray } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.config';
import { PayByLinkStatus } from '../StatusPayByLink/StatusPayByLink.config';

export interface ClientInfoCardProps {
  dueDate?: string;
  installmentExpiration?: string;
  installmentPayment?: string;
  statusLink: StatusPyByLinkValues;
  clientId: string | number;
  clientName: string;
  totalAmount: string | number;
  madeFromPlugin: boolean;
  pluginId?: string;
}

const ClientInfoCard = ({
  dueDate,
  statusLink,
  clientName,
  totalAmount,
  clientId,
  madeFromPlugin,
  pluginId,
  installmentExpiration,
  installmentPayment,
}: ClientInfoCardProps) => {
  const navigate = useNavigate();
  const label =
    pblDetailPageArray[
      madeFromPlugin.toString() as keyof typeof pblDetailPageArray
    ];
  const [datePart, timePart] = dueDate ? dueDate.split(' ') : ['', ''];
  return (
    <ClientInfoCardContainer
      data-component="client-info-card"
      statusLink={statusLink}
    >
      <ClientInfoCardHeader align="center" padding="1.2rem">
        {((dueDate && !madeFromPlugin) ||
          (madeFromPlugin && installmentExpiration) ||
          (madeFromPlugin && installmentPayment)) && (
          <PSmall>
            {dueDate &&
              !madeFromPlugin &&
              (statusLink === PayByLinkStatus.createdLink ? (
                <div style={{ marginRight: '16px' }}>
                  <span>
                    <Translate id="text.dueDate" />
                    {': '}
                    <b>
                      {datePart} <Translate id="text.time" /> {timePart}
                    </b>
                  </span>
                </div>
              ) : (
                <b style={{ marginRight: '16px' }}>{dueDate}</b>
              ))}
            {madeFromPlugin && (
              <b style={{ marginRight: '16px' }}>
                {installmentExpiration ?? installmentPayment}
              </b>
            )}
          </PSmall>
        )}
        <div>
          <StatusPayByLink statusLink={statusLink} />
        </div>
      </ClientInfoCardHeader>
      <ClientInfoCardBody
        align="center"
        padding="1.4rem 1.6rem"
        justify="space-between"
        gap="1.2rem"
      >
        <Flex flexDirection="column">
          <PSmall>
            <Translate
              id={label.clientCard.name}
              data={{
                pluginId: pluginId && pluginId.toString().padStart(5, '0'),
              }}
            />
          </PSmall>
          <H5>{clientName}</H5>
        </Flex>
        <Flex flexDirection="column">
          <PSmall>
            <Translate id="text.import" />
          </PSmall>
          <H5>{totalAmount}</H5>
        </Flex>
        <Icon
          onClick={() =>
            navigate(
              `${
                madeFromPlugin
                  ? RoutePath.linkOnlineDetail
                  : RoutePath.linkDiPagamentoDetail
              }${clientId}/info`,
            )
          }
          className="shrink-0"
          iconSize={'4rem'}
          iconHeight={'4rem'}
          svgIconComponent={ArrowRight}
        />
      </ClientInfoCardBody>
    </ClientInfoCardContainer>
  );
};
export default ClientInfoCard;
