import { ReactComponent as PositiveFeed } from 'src/images/icons/_positive-feed.svg';
import { ReactComponent as Credit } from 'src/images/icons/credit_assigned.svg';
import { ReactComponent as AlertIconSrc } from 'src/images/icons/alert_orange_tiny.svg';

export enum ResultUW {
  lightOK = 'LIGHT_OK',
  ok = 'OK',
}

interface ResultCard {
  subtitle: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  variant: 'success' | 'error' | 'warning' | 'info';
}

interface ResultMappingItem {
  resultCard: ResultCard;
  advPaymentCard: ResultCard;
}

interface IResultMapping {
  [0]: ResultMappingItem;
  [1]: ResultMappingItem;
  [2]: ResultMappingItem;
}

export const ResultMapping: IResultMapping = {
  0: {
    resultCard: {
      subtitle: 'text.UWOkSubTitle',
      icon: PositiveFeed,
      variant: 'success',
    },
    advPaymentCard: {
      subtitle: 'text.UWOkCreditSubTitle',
      icon: Credit,
      variant: 'success',
    },
  },
  1: {
    resultCard: {
      subtitle: 'text.UWOkSubTitle',
      icon: PositiveFeed,
      variant: 'success',
    },
    advPaymentCard: {
      subtitle: 'text.UWKoCreditSubTitle',
      icon: Credit,
      variant: 'info',
    },
  },
  2: {
    resultCard: {
      subtitle: 'text.UWKoSubTitle',
      icon: AlertIconSrc,
      variant: 'warning',
    },
    advPaymentCard: {
      subtitle: 'text.flagCreditNotAvailableUWLightOk',
      icon: Credit,
      variant: 'warning',
    },
  },
};
