import styled, { css } from 'styled-components';
import colors from './colors';
import { withMargin, WithMarginProps } from './dimensionsMixins';
import media from './media-queries';
import { CommonTextProps, TextAlignProps, withTextAlign } from './text';

type commonHStylesProps = {
  colorGray?: boolean;
  light?: boolean;
} & CommonTextProps &
  WithMarginProps &
  TextAlignProps;

type commonPStylesProps = {
  colorBlack?: boolean;
  bold?: boolean;
} & CommonTextProps &
  WithMarginProps &
  TextAlignProps;

const commonHStyles = css<commonHStylesProps>`
  ${withTextAlign}
  ${(props) => (props.textColor ? `color: ${props.textColor};` : null)}
  ${(props) =>
    props.lineHeight ? `line-height: ${props.lineHeight}!important;` : null}
  ${(props) => (props.light ? 'font-weight: 400;' : null)}
  ${(props) => (props.colorGray ? `color: ${colors.textP};` : null)}
  ${withMargin}
`;
export const H1 = styled.h1<commonHStylesProps>`
  ${commonHStyles}
  font-size: 4.2rem;
  line-height: 6rem;
  ${media.tablet`
    font-size: 9rem;
    line-height: 12rem;
  `}
`;
export const H2 = styled.h2<commonHStylesProps>`
  ${commonHStyles}
  font-size: 3.4rem;
  line-height: 5.2rem;
  ${media.tablet`
    font-size: 6.4rem;
    line-height: 9rem;
  `}
`;
export const H3 = styled.h3<commonHStylesProps>`
  ${commonHStyles}
  font-size: 2.8rem;
  line-height: 4.4rem;
  ${media.tablet`
    font-size: 4.6rem;
    line-height: 6.6rem;
  `}
`;
export const H4 = styled.h4<commonHStylesProps>`
  ${commonHStyles}
  font-size: 2.2rem;
  line-height: 3.4rem;
  ${media.tablet`
    font-size: 3.2rem;
    line-height: 4.8rem;
  `}
`;
export const H5 = styled.h5<commonHStylesProps>`
  ${commonHStyles}
  font-size: 1.8rem;
  line-height: 2.8rem;
  ${media.tablet`
    font-size: 2.4rem;
    line-height: 3.6rem;
  `}
`;

const commonPStyles = css<commonPStylesProps>`
  ${(props) => (props.textColor ? `color: ${props.textColor};` : null)}
  ${(props) =>
    props.lineHeight ? `line-height: ${props.lineHeight}!important;` : null}
  ${(props) => (props.bold ? 'font-weight: 700;' : null)}
  ${(props) =>
    props.textTransform ? `text-transform: ${props.textTransform};` : null}
  ${(props) => (props.colorBlack ? `color: ${colors.textColorBlack};` : null)}
  ${withMargin}
  ${withTextAlign}
`;
export const P = styled.p<commonPStylesProps>`
  ${commonPStyles}
  font-size: 1.4rem;
  line-height: 2.4rem;
  ${media.tablet`
    font-size: 1.6rem;
  `}
`;
export const PSmall = styled.p<commonPStylesProps>`
  ${commonPStyles}
  font-size: 1.2rem;
  line-height: 2rem;
  ${media.tablet`
    font-size: 1.4rem;
    line-height: 2.2rem;
  `}
`;
export const PLegal = styled.p<commonPStylesProps>`
  ${commonPStyles}
  font-size: 1.2rem;
  line-height: 1.8rem;
`;
export const PNote = styled.p<commonPStylesProps>`
  ${commonPStyles}
  font-size: 1rem;
  line-height: 1.6rem;
`;
