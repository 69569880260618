import { InstallmentStats } from 'src/services/monitoring/monitoring.types';
import colors from 'src/style-utils/colors';
import { PSmall } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { Circular } from './tableInstallments.style';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import Translate from 'src/components/Translate/Translate.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { TableColumn } from 'src/components/DataTable/DataTable.types';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Star } from 'src/images/icons/star.svg';
import { Flex } from 'src/style/flexbox.style';

export const installmentsTableColumnConfig: TableColumn<any>[] = [
  {
    id: 'INo',
    header: 'text.installmentNumber',
    RenderComponent: (props: InstallmentStats) => {
      return (
        <div
          style={{
            position: 'relative',
          }}
        >
          <Circular borderColor={colors.primaryGreen}>{props.INo}</Circular>
        </div>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'FirstInstallmentDate',
    header: 'text.rataPlanning',
    RenderComponent: (props: InstallmentStats) => {
      return (
        <PSmall colorBlack>
          {props.INo} <Translate id="lbl.of" /> {props.OrderInstallmentsCount}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'Expiration',
    header: 'lbl.payDate',
    RenderComponent: (props: InstallmentStats) => {
      return (
        <PSmall colorBlack>
          {refactorFormatDate(props.Expiration, 'DD/MM/YYYY')}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },

  {
    id: 'Amount',
    header: 'installment.amount',
    RenderComponent: (props: InstallmentStats) => {
      const expired = props.Status === PayByLinkStatus.expiredLink;
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <>
          <Flex>
            <PSmall
              bold
              className={expired ? 'text-danger' : 'text-pblack-400'}
              data-info={expired ? 'danger' : undefined}
            >
              {formatCurrency(props.Amount)}
            </PSmall>
            {props.Status === 4 && (
              <Icon
                svgIconComponent={Star}
                margin="auto 0"
                iconSize="1.6rem"
                iconHeight="1.6rem"
                marginLeft="0.4rem"
                color="black"
              />
            )}
          </Flex>
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'OrderId',
    header: 'lbl.orderNumber',
    RenderComponent: (props: InstallmentStats) => {
      return (
        <PSmall colorBlack>#{props.OrderId.toString().padStart(5, '0')}</PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
];
