import Translate from 'src/components/Translate/Translate.component';
import { ListTableAdvPlanRequests } from 'src/pages/advancePlanRequests/AdvancePlanRequests.types';
import React from 'react';
import colors from 'src/style-utils/colors';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

const OrderIdColumn: React.FC<ListTableAdvPlanRequests> = ({
  OrderId,
  BuyerName,
}) => {
  return (
    <Flex fluid justify="flex-start" flexDirection="column">
      <PSmall textColor={colors.textTableLightBody}>
        <Translate id={'advPlanRequestTableColumn.OrderId'} />
        {OrderId}
      </PSmall>
      <P textColor={colors.textTableBody}>{BuyerName}</P>
    </Flex>
  );
};

export default OrderIdColumn;
