import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { initOrderCreateMethodApi } from 'src/services/orderLink/orderLink.request';
import { Buyer } from 'src/services/orderLink/orderLink.types';
import { usePayByLinkStore } from 'src/store/store';
import { H5, P } from 'src/style-utils/typographic';
import { IDs } from 'src/utils/config/ids';
import { ReactComponent as BackRight } from 'src/images/icons/arrow_back_right.svg';

type Props = {
  info?: Buyer;
  path?: string;
};

const PayByLinkCustomerCard: React.FC<Props> = ({ info, path }) => {
  const { categoryCode, productCode } = useParams();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);

  const { mutate: mutateInit, isLoading: isLoadingInit } = useMutation(
    initOrderCreateMethodApi,
    {
      onSuccess: (res) => {
        setValue('uwId', res?.data?.ResultSet.UwId);
        setValue('sessionGuid', res?.data?.ResultSet.Guid);
        setValue('inviteEmail', res?.data?.ResultSet.LastInviteEmail);
        navigate(
          `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepImport}`,
        );
      },
    },
  );
  if (isLoadingInit) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onClickHandler = () => {
    setValue('guid', '');
    setValue('companySearchId', '');
    if (info) {
      setValue('buyerFiscalCode', info?.VatCode);
      setValue('buyerName', info?.Name);
      mutateInit({
        BuyerGuid: info?.Guid,
      });
    } else {
      navigate(path as never);
    }
  };

  return (
    <div
      data-component="pay-by-link-customer-card"
      className="group relative z-0 flex w-full items-center justify-between gap-2 rounded-xl bg-white p-4 shadow-md transition-shadow duration-500"
    >
      <div>
        {!info && (
          <H5>
            <Translate id="lbl.newCustomer" />
          </H5>
        )}
        {info && (
          <>
            <H5 className="mb-1 line-clamp-1 break-all">{info.Name}</H5>
            <P>
              <Translate id="lbl.cf" />
              <span className="font-bold">&nbsp;{info.VatCode}</span>
            </P>
          </>
        )}
      </div>
      <button
        className="peer ml-4 h-8 w-8 shrink-0 cursor-pointer rounded-full bg-white p-1 shadow lg:h-11 lg:w-11"
        id={!info ? IDs.btnNewBuyer : undefined}
        onClick={onClickHandler}
      >
        <BackRight />
      </button>
      <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden rounded-xl after:absolute after:right-0 after:top-1/2 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out peer-hover:after:w-[210%]" />
    </div>
  );
};

export default PayByLinkCustomerCard;
