import React from 'react';
import { countryCodesAvailable } from 'src/services/geo/availableCountries';
import getCountries from 'src/utils/functions/getCountries';
import { SupportedLanguageCode } from 'src/services/i18n/i18n.types';

export function useCreateOptions(lang: SupportedLanguageCode) {
  const countries = getCountries(lang);
  const filteredCountries = countries.filter((country) =>
    countryCodesAvailable.includes(country.code),
  );
  const options: ListboxOption[] = React.useMemo(() => {
    return filteredCountries.map((item) => {
      const prefixEntry: ListboxOption = {
        optionId: item.code,
        label: item.country_name,
      };
      return prefixEntry;
    });
  }, [filteredCountries]);

  return options;
}
