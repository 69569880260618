import colors from 'src/style-utils/colors';
import styled, { css } from 'styled-components';

export const AmountPillContainer = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: fit-content;
  border: 0.1rem solid ${colors.textColorBlack};
  padding: 0.8rem 1.2rem;
  border-radius: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  ${({ checked }) =>
    checked
      ? css`
          background-color: ${colors.textColorBlack};
          color: white;
        `
      : null}
`;
