import { ReactComponent as SecondStepIcon } from 'src/images/icons/installment-custom.svg';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PluginCheckoutResponse } from 'src/services/ecommerce/ecommerce.types';
import { Grid } from 'src/style/Container.style';
import StepCard from '../StepCard/StepCard.component';
import { InstallmentConfigForm } from './ChoosePlan.config';
import LegalInfos from './partials/LegalInfos.component';
import PlanElement from './partials/PlanElement.component';
import { useSession } from 'src/services/session/session.context';
import { useProfileStore } from 'src/store/store';
import { GetUwResult } from 'src/services/UWBuyer/UWBuyer.request';

type Props = {
  getPluginData?: PluginCheckoutResponse;
  mutateCreateOrder: any;
  checkoutSessionGuid: string;
  resultUw?: GetUwResult;
  isLoadingCreate: boolean;
};

const ChoosePlan = ({
  getPluginData,
  mutateCreateOrder,
  checkoutSessionGuid,
  resultUw,
  isLoadingCreate,
}: Props) => {
  const setCurrentOrder = useProfileStore((state) => state.setCurrentOrder);
  const { initialValues, resolver } = InstallmentConfigForm();
  const { auth } = useSession();
  const [expanded, setExpanded] = useState(0);
  const methods = useForm({
    defaultValues:
      initialValues.variant || !auth.authToken
        ? initialValues
        : { variant: getPluginData?.AvailablePlans?.[0].Code },
    resolver,
    mode: 'onChange',
  });

  const value = methods.watch('variant');
  useEffect(() => {
    if (resultUw?.ResultSet.Result === 'OK') {
      methods.handleSubmit(onSubmit)();
    }
  }, [value, resultUw?.ResultSet.Result]);

  const onSubmit = async (data: { variant: string }) => {
    const plan = getPluginData?.AvailablePlans?.find(
      (item) => item.Code === data.variant,
    );
    const currentOrder = {
      FirstInstallmentAmount: plan?.InstallmentAmount,
      NextInstallmentAmount: plan?.NumRate ?? 0 > 1 ? 999 : null,
      FirstInstallmentDueDate: plan?.NextInstallmentExpiration,
    };
    setCurrentOrder(currentOrder);

    mutateCreateOrder({
      variantCode: data.variant,
      amount: getPluginData?.OrderAmount,
      flagCredit: plan?.FlagCredit,
      currency: 'EUR',
      invoiceDate: new Date().toISOString(),
      checkoutSessionGuid,
    });
  };
  const plans = getPluginData?.AvailablePlans;
  const OrderAmount = getPluginData?.OrderAmount;

  return (
    <StepCard
      icon={SecondStepIcon}
      stepNumber={2}
      stepTitle="text.rataPlanning"
      checked={methods.formState.isValid}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid gap="1.6rem">
            {plans?.map((item, index) => (
              <PlanElement
                item={item}
                orderAmount={OrderAmount}
                key={index}
                disabled={!auth.authToken}
                expanded={expanded === index}
                setExpanded={() => setExpanded(expanded === index ? -1 : index)}
                setExpandedOnCheckbox={() => setExpanded(index)}
                isLoadingCreate={isLoadingCreate}
              />
            ))}
          </Grid>
        </form>
      </FormProvider>
      <LegalInfos sdd={getPluginData?.CanPayWithSDD} />
    </StepCard>
  );
};

export default ChoosePlan;
