import React from 'react';

// useHistory
import { useLocation, useNavigate } from 'react-router-dom';
import useIsFirstRender from 'src/utils/hooks/useIsFirstRender';
import { setHistory } from './routers.history';

export function useInjectBrowserHistory() {
  let history = useNavigate();
  const location = useLocation();
  const isFirstRender = useIsFirstRender();

  if (isFirstRender) {
    setHistory(location);
  }

  // We intentionally subscribe to `location` changes:
  // history is a mutable singleton hence it would not trigger changes.
  React.useLayoutEffect(() => {
    setHistory(location);
  }, [history, location]);
}

/**
 * Triggers a scroll to the top of the page of pathname change.
 * This feature is generally called scroll restoration.
 * This hook is meant to be a singleton inside a react-tree:
 * do not use it more than once!
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
export function useScrollToTopOnPathnameChange() {
  const { pathname } = useLocation();

  // We prefer to use layout effect in this case to render immediatly the
  // pages with the correct scroll position:
  // Subscription with `useEffect` causes an annoying jumping effect.
  React.useLayoutEffect(() => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, [pathname]);
}
