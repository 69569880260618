import Loader from 'src/components/Loader/Loader.component';
import { useQuery } from 'react-query';
import { GetUrlToSignContract } from 'src/services/user/user.config';
import { GetUrlToSignContractMethodApi } from 'src/services/user/user.request';

function LoadingNamirial() {
  const { refetch } = useQuery(
    GetUrlToSignContract,
    () => GetUrlToSignContractMethodApi(),
    {
      onSuccess: (res) => {
        if (res.data.ResultSet.ReadyToSign) {
          (window as any).open(res?.data?.ResultSet.Url, '_self');
        } else {
          setTimeout(() => {
            refetch();
          }, 3000);
        }
      },
    },
  );

  return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
}

export default LoadingNamirial;
