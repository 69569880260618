import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import { emailField } from 'src/services/registration/registration.config';
import { H4, PLegal } from 'src/style-utils/typographic';
import { formConfigAddCollaborator } from './AddCollaborator.helpers';
import {
  ContainerInput,
  FlowContainerCustom,
} from './style/addCollaborator.style';
import { createInviteRoleMethodApi } from 'src/services/collaborator/collaborator.request';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';

function AddCollaborator() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { mutate, isLoading } = useMutation(createInviteRoleMethodApi, {
    onSuccess: (res, variables) => {
      navigate(RoutePath.successAddCollaborator, {
        state: { email: variables.Email },
      });
    },
    onError: (error: any) => {
      const errorResponse = error.response as AxiosResponse;
      setErrorMessage(errorResponse.data.errorInfos.code);
    },
  });
  const onSubmit = (data: any) => {
    const dataInvite = {
      Role: 'collaborator',
      Email: data[emailField],
      Description: data[descriptionField],
    };
    mutate({ ...dataInvite });
  };
  const { initialValues, resolver } = formConfigAddCollaborator();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <FlowContainerCustom>
      <H4>
        <Translate id="menu.addCollaborator" />
      </H4>
      <H4 light>
        <Translate id="text.addCollaboratorSubtitle" />
      </H4>
      <div className="content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ContainerInput>
              <InputField label="lbl.emailAddressRequired" name={emailField} />
              <InputField
                label="lbl.collaboratorDescription"
                name={descriptionField}
              />
            </ContainerInput>
            {errorMessage && (
              <GenericErrorForm
                isPresentMessage
                errorMessageID={errorMessage}
              />
            )}
            <PLegal>
              <Translate id="text.fieldsRequired" />
            </PLegal>
            <ButtonContainer>
              <Button
                type="submit"
                variant="Primary"
                i18n={true}
                translatedText="text.sendEmail"
              />
            </ButtonContainer>
          </form>
        </FormProvider>
      </div>
    </FlowContainerCustom>
  );
}

export default AddCollaborator;
