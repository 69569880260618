import React, { useState } from 'react';
import Tabs from 'src/components/Tabs/Tabs.component';
import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import { useQuery } from 'react-query';
import { getLinksFilteredMethodApi } from 'src/services/linksFilteredData/linksFilteredData.request';
import { WrapperList } from './WrapperList.component';
import { DataFormPayByLink } from '../SearchCriteriaForm.helpers';
import Loader from 'src/components/Loader/Loader.component';
import { Navigate } from 'react-router';
import { skipField, takeLinks } from '../SearchCriteriaForm.config';
import { TabModel } from '../../payByLinkPage.types';
import {
  PayByLinkOrdersTabs,
  PayByLinkTabs,
} from '../../constants/tab.constants';
import { TabContext } from '../../context';

interface Props {
  madeFromPlugin: boolean;
  filters: DataFormPayByLink;
}

const TabsLinks: React.FC<Props> = ({ madeFromPlugin, filters }) => {
  const [tabsInstallments, setTabsInstallments] = useState<ObjectTab[]>([]);
  const [error, setError] = useState(false);
  const [currentPageByTab, setCurrentPageByTab] = useState<{
    [key: number]: number;
  }>({});
  const LinkTabs = madeFromPlugin ? PayByLinkOrdersTabs : PayByLinkTabs;

  const nextPage = async (index: number) => {
    if (index === -1) {
      setError(true);
      return;
    }

    const currentPage = (currentPageByTab[index] || 1) + 1;
    const currentTab = LinkTabs[index];
    const updatedTab = await fetchDataForTab(
      currentTab,
      (currentPage - 1) * takeLinks,
    );

    setTabsInstallments((prevTabs: ObjectTab[]) => {
      const newTabs = [...prevTabs];
      const existingTab = newTabs[index];

      if (existingTab && existingTab.content) {
        const newLinks = [
          ...(existingTab.content?.props?.links || []),
          ...updatedTab.Orders,
        ];
        const newLinksCount = updatedTab.OrdersCount || 0;
        newTabs[index] = {
          ...existingTab,
          tabCount: newLinksCount,
          content: (
            <WrapperList
              tabId={currentTab.id}
              index={index}
              links={newLinks}
              linksCount={newLinksCount}
              noTitle={currentTab.noTitle}
              noSubtitle={currentTab.noSubtitle}
              madeFromPlugin={madeFromPlugin}
              currentPage={currentPage}
            />
          ),
        };
      }

      return newTabs;
    });

    setCurrentPageByTab({ ...currentPageByTab, [index]: currentPage });
  };

  const fetchDataForTab = async (tab: TabModel, skip: number) => {
    const res = await getLinksFilteredMethodApi({
      ...filters,
      statuses: tab.statuses.map((status) => String(status)),
      [skipField]: skip,
    });

    return res.data.ResultSet;
  };

  const {
    data: tempTabs,
    isLoading,
    isError,
  } = useQuery<ObjectTab[]>(
    ['tabsLinks', filters],
    async () => {
      const fetchedTabs: ObjectTab[] = [];

      for (let i = 0; i < LinkTabs.length; i++) {
        const tab = LinkTabs[i];
        const tabData = await fetchDataForTab(tab, 0);
        fetchedTabs.push({
          tabTitle: tab.label,
          tabCount: tabData.OrdersCount || 0,
          content: (
            <WrapperList
              tabId={tab.id}
              index={i}
              links={tabData.Orders}
              linksCount={tabData.OrdersCount || 0}
              noTitle={tab.noTitle}
              noSubtitle={tab.noSubtitle}
              madeFromPlugin={madeFromPlugin}
              currentPage={1}
            />
          ),
        });
      }

      return fetchedTabs;
    },
    { enabled: true },
  );

  React.useEffect(() => {
    if (tempTabs && tempTabs.length > 0) {
      setTabsInstallments(tempTabs);
    }
  }, [tempTabs]);

  React.useEffect(() => {
    if (filters.skip === 0) {
      setCurrentPageByTab({});
    }
  }, [filters]);

  if (isError || error) {
    return <Navigate to="/oops" />;
  }

  return (
    <>
      {isLoading && (
        <Loader overlayViewMode="fullscreen" active viewMode="fluid" />
      )}
      {tabsInstallments.length > 0 && (
        <div className="py-10">
          <TabContext.Provider value={{ nextPage }}>
            <Tabs
              tabs={tabsInstallments}
              variant="Secondary"
              margin="0 auto 3.2rem"
            />
          </TabContext.Provider>
        </div>
      )}
    </>
  );
};

export default TabsLinks;
