import axios, { AxiosResponse } from 'axios';

export function getPublicIp(): Promise<string> {
  const onApiFetchSuccess = ({ data }: AxiosResponse<string>) => data.trim();

  const requestIp: (apiServiceEndpoint: string) => Promise<string> = (
    apiServiceEndpoint,
  ) =>
    axios.get(apiServiceEndpoint, { timeout: 10_000 }).then(onApiFetchSuccess);

  return requestIp('https://ipv4.icanhazip.com').catch(() =>
    requestIp('https://api.ipify.org'),
  );
}
