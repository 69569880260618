import styled from 'styled-components';
import colors from '../style-utils/colors';
import {
  ContainerMessage,
  IconInsideInput,
} from 'src/components/InputField/style/input.style';
import media from 'src/style-utils/media-queries';

interface FormGroupProps {
  isError?: boolean;
  isAlert?: boolean;
  isDisabled?: boolean;
}

export const LabelInput = styled.label`
  color: ${colors.textColorBlack};
  font-weight: 400;
  background-color: inherit;
  line-height: 1.72;
  font-size: 1.4rem;
`;

const computeDataShrinkValue = (
  value: string | number | readonly string[] | undefined,
) => {
  if (
    typeof value === 'undefined' ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return undefined;
  }
  return '1';
};

export const FormStyle = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div<FormGroupProps>`
  position: relative;

  & + ${ContainerMessage} {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  ${({ isError, isDisabled }) => {
    if (isError) {
      return `
        color: ${colors.redInput};

        & + ${ContainerMessage} {
          color:  ${colors.redInput} !important;
        }

        /* '& > Input:focus' is there only to fix specificity issues. */

        & > ${Input},
        & > ${Input}:focus,
        & > ${TextArea},
        & > ${TextArea}:focus {
          outline-color: ${colors.redInput};
        }

        & > ${IconInsideInput} {
          display: block;
        }
      `;
    }
    if (isDisabled) {
      return `
      & > ${IconInsideInput} {
        display: none;
      }`;
    }
  }};
`;

export const TextArea = styled.textarea.attrs(({ value }) => ({
  'data-shrink': computeDataShrinkValue(value),
}))`
  color: ${colors.textInputColor};
  border-radius: 0.4rem;
  resize: none;
  height: 9.6rem;
  display: block;
  width: 100%;
  border: none;
  outline: 0.15rem solid ${colors.inputBorderColor};
  font-weight: 400;
  line-height: 1.857;
  font-size: 1.4rem;
  background: ${colors.white};
  box-shadow: none;
  padding: 0.2rem 1.2rem;

  /* Remove box-shadow inside input */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Hides the credentials indicator in safari. */
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    outline-color: ${colors.inputBorderColor};
  }

  &:focus ~ ${LabelInput}, &[data-shrink='1'] ~ ${LabelInput} {
    top: -1rem;
    padding: 0 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    background: ${colors.white};
  }
  &[disabled] {
    background: ${colors.bgDisabledInput};
  }

  &::placeholder {
    color: ${colors.placeholder};
  }
`;

export const Input = styled.input.attrs(({ value }) => ({
  'data-shrink': computeDataShrinkValue(value),
}))`
  color: ${colors.textInputColor};
  border-radius: 0.4rem;
  padding: 0.8rem 3.6rem 0.8rem 1.2rem;
  display: block;
  width: 100%;
  border: none;
  outline: 0.15rem solid ${colors.inputBorderColor};
  font-weight: 400;
  line-height: 1.857;
  font-size: 1.4rem;
  background: ${colors.white};
  box-shadow: none;
  ${media.tablet`
    padding: 1.2rem 3.6rem 1.2rem 1.2rem;
  `}

  /* Remove box-shadow inside input */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Hides the credentials indicator in safari. */
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &:focus {
    box-shadow: none;
    outline-color: ${colors.inputBorderColorFocus};
  }

  &:focus ~ ${LabelInput}, &[data-shrink='1'] ~ ${LabelInput} {
    top: -1rem;
    padding: 0 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    background: ${colors.white};
  }
  &[disabled] {
    background: ${colors.bgDisabledInput};
  }

  &::placeholder {
    color: ${colors.placeholder};
  }
`;
