import colors from 'src/style-utils/colors';

const checkboxTheme = {
  checkbox: {
    label: {
      marginTop: '0',
      color: colors.midGrey,
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      fontWeight: 'normal',
    },
    root: {
      margin: '',
      paddingLeft: '5rem',
      desktop: {
        margin: '',
        paddingLeft: '5.2rem',
      },
    },
  },
};

export default checkboxTheme;
