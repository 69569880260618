import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const CheckboxCard = styled.div`
  margin: 0.8rem auto;
  padding: 1.2rem;
  border-radius: 1.2rem;
  background-color: ${colors.backgroundBlue};
  width: 100%;
  max-width: 44.8rem;
`;
