import Icon from 'src/components/Icon/Icon.component';
import { selectFieldStateReducer } from 'src/components/Select/Select.helpers';
import { SelectProps } from 'src/components/Select/Select.types';
import {
  ContainerMessageSelect,
  MessageErrorSelect,
} from 'src/components/Select/style/Select.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FlagIcon,
  SelectRootWrapper,
  StyledNativeSelect,
} from './style/NativeSelectLanguage.style';
import { SelectFieldUIStates } from 'src/components/Select/Select.config';

const NativeSelectLanguage = React.memo(function Select({
  onChange,
  options,
  i18n = false,
  i18nLabel = true,
  value,
  className,
  labelledBy,
  describedBy,
  id,
  label,
  iconComponent = Icon,
  name,
  isPresentErrorMessage,
  disabled,
}: SelectProps) {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  const selectedOptionIndex = React.useMemo(() => {
    return options.findIndex(
      ({ optionId }: ListboxOption) => String(optionId) === String(value),
    );
  }, [options, value]);
  const error = errors[name];
  const touched = touchedFields[name];
  const { messageProps, selectFieldState } = selectFieldStateReducer({
    error,
    touched,
  });
  const isError = selectFieldState === SelectFieldUIStates.error;
  return (
    <>
      <SelectRootWrapper disabled={disabled}>
        {options[selectedOptionIndex]?.iconSrc && (
          <FlagIcon
            iconSrc={options[selectedOptionIndex].iconSrc}
            iconSize="2rem"
          />
        )}
        <StyledNativeSelect {...register(name)} disabled={disabled} required>
          <option style={{ display: 'none' }} />
          {options &&
            options.map((item, index) => (
              <option value={item.optionId} key={index}>
                {item.label}
              </option>
            ))}
        </StyledNativeSelect>
        <Icon svgIconComponent={ArrowDown} iconSize="2.4rem" />
      </SelectRootWrapper>
      <ContainerMessageSelect isPresentMessage={isPresentErrorMessage}>
        <MessageErrorSelect isError={isError}>
          {messageProps && <Translate id={messageProps} />}
        </MessageErrorSelect>
      </ContainerMessageSelect>
    </>
  );
});

export default NativeSelectLanguage;
