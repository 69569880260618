import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { ColoredSection, ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const CLInfoCardsWrapper = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
`;

export const CLInfoContainer = styled.div`
  display: flex;
  padding: 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1.6rem;
  background: ${colors.white};
  box-shadow: 0px 1px 4px 1px ${colors.cardShadowBlue};
`;

export const IconCircle = styled.div`
  display: flex;
  padding: 0.6rem;
  align-items: flex-start;
  border-radius: 99rem;
  background: ${colors.backgroundOutlined};
  color: ${colors.textColorBlack};
`;

export const CustomColoredSection = styled(ColoredSection)`
  padding-bottom: 12rem;
  ${media.tablet`
    padding-bottom: 4rem;`}
`;
