import { Resolver } from 'react-hook-form';
import { validateEndDate } from 'src/utils/validation/date';

import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import { clientList, startDate, endDate } from './SearchCriteriaForm.config';

export type DataFormEligibleOrders = {
  [clientList]?: string[];
  [startDate]?: string;
  [endDate]?: string;
};

export const searchCriteriaFormConfig = (fromPlugin?: boolean) => {
  const initialValues: DataFormEligibleOrders = {
    [clientList]: [],
    [startDate]: '',
    [endDate]: '',
  };
  const validate = (values: any) => {
    return validateFormSync({
      [endDate]: validateFieldSync(validateEndDate(values[startDate])),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
