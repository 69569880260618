import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getAdvanceRequests } from 'src/services/advanceRequests/advanceRequests.config';
import { getAdvanceRequestsMethodApi } from 'src/services/advanceRequests/advanceRequests.request';
import { H4, P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import AdvancePlanRequestsTable from './partials/AdvancePlanRequestsTable.component';

const AdvancePlanRequests: React.FC = () => {
  const { isLoading, data } = useQuery(getAdvanceRequests, () =>
    getAdvanceRequestsMethodApi(),
  );
  const navigate = useNavigate();

  return (
    <ResponsiveContainer margin="4.8rem auto 0">
      <Flex fluid justify="space-between" flexDirection="row">
        <div>
          <H4 margin="0 0 0.8rem">
            <Translate id="advPlanRequest.title" />
          </H4>
          <P marginBottom="3.2rem">
            <Translate id="advPlanRequest.subTitle" />
          </P>
        </div>
        {data?.data?.ResultSet?.AdvanceRequests && (
          <ButtonContainer>
            <Button
              variant="Primary"
              onClick={() =>
                navigate(
                  `${RoutePath.advancePaymentRequest}${RoutePath.chooseLink}`,
                )
              }
            >
              <Translate id="advPlanRequest.btn" />
            </Button>
          </ButtonContainer>
        )}
      </Flex>
      <AdvancePlanRequestsTable data={data} isLoading={isLoading} />
    </ResponsiveContainer>
  );
};

export default AdvancePlanRequests;
