import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const MovementsCardContainer = styled(Flex)`
  min-width: 15rem;
  background-color: ${colors.white};
  border-radius: 1.2rem;
  gap: 1.4rem;
  flex-shrink: 0;
  min-height: 8.4rem;
  box-shadow: 0 0.2rem 0.4rem 0.1rem ${colors.cardShadowBlue};
  white-space: nowrap;
  ${media.tablet`
    flex: 1;
  `}
`;
