import { useMatches } from 'react-router-dom';

type HandleType = {
  hasHeader: boolean | undefined;
  hasNavBar: boolean | undefined;
  hasFooter: boolean | 'onlyDesktop';
  hasHeaderPlugin: boolean | undefined;
  isResponsive: boolean | undefined;
};

export function useHandle() {
  const matches = useMatches();

  const currentHandle = matches[matches.length - 1].handle as HandleType;

  return {
    hasHeader:
      currentHandle?.hasHeader !== undefined ? currentHandle?.hasHeader : true,
    hasHeaderPlugin:
      currentHandle?.hasHeaderPlugin !== undefined
        ? currentHandle?.hasHeaderPlugin
        : false,
    hasNavBar:
      currentHandle?.hasNavBar !== undefined ? currentHandle?.hasNavBar : true,
    hasFooter:
      currentHandle?.hasFooter !== undefined ||
      currentHandle?.hasFooter === 'onlyDesktop'
        ? currentHandle?.hasFooter
        : true,
    isResponsive:
      currentHandle?.isResponsive !== undefined
        ? currentHandle?.isResponsive
        : false,
  };
}
