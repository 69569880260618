import FormEmail from 'src/pages/registration/partials/FormEmail/FormEmail.component';
import { RoutePath } from 'src/routers/routers.config';

const EmailStepRegistration: React.FC = () => {
  return (
    <FormEmail
      nextPath={RoutePath.registrationBuyer + RoutePath.otpConfirmEmailStep}
      step={3}
      stepMax={6}
      typeAccount="BUYER"
    />
  );
};

export default EmailStepRegistration;
