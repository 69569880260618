import Footer from 'src/components/Footer/Footer.component';
import Header from 'src/components/Header/Header.component';
import { useHandle } from 'src/components/Header/Header.hooks';
import { Navigate, Outlet } from 'react-router-dom';
import { useScrollToTopOnPathnameChange } from 'src/routers/routers.hooks';
import { useSession } from 'src/services/session/session.context';
import colors from 'src/style-utils/colors';
import { Container, ContainerPage } from 'src/style/Container.style';
import DevOnlyDashBoard from '../DevOnlyDashboard/DevOnlyDashboard.loadable';
import React from 'react';
import { ENV } from '../../settings';

export const ProtectedRoute: React.FC = () => {
  const { auth } = useSession();
  const authToken = auth.authToken;
  const shouldLoadDevTools = ENV !== 'production';

  const hasHeader = useHandle().hasHeader;
  const isResponsive = useHandle().isResponsive;
  const hasFooter = useHandle().hasFooter;
  useScrollToTopOnPathnameChange();

  const pageResponsive: boolean = isResponsive;

  return authToken ? (
    <>
      {hasHeader && <Header loggedIn={true} />}
      {pageResponsive ? (
        <ContainerPage>
          <Outlet />
        </ContainerPage>
      ) : (
        <Container backgroundStyle={colors.backgroundGrey}>
          <Outlet />
        </Container>
      )}
      {shouldLoadDevTools && <DevOnlyDashBoard />}
      {hasFooter === 'onlyDesktop' ? (
        <Footer onlyDesktop={true} />
      ) : hasFooter ? (
        <Footer />
      ) : null}
    </>
  ) : (
    <Navigate to="/login" />
  );
};
