import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import CheckBoxPopover from 'src/pages/monitoring/checkbox/CheckBoxPopover.component';
import React, { MouseEventHandler } from 'react';
import { Buyers } from 'src/services/linksFilterInitialData/linksFilterInitialData.types';
import { DataFormEligibleOrders } from '../../SearchCriteriaForm.helpers';
import { clientList } from '../../SearchCriteriaForm.config';

type BuyersPopoverProps = {
  buyersList?: Buyers[];
  submitHandler?: MouseEventHandler | undefined;
  initialData?: Buyers[];
  dataFiltered: DataFormEligibleOrders;
  onCloseHandler?: () => void;
};
export const BuyersPopover: React.FC<BuyersPopoverProps> = ({
  buyersList,
  submitHandler,
  dataFiltered,
  onCloseHandler,
}) => {
  const count = dataFiltered?.buyers?.length;
  return (
    <SelectPopover
      submitHandler={submitHandler}
      label="lbl.customers"
      countFilters={count}
      onCloseHandler={onCloseHandler}
    >
      {buyersList?.map(({ Name, Id }) => (
        <CheckBoxPopover
          key={Id}
          fieldName={clientList}
          label={Name}
          id={Id.toString()}
        />
      ))}
    </SelectPopover>
  );
};

export default BuyersPopover;
