import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import { PaymentFlowContextType } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

function PayWithOpynPage() {
  const {
    selectedMethodId,
    paymentMethods,
    setMethod,
    addingBackup,
    payNowHandler,
    goToStripeFormHandler,
    formattedAmount,
    type,
    canPayWithSDD,
    confirmOrderHandler,
  } = useOutletContext<PaymentFlowContextType>();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const purchasing = currentOrder?.OrderId !== undefined;
  const location = useLocation();
  const showBack = location?.state?.showBack;
  const PaymentMethod =
    selectedMethodId > 0
      ? paymentMethods.find((item) => item.Id === selectedMethodId)
      : paymentMethods
        ? paymentMethods[0]
        : null;
  const Details = PaymentMethod?.Details;

  const navigate = useNavigate();
  if (!Details && canPayWithSDD) {
    return (
      <Navigate to={RoutePath.paymentFlow + RoutePath.setMethods} replace />
    );
  }
  if (!Details) goToStripeFormHandler();

  if (setMethod || addingBackup) {
    return (
      <Navigate to={RoutePath.paymentFlow + RoutePath.setMethods} replace />
    );
  }

  const d = new Date();
  const currentAbsoluteMonth = d.getFullYear() * 12 + d.getMonth() + 1;
  const expired = Details
    ? currentAbsoluteMonth - (Details.ExpYear! * 12 + Details?.ExpMonth!) > 0
    : false;

  return (
    <>
      <div className="container pb-8">
        {showBack ? <GoBackButton /> : <div className="h-8"></div>}
        <div className="mx-auto max-w-oxl">
          <div className="mx-auto grid max-w-olg gap-4 lg:text-center">
            <H4>
              <Translate id="text.payWithOpynTitle" />
            </H4>
            <P>
              <Translate id="text.paymentSuccessSubtitle" />
            </P>
          </div>
          <Flex
            flexDirection="column"
            align="center"
            gap="2.4rem"
            margin="3rem 0"
          >
            {PaymentMethod && <PaymentMethodElement {...PaymentMethod} />}
            <Button
              variant="LinkPrimary"
              i18n
              underline
              fontWeight="400"
              translatedText="text.chooseAnotherPaymentMethod"
              onClick={() =>
                navigate(`${RoutePath.paymentFlow}${RoutePath.chooseMethod}`)
              }
            />
          </Flex>
          <ButtonContainer marginTop="4rem">
            <Button
              onClick={() => {
                if (purchasing) {
                  confirmOrderHandler({
                    paymentMethodId: selectedMethodId,
                    orderGuid: currentOrder?.OrderGuid,
                  });
                  return;
                }
                payNowHandler({
                  paymentMethodId: selectedMethodId,
                });
              }}
              variant="Primary"
              sizeOnDesktop="medium"
              minWidth="100%"
              type="button"
              disabled={expired}
            >
              <Translate
                id={
                  type === 'pay'
                    ? currentOrder?.FirstInstallmentToBePaid || !purchasing
                      ? 'text.payNow'
                      : 'lbl.confirm&PayDate'
                    : 'text.continue'
                }
                data={{
                  paymentValue: formattedAmount,
                  paymentDate: refactorFormatDate(
                    currentOrder?.FirstInstallmentDueDate,
                    'DD/MM/YYYY',
                  ),
                }}
              />
            </Button>
          </ButtonContainer>
        </div>
      </div>
    </>
  );
}

export default PayWithOpynPage;
