import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import { ReactComponent as DownloadIcon } from 'src/images/icons/download_icon.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';
import { exportMovementsMethodApi } from 'src/services/exportMovements/exportMovements.request';
import { H5 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { downloadFile, toXSL } from 'src/utils/functions/download';
import { MovementsDownloadXlsProps } from './MovementsDownloadXls.types';

export const MovementsDownloadXls = ({
  requestPayload,
}: MovementsDownloadXlsProps) => {
  const { mutate, isLoading, isError } = useMutation(
    (values: any) => exportMovementsMethodApi(values),
    {
      onSuccess: (res) => {
        const csvBase64Content = res?.data?.ResultSet;
        downloadFile(csvBase64Content, 'documento.xlsx', true, toXSL);
      },
    },
  );

  if (isError) {
    return <Navigate to="/oops" />;
  }
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <Flex
      data-component="movements-download-xls"
      fluid
      align="center"
      justify="space-between"
    >
      <H5>
        <Translate id="text.movement" />
      </H5>
      <Button
        i18n
        onClick={() => mutate({ ...requestPayload })}
        translatedText="link.excel"
        variant="LinkPrimary"
        sizeOnDesktop="small"
        padding="0"
        margin="0"
        rightChild={
          <Icon
            iconSize="2rem"
            iconHeight="2rem"
            svgIconComponent={DownloadIcon}
          />
        }
      />
    </Flex>
  );
};
