import { Button } from 'src/components/Button/Button.component';
import { IconRoot } from 'src/components/Icon/style/Icon.style';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const PaymentsFilterModalHeaderStyled = styled.div`
  width: 100%;
  background-color: ${colors.backgroundGrey};
  display: flex;
  height: 6.4rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${colors.greyBorder};
  padding: 1.4rem 2.4rem;
  color: ${colors.textColorBlack};
  font-size: 2.2rem;
  font-weight: 400;
  ${IconRoot} {
    cursor: pointer;
    margin-left: 2.4rem;
  }
`;

export const ApplyButton = styled(Button)`
  height: 5.4rem;
  width: 100%;
  font-weight: 500;
  text-transform: none;
  ${media.tablet`
    max-width: 24rem;
  `}
`;

export const ButtonContainer = styled(Flex)`
  background-color: ${colors.backgroundGrey};
  height: auto;
  box-shadow: 0 0.4rem 2.4rem ${colors.cardShadowBlue};
  min-width: unset;
  padding: 1.6rem 2.4rem 2.8rem;
  ${media.tablet`
    min-width: 100%;
    position: unset;
    box-shadow: none;
  `};
`;

export const ModalBodyStyled = styled.div`
  padding: 3.4rem 2.4rem;
  background-color: ${colors.backgroundGrey};
  height: 100%;
  overflow: auto;
  color: ${colors.textColorBlack};
  ::-webkit-scrollbar {
    display: none;
  }
  > p {
    color: unset !important;
  }
  ${media.tablet`
    padding: 4.2rem 4rem 0;`}
`;
