// import Loader from 'src/components/Loader/Loader.component';
import { useState } from 'react';
import ChoosePaymentMethod from './ChoosePaymentMethod.component';
import PayWithMethod from './PayWithMethod.component';

type Props = { pContext: pContextType };

const PaymentMethodContainer = ({ pContext }: Props) => {
  const [page, setPage] = useState(0);
  return (
    <>
      {page === 0 && (
        <PayWithMethod
          goToChangeMethod={() => setPage(1)}
          pContenxt={pContext}
        />
      )}
      {page === 1 && <ChoosePaymentMethod pContext={pContext} />}
    </>
  );
};

export default PaymentMethodContainer;
