import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const TitleIconContainer = styled(Flex)`
  margin-bottom: 1.6rem;
  align-items: center;
  gap: 1.2rem;
  ${media.mobileSm`
    justify-content: center;
    margin-bottom: 3.2rem;
  `}
`;
export const UL = styled.ul`
  > li {
    list-style: unset;
    list-style-type: disc;
  }
  padding-inline-start: 2rem;
`;
