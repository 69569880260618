import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as MailIcon } from 'src/images/icons/email.svg';
import { PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ToastContainer } from './ClientEmailCard.style';

type Props = {
  email: string;
  label: string;
};

const ClientEmailCard: React.FC<Props> = ({ email, label }) => {
  return (
    <ToastContainer
      data-component="client-email-card"
      data-type="primary"
      notShadow
    >
      <div>
        <Icon
          svgIconComponent={MailIcon}
          iconSize="3.2rem"
          iconHeight="3.2rem"
        />
      </div>

      <Flex flexDirection="column">
        <PSmall>
          <Translate id={label} />
        </PSmall>
        <PSmall colorBlack bold>
          {email}
        </PSmall>
      </Flex>
    </ToastContainer>
  );
};

export default ClientEmailCard;
