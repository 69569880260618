import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const ClientButtonSelection = styled.label`
  white-space: nowrap;
  max-width: clamp(1rem, 100%, 38ch);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  input {
    position: fixed;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  span {
    border: 0.1rem solid ${colors.textColorBlack};
    padding: 0.8rem 1.2rem;
    display: block;
    border-radius: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  input:checked + span {
    background-color: ${colors.textColorBlack};
    color: white;
  }
`;
