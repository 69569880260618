import DividerOr from 'src/components/DividerOr/DividerOr.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkCustomerCard from 'src/components/PayByLinkCustomerCard/PayByLinkCustomerCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { t } from 'i18next';
import { ReactComponent as Contacts } from 'src/images/icons/contacts.svg';
import { ReactComponent as Search } from 'src/images/icons/search.svg';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router';
import { getBuyersWithMovementsMethodApi } from 'src/services/buyersWithMovements/buyersWithMovements.request';
import { getBuyers } from 'src/services/orderLink/orderLink.config';
import { useProfileStore } from 'src/store/store';
import { H5 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { Input } from 'src/style/styleInput.style';
import { IconSearch } from './StepCustomer.style';

function StepCustomer() {
  const [customer, setCustomer] = useState('');
  const hasOrders = useProfileStore((state) => state.HasOrders);
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { isLoading, isError, data } = useQuery(getBuyers, () =>
    getBuyersWithMovementsMethodApi(),
  );
  if (!hasOrders) {
    return <Navigate to={urlRedirectToFlow} />;
  }
  if (isError) {
    return <Navigate to="/oops" />;
  }
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.whoIsCustomer"
      />
      <FlowContainer>
        <div className="content">
          <PayByLinkCustomerCard path={urlRedirectToFlow} />
          <DividerOr bold />
          <H5 light marginBottom="2.4rem" className="centerOnDesktop">
            <Translate id="lbl.selectCustomer" />
          </H5>
          <div style={{ position: 'relative' }}>
            <Input
              name="customer"
              placeholder={t('lbl.searchForAClient')}
              onChange={(val) => setCustomer(val.target.value.toLowerCase())}
            />
            <IconSearch svgIconComponent={Search} iconSize={'2.4rem'} />
          </div>
          {data?.data?.ResultSet?.length === 0 ? (
            <Flex align="center" flexDirection="column">
              <Icon
                svgIconComponent={Contacts}
                iconSize="10.0rem"
                iconHeight="10.9rem"
              />
              <H5 textAlign="center">
                <Translate id="text.pblEmptyCustomersList" />
              </H5>
            </Flex>
          ) : (
            <div className="my-4 grid gap-4">
              {data?.data?.ResultSet?.filter(
                (item) =>
                  item?.Name?.toLowerCase().includes(customer) ||
                  item?.VatCode?.toLowerCase().includes(customer),
              ).map((buyer: any, index: number) => (
                <PayByLinkCustomerCard key={index} info={buyer} />
              ))}
            </div>
          )}
        </div>
      </FlowContainer>
    </>
  );
}

export default StepCustomer;
