import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import { ReactComponent as Send } from 'src/images/icons/IconSend.svg';
import { ReactComponent as CheckSquare } from 'src/images/icons/check_square.svg';
import { ReactComponent as EarningIcon } from 'src/images/icons/earnings.svg';
import { ReactComponent as EuroIcon } from 'src/images/icons/euro.svg';
import { ReactComponent as PaymentLink } from 'src/images/icons/payment_link.svg';
import { ReactComponent as ShieldIcon } from 'src/images/icons/shield_check.svg';
import { ReactComponent as SmartphoneIcon } from 'src/images/icons/smartphone.svg';
import { ReactComponent as CreditAnalysis } from 'src/images/icons/credit_analysis.svg';
import { ReactComponent as Calendar } from 'src/images/icons/calendar_light.svg';
import HomeStepper from 'src/pages/privateArea/partials/merchant/partials/homeStepper/HomeStepper.component';

const advPayStepperArray = [
  {
    title: 'text.advPayStepperTitle1',
    subTitle: 'text.advPayStepperDescr1',
    icon: PaymentLink,
  },
  {
    title: 'text.advPayStepperTitle2',
    subTitle: 'text.advPayStepperDescr2',
    icon: Calendar,
  },
  {
    title: 'text.advPayStepperTitle3',
    subTitle: 'text.advPayStepperDescr3',
    icon: CreditAnalysis,
  },
  {
    title: 'text.advPayStepperTitle4',
    subTitle: 'text.advPayStepperDescr4',
    icon: EarningIcon,
  },
];
const advPayStepper2Array = [
  {
    title: 'text.advPayStepper2Title1',
    subTitle: 'text.advPayStepper2Descr1',
    icon: PaymentLink,
  },
  {
    title: 'text.advPayStepper2Title2',
    subTitle: 'text.advPayStepper2Descr2',
    icon: CheckSquare,
  },
  {
    title: 'text.advPayStepper2Title3',
    subTitle: 'text.advPayStepper2Descr3',
    icon: Send,
  },
  {
    title: 'text.advPayStepper2Title4',
    subTitle: 'text.advPayStepper2Descr4',
    icon: EarningIcon,
  },
];

export const advPayListItems = [
  {
    SvgComponent: EuroIcon,
    label: 'text.advPayAccordionTitle1',
    content: 'text.advPayAccordionDescr1',
  },
  {
    SvgComponent: ShieldIcon,
    label: 'text.advPayAccordionTitle2',
    content: 'text.advPayAccordionDescr2',
  },
  {
    SvgComponent: SmartphoneIcon,
    label: 'text.advPayAccordionTitle3',
    content: 'text.advPayAccordionDescr3',
  },
];
export const advPayLegalNotes = {
  title: 'lbl.usageRequirements',
  content: [
    'text.usageRequirementsInfoSubtitle1',
    'text.usageRequirementsInfoSubtitle2',
    'text.usageRequirementsInfoSubtitle3',
    'text.usageRequirementsInfoSubtitle4',
  ],
};

export const tabsAdvancePaymentInfo: ObjectTab[] = [
  {
    tabTitle: 'text.advPayTab1',
    content: <HomeStepper stepperArray={advPayStepperArray} />,
  },
  {
    tabTitle: 'text.advPayTab2',
    content: <HomeStepper stepperArray={advPayStepper2Array} />,
  },
];
