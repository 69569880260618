import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import StepperRemodulation from 'src/components/Stepper/StepperRemodulation.component';
import { Installment } from 'src/utils/types/common.types';

const RemodulationRequestsModalBody: React.FC<{
  dataStepper: Installment[];
}> = ({ dataStepper }) => {
  return (
    <ModalBody>
      <StepperRemodulation dataStepper={dataStepper} />
    </ModalBody>
  );
};

export default RemodulationRequestsModalBody;
