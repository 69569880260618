import {
  Cash,
  ServiceCost,
  Payout,
} from 'src/components/MovementDetailCard/MovementsDetailCard.config';

export const MoveDetailCheckArray = [
  {
    title: 'lbl.cash',
    subtitle: 'lbl.cashExplain',
    name: Cash,
  },
  {
    title: 'lbl.withdrawals',
    subtitle: 'lbl.withdrawalsExplain',
    name: Payout,
  },
  {
    title: 'lbl.serviceCost',
    subtitle: 'lbl.serviceCostExplain',
    name: ServiceCost,
  },
];

export const MoveCheckboxConfig = {
  [Cash]: {
    title: 'lbl.cash',
    subtitle: 'lbl.cashExplain',
  },
  [Payout]: {
    title: 'lbl.withdrawals',
    subtitle: 'lbl.withdrawalsExplain',
  },
  [ServiceCost]: {
    title: 'lbl.serviceCost',
    subtitle: 'lbl.serviceCostExplain',
  },
};

export const MoveCheckboxArray = [Cash, Payout, ServiceCost];
