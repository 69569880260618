import styled from 'styled-components';
import media from '../../../../style-utils/media-queries';

export const UWADVBodyContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  ${media.tablet`
  display: flex;
  width: 560px;
  flex-direction: column;
  gap: 8px;
  `}
`;
