import {
  OrderStatus,
  PayByLinkStatus,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { PayByLinkTabsModel } from '../payByLinkPage.types';

export const PayByLinkTabs: PayByLinkTabsModel = [
  {
    id: 'created',
    label: 'lbl.created',
    noTitle: 'linkpayment.noTitle.created',
    noSubtitle: 'linkpayment.noSubtitle.created',
    statuses: [PayByLinkStatus.createdLink],
  },
  {
    id: 'progress',
    label: 'lbl.inProgress',
    noTitle: 'linkpayment.noTitle.progress',
    noSubtitle: 'linkpayment.noSubtitle.progress',
    statuses: [
      PayByLinkStatus.regularIncoming,
      PayByLinkStatus.expiredPayment,
      PayByLinkStatus.planned,
    ],
  },
  {
    id: 'completed',
    label: 'lbl.completed',
    noTitle: 'linkpayment.noTitle.completed',
    noSubtitle: 'linkpayment.noSubtitle.completed',
    statuses: [PayByLinkStatus.fullCashed],
  },
  {
    id: 'inactive',
    label: 'lbl.link.inactive',
    noTitle: 'linkpayment.noTitle.inactive',
    noSubtitle: 'linkpayment.noSubtitle.inactive',
    statuses: [
      PayByLinkStatus.expiredLink,
      PayByLinkStatus.cancelledLink,
      PayByLinkStatus.interrupted,
      PayByLinkStatus.wrongLink,
      PayByLinkStatus.unsuitableLink,
    ],
  },
  {
    id: 'all',
    label: 'lbl.all',
    noTitle: 'linkpayment.noTitle.all',
    noSubtitle: 'linkpayment.noSubtitle.all',
    statuses: [],
  },
];

export const PayByLinkOrdersTabs: PayByLinkTabsModel = [
  {
    id: 'progress',
    label: 'lbl.inProgress',
    noTitle: 'linkpaymentorder.noTitle.progress',
    noSubtitle: 'linkpaymentorder.noSubtitle.progress',
    statuses: [
      OrderStatus.regularIncoming,
      OrderStatus.expiredPayment,
      OrderStatus.planned,
    ],
  },
  {
    id: 'completed',
    label: 'lbl.completed',
    noTitle: 'linkpaymentorder.noTitle.completed',
    noSubtitle: 'linkpaymentorder.noSubtitle.completed',
    statuses: [OrderStatus.fullCashed],
  },
  {
    id: 'all',
    label: 'lbl.all',
    noTitle: 'linkpaymentorder.noTitle.all',
    noSubtitle: 'linkpaymentorder.noSubtitle.all',
    statuses: [],
  },
];
