import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getUWADV, getUWBuyer, notifyBuyerCreated } from './UWBuyer.config';

type startUWData = {
  FiscalCode?: string;
  Reason?: string;
  OrderGuid?: string;
  CheckoutSessionGuid?: string;
};
type getUWData = {
  uwId?: number;
};
export interface GetUwResult {
  ResultSet: {
    Id: number;
    FiscalCode: string;
    Result: string;
    CanPayWithSDD: boolean;
    GiveCreditEnabled: boolean;
    Status: number;
  };
}
// interface GetUwResult {
//   ResultSet: {
//     Id: number;
//     FiscalCode: string;
//     Result: string;
//     CanPayWithSDD: boolean;
//     GiveCreditEnabled: boolean;
//     Status: number;
//   };
// }
type getUWADVData = {
  groupGuid?: string;
};
interface GetUwADVResult {
  ResultSet: {
    Id: number;
    CodiceFiscale: string;
    Esito: string;
    Stato: number;
  }[];
}
export const getUWBuyerMethodApi = async (data: getUWData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getUWBuyer,
    method: 'GET',
    authenticated: true,
    params: data,
  });
  return await apiRequest.request<GetUwResult>(requestConfig);
};

export const startUWBuyerMethodApi = async (data: startUWData) => {
  const requestConfig = makeEndpointApiConfig({
    url: notifyBuyerCreated,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request(requestConfig);
};
export const getUWADVMethodApi = async (data: getUWADVData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getUWADV,
    method: 'GET',
    authenticated: true,
    params: data,
  });
  return await apiRequest.request<GetUwADVResult>(requestConfig);
};
