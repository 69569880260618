import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  changePaymentMethodEndPoint,
  confirmOrderEndPoint,
  confirmSetupAttemptEndPoint,
  createInstantPaymentEndPoint,
  createSetupAttemptEndPoint,
  getPaymentMethodsEndPoint,
  removeMethodEndpoint,
  setBackupPaymentMethodEndPoint,
  setPreferredMethodEndpoint,
} from './payments.config';
import {
  ChangeMethodFormData,
  ChangeMethodResponse,
  ConfirmOrderData,
  ConfirmSetupAttemptData,
  ConfirmSetupAttemptResponse,
  CreateSetupAttemptData,
  InstantPaymentData,
  PaymentSuccessResponse,
} from './payments.types';

export const getPaymentMethodsApi = async (orderGuid?: string) => {
  const requestConfig = makeEndpointApiConfig({
    url: getPaymentMethodsEndPoint,
    method: 'GET',
    authenticated: true,
    params: { orderGuid: orderGuid },
  });
  return await apiRequest.request<{ ResultSet: PaymentMethodResult[] }>(
    requestConfig,
  );
};

export const createSetupAttemptMethodApi = async (
  data: CreateSetupAttemptData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: createSetupAttemptEndPoint,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: string }>(requestConfig);
};

export const confirmSetupAttemptMethodApi = async (
  data: ConfirmSetupAttemptData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: confirmSetupAttemptEndPoint,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: ConfirmSetupAttemptResponse }>(
    requestConfig,
  );
};

export const confirmOrderMethodApi = async (data: ConfirmOrderData) => {
  const requestConfig = makeEndpointApiConfig({
    url: confirmOrderEndPoint,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: PaymentSuccessResponse }>(
    requestConfig,
  );
};

export const createInstantPaymentMethodApi = async (
  data: InstantPaymentData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: createInstantPaymentEndPoint,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<{
    ResultSet: PaymentSuccessResponse;
  }>(requestConfig);
};

export const changePaymentMethodApi = async (data: ChangeMethodFormData) => {
  const requestConfig = makeEndpointApiConfig({
    url: changePaymentMethodEndPoint,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: ChangeMethodResponse }>(
    requestConfig,
  );
};

export const setBackupPaymentMethodApi = async (data: {
  BackupPaymentMethodId: number;
}) => {
  const requestConfig = makeEndpointApiConfig({
    url: setBackupPaymentMethodEndPoint,
    method: 'POST',
    data: data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: boolean }>(requestConfig);
};

export const setPreferredPaymentMethodApi = async (Id: number) => {
  const requestConfig = makeEndpointApiConfig({
    url: setPreferredMethodEndpoint,
    method: 'POST',
    data: { PreferredPaymentMethodId: Id },
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: boolean }>(requestConfig);
};

export const removePaymentMethodApi = async (Id: number) => {
  const requestConfig = makeEndpointApiConfig({
    url: removeMethodEndpoint,
    method: 'POST',
    data: { PaymentMethodIdToRemove: Id },
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: boolean }>(requestConfig);
};
