import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import NeedHelpBase from 'src/components/NeedHelpButton/NeedHelpBase.component';
import NeedHelpInline from 'src/components/NeedHelpButton/NeedHelpInline.component';
import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { confirmSetupAttemptMethodApi } from 'src/services/payments/payments.request';
import { Hr } from 'src/style-utils/hr';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { useIsAtLeastTabletBreakpoint } from 'src/utils/hooks/media-queries';
import CheckboxStripe from '../../CheckboxStripe/CheckboxStripe.component';
import { StateStripe } from '../PaymentForm.component';
import { PaymentErrorMessage } from '../style/paymentCard.style';
import { t } from 'i18next';

type CheckoutFormProps = { stateStripe: StateStripe };

const CheckoutForm = ({ stateStripe }: CheckoutFormProps) => {
  const {
    confirmOrderHandler,
    isLoadingConfirmOrder,
    sessionData: { LenderName },
  } = useOutletContext<EcommerceContext>();
  const stripe = useStripe();
  const elements = useElements();
  const [checked, setChecked] = useState(false);
  const buttonLabel = 'lbl.payNowAlt';
  const buttonDateLabel = 'lbl.confirm&PayDate';
  const navigate = useNavigate();
  const isTablet = useIsAtLeastTabletBreakpoint();
  const [message, setMessage] = React.useState('');
  const [showNeedHelp, setShowNeedHelp] = React.useState(false);
  const { mutate: mutateSetup, isLoading: isLoadingSetup } = useMutation(
    confirmSetupAttemptMethodApi,
    {
      onSuccess: (res) => {
        confirmOrderHandler({
          paymentMethodId: res.data.ResultSet.PaymentMethod.Id,
          orderGuid: stateStripe.orderGuid,
          guidParam: stateStripe.guidParam,
        });
      },
      onError: () => navigate(RoutePath.oops),
    },
  );
  React.useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );
    if (!clientSecret) return;

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    await stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then((res: any) => {
        if (res.error) {
          const { errMessage } = res.error ?? {};
          (window as any).parent.postMessage(
            JSON.stringify({
              status: 'reject',
              message: 'The payment has failed',
            }),
            '*',
          );
          if (
            errMessage === 'setup_intent_authentication_failure' ||
            errMessage === 'payment_intent_authentication_failure'
          )
            setMessage(errMessage);
          else setShowNeedHelp(true);
        } else {
          mutateSetup({
            stripePaymentMethodId: res.setupIntent.payment_method,
            stripeSetupId: res.setupIntent.id,
            isBackup: false,
          });
        }
      })
      .catch(() => {
        setMessage('stripe.confirmSetup() error');
      });
    // }
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
  };

  return (
    <>
      {(isLoadingConfirmOrder || isLoadingSetup) && (
        <Loader overlayViewMode="fullscreen" active viewMode="fluid" />
      )}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <br />
        <Hr />
        <CheckboxStripe
          description={t('text.okCreditAssignment', { x: LenderName })}
          checked={checked}
          setChecked={() => setChecked(!checked)}
        />
        {isTablet && <Hr />}
        <ButtonContainer>
          <Button
            type={'submit'}
            minWidth="100%"
            variant="Primary"
            disabled={isLoadingConfirmOrder || !stripe || !elements || !checked}
            id="submit"
          >
            <Translate
              id={
                moment().isSame(stateStripe.paymentDate, 'day')
                  ? buttonLabel
                  : buttonDateLabel
              }
              data={{
                paymentDate: refactorFormatDate(
                  stateStripe.paymentDate,
                  'DD/MM',
                ),
              }}
            />
          </Button>
        </ButtonContainer>
      </form>
      {message && (
        <PaymentErrorMessage id="payment-message">
          {message}
        </PaymentErrorMessage>
      )}
      {showNeedHelp && (
        <PaymentErrorMessage id="payment-message">
          <Translate id="error.stripeFail" />{' '}
          <NeedHelpBase BaseComponent={NeedHelpInline} />
        </PaymentErrorMessage>
      )}
    </>
  );
};

export default CheckoutForm;
