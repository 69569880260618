import { Resolver } from 'react-hook-form';
import { ValidationFormat } from 'src/services/orderLink/orderLink.types';
import createLength from 'src/utils/validation/length';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const nameField = 'business-name';

export const formConfigNamePayByLink = (
  buyerName?: string,
  validationName?: ValidationFormat,
) => {
  const initialValues = {
    [nameField]: buyerName,
  };
  const validate = validateFormSync({
    [nameField]: validateFieldSync(
      required,
      createLength({ min: validationName?.minLength ?? undefined }),
    ),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
