import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import { ModalHeader } from 'src/components/Modal/partials/ModalHeader/ModalHeader.component';
import CollaboratorModalBody from './CollaboratorModalBody.component';
import CollaboratorModalFooter from './CollaboratorModalFooter.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'CollaboratorModal',
  headerComponent: ModalHeader,
  bodyComponent: CollaboratorModalBody,
  footerComponent: CollaboratorModalFooter,
});
