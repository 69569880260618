import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: grid;
  ${media.tablet`
    gap: 6.4rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    text-align: center;
    pointer-events: none;
  `}
`;

export const AccordionArrow = styled(Icon)`
  color: ${colors.greyStatus};
  margin-left: 2rem;
  rotate: 0deg;
  transition: rotate 250ms ease-in-out;
  ${media.tablet`
    display: none;
  `}
`;

export const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 250ms ease-in-out;
  grid-template-rows: 0fr;
  ${media.tablet`
    grid-template-rows: 1fr;
  `}
`;

export const AccordionContainer = styled.div`
  border: none;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid #35302f;
  cursor: pointer;
  &[data-expanded='true'] ${AccordionBody} {
    grid-template-rows: 1fr;
  }
  &[data-expanded='true'] ${AccordionArrow} {
    rotate: -180deg;
  }
  ${media.tablet`
    border-bottom: 0;
    cursor: default;
  `}
`;

export const ResponsiveAccordionFlex = styled.div`
  color: ${colors.textColorBlack};
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 1.6rem;
  > svg {
    width: 3.2rem;
    height: 3.2rem;
    ${media.tablet`
      width: 5.6rem;
      height: 5.6rem;`}
  }
  ${media.tablet`
    flex-direction: column;
    margin: 0 auto;
    font-weight: 700;
  `}
  > h4 {
    font-weight: inherit !important;
  }
`;
