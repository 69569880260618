import { Resolver } from 'react-hook-form';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigCollaboratorDescription = (description?: string) => {
  const initialValues = {
    [descriptionField]: description ?? '',
  };

  const validate = validateFormSync({
    [descriptionField]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
