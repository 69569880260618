import FilteredListPayByLink from 'src/components/FilteredList/FilteredListPayByLink.component';
import { DataFormAmounts } from 'src/pages/privateArea/partials/buyer/BuyerArea.helper';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getLinkFilterInitialDataMethodApi } from 'src/services/linksFilterInitialData/linkFilterInitialData.request';
import { linksFilterInitialDataEndPoint } from 'src/services/linksFilterInitialData/linksFilterInitialData.config';
import { useProfileStore } from 'src/store/store';
import { ResponsiveContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import { AccountStatus } from 'src/utils/config/stateAccount';
import {
  DataFormPayByLink,
  searchCriteriaFormConfig,
} from './partials/SearchCriteriaForm.helpers';
import PayByLinkHeader from './partials/PayByLinkHeader/PayByLinkHeader.component';
import { pblPageArray } from './payByLinkPage.config';
import PeriodPopover from './partials/popover/period/PeriodPopover.component';
import BuyersPopover from './partials/popover/buyers/BuyersPopover.component';
import CollectionMethodPopover from './partials/popover/collectionMethod/CollectionMethodPopover.component';
import { Buyers } from 'src/services/linksFilterInitialData/linksFilterInitialData.types';
import TabsLinks from './partials/tabs/TabsLinks.component';
import { Flex } from 'src/style/flexbox.style';

const PayByLinkPage = ({ madeFromPlugin }: { madeFromPlugin: boolean }) => {
  const { initialValues, resolver } = searchCriteriaFormConfig(madeFromPlugin);
  const statoAccount = useProfileStore((state) => state.Status);
  const HasPluginProduct = useProfileStore((state) => state.HasPluginProduct);
  const { completed } = AccountStatus;
  const [dataFiltered, setDataFiltered] = useState(initialValues);

  const label =
    pblPageArray[madeFromPlugin.toString() as keyof typeof pblPageArray];
  const [buyersList, setBuyersList] = useState<Buyers[]>();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const navigate = useNavigate();

  const onSubmit = async (values: DataFormPayByLink) => {
    setDataFiltered({ ...values });
  };

  const { data: dataBuyers } = useQuery(
    [linksFilterInitialDataEndPoint, { madeFromPlugin }],
    () => getLinkFilterInitialDataMethodApi({ madeFromPlugin }),
    { staleTime: 5 * 60 * 1000 },
  );

  const onCloseHandler = () => {
    Object.keys(dataFiltered).map((field) =>
      methods.setValue(field, dataFiltered[field as keyof DataFormPayByLink]),
    );
  };

  useEffect(() => {
    if (statoAccount !== completed || (madeFromPlugin && !HasPluginProduct)) {
      navigate(RoutePath.oops);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBuyersList(dataBuyers?.data.ResultSet.Buyers);
  }, [dataBuyers?.data.ResultSet.Buyers]);

  return (
    <>
      <div className="bg-white">
        <ResponsiveContainer>
          <Flex gap="4.8rem" flexDirection="column" padding="3.2rem 0">
            <div>
              <FormProvider {...methods}>
                <FormStyle>
                  <PayByLinkHeader
                    madeFromPlugin={madeFromPlugin}
                    title={label.title}
                  />
                  <div className="flex gap-3 overflow-auto border-0 border-b border-solid border-pblack-100 pb-3">
                    <BuyersPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      buyersList={buyersList}
                      initialData={buyersList}
                      setData={setBuyersList}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                    <PeriodPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                    <CollectionMethodPopover
                      madeFromPlugin={madeFromPlugin}
                      submitHandler={methods.handleSubmit(onSubmit)}
                      setData={setBuyersList}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                  </div>

                  <FilteredListPayByLink
                    amounts={DataFormAmounts}
                    setDataFilter={setDataFiltered}
                    handleClick={onSubmit}
                    dataForm={dataFiltered}
                    clients={buyersList || []}
                    initialData={initialValues}
                  />
                </FormStyle>
              </FormProvider>
            </div>
          </Flex>
        </ResponsiveContainer>
      </div>
      <TabsLinks madeFromPlugin={madeFromPlugin} filters={dataFiltered} />
    </>
  );
};

export default PayByLinkPage;
