export const pblPageArray = {
  true: {
    title: 'lbl.orders',
    buttonLabel: 'text.showMorePayByLink',
    modalCheckbox: {
      adv: 'text.ordersADV',
      noAdv: 'text.ordersNoADV',
    },
  },
  false: {
    title: 'text.payByLink',
    buttonLabel: 'text.showMorePayByLink',
    modalCheckbox: {
      adv: 'lbl.withAdvancePaymentTitle',
      noAdv: 'lbl.withoutAdvancePaymentTitle',
    },
  },
};
