import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const SelectRootWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  position: relative;
  margin: 0;
  padding: 0;
  /* Use this property to trigger fluid behaviour inside a flex container. */
  flex: ${({ theme }) => theme.select.root.flex};
  ${({ disabled }) =>
    disabled && `pointer-events: none; background : ${colors.bgDisabledInput}`};

  & > * {
    flex: 1 1 auto;
  }
`;

export const StyledNativeSelect = styled.select`
  background: transparent;
  color: ${colors.textInputColor};
  border-radius: 99rem;
  padding: 0.4rem 3.6rem;
  display: block;
  border: none;
  outline: 1px solid ${colors.textColorBlack};
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 1.4rem;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Hides the credentials indicator in safari. */
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  ~ div {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    right: 0.8rem;
    transition: rotate 150ms ease-in-out;
  }
  &:focus {
    ~ div {
      rotate: -180deg;
    }
    z-index: 1;
    outline-width: 0.2rem;
    box-shadow: none;
  }
  &[disabled] {
    background: ${colors.bgDisabledInput};
  }
  &::placeholder {
    color: ${colors.placeholder};
  }
`;

export const FlagIcon = styled(Icon)`
  position: absolute;
  pointer-events: none;
  left: 1rem;
  z-index: 2;
`;
