import { useFormContext } from 'react-hook-form';
import {
  CheckBoxPopoverSelection,
  CheckmarkPopover,
} from './style/checkBoxPopover.style';
import Translate from 'src/components/Translate/Translate.component';

interface CheckBoxPopoverProps {
  label: string;
  id?: string;
  fieldName: string;
  typeBoolean?: boolean;
  i18n?: boolean;
}

const CheckBoxPopover = ({
  label,
  id,
  fieldName,
  typeBoolean,
  i18n,
}: CheckBoxPopoverProps) => {
  const { register } = useFormContext();

  return (
    <CheckBoxPopoverSelection htmlFor={id} className="overflow-hidden">
      {typeBoolean ? (
        <input type="checkbox" id={fieldName} {...register(fieldName)} />
      ) : (
        <input type="checkbox" id={id} value={id} {...register(fieldName)} />
      )}
      <span>{i18n ? <Translate id={label} /> : label}</span>
      <CheckmarkPopover />
    </CheckBoxPopoverSelection>
  );
};

export default CheckBoxPopover;
