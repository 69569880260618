import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { ReactComponent as Icon1 } from 'src/images/icons/bill.svg';
import { ReactComponent as Icon2 } from 'src/images/icons/calendar.svg';
import { ReactComponent as Icon3 } from 'src/images/icons/euro.svg';
import { useFormContext } from 'react-hook-form';
import { Plan } from 'src/services/ecommerce/ecommerce.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import {
  AccordionArrow,
  AccordionBody,
  AccordionContainer,
  Checkmark,
  CustomRadioBox,
  DetailContainer,
  ResponsiveAccordionFlex,
} from './PlanElement.style';
import ChoosePlanModalComponent from '../modal/ChoosePlanModal.component';
import { useState } from 'react';
import { Button } from 'src/components/Button/Button.component';
import moment from 'moment';
import Loader from 'src/components/Loader/Loader.component';

type Props = {
  item: Plan;
  orderAmount?: number;
  disabled: boolean;
  expanded: boolean;
  isLoadingCreate: boolean;
  setExpanded: () => void;
  setExpandedOnCheckbox: () => void;
};

const PlanElement = ({
  item,
  orderAmount,
  disabled,
  expanded,
  setExpanded,
  setExpandedOnCheckbox,
  isLoadingCreate,
}: Props) => {
  const { register, setValue } = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const {
    InstallmentMinAmount,
    InstallmentMaxAmount,
    NumRate,
    NextInstallmentExpiration,
  } = item;

  const rataMin = formatCurrency(InstallmentMinAmount, {
    removeCurrencySymbols: true,
  });
  const rataMax = formatCurrency(InstallmentMaxAmount);

  const payToday = moment().isSame(NextInstallmentExpiration, 'day');

  if (isLoadingCreate)
    return (
      <Loader
        backgroundColor="rgba(53, 48, 47, 0.40)"
        backdropFilter="blur(3px)"
        overlayViewMode="fullscreen"
        active
        viewMode="fluid"
      />
    );

  return (
    <>
      <CustomRadioBox
        id={item.Code}
        type="radio"
        value={item.Code}
        onClick={setExpandedOnCheckbox}
        {...register('variant', { required: true })}
      />
      <AccordionContainer
        data-disabled={disabled}
        data-expanded={disabled ? false : expanded}
      >
        <Flex fluid align="center">
          <ResponsiveAccordionFlex>
            <label htmlFor={item.Code}>
              <Checkmark style={{ display: 'block' }} />
            </label>
            <H5 colorGray>
              {NumRate > 1 ? (
                <Translate
                  id="lbl.installment"
                  data={{ rateNumber: NumRate }}
                />
              ) : (
                <Translate id="lbl.singleInstallment" />
              )}
            </H5>
            <P bold marginLeft="auto">
              <Translate id="lbl.pay" />
              &nbsp;
              {formatCurrency(item.NextInstallmentAmount)}
            </P>
            &nbsp;
            <P>
              {payToday ? (
                <Translate id="lbl.now" />
              ) : (
                <Translate
                  id="lbl.the"
                  data={{
                    day: refactorFormatDate(
                      item.NextInstallmentExpiration,
                      'DD/MM',
                    ),
                  }}
                />
              )}
            </P>
          </ResponsiveAccordionFlex>
          {NumRate > 1 && (
            <AccordionArrow
              svgIconComponent={ArrowDown}
              iconSize="4rem"
              onClick={setExpanded}
            />
          )}
        </Flex>
        {NumRate > 1 && (
          <AccordionBody>
            <div style={{ overflow: 'hidden' }}>
              <DetailContainer>
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap="0.8rem">
                    <Icon
                      svgIconComponent={Icon1}
                      iconSize="2.4rem"
                      className="hideOnMobile"
                    />
                    <PSmall>
                      <Translate id="lbl.installmentAmount" />
                    </PSmall>
                  </Flex>
                  <PSmall bold>
                    {InstallmentMinAmount === InstallmentMaxAmount ? (
                      rataMax
                    ) : (
                      <Translate
                        id={'lbl.fromTo'}
                        data={{ minAmount: rataMin, maxAmount: rataMax }}
                      />
                    )}
                  </PSmall>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap="0.8rem">
                    <Icon
                      svgIconComponent={Icon2}
                      iconSize="2.4rem"
                      className="hideOnMobile"
                    />
                    <PSmall>
                      <Translate id="lbl.lastInstallment" />
                    </PSmall>
                  </Flex>
                  <PSmall bold>
                    {refactorFormatDate(
                      item.Installments.at(-1)?.Expiration ?? '',
                      'DD MMMM YYYY',
                    )}
                  </PSmall>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap="0.8rem">
                    <Icon
                      svgIconComponent={Icon3}
                      iconSize="2.4rem"
                      className="hideOnMobile"
                    />
                    <PSmall>
                      <Translate id="lbl.totalOrder" />
                    </PSmall>
                  </Flex>
                  <PSmall bold>{formatCurrency(orderAmount ?? 0)}</PSmall>
                </Flex>
                <Button
                  type="button"
                  display="inline-flex"
                  variant="LinkPrimary"
                  underline
                  fontWeight="400"
                  height="auto"
                  padding="1.6rem 0 1.2rem"
                  onClick={() => setShowModal(true)}
                >
                  <Translate id="lbl.seeDetail" />
                </Button>
              </DetailContainer>
            </div>
          </AccordionBody>
        )}
      </AccordionContainer>
      {showModal && (
        <ChoosePlanModalComponent
          buttonTitle="lbl.chooseThisPlan"
          modalTitle="lbl.installmentDetailCount"
          i18nData={{ installmentNumber: item.NumRate }}
          data={item.Installments}
          applyForm={() => {
            setShowModal(false);
            setValue('variant', item.Code);
          }}
          handleClose={() => {
            setShowModal(false);
          }}
          i18n
        />
      )}
    </>
  );
};

export default PlanElement;
