import InputField from 'src/components/InputField/InputField.component';
import InputFieldCustom from 'src/components/InputFieldCustom/InputFieldCustom.component';
import colors from 'src/style-utils/colors';
import { Input } from 'src/style/styleInput.style';
import styled, { css } from 'styled-components';

export const InputDateStyled = styled(InputField)`
  width: 100%;
  input[type='date'] {
    text-transform: uppercase;

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &::-webkit-datetime-edit {
      color: ${colors.textColorBlack};
    }
    &[value='']::-webkit-datetime-edit {
      color: ${colors.textColorLightGrey};
    }
  }
`;

export const InputDateCustomStyledDefault = css`
  input[type='date'] {
    text-transform: uppercase;

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &::-webkit-datetime-edit {
      color: ${colors.textColorBlack};
    }
    &[value='']::-webkit-datetime-edit {
      color: ${colors.textColorLightGrey};
    }
  }
`;

export const InputDateCustomStyled = styled(InputFieldCustom)`
  ${InputDateCustomStyledDefault};
`;
export const InputDatePickerStyled = styled(Input)`
  ${InputDateCustomStyledDefault};
`;
