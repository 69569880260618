import { RoutePath } from 'src/routers/routers.config';

export type DataChangeContext = {
  type: 'email' | 'password' | 'phone';
  sessionGuid: string;
};
export enum ChangeModes {
  phone = 'phone',
  email = 'email',
  password = 'password',
}
export const AuthOtpArray = {
  [ChangeModes.email]: {
    redirect: RoutePath.changeAccessData + RoutePath.changeEmail,
  },
  [ChangeModes.phone]: {
    redirect: RoutePath.changeAccessData + RoutePath.changePhone,
  },
  [ChangeModes.password]: {
    redirect: RoutePath.changeAccessData + RoutePath.changePassword,
  },
};
export const PerformOtpArray = {
  [ChangeModes.email]: {
    redirect: RoutePath.ThankyouPage + RoutePath.successChangeEmail,
  },
  [ChangeModes.phone]: {
    redirect: RoutePath.ThankyouPage + RoutePath.successChangeNumber,
  },
  [ChangeModes.password]: {
    redirect: RoutePath.ThankyouPage + RoutePath.successChangePassword,
  },
};
