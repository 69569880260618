import Translate from 'src/components/Translate/Translate.component';
import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import colors from 'src/style-utils/colors';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

const OrderIdColumn: React.FC<ListRequestsResultsTable> = ({
  OrderId,
  BuyerName,
}) => {
  return (
    <Flex fluid justify="flex-start" flexDirection="column">
      <PSmall textColor={colors.textTableLightBody}>
        <Translate id={'advPlanRequestTableColumn.OrderId'} />
        {OrderId}
      </PSmall>
      <P textColor={colors.textTableBody}>{BuyerName}</P>
    </Flex>
  );
};

export default OrderIdColumn;
