import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  refreshRecoveryOtp2MethodApi,
  verifyRecoveryOtp2MethodApi,
} from 'src/services/recoveryData/recoveryData.request';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import { formConfigRecoveryOtp } from './Otp.helpers';

function ConfirmOtp() {
  const { initialValues, resolver } = formConfigRecoveryOtp();
  const { sessionGuid, activeOrder } = useOutletContext<RecoveryDataContext>();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const mobileNumber = location?.state?.value ?? '0000000000';
  const prefixNumber = (location?.state?.prefix ?? '+0000').slice(0, -2);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(verifyRecoveryOtp2MethodApi, {
    onSuccess: (res) => {
      res.data.ResultSet
        ? navigate(
            `${RoutePath.recoveryData}${RoutePath.insertPassword}?sessionGuid=${sessionGuid}`,
            { state: { activeOrder } },
          )
        : setErrorMessage('error.otpNotMatching');
    },
  });
  const { mutate: mutateRefresh, isLoading: isLoadingRefresh } = useMutation(
    refreshRecoveryOtp2MethodApi,
  );
  if (isLoadingRefresh) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const dataOtp = {
      sessionGuid,
      AuthOtp2: Object.values(data).join(''),
    };
    mutate(dataOtp);
  };
  return (
    <>
      <FlowHeader
        stepNumber={3}
        stepMaxNumber={4}
        showWhiteSpaceRight
        showBackButton
        backButtonAction={() => navigate(-1)}
        label="text.insertCodeLite"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryOtp" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OtpForm
              mobile={maskPhoneNumber(mobileNumber)}
              prefix={prefixNumber}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              sendAgainHandler={() => mutateRefresh({ sessionGuid })}
              isLoadingRefresh={isLoadingRefresh}
            />
            <ButtonContainer>
              <SubmitButton
                i18n
                disabled={isLoading}
                translatedText="lbl.next"
              />
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
}

export default ConfirmOtp;
