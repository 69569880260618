import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled, { keyframes } from 'styled-components';

export const CopiedLinkBox = styled(Flex)`
  box-shadow: 0px 2px 4px 1px ${colors.copiedLinkShadow};
  background-color: ${colors.white};
  border-radius: 1.2rem;
  padding: 2.4rem;
  position: absolute;
  min-height: 17.2rem;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
  width: calc(100% - 4.8rem);
  z-index: 1301;
  ${media.tablet`
    top:50%;
    max-width:65rem;
    height:40%;
    min-height:unset;
    >*{margin-bottom: 2.4rem;}
    `}
`;

const CopiedLinkAnimation = keyframes`
  from{ opacity: 0;}
  16%{ opacity: 1;}
  90%{ opacity:1;}
  to{ opacity:0;} 
`;

export const CopiedLinkOverlay = styled(Flex)`
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(0, 59, 92, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1300;
  animation: ${CopiedLinkAnimation} 3s 1 forwards;
`;

export const CopiedLinkP = styled(P)`
  margin-top: 1rem;
  margin-bottom: 0;
`;
