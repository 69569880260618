import Loader from 'src/components/Loader/Loader.component';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { getAvailableProducts } from 'src/services/orderLink/orderLink.config';
import { getAvailableProductsMethodApi } from 'src/services/orderLink/orderLink.request';
import { useEffect } from 'react';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { AccountStatus } from 'src/utils/config/stateAccount';
import { LinkFlowContext } from './CreateLinkFlow.helper';
import { RoutePath } from 'src/routers/routers.config';
import { Product } from 'src/services/orderLink/orderLink.types';
import { CategoryCodes } from 'src/utils/types/common.types';

function CreateLinkFlow() {
  const { completed } = AccountStatus;
  const AccountType = useProfileStore((state) => state.AccountType);
  const statoAccount = useProfileStore((state) => state.Status);
  const stateFlow = usePayByLinkStore((state) => state.stateFlow);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  useEffect(() => {
    if (statoAccount !== completed) {
      navigate(RoutePath.oops);
      return;
    }
  }, []);

  useEffect(() => {
    if (!stateFlow) {
      navigate(`${RoutePath.privateArea}?typeAccount=${AccountType}`);
      return;
    }
  }, [pathname]);

  const { isLoading, data, isFetching } = useQuery(
    getAvailableProducts,
    getAvailableProductsMethodApi,
  );
  if (isLoading || isFetching)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  const products = data?.data?.ResultSet?.reduce(
    (acc: any, product: Product) => {
      if (!acc[product.CategoryCode])
        acc[product.CategoryCode] = Object.create(null);
      acc[product.CategoryCode][product.Code] = Object.create(null);
      return acc;
    },
    Object.create(null),
  );
  const availableProducts = data?.data?.ResultSet;
  const categories =
    availableProducts?.reduce((acc: CategoryCodes[], item: Product) => {
      if (!acc.includes(item.CategoryCode)) acc.push(item.CategoryCode);
      return acc;
    }, []) ?? [];

  const linkFlowContext = Object.create(null) as LinkFlowContext;

  linkFlowContext.availableProducts = availableProducts!;
  linkFlowContext.products = products;
  linkFlowContext.categories = categories;

  return <Outlet context={linkFlowContext} />;
}
export default CreateLinkFlow;
