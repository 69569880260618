import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import PaymentMethodDetail from 'src/components/PaymentMethodDetail/PaymentMethodDetail.component';
import { getPaymentIcon } from 'src/components/PaymentMethodElement/PaymentMethodElement.helpers';
import StatusPayByLink from 'src/components/StatusPayByLink/StatusPayByLink.component';
import { OrderStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CreditCard } from 'src/images/icons/credit_card.svg';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { IncomeRatios } from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H3, H4, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { OrderDetailContextType } from '../../OrderDetail.component';
import { cardIsExpired } from 'src/utils/functions/cardExpired';
import { InstallmentsRecapStatusComponent } from 'src/components/InstallmentsRecapStatus/InstallmentsRecapStatus.component';

type Props = {
  creationDate?: string;
  merchantName?: string;
  amount?: number;
  paymentMethod?: PaymentMethodResult;
  backupPaymentMethod?: PaymentMethodResult | null;
  incomeRatios: IncomeRatios;
  status?: StatusPyByLinkValues | OrderStatus;
};

const ResponsiveOrderDetailCard: React.FC<Props> = ({
  amount,
  status,
  creationDate,
  merchantName,
  paymentMethod,
  backupPaymentMethod,
  incomeRatios,
}) => {
  const { changeMethodHandler } =
    useOutletContext<OrderDetailContextType>() ?? {};
  const { ExpMonth, ExpYear, Last4, Network } = paymentMethod?.Details ?? {};
  const {
    ExpMonth: bExpMonth,
    ExpYear: bExpYear,
    Last4: bLast4,
    Network: bNetwork,
  } = backupPaymentMethod?.Details ?? {};
  const methodExpired = cardIsExpired(paymentMethod);
  const bMethodExpired = cardIsExpired(backupPaymentMethod);
  const icon = getPaymentIcon(Network);
  const bIcon = getPaymentIcon(bNetwork);
  const formattedCreationDate = refactorFormatDate(
    creationDate ?? '',
    'DD MMMM YYYY',
  );
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const formattedAmount = formatCurrency(amount ?? 0);

  const progress = {
    green: incomeRatios.PaidRatio,
    red: incomeRatios.OverdueRatio,
    white: incomeRatios.ProcessingRatio,
  };

  return (
    <div className="flex flex-col gap-4 pb-8 pt-4 lg:flex-row lg:gap-6">
      <div className="basis-2/3">
        <div className="grid gap-8">
          <div>
            <div className="flex justify-between">
              <P>
                <Translate id="lbl.creationDate" />
              </P>
              <div className="lg:hidden">
                <StatusPayByLink statusLink={status ?? 1} typeAccount="BUYER" />
              </div>
            </div>
            <H4>{formattedCreationDate}</H4>
          </div>
          <div className="overflow-hidden">
            <P>
              <Translate id="lbl.supplier" />
            </P>
            <H4 className="line-clamp-2">{merchantName}</H4>
          </div>
          <div>
            <P>
              <Translate id="lbl.orderImport" />
            </P>
            <H3>{formattedAmount}</H3>
          </div>
          <div>
            <InstallmentsRecapStatusComponent
              progressBarProps={{ progress }}
              installmentRatios={incomeRatios}
            />
          </div>
        </div>
      </div>
      <span className="block border-b border-pgreyoutlined lg:border-l" />
      <div className="basis-1/3">
        <div className="grid gap-8">
          <div className="hidden gap-1 lg:grid">
            <P>
              <Translate id="lbl.linkStatus" />
            </P>
            <StatusPayByLink statusLink={status ?? 1} typeAccount="BUYER" />
          </div>
          <div>
            <P>
              <Translate id="lbl.orderPaymentMethod1" />
            </P>
            <div className="flex justify-between lg:flex-col lg:gap-8">
              <PaymentMethodDetail
                last4={Last4}
                payIcon={icon}
                dueDate={ExpMonth?.toString().padStart(2, '0') + '/' + ExpYear!}
                expired={methodExpired}
                type={paymentMethod?.Type}
                country={paymentMethod?.Details?.Country}
              />
              {status === 4 && (
                <Button
                  sizeOnDesktop="medium"
                  i18n
                  className="max-w-[32.7rem]"
                  translatedText="lbl.change"
                  rightChild={<Icon svgIconComponent={CreditCard} />}
                  onClick={changeMethodHandler}
                />
              )}
            </div>
          </div>
          {backupPaymentMethod && paymentMethod?.Type === 'sepa_debit' && (
            <div>
              <P>
                <Translate id="lbl.orderPaymentMethod2" />
              </P>
              <div className="flex justify-between lg:flex-col lg:gap-8">
                <PaymentMethodDetail
                  last4={bLast4}
                  payIcon={bIcon}
                  dueDate={
                    bExpMonth?.toString().padStart(2, '0') + '/' + bExpYear!
                  }
                  expired={bMethodExpired}
                  type={backupPaymentMethod?.Type}
                  country={backupPaymentMethod?.Details?.Country}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveOrderDetailCard;
