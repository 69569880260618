const typographicScale = {
  h1: '3.6rem',
  h2: '3rem',
  h3: '2.2rem',
  h4: '1.8rem',
  h5: '1.6rem',
  h6: '1.4rem',
  p: '1.4rem',
  input: '1.6rem',
};

if (process.env.NODE_ENV !== 'production') {
  Object.freeze(typographicScale);
}

export type TypographicScale = Readonly<typeof typographicScale>;
export type TypographicScaleKeys = Readonly<keyof typeof typographicScale>;

export const applyTypographyOf: (element: TypographicScaleKeys) => string = (
  element: TypographicScaleKeys,
) => `
  font-size: ${typographicScale[element]};
`;

export default typographicScale;
