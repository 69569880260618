import { ButtonProps, Variant } from 'src/components/Button/Button.types';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as chatIcon } from 'src/images/icons/chat-icon.svg';
import NeedHelpBase from './NeedHelpBase.component';

type NeedHelpButtonProps = ButtonProps & {
  label?: string;
  customIcon?: boolean;
  variant?: Variant;
};
const NeedHelpButton: React.FC<NeedHelpButtonProps> = ({
  customIcon,
  label,
  variant = 'Tertiary',
  ...otherProps
}) => {
  const text = label ?? 'supportModal.Button';
  return (
    <>
      {customIcon ? (
        <NeedHelpBase
          variant={variant}
          i18n
          translatedText={text}
          {...otherProps}
        />
      ) : (
        <NeedHelpBase
          variant={variant}
          i18n
          translatedText={text}
          {...otherProps}
          leftChild={<Icon iconSize="2rem" svgIconComponent={chatIcon} />}
        />
      )}
    </>
  );
};

export default NeedHelpButton;
