import { InputFieldProps } from 'src/components/InputField/InputField.types';
import React from 'react';
import {
  InputDateCustomStyled,
  InputDateStyled,
} from './style/InputDate.style';

const InputDate: React.FC<InputFieldProps> = (props) => {
  return props.custom ? (
    <InputDateCustomStyled
      data-component="input-date"
      name={props.name}
      disabled={props.disabled}
      type="date"
      isPresentMessage={props.isPresentMessage}
      onFocus={props.onFocus}
    />
  ) : (
    <InputDateStyled
      data-component="input-date"
      name={props.name}
      disabled={props.disabled}
      type="date"
      isPresentMessage={props.isPresentMessage}
    />
  );
};

export default InputDate;
