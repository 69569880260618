import FlexInstallmentCard, {
  FlexInstallmentCardProps,
} from 'src/components/FlexInstallmentCard/FlexInstallmentCard.component';
import { flexDate } from 'src/components/FlexInstallmentCard/FlexInstallmentCard.config';
import { ReactComponent as StepperCircle } from 'src/images/icons/verticalStepperCircle.svg';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { Flex } from 'src/style/flexbox.style';
import {
  StepperCircleContainer,
  VerticalStepperContainer,
  VerticalStepperRowLabel,
} from './style/VerticalStepperRow.style';

interface VerticalStepperProp extends FlexInstallmentCardProps {
  onClickHandler: () => void;
  numRate: number;
}

function VerticalStepperRow({
  focused,
  index,
  valid,
  disabled,
  errorMessageDate,
  errorMessageAmount,
  onClickHandler,
  numRate,
}: VerticalStepperProp) {
  const { getValues } = useFormContext();
  const values = getValues();
  const nameDate = `${flexDate}-${index}`;
  const prevNameDate = `${flexDate}-${index - 1}`;
  const nextNameDate = `${flexDate}-${index + 1}`;

  const labelParam =
    index > 0
      ? values[nameDate] && values[prevNameDate]
        ? moment(values[nameDate]).diff(moment(values[prevNameDate]), 'days')
        : undefined
      : numRate;

  const label =
    index > 0
      ? labelParam && labelParam > 0
        ? 'lbl.afterXDays'
        : 'lbl.installmentsInterval'
      : 'lbl.XInstallmentsSet';

  const minDate = new Date(
    values[prevNameDate] ?? Date.now() - 3600 * 24 * 1000,
  );
  minDate.setDate(minDate.getDate() + 1);

  const maxDate = new Date(values[nextNameDate]);
  maxDate.setDate(maxDate.getDate() - 1);

  return (
    <VerticalStepperContainer
      data-component="vertical-stepper-row"
      disabled={disabled}
      justify="center"
      align="center"
      padding="3.6rem 0"
      margin="auto"
      gap="2rem"
      onClick={onClickHandler}
      data-error={
        errorMessageDate?.length > 0 || errorMessageAmount?.length > 0
      }
      data-focused={focused}
    >
      <StepperCircleContainer>
        <StepperCircle />
      </StepperCircleContainer>
      <Flex flexDirection="column" align="center">
        <VerticalStepperRowLabel margin="0">
          <Translate id={label} data={{ x: labelParam }} />
        </VerticalStepperRowLabel>
        <FlexInstallmentCard
          index={index}
          {...{
            valid,
            disabled,
            focused,
            errorMessageDate,
            errorMessageAmount,
            minDate,
            maxDate,
          }}
        />
      </Flex>
    </VerticalStepperContainer>
  );
}

export default VerticalStepperRow;
