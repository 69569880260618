import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import { OrderStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { OrderDetailContextType } from '../OrderDetail.component';

export const OrderDetailHelp: React.FC = () => {
  const navigate = useNavigate();
  const { orderData, changeMethodHandler, orderGuid } =
    useOutletContext<OrderDetailContextType>();
  return (
    <div className="container">
      <GoBackButton label="text.orderDetail" />
      <div className="my-6 grid gap-6">
        {((orderData?.ResultSet.Status === OrderStatus.regularIncoming &&
          orderData?.ResultSet.Installments.some(
            (installment) => installment.CanPay,
          )) ||
          (orderData?.ResultSet.Status === OrderStatus.planned &&
            orderData?.ResultSet.Installments.some(
              (installment) => installment.CanPay,
            ))) && (
          <PayByLinkChoiceCard
            title="lbl.payMultiRegular"
            onClick={() =>
              navigate(
                `${RoutePath.orderDetail}${orderGuid}/${RoutePath.selectInstallments}`,
              )
            }
          />
        )}
        {orderData?.ResultSet.Status === OrderStatus.expiredPayment && (
          <PayByLinkChoiceCard
            title="lbl.payMultiExpired"
            onClick={() =>
              navigate(
                `${RoutePath.orderDetail}${orderGuid}/${RoutePath.selectExpiredInstallments}`,
              )
            }
          />
        )}
        {orderData?.ResultSet.Status !== OrderStatus.fullCashed && (
          <PayByLinkChoiceCard
            title="text.changePaymentOrder"
            onClick={changeMethodHandler}
          />
        )}
        <PayByLinkChoiceCard
          title="lbl.requestAssistance"
          onClick={() => navigate(RoutePath.support)}
        />
      </div>
    </div>
  );
};

export default OrderDetailHelp;
