import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Fishes } from 'src/images/icons/fishes.svg';
import { useSession } from 'src/services/session/session.context';
import { H4 } from 'src/style-utils/typographic';

function EcommerceWrongData() {
  const { onLogout } = useSession();
  return (
    <>
      <Icon
        svgIconComponent={Fishes}
        iconHeight="19.5rem"
        iconSize="26.6rem"
        margin="2.6rem auto 2.7rem"
        display="flex"
      />
      <H4 textAlign="center">
        <Translate id={'text.ecommerceWrongData'} />
      </H4>
      <ButtonContainer>
        <Button
          onClick={() => {
            (window as any).parent.postMessage(
              JSON.stringify({ status: 'close' }),
              '*',
            );
            onLogout();
          }}
          minWidth="100%"
          variant="Primary"
        >
          <Translate id="lbl.backToEcommerce" />
        </Button>
        <NeedHelpButton />
      </ButtonContainer>
    </>
  );
}

export default EcommerceWrongData;
