import styled from 'styled-components';
import { ContainerPadding } from 'src/style/Container.style';
import colors from 'src/style-utils/colors';

export const ContStripe = styled(ContainerPadding)`
  width: 100%;
`;
export const PaymentErrorMessage = styled.div`
  width: 100%;
  color: ${colors.redInput};
  padding: 1rem 0 0 0;
`;
