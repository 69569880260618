import FormOtpPhone from 'src/pages/registration/partials/FormOtpPhone/FormOtpPhone.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationStepOtpPhone: React.FC = () => {
  return (
    <FormOtpPhone
      nextPath={RoutePath.registrationMerchant + RoutePath.passwordStep}
      step={7}
      stepMax={9}
    />
  );
};

export default RegistrationStepOtpPhone;
