import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SupportCards from 'src/components/SupportCards/SupportCards.component';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import { useOutletContext } from 'react-router-dom';
import { ContainerPadding } from 'src/style/Container.style';

const PayByLinkActionsSupport = () => {
  const { OrderId, pathDetail } = useOutletContext<PayByLinkDetailsContext>();
  return (
    <>
      <PayByLinkHeader
        stepBack
        title="text.OutContacts"
        idLink={OrderId}
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <SupportCards />
      </ContainerPadding>
    </>
  );
};

export default PayByLinkActionsSupport;
