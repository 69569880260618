import {
  FormatCurrencyOptions,
  CurrencyFormatter,
  I18nFormatters,
} from './i18n.types';
import { currencyCode } from './i18n.config';
import i18n from './i18n';
import { mapCurrenciesLocale } from 'src/components/CurrencyConverter/CurrencyConverter.config';

const defaultFormatCurrencyOptions: FormatCurrencyOptions = {
  // Assumes that '10_00 (EUR)' is '10,00 €'
  isNumberExpressedInCents: false,
  // If 'true' displays '10,00 €' as '10 €'.
  // Note that unless 'hideCents' or 'hideCentsIfInteger' is set to 'true',
  // fractional digits are always displayed.
  hideCents: false,
  // If 'true' displays '10,00 €' as '10 €' and '10,12 €' as '10,12 €'.
  // Note that unless 'hideCents' or 'hideCentsIfInteger' is set to 'true',
  // fractional digits are always displayed.
  hideCentsIfInteger: false,
  // removes any white space if present.
  removeWhiteSpaces: true,
  // Returns a unitless formatted number.
  removeCurrencySymbols: false,
};

/**
 * Given the current locale generates a function that formats input number to
 * a localized currency string.
 * @param locale e.g. 'it-IT', 'en-GB'.
 * @param currency e.g. 'EUR', 'JPY'.
 * @return @see CurrencyFormatter in './i18n.types'.
 */
export function createFormatCurrency(
  locale: string,
  currency: string = currencyCode,
): CurrencyFormatter {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  const baseCurrencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const baseCurrencyFormatterWithoutFractionalDigits = new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  );

  return function formatCurrency(money, options) {
    const {
      isNumberExpressedInCents,
      hideCents,
      hideCentsIfInteger,
      removeWhiteSpaces,
      removeCurrencySymbols,
    } = {
      ...defaultFormatCurrencyOptions,
      ...options,
    };

    if (process.env.NODE_ENV !== 'production') {
      if (!Number.isFinite(money)) {
        throw new TypeError(
          `formatCurrency: input number (money) is not valid:\n` +
            `expected a finite number got '${money}' instead.\n` +
            'See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isFinite\n\n' +
            `This validation is disabled in production`,
        );
      }
    }

    let processedNumber = money;

    if (isNumberExpressedInCents) {
      processedNumber /= 100;
    }

    let isInteger = false;
    if (hideCentsIfInteger) {
      if ((processedNumber * 100) % 100 === 0) {
        isInteger = true;
      }
    }

    if (hideCents) {
      processedNumber = Math.floor(processedNumber);
    }

    let processedOutput =
      options?.hideCents || (options?.hideCentsIfInteger && isInteger)
        ? baseCurrencyFormatterWithoutFractionalDigits.format(processedNumber)
        : baseCurrencyFormatter.format(processedNumber);

    if (removeWhiteSpaces) {
      processedOutput = processedOutput.replace(/\s+/g, '');
    }

    if (removeCurrencySymbols) {
      processedOutput = processedOutput.replace(/[^\d.,\s]/g, '');
    }

    return processedOutput;
  };
}

export const createDateTimeFormatters = function (
  locale: string,
): Omit<I18nFormatters, 'formatCurrency'> {
  const hour12 = locale === 'en-US';

  // dd/mm/yyyy in 'it-IT'
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  // e.g 20 dic
  const dateOfYearFormatter = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
  });

  // test fermatter 02 dicembre 2022
  const dateFormatterLongComplete = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });

  // test fermatter dicembre 2022
  const dateFormatterLong = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
  });

  const timeFormatterShort = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12,
  });

  const timeFormatterLong = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12,
  });

  const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12,
  });

  return {
    formatDate: dateFormatter.format.bind(dateFormatter),
    formatTimeShort: timeFormatterShort.format.bind(timeFormatterShort),
    formatTimeLong: timeFormatterLong.format.bind(timeFormatterLong),
    formatDateOfYear: dateOfYearFormatter.format.bind(dateOfYearFormatter),
    formatDateTime: dateTimeFormatter.format.bind(dateTimeFormatter),
    formatDateLongComplete: dateFormatterLongComplete.format.bind(
      dateFormatterLongComplete,
    ),
    formatDateLong: dateFormatterLong.format.bind(dateFormatterLong),
  };
};

export const addi18nLanguage = (language: string, languageResource: object) => {
  i18n.addResourceBundle(language, 'translation', languageResource);
  i18n.changeLanguage(language);
};

export const isItalian = (country?: string) => {
  return country?.toUpperCase() === 'IT' || country?.toUpperCase() === 'ITALY';
};

export const currencyConvert = (
  money: number,
  currencyCode: string,
  currencyValue: number,
  options?: Partial<FormatCurrencyOptions>,
) => {
  const amount = money * currencyValue;
  const locale =
    mapCurrenciesLocale[currencyCode as keyof typeof mapCurrenciesLocale];

  const createFormatCurrencyLocale = createFormatCurrency(locale, currencyCode);
  const formattedAmount = createFormatCurrencyLocale(amount, options);

  return formattedAmount;
};
