import { css } from 'styled-components';
import breakpoints, { BreakpointsKeys } from './breakpoints';

type MediaQueryValue = (l: TemplateStringsArray, ...placeholders: any[]) => any;

type BreakpointMediaQueries = Omit<
  Record<BreakpointsKeys, MediaQueryValue>,
  'xs'
>;

type MediaQueries = {
  ie11: BreakpointMediaQueries & { all: MediaQueryValue };
  breakpoint: BreakpointMediaQueries;
  mobileXs: MediaQueryValue;
  mobileSm: MediaQueryValue;
  mobileMd: MediaQueryValue;
  tablet: MediaQueryValue;
  desktop: MediaQueryValue;
  desktopXl: MediaQueryValue;
  desktopXXl: MediaQueryValue;
};

/**
 * Media queries mixins.
 *
 * Usage
 * ```javascript
 * const MyElem = styled.p`
 *   -- styles applied to md or greater viewports. --
 *   ${media.breakpoint.md`
 *     color: pink;
 *   `};
 *
 *   -- styles applied to ie11 if the viewport is at least md. --
 *   ${media.ie11.md`
 *     color: cornflowerblue;
 *   `};
 * `;
 * ```
 */
const media: MediaQueries = (function generateMediaQueries() {
  const initialMediaObject = {
    ie11: {
      all: (literals, ...placeholders) => `
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        ${css(literals, ...placeholders)};
      }
    `,
    },
    breakpoint: {},
    desktop: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.xl}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    mobileXs: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.xs}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    mobileSm: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.sm}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    mobileMd: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.md}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    tablet: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.lg}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    desktopXl: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.xxl}px) {
        ${css(literals, ...placeholders)};
      }
    `,
    desktopXXl: (literals, ...placeholders) => css`
      @media all and (min-width: ${breakpoints.xxxl}px) {
        ${css(literals, ...placeholders)};
      }
    `,
  } as MediaQueries;

  const allBreakpointKeys = Object.keys(breakpoints) as BreakpointsKeys[];
  const output = initialMediaObject;

  for (let i = 0, len = allBreakpointKeys.length; i < len; i++) {
    const breakpointKey = allBreakpointKeys[i];

    if (breakpointKey !== 'xs') {
      const breakpointValue: number = breakpoints[breakpointKey];

      output.breakpoint[breakpointKey] = (literals, ...placeholders) => css`
        @media all and (min-width: ${breakpointValue}px) {
          ${css(literals, ...placeholders)};
        }
      `;

      // We generate the equivalent values for `ie11` because it does not support
      // nested media queries.
      output.ie11[breakpointKey] = (literals, ...placeholders) => css`
        @media all and (min-width: ${breakpointValue}px) and (-ms-high-contrast: active),
          all and (min-width: ${breakpointValue}px) and (-ms-high-contrast: none) {
          ${css(literals, ...placeholders)};
        }
      `;
    }
  }

  return output;
})();

export default media;
