import { ModalHeader } from 'src/components/Modal/partials/ModalHeader/ModalHeader.component';
import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import IncorrectDataModalBody from './IncorrectDataModalBody.component';
import IncorrectDataModalFooter from './IncorrectDataModalFooter.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'FindOutMore',
  headerComponent: ModalHeader,
  bodyComponent: IncorrectDataModalBody,
  footerComponent: IncorrectDataModalFooter,
});
