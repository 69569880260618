import { ModalFooterRoot } from 'src/components/Modal/partials/ModalFooter/style/ModalFooterDefault.style';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const ButtonContainerFooter = styled(ModalFooterRoot)`
  flex-direction: column;
  padding: 2.4rem 4rem;
  gap: 0.8rem;
  height: auto;
  min-height: auto;
  box-shadow: 0px 4px 24px 0px rgba(53, 48, 47, 0.24);
  overflow: unset;
  > button {
    min-width: 32.7rem;
  }
  ${media.tablet`
  height: auto;
  min-height: auto;
  padding: 2.4rem 4rem;
  > button {
    min-width: 28rem;
  }
  `}
`;
