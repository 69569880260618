import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type ModalInjectorProps = {
  modalName: string;
  children?: ReactNode;
};

const ModalInjector: React.FC<ModalInjectorProps> = ({
  children,
  modalName,
}) => {
  // https://reactjs.org/docs/hooks-reference.html#useref
  const rootPortalElementRef = React.useRef<HTMLDivElement>();
  const nodeToRestore = React.useRef<Element | null>(null);
  // This block is invoked only during the very first render.
  if (!rootPortalElementRef.current) {
    rootPortalElementRef.current = document.createElement('div');

    if (process.env.NODE_ENV !== 'production') {
      rootPortalElementRef.current.setAttribute('data-modal-name', modalName);
    }
  }

  // Subscribes the removal of the 'rootPortalElement' from the 'DOM' after
  // ModalInjector gets unmounted.
  React.useEffect(() => {
    if (rootPortalElementRef.current) {
      document.body.appendChild(rootPortalElementRef.current);

      nodeToRestore.current = document.activeElement;
    }
    const rootAppElem = document.getElementById('root');
    let startFocusSentinel: HTMLElement | null;
    let endFocusSentinel: HTMLElement | null;

    const handleKeyDown = (domEvent: KeyboardEvent) => {
      // 9 -> Tab key
      if (domEvent.keyCode !== 9 || !startFocusSentinel || !endFocusSentinel) {
        return;
      }

      if (!domEvent.shiftKey && domEvent.target === endFocusSentinel) {
        domEvent.preventDefault();
        startFocusSentinel.focus();
        return;
      }

      if (domEvent.shiftKey && domEvent.target === startFocusSentinel) {
        domEvent.preventDefault();
        endFocusSentinel.focus();
        return;
      }
    };

    if (rootAppElem) {
      rootAppElem.setAttribute('aria-hidden', 'true');
    }

    if (rootPortalElementRef.current) {
      const portalRoot = rootPortalElementRef.current;

      portalRoot.setAttribute('role', 'dialog');
      portalRoot.setAttribute('aria-modal', 'true');
      portalRoot.setAttribute('tabIndex', '-1');
      portalRoot.addEventListener('keydown', handleKeyDown);

      startFocusSentinel = portalRoot.querySelector(
        '[data-focus-sentinel="start"]',
      );

      endFocusSentinel = portalRoot.querySelector(
        '[data-focus-sentinel="end"]',
      );

      if (startFocusSentinel) {
        (startFocusSentinel as HTMLElement).focus();
      }
    }

    return () => {
      if (rootAppElem) {
        rootAppElem.removeAttribute('aria-hidden');
      }

      rootPortalElementRef.current?.removeEventListener(
        'keydown',
        handleKeyDown,
      );

      // Tries to restore the focus to the node
      if (
        nodeToRestore.current &&
        typeof (nodeToRestore.current as HTMLElement).focus === 'function'
      ) {
        (nodeToRestore.current as HTMLElement).focus();
      }

      document.body.removeChild(rootPortalElementRef.current as Node);
    };
  }, []);

  // Docs: https://reactjs.org/docs/portals.html

  return <>{ReactDOM.createPortal(children, rootPortalElementRef.current)}</>;
};

export default ModalInjector;
