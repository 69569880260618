import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import Translate from 'src/components/Translate/Translate.component';
import { P } from 'src/style-utils/typographic';
import { UL } from '../RegistrationStepVAT.style';

const StepVATModalBody = () => {
  return (
    <ModalBody>
      <P>
        <Translate
          id="text.whatIsVAT"
          extraTranslationProps={{
            components: { ul: <UL />, li: <li /> },
          }}
        />
      </P>
    </ModalBody>
  );
};

export default StepVATModalBody;

// "La composizione esatta di un VAT number varia da un paese all'altro, ma solitamente segue un formato simile. In generale, un VAT number è composto da un codice di paese e un numero di identificazione fiscale specifico per l'impresa. Ecco alcuni esempi di VAT numbers in diversi stati europei: <ul>     <li>Francia: FR12345678901</li>     <li>Spagna: ESX1234567X</li>     <li>Germania: DE123456789</li>      <li>Romania: RO1234567890     </li>     <li>Polonia: PL1234567890</li>     <li>Olanda: NL123456789B01     </li></ul> <br/> Si prega di notare che questi sono solo esempi generici e che i formati precisi e i requisiti per l'ottenimento di un VAT number possono variare da un paese all'altro. È importante consultare le autorità fiscali del paese in cui si desidera registrare un VAT number per ottenere informazioni dettagliate e aggiornate. ");

// Per Germania e Austria devi inserire il codice paese come da esempio:

// <ul>     <li>Germania: <strong>DE</strong>123456789</li>
// <li>Austria: <strong>AUT</strong>123456789</li>
// </ul>
// Per tutti gli altri paesi non è necessario inserire il codice paese.
