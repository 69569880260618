export interface ProgressBarProps {
  progress: {
    green: number;
    red: number;
    white: number;
  };
}

function ProgressBar({ progress }: ProgressBarProps) {
  const { red, green, white } = progress;
  return (
    <div
      className="flex h-7 gap-[0.2rem] overflow-hidden rounded-full border-2 border-white outline outline-1 outline-pgreyoutlined lg:h-8"
      style={{ marginBottom: '0.8rem' }}
    >
      {green > 0 && (
        <span
          className="block h-full rounded bg-success"
          style={{ width: `${progress.green}%` }}
        />
      )}
      {red > 0 && (
        <span
          className="block h-full rounded bg-danger"
          style={{ width: `${progress.red}%` }}
        />
      )}
      {white > 0 && (
        <span
          className="block h-full rounded border-green-500 bg-white"
          style={{
            width: `${progress.white}%`,
            border: '1px solid green',
            backgroundColor: 'white',
            borderTopLeftRadius: red > 0 || green > 0 ? '5px' : '20px',
            borderBottomLeftRadius: red > 0 || green > 0 ? '5px' : '20px',
            borderTopRightRadius: red + green + white < 100 ? '5px' : '20px',
            borderBottomRightRadius: red + green + white < 100 ? '5px' : '20px',
          }}
        />
      )}
      {red + green + white < 100 && (
        <span
          className="block h-full rounded bg-pgreyoutlined"
          style={{
            width: `${100 - progress.red - progress.green - progress.white}%`,
          }}
        />
      )}
    </div>
  );
}

export default ProgressBar;
