export const listCurrencies = [
  { label: 'currency.lbl.czk', optionId: 'CZK' }, //Corona ceca
  { label: 'currency.lbl.dkk', optionId: 'DKK' }, //Corona danese
  { label: 'currency.lbl.nok', optionId: 'NOK' }, //Corona norvegese
  { label: 'currency.lbl.sek', optionId: 'SEK' }, //Corona svedese
  { label: 'currency.lbl.eur', optionId: 'EUR' }, //Euro
  { label: 'currency.lbl.huf', optionId: 'HUF' }, //Fiorino ungherese
  { label: 'currency.lbl.chf', optionId: 'CHF' }, //Franco svizzero
  { label: 'currency.lbl.ron', optionId: 'RON' }, //Leu romeno
  { label: 'currency.lbl.bgn', optionId: 'BGN' }, //Lev bulgaro
  { label: 'currency.lbl.gbp', optionId: 'GBP' }, //Sterlina inglese
  { label: 'currency.lbl.pln', optionId: 'PLN' }, //Zloty polacco
];

export const mapCurrenciesLocale = {
  CZK: 'cs-CZ',
  DKK: 'da-DK',
  NOK: 'no-NO',
  SEK: 'sv-SE',
  EUR: 'de-DE',
  HUF: 'hu-HU',
  CHF: 'de-CH',
  RON: 'ro-RO',
  BGN: 'bg-BG',
  GBP: 'en-GB',
  PLN: 'pl-PL',
};
