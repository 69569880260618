import DataTable from 'src/components/DataTable/DataTable';
import Loader from 'src/components/Loader/Loader.component';
import { GetStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import NoBuyersData from './NoBuyersData.component';
import NoCriteriaData from './NoCriteriaData.component';
import { buyersTableColumnConfig } from './TableBuyers.config';

interface Props {
  data?: GetStatsDataResponse;
  isLoading: boolean;
  criteria?: boolean;
}

const TableBuyers = ({ data, isLoading, criteria }: Props) => {
  const tabProps = {
    data: data?.ResultSet?.BuyersStats ?? [],
    hideHeader: false,
    NoDataComponent: criteria ? NoCriteriaData : NoBuyersData,
    columns: buyersTableColumnConfig,
    customTableTheme: {
      cells: {
        style: {
          ':first-child': {
            background: '#fff',
            display: 'block',
          },
          ':last-child': {
            background: '#fff',
            '::before': {
              display: 'none',
            },
          },
        },
      },
    },
  };

  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return <DataTable {...tabProps} />;
};

export default TableBuyers;
