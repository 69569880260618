import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 250ms ease-in-out;
  grid-template-rows: 0fr;
  ${media.tablet`
    grid-template-rows: 1fr;
  `}
`;
export const AccordionArrow = styled(Icon)`
  color: ${colors.greyStatus};
  margin-left: 2rem;
  rotate: 0deg;
  transition: rotate 250ms ease-in-out;
  ${media.tablet`
    display: none;
  `}
`;

export const CompanyDataCardContainer = styled(Card)`
  padding: 1.6rem;
  width: 32.6rem;
  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &[data-expanded='true'] ${AccordionBody} {
    grid-template-rows: 1fr;
  }
  &[data-expanded='true'] ${AccordionArrow} {
    rotate: -180deg;
  }
  ${media.tablet`
    border-bottom: 0;
    cursor: default;
    width: 28.8rem;
  `}
`;

export const DataContainer = styled.div`
  background-color: ${colors.backgroundGrey};
  padding: 1.6rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1.6rem;
`;
