import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  initRecoveryData,
  performRecoveryData,
  refreshRecoveryOtp1,
  refreshRecoveryOtp2,
  verifyRecoveryOtp1,
  verifyRecoveryOtp2,
} from './recoveryData.config';
import {
  InitRecoveryData,
  InitRecoveryResponse,
  PerformRecoveryData,
  RecoveryDataResponse,
  RefreshRecoveryData,
  VerifyRecoveryOtp1Data,
  VerifyRecoveryOtp1Response,
  VerifyRecoveryOtp2Data,
} from './recoveryData.types';

export const initRecoveryDataMethodApi = async (data: InitRecoveryData) => {
  const requestConfig = makeEndpointApiConfig({
    url: initRecoveryData,
    method: 'POST',
    data,
  });
  return await apiRequest.request<InitRecoveryResponse>(requestConfig);
};

export const verifyRecoveryOtp1MethodApi = async (
  data: VerifyRecoveryOtp1Data,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifyRecoveryOtp1,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
  });
  return await apiRequest.request<VerifyRecoveryOtp1Response>(requestConfig);
};
export const verifyRecoveryOtp2MethodApi = async (
  data: VerifyRecoveryOtp2Data,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifyRecoveryOtp2,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
  });
  return await apiRequest.request<RecoveryDataResponse>(requestConfig);
};
export const refreshRecoveryOtp1MethodApi = async (
  data: RefreshRecoveryData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: refreshRecoveryOtp1,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
  });
  return await apiRequest.request<RecoveryDataResponse>(requestConfig);
};
export const refreshRecoveryOtp2MethodApi = async (
  data: RefreshRecoveryData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: refreshRecoveryOtp2,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
  });
  return await apiRequest.request<RecoveryDataResponse>(requestConfig);
};
export const performRecoveryDataMethodApi = async (
  data: PerformRecoveryData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: performRecoveryData,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
  });
  return await apiRequest.request<RecoveryDataResponse>(requestConfig);
};
