import InputField from 'src/components/InputField/InputField.component';
import { IconInsideInput } from 'src/components/InputField/style/input.style';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const InputCustom = styled(InputField)`
  width: 9.6rem;
  height: 9.6rem;
  display: inline-block;
  ${IconInsideInput} {
    display: none;
  }
  input {
    height: 9.6rem;
    padding: unset;
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 6rem;
    text-align: center;
    border: 1.5px solid transparent;
    box-shadow: 0 0.4rem 0.8rem 0.3rem rgba(0, 59, 92, 0.12);
    border-radius: 0.8rem;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    :focus {
      border: 1.5px solid ${colors.lightGreyBorder};
      box-shadow: 0 0.4rem 0.8rem 0.3rem rgba(0, 59, 92, 0.12);
    }
  }
`;
