import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type AutoSubmitOnChangeProps = {
  onSubmit: (...args: any[]) => any;
  watchFields?: string[];
};

const AutoSubmitOnChange = (props: AutoSubmitOnChangeProps) => {
  const { onSubmit, watchFields = [] } = props;
  const { handleSubmit, watch } = useFormContext();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (watchFields.length === 0) {
        return handleSubmit(onSubmit)();
      }
      if (name && watchFields.includes(name)) {
        handleSubmit(onSubmit)();
      }
      return null;
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);
  return null;
};

export default AutoSubmitOnChange;
