import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import CopiedLinkToast from 'src/components/CopiedLinkToast/CopiedLinkToast.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import CreateLinkInfo from 'src/pages/createLinkFlow/partials/CreateLinkInfo/CreateLinkInfo.component';
import { LinkUrlGroup } from 'src/pages/createLinkFlow/partials/SendLinkNow/SendLinkNow.style';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { P } from 'src/style-utils/typographic';
import { ContainerPadding } from 'src/style/Container.style';
import { writeToClipboard } from 'src/utils/functions/clipboard';

const PayByLinkActionsSendLinkNow = () => {
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const { PayByLinkUrl, OrderId, path, pathDetail } =
    useOutletContext<PayByLinkDetailsContext>();
  return (
    <>
      <PayByLinkHeader
        title="text.paymentLink"
        idLink={OrderId}
        stepBack
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <TextDefaultPage title="text.forwardNowToYourClient" />
        <P colorBlack>
          <Translate id="lbl.linkPayment" />
        </P>
        <LinkUrlGroup>
          <div>{PayByLinkUrl}</div>
          <Button
            onClick={() => {
              setOpenToast(true);
              setTimeout(() => setOpenToast(false), 3000);
              writeToClipboard(PayByLinkUrl);
            }}
            variant="LinkPrimary"
            height="100%"
            i18n
            translatedText="link.copyLinkStrong"
          />
        </LinkUrlGroup>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            onClick={() => navigate(path)}
          >
            <Translate id={'text.seeAllPayByLink'} />
          </Button>
        </ButtonContainer>
      </ContainerPadding>
      <CreateLinkInfo resent />
      {openToast && <CopiedLinkToast />}
    </>
  );
};

export default PayByLinkActionsSendLinkNow;
