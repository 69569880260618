import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_right.svg';
import { LinkAccount } from 'src/pages/account/style/LinkAccount.style';
import React from 'react';
import { RoutePath } from 'src/routers/routers.config';
import { P } from 'src/style-utils/typographic';
import { UsageSettingOptionsArray } from '../usageSettingOptions/usageSettingOptions.config';

const UsageSettingOptions: React.FC = () => {
  return (
    <div className="container">
      <GoBackButton url={RoutePath.usageSetting} label="lbl.usageSetting" />
      <div className="mx-auto max-w-md">
        <TextDefaultPage title="text.linkPaymentWAdvPayment" />
        {UsageSettingOptionsArray.map((card: any, index: any) => (
          <React.Fragment key={index}>
            <LinkAccount to={card.path}>
              <P>
                <Translate id={card.text} />
              </P>
              <Icon iconSize={'2.8rem'} svgIconComponent={ArrowRight} />
            </LinkAccount>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default UsageSettingOptions;
