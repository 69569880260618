import ModalFooterDefault from 'src/components/Modal/partials/ModalFooter/ModalFooterDefault.component';
import { ModalHeader } from 'src/components/Modal/partials/ModalHeader/ModalHeader.component';
import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import StepVATModalBody from './StepVATModalBody.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'Modal Info',
  headerComponent: ModalHeader,
  bodyComponent: StepVATModalBody,
  footerComponent: ModalFooterDefault,
});
