import { ReactComponent as PaymentLink } from 'src/images/icons/payment_link.svg';
import { ReactComponent as EuroIcon } from 'src/images/icons/euro.svg';
import { ReactComponent as Call } from 'src/images/icons/call.svg';
import { ReactComponent as EditPencil } from 'src/images/icons/editPencil.svg';
import { ReactComponent as InstallmentCustom } from 'src/images/icons/installment-custom.svg';
import { ReactComponent as Step } from 'src/images/icons/step.svg';
import { ReactComponent as Partner } from 'src/images/icons/partner.svg';

export const remodulationStepperArray = [
  {
    title: 'text.remodulationInfoStepperTitle1',
    subTitle: 'text.remodulationInfoStepperDescr1',
    icon: Call,
  },
  {
    title: 'text.remodulationInfoStepperTitle2',
    subTitle: 'text.remodulationInfoStepperDescr2',
    icon: PaymentLink,
  },
  {
    title: 'text.remodulationInfoStepperTitle3',
    subTitle: 'text.remodulationInfoStepperDescr3',
    icon: EditPencil,
  },
  {
    title: 'text.remodulationInfoStepperTitle4',
    subTitle: 'text.remodulationInfoStepperDescr4',
    icon: EuroIcon,
  },
];

export const remodulationListItems = [
  {
    SvgComponent: InstallmentCustom,
    label: 'text.remodulationInfoAccordionTitle1',
    content: 'text.remodulationInfoAccordionDescr1',
  },
  {
    SvgComponent: Step,
    label: 'text.remodulationInfoAccordionTitle2',
    content: 'text.remodulationInfoAccordionDescr2',
  },
  {
    SvgComponent: Partner,
    label: 'text.remodulationInfoAccordionTitle3',
    content: 'text.remodulationInfoAccordionDescr3',
  },
];
