import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import InputPasswordField from 'src/components/InputPasswordField/InputPasswordField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { passwordField } from 'src/services/registration/registration.config';
import {
  DataTypeArray,
  UpdateSignUpData,
  updateSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { formConfigRegistrationStepPassword } from './FormPassword.helpers';
import { createBrowserHistory } from '@remix-run/router';
import { ButtonContainer } from 'src/components/Button/style/Button.style';

const FormPassword: React.FC<FormProps> = ({ nextPath, step, stepMax }) => {
  const history = createBrowserHistory();
  const { initialValues, resolver } = formConfigRegistrationStepPassword();
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(
    (values: any) => updateSignUpSessionMethodApi(values),
    {
      onSuccess: (res) => {
        if (res.data.ResultSet) navigate(nextPath);
      },
      onError: () => {
        navigate(RoutePath.oops);
      },
    },
  );

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = async (data: any) => {
    const UpdateData: UpdateSignUpData = {
      DataType: DataTypeArray.password,
      DataValue: data[passwordField],
      SessionGuid: sessionGuid,
    };
    mutate(UpdateData);
  };

  return (
    <>
      <FlowHeader
        label="lbl.choosePassword"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton
        backButtonAction={() => history.go(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.choosePassword" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputPasswordField />
              <ButtonContainer>
                <SubmitButton minWidth="100%">
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
};

export default FormPassword;
