import { OrderDetails } from 'src/services/getOrderDetails/getOrderDetails.types';

export type UrlParams = {
  idlink: string;
};

export interface PayByLinkDetailsContext extends OrderDetails {
  refetchHandler: () => void;
  madeFromPlugin: boolean;
  path: string;
  pathDetail: string;
}
export type PayByLinkDetailsPageProps = {
  madeFromPlugin: boolean;
};

export const resendLimit = 2;
