import NativeSelect from 'src/components/NativeSelect/NativeSelect.component';
import SelectField from 'src/components/SelectField/SelectField.component';
import { useCreateOptions } from './SelectCountry.hook';
import { useLanguage } from 'src/services/i18n/i18n.context';

const SelectCountry = () => {
  const { language } = useLanguage();
  const options: ListboxOption[] = useCreateOptions(language);

  return (
    <SelectField
      data-component="select-country"
      id="2"
      name="country"
      i18n={false}
      enableModal={true}
      options={options}
      component={NativeSelect}
    />
  );
};

export default SelectCountry;
