import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const ContainerBar = styled.div`
  width: 100%;
  height: 0.4rem;
  background-color: ${colors.backgroundOutlined};
  > div {
    height: 100%;
    background: ${colors.bgBtnSecondary};
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

export const CustomResponsiveContainer = styled(ResponsiveContainer)`
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  > div {
    margin-right: auto;
  }
  ${media.mobileSm`
    text-align: center;
    > div {
    margin-left: auto;
  }
  `}
`;

export const WhiteSpace = styled.span`
  display: none;
  width: 3.6rem;
  height: 3.6rem;
  ${media.mobileSm`
    display: block;
  `}
`;
