import { TableColumn } from 'src/components/DataTable/DataTable.types';
import { memo } from 'react';

import Column from './RowCell';
import TableRowCellWrapper, {
  TableRowWrapper,
  TableRowWrapperProps,
} from '../style/DataTable.style';

type TableRowProps<TData> = TableRowWrapperProps & {
  columns: TableColumn<TData>[];
  rowData: TData;
  defaultExpanded?: boolean;
};

const TableRow = <TData extends object>(props: TableRowProps<TData>) => {
  const { rowData, columns } = props;

  return (
    <TableRowWrapper role="rowgroup">
      <TableRowCellWrapper role="row">
        {columns.map((column) => (
          <Column
            key={column.id}
            numberOfCells={columns.length}
            column={column}
            rowData={rowData}
          />
        ))}
      </TableRowCellWrapper>
    </TableRowWrapper>
  );
};

export default memo(TableRow) as typeof TableRow;
