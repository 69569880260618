import React, { useEffect, useMemo, useState } from 'react';
import { I18nContextProps, SupportedLanguageKey } from './i18n.types';
import { getI18nLanguageRequest, getLocalizationsApi } from './i18n.requests';
import { useMutation } from 'react-query';
import { languageLocalizationsEndpoint } from './i18n.config';
import { useSession } from 'src/services/session/session.context';
import { setCookie } from 'src/utils/functions/cookie';
import { updateLanguageMethodApi } from 'src/services/user/user.request';
import { addi18nLanguage } from './i18n.helpers';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { COOKIEHUB_GLOBAL_VAR } from '../../constants';

export const i18nContext = React.createContext<I18nContextProps>(
  {} as I18nContextProps,
);

export const I18nProvider: React.FC<any> = ({ children }) => {
  const { language } = getI18nLanguageRequest();
  const { auth } = useSession();
  const [lang, setLang] = useState(language);
  const [isLangLabelsLoaded, setIsLangLabelsLoaded] = useState(false);
  const setValue = useProfileStore((state) => state.setValue);
  const languageStore = useAccountStore((state) => state.Language);

  const { isLoading, mutate: mutateLangLabels } = useMutation(
    languageLocalizationsEndpoint,
    getLocalizationsApi,
    {
      onSuccess: (res, variables) => {
        addi18nLanguage(variables, res.data);
        setIsLangLabelsLoaded(true);
      },
    },
  );

  const { isLoading: isLoadingUpLang, mutate: mutateUpLang } = useMutation(
    updateLanguageMethodApi,
    {
      onSuccess: (res) => {
        setValue('Language', res.data.ResultSet.Language);
      },
      onError: () => {
        setLang(languageStore);
      },
    },
  );

  const handleSetLanguage = (lan: SupportedLanguageKey) => {
    setLang(lan);
    setIsLangLabelsLoaded(false);
    mutateLangLabels(lan);
    setCookie('language', lan, 30);

    if (auth?.authToken) {
      mutateUpLang({ Language: lan });
    }
    return;
  };

  useEffect(() => {
    const l = lang.substring(0, 2);
    document.documentElement.lang = l;
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.setLanguage(l);
  }, [lang]);

  useEffect(() => {
    mutateLangLabels(language);
  }, []);

  const contextValue = useMemo(
    () => ({
      language: lang,
      isLoadingLang: isLoading,
      isLoadingUpLang: isLoadingUpLang,
      isLangLabelsLoaded,
      setLanguage: handleSetLanguage,
      mutateLangLabels: mutateLangLabels,
      mutateUpLang: mutateUpLang,
    }),
    [lang, isLoading, isLoadingUpLang],
  );

  return (
    <i18nContext.Provider value={contextValue as I18nContextProps}>
      {children}
    </i18nContext.Provider>
  );
};

export function useLanguage() {
  return React.useContext(i18nContext);
}
