import Loader from 'src/components/Loader/Loader.component';
import React from 'react';
import { useQuery } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getStripeRegistrationLink } from 'src/services/stripe/stripe.config';
import { getStripeRegistrationLinkMethodApi } from 'src/services/stripe/stripe.request';
import { useProfileStore } from 'src/store/store';

export interface RedirectStripeProps {}

const RedirectStripe: React.FC<RedirectStripeProps> = () => {
  const setUser = useProfileStore((state) => state.setUser);
  const { state } = useLocation();

  const { isFetching } = useQuery(
    getStripeRegistrationLink,
    getStripeRegistrationLinkMethodApi,
    {
      onSuccess: (res) => {
        setUser({ stripeLink: res.data.ResultSet });
        if (res.data.ResultSet.length > 1) {
          (window as any).open(res.data.ResultSet, '_self');
        }
      },
      onError: () => {
        (window as any).open(RoutePath.oops, '_self');
      },
    },
  );

  if (isFetching) {
    return (
      <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
    );
  }
  return <Navigate to={state?.prevPath} replace />;
};

export default RedirectStripe;
