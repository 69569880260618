import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const CompanyGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 32.6rem), 1fr));
  grid-auto-rows: 1fr;
  gap: 2.4rem;
  padding-bottom: 5rem;
  ${media.tablet`
  padding-bottom: unset;
  `}
`;
