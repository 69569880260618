import colors from 'src/style-utils/colors';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const ChangeAccountCard = styled(Card)`
  cursor: default;
  &:hover {
    background-color: ${colors.bgBtnHoverTertiary};
    color: ${colors.textBtnHoverTertiary};
  }
`;
