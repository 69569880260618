import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import styled from 'styled-components';

export const Privacy = styled(P)`
  box-sizing: border-box;
  color: ${colors.textColorGrey};
  font-size: 1.2rem;
  text-align: center;
  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.textColorGrey};
  }
`;
