import { cva } from 'class-variance-authority';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Alert } from 'src/images/icons/input_error.svg';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useFormContext } from 'react-hook-form';
import colors from 'src/style-utils/colors';
import { H5, PSmall } from 'src/style-utils/typographic';
import useParamsPayCard from './PaymentMethodElement.hooks';

const cardVariants = cva(
  'group relative z-0 flex w-full cursor-pointer items-center justify-between gap-2 rounded-xl bg-white p-4 shadow-md outline outline-1 transition-shadow duration-500 hover:shadow-lg has-[:checked]:outline-pgrey',
  {
    variants: {
      status: {
        checked: 'outline-pgrey',
        expired: 'pointer-events-none outline-danger',
        default: 'outline-pgreyoutlined',
      },
      clickable: {
        false: 'pointer-events-none',
      },
      shadows: {
        true: 'shadow-md hover:shadow-lg',
      },
    },
    defaultVariants: {
      status: 'default',
      clickable: true,
      shadows: false,
    },
  },
);

function PaymentMethodElement(payCard: PaymentMethodResult) {
  const {
    country,
    paymentMethodId,
    payIcon,
    fieldName,
    expired,
    last4,
    dueDate,
    type,
  } = useParamsPayCard({ ...payCard });

  const formContext = useFormContext();

  const fieldValue = paymentMethodId?.toString();

  const isInput = formContext !== null && fieldName !== undefined;
  if (isInput) {
    const { watch } = formContext;
    watch(fieldName);
  }

  return (
    <label
      data-component="payment-method-element"
      htmlFor={fieldValue}
      className={cardVariants({
        status: expired ? 'expired' : 'default',
        clickable: isInput,
      })}
    >
      <div className="flex items-center gap-4">
        <img
          className="m-3 h-6 w-6 lg:h-9 lg:w-9"
          src={payIcon}
          alt="payment method logo"
        />
        <div className="grid gap-1">
          <H5>
            {type === 'sepa_debit' && country + ' '}
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
            &bull;&bull;&bull;&bull; {last4}
          </H5>
          {type !== 'sepa_debit' && (
            <div className="flex items-center">
              {expired && <Alert width="2rem" height="2rem" />}
              <PSmall textColor={expired ? colors.redInput : undefined}>
                <Translate id="text.dueDate" /> {dueDate}
              </PSmall>
            </div>
          )}
        </div>
      </div>
      <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden rounded-xl after:absolute after:right-0 after:top-1/2 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out group-hover:after:w-[210%]" />
      {isInput && (
        <input
          className="relative m-2 block h-6 w-6 appearance-none rounded-full border-2 border-current bg-white text-pgreyoutlined after:pointer-events-none after:absolute after:inset-1 after:scale-0 after:rounded-full after:bg-current after:transition-transform checked:text-pblack-400 after:checked:scale-100 disabled:text-pgreyoutlined after:disabled:scale-100"
          type="radio"
          disabled={expired}
          value={fieldValue}
          id={fieldValue}
          {...formContext.register(fieldName, { required: true })}
        />
      )}
    </label>
  );
}

export default PaymentMethodElement;
