import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import { ReactComponent as ArrowBackRight } from 'src/images/icons/arrow_back_right.svg';
import React, { useState } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';
import { feesForOrderMethodApi } from 'src/services/feesForOrder/feesForOrder.request';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  Cash,
  Payout,
  ServiceCost,
  configDetailMovementsTitle,
} from './MovementsDetailCard.config';
import { MovementsCardDetailProps } from './MovementsDetailCard.types';
import ServiceCostModalLodable from './ServiceCostModal/ServiceCostModal.lodable';
import { MovementsDetailCardContainer } from './style/MovementsDetailCard.style';
import { getPayoutDetailsMethodApi } from 'src/services/payoutDetails/payoutDetails.request';
import PayoutDetailsModalLodable from './PayoutDetailsModal/PayoutDetailsModal.lodable';

const MovementsDetailCard: React.FC<MovementsCardDetailProps> = ({
  movementsType,
  advance,
  amount,
  date,
  orderId,
  orderGuid,
  INo,
  customer,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [openPayoutModal, setOpenPayoutModal] = useState(false);
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const { mutate, isLoading, isError, data } = useMutation(
    feesForOrderMethodApi,
    {
      onSuccess: () => {},
    },
  );

  const {
    mutate: mutatePayout,
    isLoading: isLoadingPayout,
    isError: isErrorPayout,
    data: dataPayoutDetails,
  } = useMutation(getPayoutDetailsMethodApi, {
    onSuccess: () => {},
  });

  const handleClick = () => {
    if (movementsType === ServiceCost) {
      mutate({ orderGuid, iNo: INo });
      setModalOpen(true);
      return;
    }
    mutatePayout({ payoutId: orderId });
    setOpenPayoutModal(true);
  };

  if (isError || isErrorPayout) {
    return <Navigate to="/oops" />;
  }
  if (isLoading || isLoadingPayout) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <MovementsDetailCardContainer
        data-component="movements-detail-card"
        fluid
        align="center"
        padding="1.4rem 1.6rem"
        justify="space-between"
      >
        <Flex flexDirection="column" justify="center">
          <PSmall>{date}</PSmall>
          {movementsType === Payout ? (
            <P bold textColor="inherit">
              <Translate id="text.automaticTransfer" />
            </P>
          ) : (
            <>
              <P bold textColor="inherit">
                {customer}
              </P>
              <P textColor="inherit">
                <Translate id={'lbl.linkNumber'} />
                &nbsp;{orderId.toString().padStart(5, '0')}
              </P>
            </>
          )}
        </Flex>
        <Flex flexDirection="column" justify="center" align="flex-end">
          {movementsType === ServiceCost || movementsType === Payout ? (
            <Button
              height="fit-content"
              variant="LinkPrimary"
              gap="0"
              fontWeight="400"
              padding="0!important"
              onClick={handleClick}
              underline
              children={
                <PSmall>
                  <Translate
                    id={configDetailMovementsTitle[movementsType].label}
                  />
                </PSmall>
              }
              rightChild={
                <Icon
                  iconHeight="2rem"
                  iconSize="2rem"
                  svgIconComponent={ArrowBackRight}
                />
              }
            />
          ) : (
            <PSmall>
              <Translate
                id={
                  movementsType === Cash && advance
                    ? 'lbl.labelADV'
                    : configDetailMovementsTitle[movementsType].label
                }
              />
            </PSmall>
          )}
          <P
            bold
            textColor={
              movementsType === Cash
                ? colors.greenStatus
                : colors.textColorBlack
            }
          >
            {movementsType === Cash ? '+' : '-'}
            {formatCurrency(amount)}
          </P>
        </Flex>
      </MovementsDetailCardContainer>
      {modalOpen && (
        <ServiceCostModalLodable
          buttonTitle="text.okUnderstand"
          modalTitle="text.serviceCost"
          i18n
          orderId={orderId}
          INo={INo}
          modalData={data?.data?.ResultSet}
          handleClose={() => setModalOpen(false)}
        />
      )}
      {openPayoutModal && (
        <PayoutDetailsModalLodable
          buttonTitle="lbl.close"
          modalTitle="lbl.payoutDetail"
          i18n
          modalData={dataPayoutDetails?.data}
          isLoading={isLoadingPayout}
          handleClose={() => setOpenPayoutModal(false)}
        />
      )}
    </>
  );
};

export default MovementsDetailCard;
