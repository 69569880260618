import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { NeedHelpButtonContainer } from 'src/pages/registration/registrationGuest/style/registrationGuest.style';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  LoginGuestMethodApi,
  SignInSendOtpGuestMethodApi,
} from 'src/services/login/login.request';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import { useSession } from 'src/services/session/session.context';
import { userEndPoint } from 'src/services/user/user.config';
import { porfileMethodApi } from 'src/services/user/user.request';
import { useAccountStore, useRegistrationStore } from 'src/store/store';
import { Hr } from 'src/style-utils/hr';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { formConfigRegistrationGuestStepOtpPhone } from './RegistrationGuestStepOtpPhone.helpers';

const RegistrationGuestStepOtpPhone: React.FC = () => {
  const mobileNumber = useRegistrationStore((state) => state.mobile);
  const guestSignInGuid = useRegistrationStore(
    (state) => state.guestSignInGuid,
  );
  const setValue = useRegistrationStore((state) => state.setValue);
  const setAccount = useAccountStore((state) => state.setAccount);
  const prefixNumber = useRegistrationStore((state) => state.prefix) ?? '';
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { onLogin } = useSession();
  const { initialValues, resolver } = formConfigRegistrationGuestStepOtpPhone();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading, isSuccess } = useMutation(LoginGuestMethodApi, {
    onSuccess: (res) => {
      const { AccessToken } = res?.data?.ResultSet;
      onLogin(AccessToken);
    },
    onError: () => {
      setErrorMessage('error.otpNotMatching');
    },
  });

  const { isLoading: isLoadingInfo } = useQuery(
    userEndPoint,
    porfileMethodApi,
    {
      onSuccess: (res) => {
        setAccount(res?.data.ResultSet);
        navigate(
          RoutePath.registrationGuest +
            RoutePath.checkDataStep +
            `?guid=${res.data.ResultSet.BuyerRoles[0].Guid}`,
        );
      },
      enabled: isSuccess,
      cacheTime: 0,
    },
  );

  const { mutate: mutateRefresh, isLoading: isLoadingRefresh } = useMutation(
    SignInSendOtpGuestMethodApi,
    {
      onSuccess: (res) => {
        setValue('guestSignInGuid', res.data.ResultSet);
        navigate(RoutePath.registrationGuest + RoutePath.otpConfirmPhoneStep);
      },
      onError: (error: any) => {
        navigate(RoutePath.oops);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataOtp = {
      guestSignInGuid,
      otp: Object.values(data).join(''),
    };
    mutate(dataOtp);
  };

  if (isLoadingRefresh || isLoadingInfo) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <FlowHeader
        label="text.insertCodeLite"
        showWhiteSpaceRight
        stepNumber={2}
        stepMaxNumber={7}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryOtp" />
        </H4>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OtpForm
              sendAgainHandler={() =>
                mutateRefresh({
                  [phoneField]: mobileNumber,
                  [prefix]: prefixNumber,
                })
              }
              isLoadingRefresh={isLoadingRefresh}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              prefix={prefixNumber.slice(0, -2)}
              mobile={mobileNumber}
            />
            <Hr />
            <NeedHelpButtonContainer>
              <NeedHelpButton
                padding="0"
                underline
                customIcon
                label="text.changedMobilePhone"
                variant="LinkPrimary"
                fontSize="1.2rem"
                fontWeight="700"
                rightChild={
                  <Icon svgIconComponent={ArrowRight} iconSize={'2rem'} />
                }
              />
            </NeedHelpButtonContainer>
            <ButtonContainer>
              <SubmitButton minWidth="100%" disabled={isLoading}>
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
};

export default RegistrationGuestStepOtpPhone;
