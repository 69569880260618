import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import { Resolver } from 'react-hook-form';
import { flagB, flagC, flagPrivacy } from './FormFlag.config';
import required from 'src/utils/validation/required';

export const formConfigRegistrationStepFlag = (typeAccount?: string) => {
  const initialValues = {
    [flagPrivacy]: typeAccount === 'MERCHANT' ? false : true,
    [flagB]: false,
    [flagC]: false,
  };

  const validate = (values: any) => {
    return validateFormSync({
      [flagPrivacy]: validateFieldSync(required),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
