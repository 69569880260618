import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  buyerGuid,
  loginEndPoint,
  loginEndPointGuest,
  preLoginEndPoint,
  preLoginEndPointGuest,
  signInSendOtpGuest,
} from './login.config';
import {
  DataLogin,
  DataLoginGuest,
  DataSignInOtpGuest,
  LoginResponse,
} from './loogin.types';
import { emailField, passwordField } from 'src/services/login/login.config';
import apiRequest from 'src/services/api/api.config';
import { otp } from 'src/services/otp/otp.config';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';

export const LoginMethodApi = async (params: DataLogin) => {
  const requestConfig = makeEndpointApiConfig({
    url: loginEndPoint,
    method: 'POST',
    data: {
      Username: params[emailField as keyof typeof params],
      Password: params[passwordField as keyof typeof params],
    },
  });

  return await apiRequest.request<LoginResponse>(requestConfig);
};
export const LoginGuestMethodApi = async (params: DataLoginGuest) => {
  const requestConfig = makeEndpointApiConfig({
    url: loginEndPointGuest,
    method: 'POST',
    data: {
      GuestSignInAttemptGuid: params.guestSignInGuid,
      PhoneOtp: params[otp],
    },
  });

  return await apiRequest.request<LoginResponse>(requestConfig);
};

export const PreLoginMethodApi = async (params: DataLogin) => {
  const requestConfig = makeEndpointApiConfig({
    url: preLoginEndPoint,
    method: 'POST',
    data: {
      Username: params[emailField as keyof typeof params],
      Password: params[passwordField as keyof typeof params],
      buyerGuid: params[buyerGuid as keyof typeof params],
    },
  });

  return await apiRequest.request(requestConfig);
};

export const PreLoginGuestMethodApi = async (params: DataLoginGuest) => {
  const requestConfig = makeEndpointApiConfig({
    url: preLoginEndPointGuest,
    method: 'POST',
    data: {
      GuestSignInAttemptGuid: params.guestSignInGuid,
      PhoneOtp: params[otp],
      buyerGuid: params[buyerGuid],
    },
  });

  return await apiRequest.request(requestConfig);
};

export const SignInSendOtpGuestMethodApi = async (
  params: DataSignInOtpGuest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: signInSendOtpGuest,
    method: 'POST',
    data: {
      PhonePrefix: params[prefix],
      Phone: params[phoneField],
    },
  });

  return await apiRequest.request<{ ResultSet: string }>(requestConfig);
};
