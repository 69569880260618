import { Resolver } from 'react-hook-form';
import {
  invoiceDateField,
  invoiceFileField,
  invoiceHiddenInvoiceFileNameField,
} from 'src/services/orderLink/orderLink.config';
import { validateInvoiceDate } from 'src/utils/validation/date';
import required, { requiredFile } from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigLinkInvoiceDate = (
  date?: string,
  daysBefore?: number,
  daysAfter?: number,
) => {
  const initialValues = {
    [invoiceHiddenInvoiceFileNameField]: '',
    [invoiceDateField]: '',
    [invoiceFileField]: '',
  };

  const validate = (values: any) => {
    return validateFormSync({
      [invoiceDateField]: validateFieldSync(
        required,
        validateInvoiceDate(values[invoiceDateField], daysBefore, daysAfter),
      ),
      [invoiceFileField]: () => {
        const invoiceHiddenFileName = values[invoiceHiddenInvoiceFileNameField];
        if (!invoiceHiddenFileName || invoiceHiddenFileName === '') {
          return validateFieldSync(requiredFile)(values[invoiceFileField]);
        }
        return undefined;
      },
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
