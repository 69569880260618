import mastercard from 'src/images/icons/paymentMethods/mastercard.svg?url';
import visa from 'src/images/icons/paymentMethods/visa.svg?url';
import amex from 'src/images/icons/paymentMethods/amex.svg?url';
import bank from 'src/images/icons/paymentMethods/bank.svg?url';
import discover from 'src/images/icons/paymentMethods/discover.svg?url';
import diners from 'src/images/icons/paymentMethods/diners.svg?url';
import jcb from 'src/images/icons/paymentMethods/jcb.svg?url';
import unionpay from 'src/images/icons/paymentMethods/unionPay.svg?url';
import generic from 'src/images/icons/paymentMethods/generic.svg?url';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';

export const getPaymentIcon = (network: string | undefined): string => {
  switch (network) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'amex':
      return amex;
    case 'diners':
      return diners;
    case 'jcb':
      return jcb;
    case 'unionpay':
      return unionpay;
    case 'discover':
      return discover;
    case 'bank':
      return bank;
    default:
      return generic;
  }
};

export interface PaymentMethodElementProps {
  payIcon: string;
  last4?: string | number;
  dueDate?: string;
  paymentMethodId?: number;
  fieldName?: string;
  country?: string;
  expired: boolean;
  type: PaymentMethodResult['Type'];
}
