import { IDs } from 'src/utils/config/ids';
import { ProductCodes, ProductsEnum } from 'src/utils/types/common.types';

type TProductCardArray = {
  [key in ProductCodes]: {
    title: string;
    subtitle1: string;
    subtitle2?: string;
    id?: string;
  };
};

export const ProductCardArray: TProductCardArray = {
  [ProductsEnum.advancePayment]: {
    title: 'product.ADV_3_67.title',
    subtitle1: 'product.ADV_3_67.description1',
    subtitle2: 'product.ADV_3_67.description2',
    id: undefined,
  },
  [ProductsEnum.singleInstant]: {
    title: 'product.SGL_INS.title',
    subtitle1: 'product.SGL_INS.description1',
    id: undefined,
  },
  [ProductsEnum.singleDelayed]: {
    title: 'product.SGL_DLY.title',
    subtitle1: 'product.SGL_DLY.description1',
    id: undefined,
  },
  [ProductsEnum.flexEqual]: {
    title: 'product.FLX_EQ.title',
    subtitle1: 'product.FLX_EQ.description1',
    subtitle2: 'product.FLX_EQ.description2',
    id: IDs.btnFlxEq,
  },
  [ProductsEnum.flexCustom]: {
    title: 'product.FLX_CUSTOM.title',
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: IDs.btnFlxCustom,
  },
  [ProductsEnum.custom]: {
    title: 'product.FLX_CUSTOM.title',
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: undefined,
  },
  [ProductsEnum.remodulation]: {
    title: 'product.FLX_CUSTOM.title',
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: undefined,
  },
};
