import { Resolver } from 'react-hook-form';
import { validateEndDate } from 'src/utils/validation/date';

import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import {
  Amounts,
  buyerSearchText,
  clientList,
  endDate,
  flagCessioneCredito,
  flagNonCessioneCredito,
  skipField,
  sortBy,
  sortDirection,
  startDate,
  statuses,
  takeField,
  takeLinks,
  madeFromPlugin,
  sort,
} from './SearchCriteriaForm.config';

export type DataFormPayByLink = {
  [clientList]: string[];
  [statuses]: PayByLinkStatus[];
  [startDate]: string;
  [endDate]: string;
  [Amounts]: { From: ''; To: '' }[];
  [buyerSearchText]?: string;
  [flagCessioneCredito]?: boolean;
  [flagNonCessioneCredito]?: boolean;
  [sort]?: { [sortBy]: string; [sortDirection]: string };
  [sortDirection]?: string;
  [skipField]?: number;
  [takeField]?: number;
  [madeFromPlugin]?: boolean;
};

export const searchCriteriaFormConfig = (fromPlugin?: boolean) => {
  const initialValues: DataFormPayByLink = {
    [startDate]: '',
    [endDate]: '',
    [Amounts]: [],
    [statuses]: [],
    [clientList]: [],
    [skipField]: 0,
    [takeField]: takeLinks,
    [sort]: { [sortBy]: 'date', [sortDirection]: 'desc' },
    [buyerSearchText]: '',
    [madeFromPlugin]: fromPlugin,
  };
  const validate = (values: any) => {
    return validateFormSync({
      [endDate]: validateFieldSync(validateEndDate(values[startDate])),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
