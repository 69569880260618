import {
  otp1,
  otp2,
  otp3,
  otp4,
  otp5,
  otp6,
} from 'src/components/Otp/Otp.config';
import { Resolver } from 'react-hook-form';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigChangeOtp = () => {
  const initialValues = {
    [otp1]: '',
    [otp2]: '',
    [otp3]: '',
    [otp4]: '',
    [otp5]: '',
    [otp6]: '',
  };

  const validate = validateFormSync({
    [otp1]: validateFieldSync(required),
    [otp2]: validateFieldSync(required),
    [otp3]: validateFieldSync(required),
    [otp4]: validateFieldSync(required),
    [otp5]: validateFieldSync(required),
    [otp6]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
