import styled from 'styled-components';
import colors from 'src/style-utils/colors';
import { SelectErrorProps } from '../Select.types';

interface ContainerMessageSelectProps {
  isPresentMessage: boolean | undefined;
}
export const SelectRootWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  position: relative;
  margin: 0;
  padding: 0;
  background: ${colors.white};
  /* Use this property to trigger fluid behaviour inside a flex container. */
  flex: ${({ theme }) => theme.select.root.flex};
  width: ${({ theme }) => theme.select.root.width};
  ${({ disabled }) =>
    disabled && `pointer-events: none; background : ${colors.bgDisabledInput}`};

  & > * {
    flex: 1 1 auto;
  }
`;

export const OptionsList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.4rem;
`;

export const OptionsListItem = styled.li`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 3.6rem;
  font-size: 1.6rem;
  font-weight: 300;
  vertical-align: center;
  pointer-events: auto;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
`;

export const MessageErrorSelect = styled.span<SelectErrorProps>`
  position: absolute;
  display: none;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.4rem;
  ${({ isError }) => {
    if (isError) {
      return `color: ${colors.redInput};
        }
      `;
    }
  }};
`;

export const ContainerMessageSelect = styled.div<ContainerMessageSelectProps>`
  position: relative;
  height: 30px;
  display: none;
  font-size: 1.6rem;
  line-height: 1.1875;
  ${({ isPresentMessage }) => {
    if (isPresentMessage) {
      return `
        display:block;
        & > ${MessageErrorSelect} {
          display:block;
        }
      `;
    }
    return '';
  }}

  padding: 0.5rem 0 0;
`;
