import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { Installment } from 'src/services/installments/installments.types';
import { P, PSmall } from 'src/style-utils/typographic';
import { MaxWidthContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { NoTabsData } from '../NoTabsData.component';
import { InstallmentsTabsProps } from '../TabsInstallments.types';
import InstallmentsModalLodable from '../modal/InstallmentsModal.lodable';

export const InstallmentsCreated: React.FC<InstallmentsTabsProps> = ({
  installments,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dataInstallment, setDataInstallment] = useState<
    Installment | undefined
  >();
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const monthTotals = installments?.ResultSet.InstallmentsCreated.reduce(
    (acc: any, installment) => {
      const expirationDate = new Date(installment.Expiration);
      const month = expirationDate.getMonth() + 1;
      const year = expirationDate.getFullYear();
      const monthKey = `${year}-${month}`;

      if (!acc[monthKey]) {
        acc[monthKey] = 0;
      }

      acc[monthKey] += installment.Amount;

      return acc;
    },
    {},
  );
  const onClickHandler = (installment: Installment) => {
    setDataInstallment(installment);
    setShowModal(true);
  };
  const ctaHandler = () => {
    navigate(
      `${RoutePath.orderDetail}${dataInstallment?.OrderGuid}/${RoutePath.info}`,
    );
  };
  return (
    <MaxWidthContainer maxWidth="66.8rem" noXMargin marginBottom="3.2rem">
      <Flex flexDirection="column" gap="1.6rem">
        {installments?.ResultSet.InstallmentsCreated.map((installment, i) => {
          const expirationDate = new Date(installment.Expiration);
          const month = expirationDate.getMonth() + 1;
          const year = expirationDate.getFullYear();
          const monthKey = `${year}-${month}`;
          const total = monthTotals[monthKey];
          return (
            <Flex gap="1.6rem" flexDirection="column" key={i}>
              {(i === 0 ||
                installment.Expiration.slice(0, 7) !==
                  installments.ResultSet.InstallmentsCreated[
                    i - 1
                  ].Expiration.slice(0, 7)) && (
                <>
                  {i > 0 && <hr className="my-8 border-pgrey lg:my-12"></hr>}
                  <Flex justify="space-between">
                    <PSmall className="capitalize">
                      {refactorFormatDate(installment.Expiration, 'MMMM YYYY')}
                    </PSmall>
                    <PSmall bold>{formatCurrency(total)}</PSmall>
                  </Flex>
                </>
              )}
              <InstallmentCard
                name={installment.MerchantName}
                date={installment.Expiration}
                amount={installment.Amount}
                NumRata={installment.INo}
                orderStatus={installment.OrderStatus}
                onClickHandler={() => onClickHandler(installment)}
                shadows
              />
            </Flex>
          );
        })}
        {installments?.ResultSet.InstallmentsCreatedCount === 0 && (
          <NoTabsData
            title="lbl.noInstallmentsToPay"
            subtitle="text.noInstallmentsToPay"
          />
        )}
        {showModal && (
          <InstallmentsModalLodable
            buttonTitle={'lbl.goToOrder'}
            ctaHandler={ctaHandler}
            modalTitle="text.installmentDetail"
            i18n
            data={dataInstallment}
            handleClose={() => setShowModal(false)}
          />
        )}
      </Flex>
      {installments && installments?.ResultSet.InstallmentsCreatedCount > 0 && (
        <div className="mb-10 mt-8 border-t border-pgreyoutlined pt-4 text-center">
          <P>
            <Translate id="text.nothingElseToShow" />
          </P>
        </div>
      )}
    </MaxWidthContainer>
  );
};
