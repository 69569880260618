import { badRequest_400 } from 'src/services/api/api.statusCodes';
import {
  ApiResponseErrorPayload,
  ApiErrorInfos,
} from 'src/services/api/api.types';
import { genericErrorId, formErrorKey } from 'src/services/forms/forms.config';
import { AxiosResponse } from 'axios';

export default function mapValidationErrors(
  errorResponse: AxiosResponse<ApiResponseErrorPayload>,
  errorMap: Record<string, string>,
): Record<string, string> | undefined {
  const { status, data } = errorResponse;

  if (status !== badRequest_400 || !data?.errorInfos) {
    return undefined;
  }

  const errorInfos = data.errorInfos as ApiErrorInfos;

  const output = {} as Record<string, string>;

  for (const [errorKey, validationError] of Object.entries(errorInfos)) {
    if (errorKey === 'generic') {
      const errorNameKey = errorMap[errorKey] || formErrorKey;

      output[errorNameKey] = validationError || genericErrorId;
    } else if (typeof errorMap[errorKey] === 'string') {
      output[errorMap[errorKey]] = validationError;
    } else {
      output[errorKey] = validationError;
    }
  }

  return output;
}
