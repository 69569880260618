import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { DataMarketing } from './marketing.types';
import { setFlags } from './marketing.config';

export const setFlagMarketingMethod = async (data: DataMarketing) => {
  const requestConfig = makeEndpointApiConfig({
    url: setFlags,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request(requestConfig);
};
