import {
  clientList,
  endDate,
  startDate,
  typesField,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { exportMovementsEndPoint } from './exportMovements.config';

export const exportMovementsMethodApi = async (data: any) => {
  const requestConfig = makeEndpointApiConfig({
    url: exportMovementsEndPoint,
    method: 'POST',
    data: {
      buyers: data[clientList],
      fromDate: data[startDate],
      toDate: data[endDate],
      types: data[typesField],
      skip: 0,
      take: 0,
    },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
