import { Resolver } from 'react-hook-form';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import createLength from 'src/utils/validation/length';
import { VATField } from './RegistrationStepVAT.config';
import { vat } from 'src/utils/validation/vat';
import { ValidationFormat } from 'src/services/orderLink/orderLink.types';

export const formConfigStepVAT = (
  codiceFiscale: string,
  validationCountry?: ValidationFormat,
  vatPrefix?: string,
) => {
  const initialValues = {
    // [VATField]:
    //   CountriesWithPrefix.includes(country) && buyerFiscalCode.length === 0
    //     ? country
    //     : buyerFiscalCode,
    [VATField]: vatPrefix ?? codiceFiscale,
  };

  const validate = validateFormSync({
    [VATField]: validateFieldSync(
      required,
      vat({
        regex: validationCountry?.regex,
      }),
      createLength({
        min: validationCountry?.minLength ?? undefined,
        max: validationCountry?.maxLength ?? undefined,
      }),
    ),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
