import FormFlagToPay from 'src/pages/registration/partials/FormFlagToPay/FormFlagToPay.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationLightStepFlag: React.FC = () => {
  return (
    <FormFlagToPay
      step={3}
      stepMax={6}
      nextPath={RoutePath.paymentFlow + RoutePath.payWithOpyn}
      label="lbl.next"
      light
    />
  );
};

export default RegistrationLightStepFlag;
