import styled from 'styled-components';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { SelectErrorProps } from 'src/components/Select/Select.types';

export const LabelSelectStyle = styled.label<SelectErrorProps>`
  margin-right: 1.6rem;

  font-size: 1.4rem;
  line-height: 2.4rem;
  flex: none;
  ${({ isError }) => {
    if (isError) return `color: ${colors.redInput}!important`;
    else return `color:color: ${colors.midGrey}`;
  }};
  ${media.desktop`
  margin-right: 1.8rem;
  `}
`;
