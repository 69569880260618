import { Resolver } from 'react-hook-form';
import { ValidationFormat } from 'src/services/orderLink/orderLink.types';
import createLength from 'src/utils/validation/length';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import { zipCode } from 'src/utils/validation/zipCode';

export const nameField = 'business-name';
export const postalCodeField = 'postal-code-field';

export const formConfigStepNamePostalCode = (
  validationZipCode?: ValidationFormat,
  validationName?: ValidationFormat,
) => {
  const initialValues = {
    [nameField]: '',
    [postalCodeField]: '',
  };

  const validate = validateFormSync({
    [nameField]: validateFieldSync(
      required,
      createLength({ min: validationName?.minLength ?? undefined }),
    ),
    [postalCodeField]: validateFieldSync(
      required,
      zipCode({
        regex: validationZipCode?.regex,
      }),
      createLength({
        min: validationZipCode?.minLength,
        max: validationZipCode?.maxLength ?? undefined,
      }),
    ),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
