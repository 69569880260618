import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from 'src/components/Button/Button.component';
import Loader from 'src/components/Loader/Loader.component';
import NeedHelpBase from 'src/components/NeedHelpButton/NeedHelpBase.component';
import NeedHelpInline from 'src/components/NeedHelpButton/NeedHelpInline.component';
import Translate from 'src/components/Translate/Translate.component';
import { MethodsContextType } from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { confirmSetupAttemptMethodApi } from 'src/services/payments/payments.request';

type StateStripe = {
  idTransaction?: string | number;
  amount?: string | number;
  showPaymentSetupPage?: boolean;
  isBackup?: boolean;
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { setBackupHandler } = useOutletContext<MethodsContextType>();
  const location = useLocation();
  const locState: StateStripe = location.state?.stateStripe
    ? location.state?.stateStripe
    : null;
  const isBackup = locState.isBackup ?? false;
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: mutateSetup, isLoading: isLoadingSetup } = useMutation(
    confirmSetupAttemptMethodApi,
    {
      onSuccess: (res, variables) => {
        const { IsValid, PaymentMethod } = res.data.ResultSet;
        if (!IsValid) {
          const d = new Date();
          d.setTime(d.getTime() + 24 * 3600 * 1000);
          localStorage.setItem('opyn-invalid-payment-method', 'true');
          navigate(-1);
          return;
        } else {
          localStorage.setItem('opyn-invalid-payment-method', 'false');
        }
        if (variables.isBackup) {
          setBackupHandler({ BackupPaymentMethodId: PaymentMethod.Id });
          return;
        }
        const d = new Date();
        d.setTime(d.getTime() + 100 * 1000);
        document.cookie = 'added=true; expires=' + d.toUTCString() + 'path=/';
        return navigate(RoutePath.methods + RoutePath.paymentMethods);
      },
      onError: () => navigate(RoutePath.oops),
    },
  );

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );
    if (!clientSecret) return;

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    await stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then((res: any) => {
        setIsLoading(false);
        if (res.error) {
          const { errMessage } = res.error ?? {};
          (window as any).parent.postMessage(
            JSON.stringify({
              status: 'reject',
              message: 'The payment has failed',
            }),
            '*',
          );
          if (
            errMessage === 'setup_intent_authentication_failure' ||
            errMessage === 'payment_intent_authentication_failure'
          )
            setMessage(errMessage);
          else setShowNeedHelp(true);
        } else {
          mutateSetup({
            stripePaymentMethodId: res.setupIntent.payment_method,
            stripeSetupId: res.setupIntent.id,
            isBackup,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setMessage('stripe.confirmSetup() error');
      });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
  };

  return (
    <>
      {(isLoading || isLoadingSetup) && (
        <Loader overlayViewMode="fullscreen" active viewMode="fluid" />
      )}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <br />
        <Button
          type={'submit'}
          minWidth="100%"
          variant="Primary"
          disabled={!stripe || !elements}
          id="submit"
        >
          <Translate id="lbl.confirm" />
        </Button>
      </form>
      {message && (
        <div className="w-full pt-2 text-danger" id="payment-message">
          {message}
        </div>
      )}
      {showNeedHelp && (
        <div className="w-full pt-2 text-danger" id="payment-message">
          <Translate id="error.stripeFail" />
          <NeedHelpBase BaseComponent={NeedHelpInline} />
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
