import styled from 'styled-components';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { H2 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { Button } from '../Button/Button.component';

export const ModalHeaderContainer = styled(Flex)`
  flex-direction: row;
  padding: 1.6rem 4rem;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  align-self: stretch;
  border-bottom: ${colors.greyBorder};
  border: 0.1rem solid ${colors.greyBorder};
`;

export const ModalTitle = styled(H2)`
  color: ${colors.textColorGrey};
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.4rem;

  ${media.tablet`
  color: ${colors.textColorGrey};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.6rem;
    `}
`;

export const ModalBodyContainer = styled(Flex)`
  padding: 4rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  align-self: stretch;
`;

export const CustomText = styled.span`
  color: ${colors.textColorBlack};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  ${media.tablet`
   color: ${colors.textColorBlack};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
    `}
`;

export const FooterContainer = styled(Flex)`
  padding: 24px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 0 0 16px 16px;
  background: ${colors.white};
  box-shadow: 0 4px 24px 0 rgba(53, 48, 47, 0.24);
`;

export const CustomButtons = styled(Button)`
  width: 100%;
  height: 5.4rem;
  padding: 1.2rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  ${media.tablet`
  width: 2.8rem;
  height: 5.4rem;
  padding: 1.2rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 14px;

    `}
`;
