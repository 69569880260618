import Translate from 'src/components/Translate/Translate.component';
import { ListTableRemodulationRequests } from 'src/pages/remodulationRequests/RemodulationRequests.types';
import RemodulationRequestsModalComponent from 'src/pages/remodulationRequests/modal/RemodulationRequestsModal.component';
import { useState } from 'react';
import { P } from 'src/style-utils/typographic';

const InstallmentPlanColumn: React.FC<ListTableRemodulationRequests> = ({
  Installments,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <P
        colorBlack
        textTransform="capitalize"
        className="cursor-pointer underline"
        onClick={() => setShowModal(true)}
      >
        <Translate id="lbl.seeDetail" />
      </P>
      {showModal && (
        <RemodulationRequestsModalComponent
          buttonTitle="text.okUnderstand"
          modalTitle="lbl.installmentDetail"
          dataStepper={Installments}
          handleClose={() => setShowModal(false)}
          i18n
        />
      )}
    </>
  );
};

export default InstallmentPlanColumn;
