import styled from 'styled-components';
import squareBoxUnchecked from 'src/images/icons/square_unchecked.svg?url';
import squareBoxChecked from 'src/images/icons/square_checked.svg?url';

export const CheckmarkPopover = styled.span`
  height: 2.4rem;
  width: 1.6rem;
  border-radius: 0.4rem;
  background-image: url(\"${squareBoxUnchecked}\");
  background-size: 1.6rem 2.4rem;
  min-width: 1.6rem;
`;

export const CheckBoxPopoverSelection = styled.label`
  padding: 0.4rem 0.6rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ ${CheckmarkPopover} {
      background-image: url(\"${squareBoxChecked}\");
    }

    &:checked ~ span {
      font-weight: bold;
    }
  }
`;
