import Translate from 'src/components/Translate/Translate.component';
import { H5, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

export const NoTabsData: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <Flex
      padding="3.2rem 0"
      backgroundStyle="#fff"
      align="center"
      flexDirection="column"
      gap="0.8rem"
      className="rounded-2xl"
    >
      <H5 light>
        <Translate id={title} />
      </H5>
      <P>
        <Translate id={subtitle} />
      </P>
    </Flex>
  );
};
