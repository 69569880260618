import React from 'react';
import { ReactComponent as Document } from 'src/images/icons/document.svg';
import { ReactComponent as CloseSvg } from 'src/images/icons/close-icon.svg';
import { ReactComponent as ErrorSvg } from 'src/images/icons/error.svg';
import colors from '../../../style-utils/colors';
import Icon from '../../Icon/Icon.component';
import { t } from 'i18next';

interface FileItem {
  file:
    | File
    | {
        size: number;
        name: string;
      };
  errors: string[];
}

export type InputFilePreviewProps = {
  selectedFiles: FileItem[];
  onRemoveFile: (
    file:
      | File
      | {
          size: number;
          name: string;
        },
  ) => void;
};

const InputFilePreview: React.FC<InputFilePreviewProps> = ({
  selectedFiles,
  onRemoveFile,
}) => {
  return (
    <div className="my-4">
      {selectedFiles.length > 0 && (
        <div>
          <div className="mt-2 space-y-2">
            {selectedFiles.map(({ file, errors }, index) => {
              const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

              return (
                <div
                  key={index}
                  className="flex items-center rounded-md border bg-white p-4 shadow-sm"
                  style={
                    errors.length > 0
                      ? { borderColor: colors.redStatus }
                      : { borderColor: colors.backgroundOutlined }
                  }
                >
                  <div className="flex h-12 w-12 items-center justify-center">
                    <Icon
                      svgIconComponent={errors.length > 0 ? ErrorSvg : Document}
                      iconSize="4rem"
                      color={colors.textColorGrey}
                    />
                  </div>
                  <div
                    className="ml-4 flex-1"
                    style={{ minWidth: '0', maxWidth: '100%' }}
                  >
                    <div style={{ color: colors.textBtn }}>
                      <div
                        className="mb-1 mr-2"
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <span className="capitalize">{file.name}</span>
                        {+fileSizeInMB > 0 && (
                          <span
                            className="ml-2 text-xs"
                            style={{ color: colors.textBtnGrey }}
                          >
                            {fileSizeInMB} MB
                          </span>
                        )}
                      </div>
                    </div>
                    <label
                      className="flex items-center text-xs"
                      style={
                        errors.length > 0
                          ? { color: colors.alertErrorBorder }
                          : { color: colors.textColorGrey }
                      }
                    >
                      {errors.length === 0 ? (
                        <span
                          className="mr-2 h-2 w-2 rounded-full"
                          style={{ background: colors.greenStatus }}
                        ></span>
                      ) : null}
                      {errors.length > 0
                        ? errors.map((l, i) => (
                            <React.Fragment key={i}>
                              {l}
                              <br />
                            </React.Fragment>
                          ))
                        : t('label.file.success')}
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={() => onRemoveFile(file)}
                    className="ml-4 cursor-pointer"
                  >
                    <Icon
                      svgIconComponent={CloseSvg}
                      iconSize="2.4rem"
                      color={colors.textColorBlack}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputFilePreview;
