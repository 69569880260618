import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const ModalFooterRoot = styled.footer`
  bottom: 0;
  display: flex;
  z-index: 3;
  flex-flow: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${colors.white};
  overflow: hidden;
  padding: 2.4rem;
  box-shadow: 0 4px 24px 0.4px ${colors.modalFooterShadowColor};
  ${media.tablet`
    gap: 2.4rem;
    padding: 2.4rem 4rem;
  `}
`;
