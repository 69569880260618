import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { ButtonProps } from './Button.types';
import { ButtonBase, ButtonText } from './style/Button.style';

export const Button: React.FC<ButtonProps> = ({
  children,
  i18n,
  translatedText,
  ...otherProps
}) => {
  return (
    <ButtonBase data-component="button" {...otherProps}>
      {otherProps.leftChild}
      {children && <ButtonText>{children}</ButtonText>}
      {i18n && translatedText && (
        <ButtonText>
          <Translate id={translatedText} />
        </ButtonText>
      )}
      {otherProps.rightChild}
    </ButtonBase>
  );
};
