import React, { memo, useRef, useLayoutEffect } from 'react';
import usePrevious from '../Otp/Otp.config';
import { InputOtp } from './Otp.style';
import { useFormContext, useController } from 'react-hook-form';
import { InputFieldProps } from 'src/components/InputField/InputField.types';

export type SingleOTPInputProps = {
  focus?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement> &
  Omit<InputFieldProps, 'label'>;

export function SingleOTPInputComponent({
  name,
  focus,
  autoFocus,
  onFocus,
  onPaste,
  onBlur: onBlurField,
  onChange,
  onKeyDown,
  value: valueField,
}: SingleOTPInputProps) {
  const { control, setValue } = useFormContext();

  const {
    field: { onChange: fieldOnChange, onBlur, value, ref, ...rest },
  } = useController({
    name,
    control,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current && focus && focus !== prevFocus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputRef, autoFocus, focus, prevFocus]);

  useLayoutEffect(() => {
    // This timeout is used to get the focus adter the ModalInjector steal the
    // focus to the data-focus-sentinel="start"
    setTimeout(() => {
      if (inputRef.current && focus && autoFocus) {
        inputRef.current!.focus();
        inputRef.current.select();
      }
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setValue(name, value);
  }, [name, setValue, value]);

  //This avoid user keep modifying values while loader is shown while a check request is pending
  // useEffect(() => {
  //   if (isSubmitting) {
  //     inputRef.current?.blur();
  //   }
  // }, [isSubmitting]);

  return (
    <InputOtp
      type="tel"
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlurField}
      onPaste={onPaste}
      value={valueField}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      {...rest}
    />
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
