import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import { PaymentsFilterModalBody } from './PaymentsFilterModalBody.component';
import {
  PaymentsFilterModalHeader,
  PaymentsFilterModalFooter,
} from './PaymentsFilterModalParts.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'PaymentsFilterModal',
  headerComponent: PaymentsFilterModalHeader,
  bodyComponent: PaymentsFilterModalBody,
  footerComponent: PaymentsFilterModalFooter,
});
