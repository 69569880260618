import { Resolver } from 'react-hook-form';
import {
  invoiceDateField,
  invoiceFileField,
} from 'src/services/orderLink/orderLink.config';
import { validateInvoiceDate } from 'src/utils/validation/date';
import required, { requiredFile } from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigInvoiceDatePayByLink = (
  date?: string,
  daysBefore?: number,
  daysAfter?: number,
) => {
  const initialValues = {
    [invoiceDateField]: '',
    [invoiceFileField]: '',
  };

  const validate = validateFormSync({
    [invoiceDateField]: validateFieldSync(
      required,
      validateInvoiceDate(date, daysBefore, daysAfter),
    ),
    [invoiceFileField]: validateFieldSync(requiredFile),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
