import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';

const AmountColumn: React.FC<ListRequestsResultsTable> = ({ Amount }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <P textColor={colors.textTableBody}>
      {formatCurrency(Amount, { removeWhiteSpaces: true })}
    </P>
  );
};

export default AmountColumn;
