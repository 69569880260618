import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import { PaymentFlowContextType } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { ChangeMethodFormData } from 'src/services/payments/payments.types';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

function SetBackupPage() {
  const {
    paymentMethods,
    type,
    selectedMethodId,
    SelectedMethod,
    setBackupHandler,
    goToStripeFormHandler,
  } = useOutletContext<PaymentFlowContextType>();

  const methods = useForm({
    defaultValues: {
      paymentMethodId: selectedMethodId.toString(),
    } as ChangeMethodFormData,
    mode: 'onSubmit',
  });

  const onSubmit = (values: ChangeMethodFormData) => {
    setBackupHandler({ BackupPaymentMethodId: +values.paymentMethodId });
  };

  const filteredPaymentMethods = paymentMethods.filter(
    (item) => item.Type === 'card',
  );

  const index =
    filteredPaymentMethods &&
    filteredPaymentMethods.map((item) => item.Id).indexOf(selectedMethodId);

  if (SelectedMethod && index > -1) {
    filteredPaymentMethods.splice(index, 1);
    filteredPaymentMethods.unshift(SelectedMethod);
  }

  return (
    <div className="container pb-8">
      <GoBackButton />
      <div className="mx-auto max-w-oxl">
        <div className="mx-auto grid max-w-olg gap-4 lg:text-center">
          <H4>
            <Translate id="text.changePaymentMethod" />
          </H4>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex
              flexDirection="column"
              align="center"
              gap="2.4rem"
              padding="2.4rem 0"
            >
              {filteredPaymentMethods &&
                filteredPaymentMethods.map((payCard, i: number) => {
                  return <PaymentMethodElement key={i} {...payCard} />;
                })}
            </Flex>
            <DividerOr />
            <ButtonCard
              createPaymentIntentHandler={() => goToStripeFormHandler(true)}
              type={type}
            />
            <ButtonContainer marginTop="4rem">
              <Button
                variant="Primary"
                sizeOnDesktop="medium"
                type="button"
                minWidth="100%"
                onClick={() => methods.handleSubmit(onSubmit)()}
                disabled={!methods.formState.isValid}
              >
                <Translate id={'lbl.confirm'} />
              </Button>
            </ButtonContainer>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default SetBackupPage;
