import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import React, { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { SelectFieldUIStates } from './Select.config';
import {
  createHandleKeyDown,
  createOptionClickHandlerByOptionId,
  getLocalizedLabelsByOptionId,
  modifyTheme,
  selectFieldStateReducer,
} from './Select.helpers';
import { SelectProps } from './Select.types';
import LabelSelect from './partials/Label/LabelSelect.componet';
import Listbox from './partials/Listbox/Listbox.component';
import {
  ContainerMessageSelect,
  MessageErrorSelect,
  SelectRootWrapper,
} from './style/Select.style';

const Select = React.memo(function Select({
  onChange,
  options,
  i18n = false,
  i18nLabel = true,
  value,
  className,
  labelledBy,
  describedBy,
  id,
  label,
  labelComponent: LabelComponent = LabelSelect,
  iconComponent = Icon,
  name,
  isPresentErrorMessage,
  disabled,
}: SelectProps) {
  const {
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext();
  const error = errors[name];
  const touched = touchedFields[name];
  const { messageProps, selectFieldState } = selectFieldStateReducer({
    error,
    touched,
  });

  const translate = useTranslation().t;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isListboxExpanded = isExpanded;

  const selectedOptionIndex = React.useMemo(() => {
    return options.findIndex(
      ({ optionId }: ListboxOption) => optionId === value,
    );
  }, [options, value]);

  const labelsByOptionId = React.useMemo(
    () => getLocalizedLabelsByOptionId(options, i18n, translate),
    [i18n, options, translate],
  );

  const iconSrcByOptionId = React.useMemo(() => {
    return Object.fromEntries(
      options
        .map(({ optionId, iconSrc }) => [optionId.toString(), iconSrc])
        .filter((entry) => Boolean(entry[1])),
    );
  }, [options]);

  const handleKeyDown = createHandleKeyDown(
    isExpanded,
    setIsExpanded,
    options,
    selectedOptionIndex,
    onChange,
    trigger,
    name,
  );
  const handleListboxBlur = React.useCallback(
    (domEvent: React.FormEvent) => {
      window.requestAnimationFrame(() => {
        if (isListboxExpanded) {
          setIsExpanded(false);
        }
      });
    },
    [isListboxExpanded],
  );

  const handleButtonClick = React.useCallback(
    (domEvent: MouseEvent) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
      const isPrimaryButton = domEvent.button === 0;

      if (isPrimaryButton) {
        setIsExpanded(!isListboxExpanded);
      }
    },
    [isListboxExpanded],
  );

  const handleItemClickBase: onListboxitemClick = React.useCallback(
    (optionId) => {
      if (optionId !== value) {
        onChange(name, optionId, { shouldTouch: true });
        trigger(name);
      }
    },
    [onChange, value], // eslint-disable-line
  );

  const handleOptionClickByOptionId = React.useMemo(() => {
    return createOptionClickHandlerByOptionId(options, handleItemClickBase);
  }, [options, handleItemClickBase]);

  const isError = selectFieldState === SelectFieldUIStates.error;
  const isAlert = selectFieldState === SelectFieldUIStates.alert;
  return (
    <ThemeProvider theme={modifyTheme}>
      <SelectRootWrapper data-component="select" disabled={disabled}>
        {label && (
          <LabelComponent
            label={label}
            id={id}
            isError={isError}
            isAlert={isAlert}
            i18nLabel={i18nLabel}
          />
        )}
        <Listbox
          disabled={disabled}
          id={id}
          labelledBy={labelledBy}
          describedBy={describedBy}
          value={value}
          className={className}
          isExpanded={isListboxExpanded}
          labelsByOptionId={labelsByOptionId}
          optionClickHandlerByOptionId={handleOptionClickByOptionId}
          selectedOptionIndex={selectedOptionIndex}
          options={options}
          iconSrcByOptionId={iconSrcByOptionId}
          onKeyDown={handleKeyDown}
          onButtonClick={handleButtonClick}
          iconComponent={iconComponent}
          onBlur={handleListboxBlur}
          isError={isError}
        />
      </SelectRootWrapper>
      <ContainerMessageSelect
        data-component="select"
        isPresentMessage={isPresentErrorMessage}
      >
        <MessageErrorSelect isError={isError}>
          {messageProps && <Translate id={messageProps} />}
        </MessageErrorSelect>
      </ContainerMessageSelect>
    </ThemeProvider>
  );
});

export default Select;
