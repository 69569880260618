const colors = {
  transparent: 'transparent',
  textLink: '#717D93',
  textColorBlack: '#35302F',
  textColorGrey: '#636E82',
  textColorLightGrey: '#C9CDD6',
  white: '#fff',
  whiteGrey: '#fafafa',
  midGrey: '#333333',
  black: '#000',
  gradientsStatusGreyDark: '#9D9C9C',
  gradientsStatusGreenLight: '#BFD72F',
  lightGrey: '#DADADA',
  primaryGreen: '#47D7AC',
  green10: '#CCFFF0',
  green50: '#3FC09A',

  // Alert
  alertDefaultBg: '#FFFFFF',
  alertDefaultBorder: '#C9CDD6',
  alertPositiveBg: '#E9FBF3',
  alertPositiveBorder: '#009E6B',
  alertErrorBg: '#FDE7E7',
  alertErrorBorder: '#F13D3E',
  alertWarningBg: '#FFF6E5',
  alertWarningBorder: '#FFAD0C',
  alertInformativeBg: '#E5F3FF',
  alertInformativeBorder: '#0075E2',

  // P
  textP: '#636E82',

  // Border
  yellowBorder: '#FFAD0C',
  greyBorder: '#C9CDD6',
  lightGreyBorder: '#636E82',
  lightBlueBorder: '#0075E2',

  // Button color
  textBtn: '#35302F',
  textBtnGrey: '#636E82',

  // Button Primary
  textBtnDisabledPrimary: '#636E82',
  textBtnHoverPrimary: '#35302F',
  bgBtnPrimary: '#47D7AC',
  bgBtnHoverPrimary: '#64FFD1',
  bgBtnFocusedPrimary: '#3FC09A',
  bgBtnDisabledPrimary: '#C9CDD6',
  bgBtnPressedPrimary: '#47D7AC',
  borderbgBtnPressedPrimary: '#47d7ac80',

  // Button Secondary
  textBtnDisabledSecondary: '#636E82',
  textBtnHoverSecondary: ' #FFFFFF',
  bgBtnSecondary: '#35302F',
  bgBtnHoverSecondary: '#514948',
  bgBtnDisabledSecondary: '#C9CDD6',
  bgBtnFocusedSecondary: '#1B1818',
  bgBtnPressedSecondary: '#35302F',
  borderbgBtnPressedSecondary: '#0075e280',

  // Button Tertiary
  textBtnDisabledTertiary: '#636E82',
  textBtnHoverTertiary: '#35302F',
  textBtnFocusedTertiary: '#FFFFFF',
  bgBtnTertiary: '#FFFFFF',
  bgBtnHoverTertiary: '#CCD8DE',
  bgBtnDisabledTertiary: '#C9CDD6',
  bgBtnFocusedTertiary: '#35302F',
  bgBtnPressedTertiary: '#FFFFFF',
  borderbgBtnTertiary: '#35302F',
  borderbgBtnPressedTertiary: '#0075e280',

  // Button Quaternary
  bgBtnQuaternary: '#FFFFFF',
  textBtnDisabledQuaternary: '#C9CDD6',
  bgBtnHoverQuaternary: '#F1EFEF',
  bgBtnDisabledQuaternary: '#F0F0F0',
  borderbgBtnQuaternary: '#47D7AC66',
  textBtnHoverQuaternary: '#636E82',

  // Button Link Primary
  textBtnLinkPrimary: '#35302F',
  textBtnLinkHoverPrimary: '#6C6260',
  textBtnLinkFocusedPrimary: '#6C6260',
  textBtnLinkPressedPrimary: '#1B1818',
  bgButtonLink: '#f5faff',

  // Button Link Secondary
  textBtnLinkSecondary: '#FFFFFF',
  textBtnLinkHoverSecondary: '#DADADA',
  textBtnLinkFocusedSecondary: '#636E82',
  textBtnLinkPressedSecondary: '#FFFFFF',

  // Form //
  redInput: '#F13D3E',
  greenInput: ' #009E6B',
  textInputColor: '#35302F',
  inputBorderColor: '#DADADA',
  inputBorderColorFocus: '#636E82',
  bgDisabledInput: '#F0F0F0',

  // Status Merchant //
  greyStatus: '#636E82',
  redStatus: '#F13D3E',
  greenStatus: '#009E6B',
  yellowStatus: '#FFAD0C',
  blueStatus: '#35302F',

  //Input
  bgCheckbox: '#C9CDD6',
  bgCheckboxChecked: '#35302F',
  placeholder: '#C9CDD6',

  // ----- //
  loaderBg: 'rgba(255, 255, 255, 0.95)',

  /* MERCHANT PRIVATE AREA  */
  //SHADOWS
  liquidityCardShadowColor: '#25ac84',
  modalFooterShadowColor: '#35302F3f',
  cardShadowBlue: '#35302F1e',

  //Backgrounds
  backgroundGreen: '#F7FDFB',
  backgroundGrey: '#F6F7F9',
  backgroundBlue: '#F5FAFF',
  backgroundOutlined: '#C9CDD6',

  //copiedLinkShadow
  copiedLinkShadow: '#35302F1F',

  //tab hover
  tabBtnHover: '#e7e4e4',

  //table
  textHeader: '#636E82',
  textTableLightBody: '#636E82',
  textTableBody: '#35302F',
  textTableHeaderMobile: '#636E82',
};

export const gradients = {
  lightGrey: `linear-gradient(
    90deg,
    ${colors.gradientsStatusGreyDark} 0%,
    ${colors.gradientsStatusGreyDark} 100%
  )`,
};

if (process.env.NODE_ENV !== 'production') {
  Object.freeze(colors);
  Object.freeze(gradients);
}

export type Colors = Readonly<typeof colors>;
export type Gradients = Readonly<typeof gradients>;
export type GradientKeys = keyof Gradients;
export type ColorKeys = keyof Colors;

export default colors;
