import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import {
  LandingPageContainer,
  LandingPageIcon,
} from 'src/pages/landingPage/LandingPage.style';
import { H4, P } from 'src/style-utils/typographic';
import OnboardingSuccess from 'src/images/pageStatus/party_popper.svg?url';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useQuery } from 'react-query';
import { merchantGetInfoMethodApi } from 'src/services/user/user.request';
import { merchantGetInfo } from 'src/services/user/user.config';
import Loader from 'src/components/Loader/Loader.component';
import { useProfileStore } from 'src/store/store';

function SuccessStripe() {
  const setUser: any = useProfileStore((state) => state.setUser);
  const setValue = useProfileStore((state) => state.setValue);
  const { isLoading: isLoadingMerchantInfo } = useQuery(
    merchantGetInfo,
    merchantGetInfoMethodApi,
    {
      onSuccess: (res) => {
        setUser(res?.data.ResultSet);
        setValue('AccountType', 'MERCHANT');
      },
    },
  );
  const navigate = useNavigate();
  if (isLoadingMerchantInfo) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <LandingPageContainer
      flex="1"
      flexDirection="column"
      padding="0 2.4rem 4rem"
      gap="1.6rem"
    >
      <LandingPageIcon src={OnboardingSuccess} />
      <H4>
        <Translate id={'text.successOnboardingTitle'} />
      </H4>
      <P>
        <Translate id={'text.successOnboardingSubtitleent'} />
      </P>

      <ButtonContainer>
        <Button
          onClick={() =>
            navigate(`${RoutePath.privateArea}?typeAccount=MERCHANT`)
          }
        >
          <Translate id={'link.startOPL'} />
        </Button>
      </ButtonContainer>
    </LandingPageContainer>
  );
}

export default SuccessStripe;
