import SupportCards from 'src/components/SupportCards/SupportCards.component';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';
import { MaxWidthContainer } from 'src/style/Container.style';

const Support: React.FC = () => {
  return (
    <MaxWidthContainer>
      <H4 marginBottom="2.4rem" marginTop="5.6rem">
        <Translate id="text.support" />
      </H4>
      <SupportCards />
    </MaxWidthContainer>
  );
};

export default Support;
