import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import moment from 'moment';
import { startDate } from 'src/pages/monitoring/Monitoring.config';
import { RadioBoxCSTPopover } from 'src/pages/monitoring/radiobox/RadioBoxCSTPopover.component';
import { RadioBoxPopover } from 'src/pages/monitoring/radiobox/RadioBoxPopover.component';
import { DataFormMonitoringBuyerStats } from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.helpers';
import { MouseEventHandler, useState } from 'react';

type PeriodPopoverProps = {
  submitHandler?: MouseEventHandler | undefined;
  initialData?: number[];
  dataFiltered: DataFormMonitoringBuyerStats;
  onCloseHandler?: () => void;
};
export const PeriodPopover: React.FC<PeriodPopoverProps> = ({
  submitHandler,
  initialData,
  dataFiltered,
  onCloseHandler,
}) => {
  const [showDate, setShowDate] = useState(false);
  return (
    <SelectPopover
      submitHandler={submitHandler}
      label="text.period"
      countFilters={dataFiltered.startDate.length > 0 ? 1 : undefined}
      onCloseHandler={onCloseHandler}
    >
      {initialData?.map((year) => (
        <RadioBoxPopover
          key={year}
          name={startDate}
          label={year.toString()}
          id={year.toString()}
          value={moment.utc({ year }).startOf('year').toISOString()}
          setStateHandler={setShowDate}
        />
      ))}
      <RadioBoxCSTPopover
        name={'custom'}
        label={'lbl.choosePeriod'}
        id="custom"
        value="custom"
        showDate={showDate}
        setStateHandler={setShowDate}
      />
    </SelectPopover>
  );
};

export default PeriodPopover;
