export enum FindOutMoreEnum {
  stripe = 'stripe',
  namirial = 'namirial',
}
export const FindOutMoreMap = {
  [FindOutMoreEnum.stripe]: {
    titlePage: 'text.findOutMoreStripeTitlePage',
    content: [
      {
        title: 'text.stripeTitle1',
        description: 'text.stripeDescription1',
      },
      {
        title: 'text.stripeTitle2',
        description: 'text.stripeDescription2',
      },
      {
        title: 'text.stripeTitle3',
        description: 'text.stripeDescription3',
      },
      {
        title: 'text.stripeTitle4',
        description: 'text.stripeDescription4',
      },
    ],
  },
  [FindOutMoreEnum.namirial]: {
    titlePage: 'text.findOutMoreNamirialTitlePage',
    content: [
      {
        title: 'text.namirialTitle1',
        description: 'text.namirialDescription1',
      },
      {
        title: 'text.namirialTitle2',
        description: 'text.namirialDescription2',
      },
      {
        title: 'text.namirialTitle3',
        description: 'text.namirialDescription3',
      },
      {
        title: 'text.namirialTitle4',
        description: 'text.namirialDescription4',
      },
      {
        title: 'text.namirialTitle5',
        description: 'text.namirialDescription5',
      },
    ],
  },
};
