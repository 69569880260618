import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const RowHomeStepper = styled.div`
  position: relative;
  border-left: 1px solid ${colors.greyBorder};
  padding: 0 0 9rem 3.6rem;
  margin-left: 2rem;
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
  ${media.tablet`
  margin-left: 4rem;
  padding: 0 0 11rem 14rem;
  `}
`;

export const CircleHomeStepper = styled.div`
  position: absolute;
  left: -2.5rem;
  border-radius: 50%;
  border: 1px solid ${colors.greyBorder};
  padding: 4px;
  background: ${colors.backgroundGrey};
  div {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background: #ccfff0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    line-height: 4.4rem;
    font-weight: 700;
  }
  ${media.tablet`
    left: -5.3rem;
    div {
      width: 9.6rem;
      height: 9.6rem;
      font-size: 6.4rem;
      line-height: 9rem;
    }
  `}
`;

export const IconHomeStepper = styled(Icon)`
  color: ${colors.textP};
  width: 3.1rem;
  height: 3.1rem;
  ${media.tablet`
    width: 5.7rem;
    height: 5.7rem;
  `}
`;
