export const continue_100 = 100;
export const switchingProtocols_101 = 101;
export const processing_102 = 102;
export const earlyHints_103 = 103;
export const ok_200 = 200;
export const created_201 = 201;
export const accepted_202 = 202;
export const nonAuthoritativeInformation_203 = 203;
export const noContent_204 = 204;
export const resetContent_205 = 205;
export const partialContent_206 = 206;
export const multiStatus_207 = 207;
export const alreadyReported_208 = 208;
export const imUsed_226 = 226;
export const multipleChoices_300 = 300;
export const movedPermanently_301 = 301;
export const found_302 = 302;
export const seeOther_303 = 303;
export const notModified_304 = 304;
export const useProxy_305 = 305;
export const unused_306 = 306;
export const temporaryRedirect_307 = 307;
export const permanentRedirect_308 = 308;
export const badRequest_400 = 400;
export const unauthorized_401 = 401;
export const paymentRequired_402 = 402;
export const forbidden_403 = 403;
export const notFound_404 = 404;
export const methodNotAllowed_405 = 405;
export const notAcceptable_406 = 406;
export const proxyAuthenticationRequired_407 = 407;
export const requestTimeout_408 = 408;
export const conflict_409 = 409;
export const gone_410 = 410;
export const lengthRequired_411 = 411;
export const preconditionFailed_412 = 412;
export const payloadTooLarge_413 = 413;
export const uriTooLong_414 = 414;
export const unsupportedMediaType_415 = 415;
export const rangeNotSatisfiable_416 = 416;
export const expectationFailed_417 = 417;
export const imATeapot_418 = 418;
export const misdirectedRequest_421 = 421;
export const unprocessableEntity_422 = 422;
export const locked_423 = 423;
export const failedDependency_424 = 424;
export const upgradeRequired_426 = 426;
export const preconditionRequired_428 = 428;
export const tooManyRequests_429 = 429;
export const requestHeaderFieldsTooLarge_431 = 431;
export const unavailableForLegalReasons_451 = 451;
export const internalServerError_500 = 500;
export const notImplemented_501 = 501;
export const badGateway_502 = 502;
export const serviceUnavailable_503 = 503;
export const gatewayTimeout_504 = 504;
export const httpVersionNotSupported_505 = 505;
export const variantAlsoNegotiates_506 = 506;
export const insufficientStorage_507 = 507;
export const loopDetected_508 = 508;
export const notExtended_510 = 510;
export const networkAuthenticationRequired_511 = 511;
