export const Cash = 'Incassi';
export const ServiceCosts = 'Costi del Servizio';
export const Withdrawals = 'Prelievi';

export const configMovementsTitle = {
  [Cash]: {
    label: 'lbl.cash',
    operator: '+',
  },
  [ServiceCosts]: {
    label: 'text.serviceCosts',
    operator: '-',
  },
  [Withdrawals]: {
    label: 'lbl.withdrawals',
    operator: '-',
  },
};
