import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import {
  ContainerPadding,
  ResponsiveContainer,
} from 'src/style/Container.style';
import styled from 'styled-components';

export const ContainerPaddingStyled = styled(ContainerPadding)`
  overflow: auto;
  margin-bottom: 10rem;
  ${media.tablet`
    height: calc(100svh - 42rem);
    margin-bottom: 0;
  `};
`;

export const ButtonContainerCustom = styled.div<{ backgroundColor?: string }>`
  position: fixed;
  width: 100%;
  gap: 1.6rem;
  bottom: 0;
  z-index: 5;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.backgroundGrey};
  padding: 2.4rem;
  box-shadow: 0 4px 24px 0.4px ${colors.modalFooterShadowColor};
  ${media.tablet`
    flex-direction: row;
  `};
`;

export const ResponsiveContainerCustom = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2.4rem;
  ${media.tablet`
  flex-direction: row;
  `}
`;
