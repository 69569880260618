import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const ContainerInfo = styled(Card)`
  background-color: white;
  display: grid;
  gap: 1.6rem;
  ${media.tablet`
  padding: 3.2rem;
  `}
`;
