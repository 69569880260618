import {
  ContainerMessage,
  MessageError,
} from 'src/components/InputField/style/input.style';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const ContainerMessageGeneric = styled(ContainerMessage)`
  margin-top: 1rem;
  text-align: ${({ textAlign }) => textAlign};
  font-size: 1rem;
  span {
    font-weight: 400;
  }
  line-height: 1.6rem;
`;

export const MessageErrorGeneric = styled(MessageError)`
  color: ${colors.redInput};
`;
