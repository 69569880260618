import ResponsiveAccordion from 'src/components/ResponsiveAccordion/ResponsiveAccordion.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as RemodulationHeroImgage } from 'src/images/products/remodulation_info_hero.svg';
import HomeStepper from 'src/pages/privateArea/partials/merchant/partials/homeStepper/HomeStepper.component';
import {
  ButtonCustom,
  CustomGrid,
  CustomHomeH2,
  CustomSeparator,
} from 'src/pages/privateArea/partials/merchant/style/MerchantArea.style';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useProfileStore } from 'src/store/store';
import { H3 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { AccountStatus } from 'src/utils/config/stateAccount';
import {
  remodulationListItems,
  remodulationStepperArray,
} from './RemodulationInfo.config';

export const RemodulationInfo: React.FC = () => {
  const accountStatus = useProfileStore((state) => state.Status);
  const { completed } = AccountStatus;
  const navigate = useNavigate();
  function clickCTAHandler() {
    navigate(RoutePath.linkDiPagamento);
  }

  return (
    <>
      <div className="relative z-0 overflow-hidden bg-white py-16">
        <CustomGrid>
          <RemodulationHeroImgage />
          <Flex fluid flexDirection="column" gap="2rem">
            <CustomHomeH2>
              <Translate id="text.remodulationInfoTitle" />
            </CustomHomeH2>
            <ButtonCustom
              variant="Primary"
              type="button"
              disabled={accountStatus !== completed}
              onClick={clickCTAHandler}
            >
              <Translate id="lbl.startNow" />
            </ButtonCustom>
          </Flex>
        </CustomGrid>
      </div>

      <CustomSeparator />

      <div className="container py-16">
        <H3 textAlign="center" light className="mb-8 lg:mb-14">
          <Translate id="text.remodulationInfoSectionTitle2" />
        </H3>
        <ResponsiveAccordion listItems={remodulationListItems} />
      </div>

      <div className="container py-16">
        <H3 light className="mb-8 lg:mb-14">
          <Translate id="text.remodulationInfoSectionTitle1" />
        </H3>
        <HomeStepper stepperArray={remodulationStepperArray} />
      </div>
    </>
  );
};

export default RemodulationInfo;
