import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe, Stripe } from '@stripe/stripe-js';
import Translate from 'src/components/Translate/Translate.component';
import React, { useEffect, useState } from 'react';
import { useLanguage } from 'src/services/i18n/i18n.context';
import CheckoutForm from './partials/checkoutForm.component';
import { STRIPE_PROMISE } from 'src/settings';
import NeedHelpBase from '../../../../../../components/NeedHelpButton/NeedHelpBase.component';
import NeedHelpInline from '../../../../../../components/NeedHelpButton/NeedHelpInline.component';
import { PaymentErrorMessage } from './style/paymentCard.style';

export type StateStripe = {
  type?: string;
  amount?: string | number;
  guidParam?: string;
  paymentDate: string;
  orderGuid: string;
};
const PaymentForm: React.FC<{
  secretKey?: string;
  stateStripe: StateStripe;
}> = ({ secretKey, stateStripe }) => {
  const clientSecretStripe = secretKey;
  const [clientSecret] = React.useState(clientSecretStripe);

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [error, setError] = useState(false);

  const { language } = useLanguage();

  const options: StripeElementsOptions = {
    clientSecret,
    locale: language,
  };

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeInstance = await loadStripe(`${STRIPE_PROMISE}`);
        setStripe(stripeInstance);
      } catch (error) {
        setError(true);
      }
    };
    initializeStripe();
  }, []);

  return (
    <div>
      {error && (
        <PaymentErrorMessage id="payment-message">
          <Translate id="error.stripeFail" />{' '}
          <NeedHelpBase BaseComponent={NeedHelpInline} />
        </PaymentErrorMessage>
      )}
      {stripe && (
        <Elements stripe={stripe} options={options} data-cookieconsent="ignore">
          <CheckoutForm stateStripe={stateStripe} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentForm;
