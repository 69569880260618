var ENV = '';

switch (true) {
  case window.location.host.includes('.dev.'):
  case window.location.host.includes('localhost'):
    ENV = 'dev';
    break;
  case window.location.host.includes('.test.'):
    ENV = 'test';
    break;
  default:
    ENV = 'production';
    break;
}

const prod = {
  apiUrl: `https://paylater-api.opyn.eu/api/v1`,
  language: `https://static.opyn.eu/opl/translations/prod/`,
  stripePromise:
    'pk_live_51LuIKPBHlcEWpgCkVmvKPuB1tXSEiwZYWR51mOAmnVcoYHY45xYdzLx2ZEUNDIPQd8fuZSBdMGxCEqYAWztVD4As00dOU2IXuY',
  indigoToken: {
    en: 'b3adebd5-dc4c-49a8-89b8-f026d88e7536',
    it: 'b6d49989-88a7-4d52-a235-757cf34d6c45',
  },
};

const dev = {
  apiUrl: `https://paylater-api.${ENV}.opyn.eu/api/v1`,
  language: `https://static.opyn.eu/opl/translations/${ENV}/`,
  stripePromise:
    'pk_test_51LuIKPBHlcEWpgCkCuB5TUL95AgdZlHznw3qzVYwiCO545sdtCm9M2hvzJG6vHV0crdkXqJsteOeVSIrsdFHUSKg00vF7t5utV',
  indigoToken: {
    en: '3dba628c-4786-46dd-ab09-9acb0f4a6b04',
    it: 'cf3e95cb-7e8e-4074-ab5e-b5981a13a671',
  },
};

const API_URL = ENV === 'production' ? prod.apiUrl : dev.apiUrl;
const LANGUAGE_URL = ENV === 'production' ? prod.language : dev.language;
const STRIPE_PROMISE =
  ENV === 'production' ? prod.stripePromise : dev.stripePromise;
const INDIGO_TOKEN = (lang: string) => {
  if (ENV === 'production') {
    return lang.toLowerCase() === 'it'
      ? prod.indigoToken['it']
      : prod.indigoToken['en'];
  } else {
    return lang.toLowerCase() === 'it'
      ? dev.indigoToken['it']
      : dev.indigoToken['en'];
  }
};
const URL_FREECURRENCY = 'https://api.freecurrencyapi.com/v1/';
const KEY_FREECURRENCY = 'fca_live_7FcBSiD4mVmEBkwzMofUyL00LgEcGvYDoRjLbGyY';

export {
  ENV,
  API_URL,
  LANGUAGE_URL,
  STRIPE_PROMISE,
  INDIGO_TOKEN,
  URL_FREECURRENCY,
  KEY_FREECURRENCY,
};
