import Translate from 'src/components/Translate/Translate.component';

import { H5, P, PLegal } from 'src/style-utils/typographic';
import { Grid } from 'src/style/Container.style';
import { CardProps, InfoCards, InfoCardsResend } from './CreateLinkInfo.helper';
import {
  CLInfoCardsWrapper,
  CLInfoContainer,
  CustomColoredSection,
  IconCircle,
} from './CreateLinkInfo.style';

const InfoCard: React.FC<CardProps> = ({ SvgIcon, title, content }) => {
  return (
    <CLInfoContainer>
      <IconCircle>
        <SvgIcon width="3.6rem" height="3.6rem" />
      </IconCircle>
      <H5>
        <Translate id={title} />
      </H5>
      <P>
        <Translate id={content} />
      </P>
    </CLInfoContainer>
  );
};

export const CreateLinkInfo: React.FC<{ resent?: boolean }> = ({
  resent = false,
}) => {
  const cards = resent ? InfoCardsResend : InfoCards;
  return (
    <CustomColoredSection
      padding="2.4rem 0 4rem 0"
      bgColor="white"
      margin="6.4rem 0 0 0"
    >
      <CLInfoCardsWrapper>
        <H5 light>
          <Translate id="avoidExp.sectionTitle" />
        </H5>
        <Grid responsive gap="1.6rem" gapOnDesktop="2.4rem">
          {cards.map((card, key) => (
            <InfoCard {...card} key={key} />
          ))}
        </Grid>
        <PLegal>
          <Translate id="avoidExp.note" />
        </PLegal>
      </CLInfoCardsWrapper>
    </CustomColoredSection>
  );
};

export default CreateLinkInfo;
