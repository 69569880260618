import React from 'react';
import { MaxWidthContainer } from 'src/style/Container.style';
import { OrderType } from 'src/services/linksFilteredData/linksFilteredData.request';
import ClientInfoCard, {
  ClientInfoCardProps,
} from 'src/components/ClientInfoCard/ClientInfoCard';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { Flex } from 'src/style/flexbox.style';
import { Button } from 'src/components/Button/Button.component';
import { EmptyStateData } from 'src/components/EmptyStateData/EmptyStateData.component';
import { takeLinks } from '../SearchCriteriaForm.config';
import { pblPageArray } from '../../payByLinkPage.config';
import { useTab } from '../../context';
import Translate from 'src/components/Translate/Translate.component';

export type WrapperOrdersListProps = {
  tabId: string;
  index: number;
  noTitle: string;
  noSubtitle: string;
  links?: OrderType[];
  linksCount: number;
  madeFromPlugin: boolean;
  currentPage: number;
};
export const WrapperList: React.FC<WrapperOrdersListProps> = ({
  tabId,
  index,
  links,
  noTitle,
  noSubtitle,
  linksCount,
  madeFromPlugin,
  currentPage,
}) => {
  const { nextPage } = useTab();
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const label =
    pblPageArray[madeFromPlugin.toString() as keyof typeof pblPageArray];

  const handleNextPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    nextPage(index);
  };

  return (
    <>
      {links && links.length > 0 && (
        <MaxWidthContainer maxWidth="66.8rem" marginTop="4.8rem" noXMargin>
          {links && links.length > 0 && (
            <>
              <div className="border-0 border-b border-solid border-pblack-100 pb-3">
                {links &&
                  links.map(
                    ({
                      RagioneSocialeBuyer,
                      DataScadenza,
                      Id,
                      Stato,
                      Amount,
                      PluginExternalId,
                      FirstInstallmentExpiration,
                      FirstInstallmentPayment,
                    }) => {
                      const clientInfos: ClientInfoCardProps = {
                        clientName: RagioneSocialeBuyer,
                        clientId: Id,
                        statusLink: Stato,
                        dueDate:
                          Stato === PayByLinkStatus.createdLink
                            ? refactorFormatDate(DataScadenza, 'DD-MM-YY HH:mm')
                            : undefined,
                        installmentExpiration:
                          Stato === PayByLinkStatus.planned
                            ? refactorFormatDate(
                                FirstInstallmentExpiration,
                                'DD/MM/YYYY',
                              )
                            : undefined,
                        installmentPayment:
                          Stato === PayByLinkStatus.regularIncoming
                            ? refactorFormatDate(
                                FirstInstallmentPayment,
                                'DD/MM/YYYY [alle h] HH:mm',
                              )
                            : undefined,
                        totalAmount: formatCurrency(Amount),
                        pluginId: PluginExternalId,
                        madeFromPlugin,
                      };
                      return (
                        <ClientInfoCard
                          key={`${tabId}-${clientInfos.clientId}`}
                          {...clientInfos}
                        />
                      );
                    },
                  )}
              </div>
              <Flex
                justify="center"
                align="center"
                desktopPaddingBottom="0"
                paddingBottom="9.2rem"
              >
                <Button
                  variant="LinkPrimary"
                  onClick={handleNextPageClick}
                  disabled={
                    !(currentPage + 1 <= Math.ceil(linksCount / takeLinks))
                  }
                >
                  <span
                    className={
                      currentPage + 1 <= Math.ceil(linksCount / takeLinks)
                        ? 'font-bold'
                        : 'font-normal'
                    }
                  >
                    {currentPage + 1 <= Math.ceil(linksCount / takeLinks) ? (
                      <Translate id={label.buttonLabel} />
                    ) : (
                      <Translate id="text.noMoreElementsToShow" />
                    )}
                  </span>
                </Button>
              </Flex>
            </>
          )}
        </MaxWidthContainer>
      )}
      {links && links.length === 0 && (
        <EmptyStateData title={noTitle} subtitle={noSubtitle} />
      )}
    </>
  );
};
