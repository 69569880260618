import createLength, { errorMessageMinPrefix } from './length';
import { TransformError } from './types';

export const telephoneErrorMessage = 'error.telephone.min';

// Prefix not included
export default createLength({ min: 6, max: 15, phone: true });

export function transformTelephoneError(error: string) {
  const output = { id: error } as ReturnType<TransformError>;

  if (error.startsWith(errorMessageMinPrefix)) {
    let offset = error.lastIndexOf('.');

    output.id = telephoneErrorMessage;
    output.data = { value: error.slice(offset + 1) };
  }

  return output;
}
