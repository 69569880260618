import { memo, useMemo } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { createTableTheme } from './DataTable.theme';
import { DataTableProps, TableColumn } from './DataTable.types';
import { Body, Head, HeadRow, Table, Wrapper } from './style/DataTable.style';
import NoData from './component/NoData';
import HeaderCell from './component/HeaderCell';
import TableRow from './component/TableRow';
import Loader from 'src/components/Loader/Loader.component';

const DataTable = <TData extends object>(props: DataTableProps<TData>) => {
  const {
    data,
    isLoadingData,
    NoDataComponent = NoData,
    columns,
    hideHeader = false,
    i18nHeader = true,
    customTableTheme,
  } = props;

  //we check if there is at least one element so we can show the pagination or the NoDataComponent if empty array data is aprovided
  const isDataAvailable = data.length > 0;

  //merge our custom theme ( if provided ) with the existing table theme
  const tableTheme = useMemo(
    () => createTableTheme(customTableTheme),
    [customTableTheme],
  );

  if (isLoadingData) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <ThemeProvider theme={tableTheme as DefaultTheme}>
      <Wrapper data-component="data-table">
        <Table role="table">
          {!hideHeader && (
            <Head role="rowgroup">
              <HeadRow role="row">
                {columns.map((column: TableColumn<TData>, index: number) => (
                  <HeaderCell
                    key={index}
                    column={column}
                    i18nHeader={i18nHeader}
                    numberOfCells={columns.length}
                  />
                ))}
              </HeadRow>
            </Head>
          )}
          {isDataAvailable ? (
            <Body role={'rowgroup'}>
              {data?.map((row: TData, index: number) => {
                return <TableRow key={index} rowData={row} columns={columns} />;
              })}
            </Body>
          ) : (
            <NoDataComponent />
          )}
        </Table>
      </Wrapper>
    </ThemeProvider>
  );
};
export default memo(DataTable) as typeof DataTable;
