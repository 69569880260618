export const userEndPoint = 'Account/GetAccountInfo';
export const merchantGetInfo = 'Merchant/GetMerchantInfo';
export const buyerGetInfo = 'Buyer/GetBuyerInfo';
export const getDocuments = 'Merchant/GetDocuments';
export const getDocument = 'Merchant/GetDocument';
export const updateLanguage = 'Account/UpdateLanguage';

export const GetUrlToIdentify = 'Merchant/GetUrlToIdentify';
export const GetUrlToSignContract = 'Merchant/GetUrlToSignContract';

export const getLegalInfo = 'Merchant/GetLegalInfo';
