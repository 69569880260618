import { cva } from 'class-variance-authority';
import Translate from 'src/components/Translate/Translate.component';
import { BuyerOrderStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import { PSmall } from 'src/style-utils/typographic';
import {
  InstallmentsStatusMap,
  PayByLinkStatus,
  PayByLinkStatusMap,
} from './StatusPayByLink.config';
import {
  StatusPayByLinkProps,
  StatusPyByLinkValues,
} from './StatusPayByLink.types';

const findVariant: (
  status: StatusPyByLinkValues,
  typeAccount: 'MERCHANT' | 'BUYER',
) => 'normal' | 'success' | 'danger' | 'warning' | 'default' = (
  status,
  typeAccount,
) => {
  switch (status) {
    case PayByLinkStatus.regularIncoming:
    case PayByLinkStatus.planned:
      return 'normal';
    case PayByLinkStatus.fullCashed:
      return 'success';
    case PayByLinkStatus.expiredPayment:
      return 'danger';
    case PayByLinkStatus.refund:
      return 'warning';
    case PayByLinkStatus.cancelledLink:
    case PayByLinkStatus.interrupted:
      return typeAccount === 'MERCHANT' ? 'default' : 'warning';
    default:
      return 'default';
  }
};

const wrapperVariants = cva(
  'w-fit whitespace-nowrap rounded border border-current bg-white px-1.5',
  {
    variants: {
      type: {
        normal: 'text-pblack-400',
        success: 'text-success',
        danger: 'text-danger',
        warning: 'text-warning',
        default: 'text-pgrey',
      },
    },
    defaultVariants: {
      type: 'default',
    },
  },
);

const StatusPayByLink = ({
  statusLink,
  typeAccount = 'MERCHANT',
}: StatusPayByLinkProps) => {
  const { label } =
    typeAccount === 'MERCHANT'
      ? PayByLinkStatusMap[statusLink as StatusPyByLinkValues]
      : InstallmentsStatusMap[statusLink as BuyerOrderStatus];

  const variant = findVariant(statusLink, typeAccount);

  return (
    <div
      data-component="status-pay-by-link"
      className={wrapperVariants({ type: variant })}
    >
      <PSmall textColor="inherit" bold>
        <Translate id={label} />
      </PSmall>
    </div>
  );
};

export default StatusPayByLink;
