import { Button } from 'src/components/Button/Button.component';
import { ModalFooterProps } from 'src/components/Modal/partials/ModalFooter/ModalFooterDefault.component';
import { ModalFooterRoot } from 'src/components/Modal/partials/ModalFooter/style/ModalFooterDefault.style';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type OtpModalFooterProps = ModalFooterProps & {
  applyForm: () => void;
};

const CollaboratorModalFooter: React.FC<OtpModalFooterProps> = ({
  applyForm,
}) => {
  const { handleSubmit, formState } = useFormContext();
  return (
    <ModalFooterRoot>
      <Button
        sizeOnDesktop="medium"
        onClick={handleSubmit(applyForm)}
        disabled={!formState.isValid}
        variant="Primary"
      >
        <Translate id="lbl.confirm" />
      </Button>
    </ModalFooterRoot>
  );
};

export default CollaboratorModalFooter;
