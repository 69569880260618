import SuccessPage from 'src/pages/registration/partials/SuccessPage/SuccessPage.component';
import { RoutePath } from 'src/routers/routers.config';

function RegistrationCollaboratorSuccessPage() {
  return (
    <SuccessPage
      nextPath={`${RoutePath.privateArea}?typeAccount=MERCHANT`}
      title="text.successAccountCreatedTitle"
      subTitle="text.startCollaboratingSubtitle"
      buttonLabel="link.startOPL"
    />
  );
}

export default RegistrationCollaboratorSuccessPage;
