import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import { H3, P } from 'src/style-utils/typographic';
import { Button } from '../Button/Button.component';
import media from 'src/style-utils/media-queries';

export const FrameContainer = styled(Flex)`
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  ${media.tablet`
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  `}
`;

export const FrameMain = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const FrameContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 325px;

  ${media.tablet`
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 560px;
  `}
`;

export const FrameIcon = styled(Flex)`
  width: 384px;
  height: 280.637px;
`;

export const FrameText = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Title = styled(H3)`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;

  ${media.tablet`
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  `}
`;

export const Description = styled(P)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  ${media.tablet`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  `}
`;

export const MediumButton = styled(Button)`
  display: flex;
  width: 280px;
  height: 54px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
