import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import React from 'react';

export const AdvPayResultContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 70ch;
  text-align: center;
  .amountColored {
    color: ${colors.greenStatus};
  }
`;

interface IconWrapperProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ icon: IconComponent }) => {
  return IconComponent ? <IconComponent /> : null;
};

export const PSIcon = styled(IconWrapper)`
  margin: 4.8rem 0;
  height: 19rem;
  ${media.tablet`
    height: 28rem;
  `}
`;
