import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Flex } from 'src/style/flexbox.style';
import { cardIsExpired } from 'src/utils/functions/cardExpired';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import CheckboxStripe from '../partials/CheckboxStripe/CheckboxStripe.component';
import { t } from 'i18next';

type Props = {
  goToChangeMethod: () => void;
  pContenxt: pContextType;
};
const PayWithMethod = ({ goToChangeMethod, pContenxt }: Props) => {
  const {
    orderGuid,
    selectedMethodId,
    paymentMethods,
    goToStripeFormHandler,
    paymentDate,
    guidParam,
  } = pContenxt;
  const {
    confirmOrderHandler,
    sessionData: { LenderName },
  } = useOutletContext<EcommerceContext>();
  const [checked, setChecked] = useState(false);
  const buttonLabel = 'lbl.payNowAlt';
  const buttonDateLabel = 'lbl.confirm&PayDate';
  const PaymentMethod =
    selectedMethodId > 0
      ? paymentMethods.find((item) => item.Id === selectedMethodId)
      : paymentMethods
        ? paymentMethods[0]
        : null;

  const Details = PaymentMethod?.Details;
  if (!Details) goToStripeFormHandler();

  const expired = cardIsExpired(PaymentMethod);

  return (
    <>
      <Flex
        flexDirection="column"
        align="center"
        gap="2.4rem"
        margin="2.4rem 0"
      >
        {PaymentMethod && <PaymentMethodElement {...PaymentMethod} />}
        <Button
          variant="LinkPrimary"
          i18n
          underline
          fontWeight="400"
          translatedText="text.chooseAnotherPaymentMethod"
          onClick={goToChangeMethod}
        />
      </Flex>
      <hr style={{ marginBlock: '2.4rem' }} />
      <CheckboxStripe
        description={t('text.okCreditAssignment', { x: LenderName })}
        checked={checked}
        setChecked={() => setChecked(!checked)}
      />
      <ButtonContainer>
        <Button
          onClick={() => {
            confirmOrderHandler({
              orderGuid,
              paymentMethodId: selectedMethodId,
              guidParam,
            });
          }}
          variant="Primary"
          height="5.4rem"
          minWidth="100%"
          type="submit"
          disabled={expired || !checked}
        >
          <Translate
            id={
              moment().isSame(paymentDate, 'day')
                ? buttonLabel
                : buttonDateLabel
            }
            data={{ paymentDate: refactorFormatDate(paymentDate, 'DD/MM') }}
          />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default PayWithMethod;
