export const takeMovements = 30;

export const startDate = 'fromDate';
export const endDate = 'toDate';
export const clientList = 'buyers';
export const typesField = 'types';
export const flagCessioneCredito = 'flagCessioneCredito';
export const flagNonCessioneCredito = 'flagNonCessioneCredito';
export const skipField = 'skip';
export const takeField = 'take';
