import { createScopedErrorHandler } from 'src/utils/functions/handleError';
import { endCallbackUrlKey } from './session.config';

export const sessionErrorHandler = createScopedErrorHandler('session');

/**
 * Extracts 'endcallbackurl' from query params.
 * @param searchParams @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export function acquireEndcallbackUrlFromQueryParams(
  searchParams: URLSearchParams,
): string | null {
  const endCallbackUrlToValidate: string | null =
    searchParams.get(endCallbackUrlKey);

  if (!endCallbackUrlToValidate || endCallbackUrlToValidate.length < 3) {
    return null;
  }

  let parsedURL: URL | null = null;

  try {
    parsedURL = new URL(endCallbackUrlToValidate);
  } catch (InvalidURLError) {
    sessionErrorHandler(
      `invalid ${endCallbackUrlKey} provided, got "${endCallbackUrlToValidate}"`,
      InvalidURLError,
    );
  }

  if (parsedURL) {
    return parsedURL.toString();
  }

  return null;
}
