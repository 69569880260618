import React from 'react';
import DisableWindowScroll from 'src/components/DisableWindowScroll/DisableWindowScroll.component';
import Nothing from 'src/components/Nothing/Nothing.component';
import invariant from 'invariant';
import { ThemeProvider } from 'styled-components';
import { useModalHelper } from './Modal.helpers';
import { ConnectModalConfig } from './Modal.types';
import { ModalHeader } from './partials/ModalHeader/ModalHeader.component';
import ModalInjector from './partials/ModalInjector.component';
import {
  FocusSentinel,
  ModalBody,
  ModalContentRoot,
  ModalRoot,
} from './style/Modal.styles';

export default function withConnectedModal({
  modalName,
  headerComponent = ModalHeader,
  bodyComponent = ModalBody,
  footerComponent = Nothing,
  modifyTheme,
  enableCloseOnEscKey = true,
  preventCloseOnClickOutside = false,
}: ConnectModalConfig) {
  const HeaderComponent = headerComponent;
  const BodyComponent = bodyComponent;
  const FooterComponent = footerComponent;

  invariant(
    typeof modalName === 'string' && modalName.length > 0,
    `connectModal: ${modalName} is not a valid modal identifier`,
  );

  const ConnectedModal: React.FC<any> = ({
    modalTitle,
    i18n,
    i18nData,
    handleClose,
    ...otherProps
  }) => {
    //Get the helper functions
    const { processHeaderTranslation, handleClickOutside, handleKeyDown } =
      useModalHelper({
        modalTitle,
        i18n,
        i18nData,
        handleClose,
        enableCloseOnEscKey,
        preventCloseOnClickOutside,
      });

    //build template
    const visibleModalContent = (
      <ModalRoot
        onClick={handleClickOutside}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <FocusSentinel tabIndex={0} data-focus-sentinel="start" />

        <DisableWindowScroll />
        <ModalContentRoot>
          <HeaderComponent
            modalTitle={processHeaderTranslation()}
            handleClose={handleClose}
          />
          <BodyComponent handleClose={handleClose} {...otherProps} />
          <FooterComponent {...otherProps} handleClose={handleClose} />
          <FocusSentinel tabIndex={0} data-focus-sentinel="end" />
        </ModalContentRoot>
      </ModalRoot>
    );
    //check if custom theme was provided
    if (modifyTheme) {
      return (
        <ModalInjector modalName={modalName}>
          <ThemeProvider theme={modifyTheme}>
            {visibleModalContent}
          </ThemeProvider>
        </ModalInjector>
      );
    }

    return (
      <ModalInjector modalName={modalName}>{visibleModalContent}</ModalInjector>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    ConnectedModal.displayName = `withConnectModal(${
      BodyComponent.displayName || BodyComponent.name || 'Component'
    })`;
  }

  return ConnectedModal;
}
