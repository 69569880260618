import styled from 'styled-components';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import media from 'src/style-utils/media-queries';

export const Wrapper = styled.div`
  padding: 3.2rem 2.4rem;
  width: 100%;
  margin-bottom: 0;
  overflow: auto;
`;

export const BackArrow = styled(Back)`
  cursor: pointer;
  height: 4rem;
  width: auto;
  ${media.tablet`
    height: 6rem;
    `}
`;
