import { Link } from 'react-router-dom';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { MaxWidthContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import { ReactComponent as BrandBG } from 'src/images/icons/brand_mini.svg';

export const Form = styled.form`
  width: 100%;
`;

export const ContRegistration = styled(Flex)`
  width: 100%;
`;

export const LoginContainer = styled(MaxWidthContainer)`
  position: relative;
  z-index: 0;
  padding-bottom: 6.4rem;
  ${media.tablet`
  padding: 3.2rem;
  ::before {
    content: '';
    border-radius: 32px;
    display: block;
    z-index: -1;
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 16px 4px rgba(53, 48, 47, 0.12);
    backdrop-filter: blur(14px);
  }
 `};
`;

export const LinkRecovery = styled(Link)`
  font-size: 1.2rem;
  color: ${colors.textLink};
  text-decoration: revert;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const AnimatedBg = styled(BrandBG)`
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  pointer-events: none;
  position: absolute;
  right: -8rem;
  bottom: -28rem;
  z-index: -1;
  animation: rotate 50s linear infinite;
  display: none;
  ${media.tablet`
    display: unset;
    height: 58.4rem;
    width: 58.4rem;
  `}
`;
