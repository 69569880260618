import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App.component';
import reportWebVitals from './reportWebVitals';
import AppProviders from './components/AppProviders/AppProviders.component';
import GlobalStyle from './style-utils/globalStyle';
import { SessionReducerShape } from 'src/services/session/session.types';
import { getSessionInfo } from 'src/services/session/session.requests';

export const createScopedErrorHandler = (scope: string) => {
  return function handleError(...args: any) {};
};

function renderApplication(
  appRootDomId: string,
  sessionInfo: SessionReducerShape,
) {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  if (!root) {
    throw new Error(
      `I couldn't locate the app root with id \`${appRootDomId}\``,
    );
  }

  root.render(
    <React.StrictMode>
      <AppProviders sessionInfo={sessionInfo}>
        <GlobalStyle />
        <App />
      </AppProviders>
    </React.StrictMode>,
  );
}

async function bootApplication() {
  await (async () => {
    const [sessionInfo] = await Promise.all([getSessionInfo()]);
    renderApplication('root', sessionInfo as SessionReducerShape);
  })();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

bootApplication()
  .catch(createScopedErrorHandler('boot-app'))
  .finally(reportWebVitals);
