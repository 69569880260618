import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H5, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

const NoCriteriaData: React.FC = () => {
  return (
    <Flex
      padding="3.2rem 0"
      backgroundStyle="#fff"
      align="center"
      flexDirection="column"
      gap="0.8rem"
      className="rounded-2xl"
    >
      <H5 light>
        <Translate id="lbl.noResults" />
      </H5>
      <P textAlign="center">
        <Translate id="lbl.changeCriteria" />
      </P>
    </Flex>
  );
};

export default NoCriteriaData;
