import { SyncFieldValidator } from './types';

export const isZipCode = (str: string, regex: RegExp) => {
  return regex.test(str);
};

export const errorMessageZipCode = 'error.zipCode';

export const zipCode = function zipCode({
  regex,
}: {
  regex?: string;
}): SyncFieldValidator {
  const reg = new RegExp(regex ?? '');
  return function validateLenth(value: any) {
    if (typeof value !== 'string' || !isZipCode(value, reg)) {
      return errorMessageZipCode;
    }

    return undefined;
  };
};
