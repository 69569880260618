import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const ClickableIcon = styled(Icon)`
  position: relative;
  top: 0.25rem;
  cursor: pointer;
  color: ${colors.textColorGrey};
`;
