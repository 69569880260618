import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Arrow } from 'src/images/icons/arrow_back_right.svg';
import { P } from 'src/style-utils/typographic';

type Props = {
  type: 'pay' | 'change';
  createPaymentIntentHandler: () => void;
  withMarginBottom?: boolean;
};

function ButtonCard({
  type,
  createPaymentIntentHandler,
  withMarginBottom = true,
}: Props) {
  const label = type === 'pay' ? 'lbl.payWithNewMethod' : 'lbl.addNewMethod';
  return (
    <div
      data-component="button-card"
      className="group relative z-0 flex w-full items-center justify-between gap-2 rounded-xl bg-white p-4 shadow-md outline outline-1 outline-pgreyoutlined transition-shadow duration-500"
      data-with-marginbottom={withMarginBottom}
    >
      <P colorBlack>
        <Translate id={label} />
      </P>
      <button
        className="peer h-8 w-8 shrink-0 cursor-pointer rounded-full bg-white p-1 shadow transition-colors hover:bg-bgray lg:h-11 lg:w-11"
        onClick={createPaymentIntentHandler}
      >
        <Arrow />
      </button>
      <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden rounded-xl after:absolute after:right-0 after:top-1/2 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out peer-hover:after:w-[210%]" />
    </div>
  );
}

export default ButtonCard;
