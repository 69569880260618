import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getStripeLoginLink, getStripeRegistrationLink } from './stripe.config';

export const getStripeRegistrationLinkMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getStripeRegistrationLink,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};

export const getStripeLoginLinkMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getStripeLoginLink,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
