import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { feesForOrderEndPoint } from './feesForOrder.config';

export interface feesForOrderData {
  orderGuid: string;
  iNo: number;
}

export const feesForOrderMethodApi = async (data: feesForOrderData) => {
  const requestConfig = makeEndpointApiConfig({
    url: feesForOrderEndPoint,
    method: 'GET',
    data,
    params: {
      orderGuid: data.orderGuid,
      iNo: data.iNo,
    },
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
