import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  getDocumentPreview,
  getDocumentsToSign,
  openSigningSupport,
  proceedNotItalianSigning,
} from './onboarding.config';
import {
  GetDocumentPreviewData,
  GetDocumentPreviewResponse,
  GetDocumentsToSignResponse,
  OpenSigningSupportData,
} from './onboarding.types';

export const openSigningSupportMethodApi = async (
  data: OpenSigningSupportData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: openSigningSupport,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request(requestConfig);
};

export const getDocumentsToSignMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getDocumentsToSign,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<GetDocumentsToSignResponse>(requestConfig);
};

export const getDocumentPreviewMethodApi = async (
  data: GetDocumentPreviewData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getDocumentPreview,
    method: 'GET',
    authenticated: true,
    params: data,
  });
  return await apiRequest.request<GetDocumentPreviewResponse>(requestConfig);
};
export const proceedNotItalianSigningMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: proceedNotItalianSigning,
    method: 'POST',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
