import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const Circular = styled(Flex)<{
  borderColor?: string;
  colorGrey?: boolean;
}>`
  color: ${({ colorGrey }) =>
    colorGrey ? colors.textColorGrey : colors.textColorBlack};
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
  border: 0.5rem solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #fff;
  position: relative;
  z-index: 2;
`;
