import { ReactComponent as SecondIcon } from 'src/images/icons/supplier.svg';
import { ReactComponent as InstallmentCustom } from 'src/images/icons/installment-custom.svg';
import { ReactComponent as ThirdIcon } from 'src/images/icons/profile.svg';
import { ReactComponent as FirstIcon } from 'src/images/icons/shopping-cart.svg';
import { ReactComponent as OkIcon } from 'src/images/icons/step.svg';
import { ReactComponent as Brick } from 'src/images/icons/brick.svg';
import { ReactComponent as FourthIcon } from 'src/images/icons/earnings.svg';

export const ecommerceStepperArray = [
  {
    title: 'text.ecommerceStepperTitle1',
    subTitle: 'text.ecommerceStepperDescr1',
    icon: FirstIcon,
  },
  {
    title: 'text.ecommerceStepperTitle2',
    subTitle: 'text.ecommerceStepperDescr2',
    icon: SecondIcon,
  },
  {
    title: 'text.ecommerceStepperTitle3',
    subTitle: 'text.ecommerceStepperDescr3',
    icon: ThirdIcon,
  },
  {
    title: 'text.ecommerceStepperTitle4',
    subTitle: 'text.ecommerceStepperDescr4',
    icon: FourthIcon,
  },
];

export const ecommerceListItems = [
  {
    SvgComponent: InstallmentCustom,
    label: 'text.ecommerceAccordionTitle1',
    content: 'text.ecommerceAccordionDescr1',
  },
  {
    SvgComponent: OkIcon,
    label: 'text.ecommerceAccordionTitle2',
    content: 'text.ecommerceAccordionDescr2',
  },
  {
    SvgComponent: Brick,
    label: 'text.ecommerceAccordionTitle3',
    content: 'text.ecommerceAccordionDescr3',
  },
];
