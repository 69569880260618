import { Link } from 'react-router-dom';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const LinkAccount = styled(Link)`
  padding: 1.8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-block: 1.6rem;
  align-items: center;
  background-color: ${colors.bgBtnQuaternary};
  border-radius: 1.2rem;
  outline: 0.1rem solid ${colors.greyBorder};
  color: ${colors.textBtnHoverQuaternary};

  &:hover {
    box-shadow: 0px 2px 4px 1px rgba(53, 48, 47, 0.12);
  }
  &:active {
    outline: 0.2rem solid rgba(71, 215, 172, 0.4);
    background: #f7fdfb;
  }
`;
