import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  getBuyerStatsData,
  getBuyerStatsInitialData,
  getStatsData,
  getStatsInitialData,
} from './monitoring.config';
import {
  DataBuyerGetStats,
  GetBuyerStatsDataResponse,
  GetBuyerStatsInitialDataResponse,
  GetStatsDataResponse,
  GetStatsInitialDataResponse,
} from './monitoring.types';
import { DataFormMonitoringStats } from 'src/pages/monitoring/Monitoring.helpers';

export const getStatsInitialDataMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getStatsInitialData,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<GetStatsInitialDataResponse>(requestConfig);
};

export const getStatsDataMethodApi = async (data: DataFormMonitoringStats) => {
  const requestConfig = makeEndpointApiConfig({
    url: getStatsData,
    method: 'GET',
    params: data,
    authenticated: true,
  });
  return await apiRequest.request<GetStatsDataResponse>(requestConfig);
};

export const getBuyerStatsInitialDataMethodApi = async (guid?: string) => {
  const requestConfig = makeEndpointApiConfig({
    url: getBuyerStatsInitialData,
    method: 'GET',
    params: {
      buyerGuid: guid,
    },
    authenticated: true,
  });
  return await apiRequest.request<GetBuyerStatsInitialDataResponse>(
    requestConfig,
  );
};

export const getBuyerStatsDataMethodApi = async (data: DataBuyerGetStats) => {
  const requestConfig = makeEndpointApiConfig({
    url: getBuyerStatsData,
    method: 'GET',
    params: data,
    authenticated: true,
  });
  return await apiRequest.request<GetBuyerStatsDataResponse>(requestConfig);
};
