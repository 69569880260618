import React from 'react';
import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { useNavigate } from 'react-router-dom';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as editedMail } from 'src/images/pageStatus/created_link_success.svg';
import { H4 } from 'src/style-utils/typographic';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { RoutePath } from 'src/routers/routers.config';

const SuccessRecoveryPsw: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Icon
        svgIconComponent={editedMail}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 marginBottom="10rem" textAlign="center">
        <Translate id="text.changePswOk" />
      </H4>
      <ButtonContainer>
        <Button minWidth="100%" i18n onClick={() => navigate(RoutePath.login)}>
          <Translate id="lbl.login" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SuccessRecoveryPsw;
