import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const ResultPageContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 70ch;
  text-align: center;
`;

export const ResultPageIcon = styled.img`
  margin: 4.8rem 0;
  height: 19rem;
  ${media.tablet`
    height: 28rem;
  `}
`;
