import { cva } from 'class-variance-authority';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Arrow } from 'src/images/icons/arrow_back_right.svg';
import { ReactComponent as BinSvg } from 'src/images/icons/bin.svg';
import { ReactComponent as Alert } from 'src/images/icons/input_error.svg';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import colors from 'src/style-utils/colors';
import { H5, PNote, PSmall } from 'src/style-utils/typographic';
import useParamsPayCard from './PaymentMethodElement.hooks';

const cardVariants = cva(
  'group relative z-0 flex w-full items-center justify-between gap-2 rounded-xl bg-white p-4 shadow-md outline outline-1 transition-shadow duration-500',
  {
    variants: {
      status: {
        expired: 'outline-danger',
        default: 'outline-pgreyoutlined',
      },
    },
    defaultVariants: {
      status: 'default',
    },
  },
);

type Props = {
  payCard: PaymentMethodResult;
  onClickHandler?: () => void;
  arrow?: boolean;
  preferred?: boolean;
};

function PaymentMethodElementButton({
  payCard,
  onClickHandler,
  arrow,
  preferred,
}: Props) {
  const { country, paymentMethodId, payIcon, expired, last4, dueDate, type } =
    useParamsPayCard({ ...payCard });
  const fieldValue = paymentMethodId?.toString();

  return (
    <div
      className={cardVariants({
        status: expired ? 'expired' : 'default',
      })}
    >
      <div className="flex items-center gap-4">
        <img
          className="m-3 h-6 w-6 lg:h-9 lg:w-9"
          src={payIcon}
          alt="card logo"
        />
        <div className="grid gap-1">
          {preferred && (
            <div className="w-fit rounded bg-pgreen-400 px-1.5 py-[0.3rem]">
              <PNote colorBlack>
                <Translate id="lbl.preferred" />
              </PNote>
            </div>
          )}
          <H5>
            {type === 'sepa_debit' && country + ' '}
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
            &bull;&bull;&bull;&bull; {last4}
          </H5>
          {type !== 'sepa_debit' && (
            <div className="flex items-center">
              {expired && <Alert width="2rem" height="2rem" />}
              <PSmall textColor={expired ? colors.redInput : undefined}>
                <Translate id="text.dueDate" /> {dueDate}
              </PSmall>
            </div>
          )}
        </div>
      </div>
      <button
        className="peer h-8 w-8 shrink-0 cursor-pointer rounded-full bg-white p-1 shadow transition-colors hover:bg-bgray lg:h-11 lg:w-11"
        id={fieldValue}
        onClick={onClickHandler}
      >
        {arrow ? <Arrow /> : <BinSvg />}
      </button>
      <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden rounded-xl after:absolute after:right-0 after:top-1/2 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out peer-hover:after:w-[210%]" />
    </div>
  );
}

export default PaymentMethodElementButton;
