import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { H2, H3, P } from 'src/style-utils/typographic';

export const OrderDatasContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  ${media.mobileMd`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  `}
`;

export const BalanceContainer = styled(Flex)<{ hasBorder?: boolean }>`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  ${media.mobileMd`
  flex-direction: column;
  align-items: flex-start;
  padding-right: 2.4rem;
  width: 100%;
  
  ${({ hasBorder }: { hasBorder?: boolean }) =>
    hasBorder &&
    `
    border-right: 1px solid ${colors.greyBorder};
  `}
  `}
`;

export const PendingIncomeContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  border-top: 1px solid ${colors.greyBorder};
  border-left: 0;
  padding-top: 1.6rem;

  ${media.mobileMd`
    flex-direction: column;
    align-items: flex-start;
    gap: 3.2rem;
    border-top: 0;
  `}
`;

export const PendingIncomeContentContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  flex-shrink: 0;
  align-self: stretch;

  ${media.mobileMd`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  margin-right: 2.4rem;
  `}
`;

export const CustomText = styled(P)`
  color: ${colors.textColorGrey};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  ${media.mobileMd`
  color: ${colors.textColorGrey};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
  `}
`;

export const PendingIncomeTitle = styled(H2)`
  color: ${colors.textColorGrey};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
`;

export const CustomCurrentBalance = styled(H3)`
  color: ${colors.textColorBlack};
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.4rem;

  ${media.mobileMd`
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.8rem;
  `}
`;

export const CustomPendingBalance = styled(H3)`
  color: ${colors.textColorBlack};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.6rem;
`;

export const CustomHyperlink = styled.a`
  color: ${colors.textColorGrey};
  text-overflow: ellipsis;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  text-decoration-line: underline;
  cursor: pointer;
`;
