import DisposabilityCard from 'src/components/DisposabilityCard/DisposabilityCard.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Loader from 'src/components/Loader/Loader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getPlafondsEndPointMerchant } from 'src/services/transactions/transactions.config';
import { getPlafondsMerchantMethodApi } from 'src/services/transactions/transactions.request';

const UsageSettingLiquidity: React.FC = () => {
  const {
    data: dataPlaf,
    isLoading,
    isError,
  } = useQuery(getPlafondsEndPointMerchant, () =>
    getPlafondsMerchantMethodApi(),
  );
  if (isError) {
    return <Navigate to="/oops" />;
  }
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <div className="container">
      <GoBackButton
        url={RoutePath.usageSettingOptions}
        label="text.linkPaymentWAdvPayment"
      />
      <div className="mx-auto max-w-md">
        <TextDefaultPage title="text.liquidity" />
        <DisposabilityCard
          totalAmount={dataPlaf?.data?.ResultSet[0].CapMerchant}
          liquidity={dataPlaf?.data?.ResultSet[0].AmmontareDisponibile}
        />
      </div>
    </div>
  );
};

export default UsageSettingLiquidity;
