import React, { useState } from 'react';
import {
  NumericFormat,
  NumberFormatBaseProps,
  NumberFormatValues,
} from 'react-number-format';
import { Input } from 'src/style/styleInput.style';
import { useI18n } from 'src/services/i18n/i18n.hooks';

export type InputNumberProps = Omit<NumberFormatBaseProps, 'onChange'> & {
  hideCents?: boolean;
  onChange: (...event: any[]) => void;
};

const InputAmountNumber: React.FC<InputNumberProps> = React.forwardRef(
  (
    {
      customInput = Input,
      onChange,
      onBlur,
      value,
      hideCents = false,
      placeholder,
      ...otherProps
    },
    ref,
  ) => {
    const {
      info: { decimalSeparator, thousandSeparator },
    } = useI18n();

    const decimalScale = hideCents ? 0 : 2;

    const handleChange = React.useCallback((values: NumberFormatValues) => {
      const { floatValue } = values;
      onChange(floatValue);
    }, []);
    const [val] = useState(value);

    return (
      <NumericFormat
        data-component="input-amount-number"
        {...otherProps}
        onBlur={onBlur}
        suffix={' €'}
        fixedDecimalScale
        valueIsNumericString
        inputMode="numeric"
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        getInputRef={ref}
        value={val}
        customInput={customInput}
        decimalScale={decimalScale}
        decimalSeparator={decimalSeparator}
        thousandSeparator={thousandSeparator}
        allowNegative={false}
        allowLeadingZeros={false}
        onValueChange={handleChange}
        placeholder={placeholder}
      />
    );
  },
);

export default InputAmountNumber;
