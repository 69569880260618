import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { usePayByLinkStore } from 'src/store/store';
import { H4, PSmall } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { VATField } from './StepVAT.config';
import { vatRegexLastTwoValue } from 'src/utils/validation/cf';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import { ReactComponent as Information } from 'src/images/icons/information.svg';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { RoutePath } from 'src/routers/routers.config';
import { searchCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import toTitleCase from 'src/utils/functions/formatString';
import { useIsAtLeastTabletBreakpoint } from 'src/utils/hooks/media-queries';
import { formConfigVATPayByLink } from './StepVAT.helpers';
import { TitleIconContainer, UL } from './StepVAT.style';
import StepVATModalComponent from './modal/StepVATModal.component';

function StepVAT() {
  const [openModal, setOpenModal] = useState(false);
  const { country, setValues, validation, VatCode } = usePayByLinkStore(
    (state) => state,
  );
  const isTablet = useIsAtLeastTabletBreakpoint();
  const { step, stepMax } = useRedirectToFlow();
  const { initialValues, resolver } = formConfigVATPayByLink(
    VatCode,
    validation?.vatNumber?.format,
    validation?.vatPrefix,
  );
  const { categoryCode, productCode } = useParams();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(searchCompanyInfoMethodApi, {
    onSuccess: (res, variables) => {
      if (res.data.ResultSet.Result === 'not_found') {
        navigate(RoutePath.ThankyouPageError + RoutePath.fiscalCodeMatchError);
        return;
      }
      const {
        FiscalCode,
        PostalCode,
        Address,
        Name,
        VatCode,
        SearchId,
        Country,
      } = res.data.ResultSet.Items[0];
      const values = {
        buyerFiscalCode: FiscalCode,
        postalCode: PostalCode,
        buyerAddress: toTitleCase(Address),
        buyerName: toTitleCase(Name),
        VatCode,
        companySearchId: SearchId,
        country: Country.toUpperCase(),
        results: res.data?.ResultSet?.Items,
      };
      setValues(values);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCheckData}`,
      );
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const filterValidPinChars = (val: unknown) =>
    typeof val !== 'string' ||
    !validation?.vatPrefix ||
    vatRegexLastTwoValue.test(val);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = (data: any) => {
    mutate({ country, vatCode: data[VATField] });
  };
  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.searchCustomerAgency"
      />
      <FlowContainer>
        <TitleIconContainer>
          <H4 light margin="0!important">
            <Translate id="text.insertVat" />
          </H4>
          <Icon
            svgIconComponent={Information}
            iconSize={isTablet ? '3.2rem' : '2.4rem'}
            onClick={() => setOpenModal(true)}
          />
        </TitleIconContainer>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField
                name={VATField}
                maxLength={16}
                filterValue={filterValidPinChars}
              />
              <PSmall marginTop="1.6rem">
                <Translate
                  id="text.vatNumberExample"
                  extraTranslationProps={{
                    components: { ul: <UL />, li: <li /> },
                  }}
                />
              </PSmall>
              <ButtonContainer>
                <SubmitButton
                  variant="Primary"
                  minWidth="100%"
                  i18n={true}
                  translatedText="lbl.search"
                  id={IDs.btnConfirm}
                />
                <Button
                  type="button"
                  variant="Tertiary"
                  minWidth="100%"
                  i18n={true}
                  translatedText="lbl.dontKnowVAT"
                  id={IDs.btnDecline}
                  onClick={() =>
                    navigate(
                      `${RoutePath.createLink}${categoryCode}/${productCode}/${
                        validation?.postalCode.isAvailable
                          ? RoutePath.stepNamePostalCode
                          : RoutePath.stepName
                      }`,
                    )
                  }
                />
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
      {openModal && (
        <StepVATModalComponent
          buttonTitle="text.okUnderstand"
          modalTitle="lbl.whatIsVAT"
          handleClose={() => setOpenModal(false)}
          i18n
        />
      )}
    </>
  );
}

export default StepVAT;
