import React from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

export function useComputeInputFieldHandleChange(
  onChange: (...event: any[]) => void,
  setValue: UseFormSetValue<FieldValues>,
  filterValue?: (val: any) => boolean,
) {
  return React.useCallback(
    (e: any) => {
      if (typeof filterValue === 'function') {
        if (filterValue(e.target.value)) onChange(e);
      } else onChange(e);
    },
    [filterValue, onChange, setValue],
  );
}
