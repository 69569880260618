import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import RadioBox from 'src/components/RadioBox/RadioBox.component';
import Translate from 'src/components/Translate/Translate.component';
import { P } from 'src/style-utils/typographic';
import { wrongData } from '../../ReadContract.helpers';
import { CheckboxCard } from './IncorrectDataModel.style';

const content = [
  'incorrectDataModal.checkbox1',
  'incorrectDataModal.checkbox2',
  'incorrectDataModal.checkbox3',
];
const IncorrectDataModalBody: React.FC = () => {
  return (
    <ModalBody>
      <P marginBottom="2.4rem">
        <Translate id="incorrectDataModal.subtitle" />
      </P>
      {content.map((text, index) => (
        <CheckboxCard key={index}>
          <RadioBox name={wrongData} i18n label={text} value={text} />
        </CheckboxCard>
      ))}
    </ModalBody>
  );
};

export default IncorrectDataModalBody;
