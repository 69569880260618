import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as noData } from 'src/images/icons/nodata-payment-link.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import colors from 'src/style-utils/colors';
import { H4 } from 'src/style-utils/typographic';

const NoDataRequests: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="mx-auto mt-16 flex max-w-olg flex-col items-center">
      <Icon iconSize="16rem" svgIconComponent={noData} />
      <H4 textAlign="center" textColor={colors.textTableBody} margin="2.4rem 0">
        <Translate id={'remodulationRequest.NoData'} />
      </H4>
      <Button
        variant="Primary"
        onClick={() => navigate(`${RoutePath.remodulationInfo}`)}
        i18n
        translatedText="remodulationRequest.NoData.btn"
      />
    </div>
  );
};

export default NoDataRequests;
