import {
  PayByLinkStatus as status,
  PayByLinkStatusLabels as label,
  PayByLinkStatusSubtitles as subtitle,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';

export const FilterCheckboxConfig = [
  {
    title: label[status.createdLink],
    subtitle: subtitle[status.createdLink],
    state: status.createdLink,
  },
  {
    title: label[status.expiredLink],
    subtitle: subtitle[status.expiredLink],
    state: status.expiredLink,
  },
  {
    title: label[status.cancelledLink],
    subtitle: subtitle[status.cancelledLink],
    state: status.cancelledLink,
  },
  {
    title: label[status.wrongLink],
    subtitle: subtitle[status.wrongLink],
    state: status.wrongLink,
  },
  {
    title: label[status.unsuitableLink],
    subtitle: subtitle[status.unsuitableLink],
    state: status.unsuitableLink,
  },
  {
    title: label[status.planned],
    subtitle: subtitle[status.planned],
    state: status.planned,
  },
  {
    title: label[status.regularIncoming],
    subtitle: subtitle[status.regularIncoming],
    state: status.regularIncoming,
  },
  {
    title: label[status.fullCashed],
    subtitle: subtitle[status.fullCashed],
    state: status.fullCashed,
  },
  {
    title: label[status.expiredPayment],
    subtitle: subtitle[status.expiredPayment],
    state: status.expiredPayment,
  },
  {
    title: label[status.refund],
    subtitle: subtitle[status.refund],
    state: status.refund,
  },
];
