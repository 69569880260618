import AmountsFormEelement from 'src/components/AmountsFormElement/AmountsFormElement.component';
import ClientsInput from 'src/components/ClientsInput/ClientsInput.component';
import InputDate from 'src/components/InputDate/InputDate.component';
import PaymentStatusCheckbox from 'src/components/PaymentStatusCheckbox/PaymentStatusCheckbox.component';
import { DataFormAmounts } from 'src/pages/paymentSummary/PaymentSummary.helper';
import Translate from 'src/components/Translate/Translate.component';
import { H5, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ModalBodyStyled } from './style/PaymentsFilterModalParts.style';

export const PaymentsFilterModalBody: React.FC = ({
  merchants,
  paymentStatusArray,
}: any) => {
  return (
    <ModalBodyStyled>
      <H5 marginBottom="1.6rem">
        <Translate id="lbl.import" />
      </H5>
      <AmountsFormEelement amounts={DataFormAmounts} />
      <H5 marginBottom="1.6rem" marginTop="5.6rem">
        <Translate id="lbl.merchantNameForm" />
      </H5>
      <ClientsInput clients={merchants} fieldName="Merchants" />
      <H5 marginBottom="1.6rem" marginTop="5.6rem">
        <Translate id="lbl.timeIntervalForm" />
      </H5>
      <Flex gap="1.6rem" marginBottom="2.7rem">
        <Flex flexDirection="column" fluid>
          <P>
            <Translate id="text.startPeriod" />
          </P>
          <InputDate name="FromDate" />
        </Flex>
        <Flex flexDirection="column" fluid>
          <P>
            <Translate id="text.endPeriod" />
          </P>
          <InputDate name="ToDate" />
        </Flex>
      </Flex>
      <H5 marginBottom="1.6rem" marginTop="5.6rem">
        <Translate id="lbl.paymentStatusForm" />
      </H5>
      {paymentStatusArray.map((id: number | string, i: number) => (
        <PaymentStatusCheckbox key={i} statusId={id.toString()} />
      ))}
    </ModalBodyStyled>
  );
};
