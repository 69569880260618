import Translate from 'src/components/Translate/Translate.component';
import React, { useEffect, useState } from 'react';
import { PSmall } from 'src/style-utils/typographic';
import { ObjectTab } from './Tabs.types';
import { BtnContainer, TabsBtn, TabsContainer } from './style/Tabs.style';
import { ResponsiveContainer } from 'src/style/Container.style';

export type TabsProps = {
  i18n?: boolean;
  tabs: ObjectTab[];
  columnOnMobile?: boolean;
  select?: number;
  fullWidth?: boolean;
  variant?: 'Primary' | 'Secondary';
  margin?: string | CSSGlobalRuleValue;
};

const Tabs = ({
  i18n = true,
  tabs,
  columnOnMobile = false,
  select = 0,
  fullWidth = false,
  variant,
  margin,
}: TabsProps) => {
  useEffect(() => {
    if (select !== undefined) setCurrentTab(select);
  }, [select]);

  const [currentTab, setCurrentTab] = useState(select);
  return (
    <div data-component="tabs">
      <TabsContainer
        variant={variant}
        style={fullWidth ? { width: '100%' } : undefined}
        margin={margin}
      >
        <BtnContainer
          className="overflow-auto"
          columnOnMobile={columnOnMobile}
          variant={variant}
          style={fullWidth ? { width: '100%' } : undefined}
        >
          {tabs.map((tab, i) => (
            <TabsBtn
              key={i}
              disabled={currentTab === i}
              onClick={() => setCurrentTab(i)}
              type="button"
            >
              <span className="flex w-full items-center justify-center gap-1">
                <PSmall textColor="inherit" style={{ whiteSpace: 'nowrap' }}>
                  {i18n ? <Translate id={tab.tabTitle} /> : tab.tabTitle}
                </PSmall>
                {tab.tabCount && tab.tabCount ? (
                  <span className="tabCount flex items-center justify-center rounded-full px-2 py-1 text-[10px] font-normal text-white">
                    {tab.tabCount > 99 ? '99+' : tab.tabCount}
                  </span>
                ) : null}
              </span>
            </TabsBtn>
          ))}
        </BtnContainer>
      </TabsContainer>
      {variant === 'Secondary' ? (
        <ResponsiveContainer>{tabs[currentTab].content}</ResponsiveContainer>
      ) : (
        tabs[currentTab].content
      )}
    </div>
  );
};

export default Tabs;
