import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';

import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { linksFilteredDataEndPoint } from './linksFilteredData.config';
import {
  clientList,
  startDate,
  endDate,
  flagCessioneCredito,
  flagNonCessioneCredito,
  skipField,
  takeField,
  statuses,
  buyerSearchText,
  Amounts,
  sortBy,
  sortDirection,
  madeFromPlugin,
  sort,
} from 'src/pages/payByLinkPage/partials/SearchCriteriaForm.config';

export type OrderType = {
  Id: number;
  RagioneSocialeBuyer: string;
  Stato: StatusPyByLinkValues;
  Amount: number;
  DataCreazione: string;
  DataScadenza: string;
  PluginExternalId: string;
  FirstInstallmentExpiration: string;
  FirstInstallmentPayment: string;
};

export type LinksArrayType = {
  Orders: OrderType[];
  OrdersCount: number;
};

export const getLinksFilteredMethodApi = async (data: any) => {
  const requestConfig = makeEndpointApiConfig({
    url: linksFilteredDataEndPoint,
    method: 'POST',
    data: {
      buyers: data[clientList],
      statuses: data[statuses],
      fromDate: data[startDate],
      [endDate]: data[endDate],
      [flagCessioneCredito]: data[flagCessioneCredito],
      [flagNonCessioneCredito]: data[flagNonCessioneCredito],
      buyerSearchText: data[buyerSearchText],
      amounts: data[Amounts],
      sortBy: data[sort]?.[sortBy],
      sortDirection: data[sort]?.[sortDirection],
      skip: data[skipField],
      take: data[takeField],
      types: data[statuses],
      madeFromPlugin: data[madeFromPlugin],
    },
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: LinksArrayType }>(requestConfig);
};
