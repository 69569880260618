import Icon from 'src/components/Icon/Icon.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import moment from 'moment';
import {
  collaborators,
  endDate,
  startDate,
} from 'src/pages/monitoring/Monitoring.config';
import { DataFormMonitoringBuyerStats } from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.helpers';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FilteredButtonContainer } from '../style/FilteredList.style';

interface Props {
  filteredName?: string;
  fromDate?: string;
  toDate?: string;
  valueId?: string;
  state?: PayByLinkStatus;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormMonitoringBuyerStats;
  disabled: boolean;
}

const FilteredButtonMonitoring = ({
  filteredName,
  fromDate,
  toDate,
  state,
  handleClick,
  dataForm,
  setDataFilter,
  disabled,
  valueId,
}: Props) => {
  const { resetField, watch } = useFormContext();

  const dataArray = watch(collaborators);

  const { remove } = useFieldArray({
    name: collaborators,
  });
  const onClick = () => {
    if (fromDate || toDate) {
      resetField(startDate);
      resetField(endDate);
      dataForm.startDate = '';
      dataForm.endDate = '';
    }

    if (valueId) {
      remove(dataArray.indexOf(valueId));
      dataForm[collaborators] = dataForm[collaborators].filter(
        (id) => id !== valueId.toString(),
      );
    }

    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <FilteredButtonContainer onClick={onClick} data-disabled={disabled}>
      {fromDate && fromDate.length > 0 && (
        <>
          <Translate id="text.startPeriod" />
          &nbsp;{moment.utc(fromDate).format('DD/MM/YYYY')}&nbsp;
        </>
      )}
      {toDate && toDate.length > 0 && (
        <>
          <Translate id="text.endPeriod" />
          &nbsp;{moment.utc(toDate).format('DD/MM/YYYY')}
        </>
      )}
      {valueId && filteredName}
      {state !== undefined && <Translate id={filteredName} />}
      <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
    </FilteredButtonContainer>
  );
};

export default FilteredButtonMonitoring;
