import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as X } from 'src/images/icons/negative-feedback-point.svg';
import { ReactComponent as NegativeLogo } from 'src/images/icons/opyn-paylater-negative.svg';
import React from 'react';
import {
  CloseButtonRoot,
  HeaderContainer,
  HeaderFlex,
} from './style/Header.style';
// import { DesktopNavbar } from 'src/components/DesktopNavbar/DesktopNavbar.component';
import { Sidebar } from 'src/components/Sidebar/Sidebar.component';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'src/services/session/session.context';
import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import { useHandle } from './Header.hooks';
import SelectLanguage from './SelectLanguage/SelectLanguage.component';
import { useProfileStore } from 'src/store/store';
import { RoutePath } from 'src/routers/routers.config';

const Header: React.FC<{ loggedIn?: boolean }> = ({ loggedIn }) => {
  const navigate = useNavigate();
  const hasNavBar = useHandle().hasNavBar;
  const hasHeaderPlugin = useHandle().hasHeaderPlugin;
  const { onLogout } = useSession();
  const AccountType = useProfileStore((state) => state.AccountType);
  const navigateLogo = () => {
    if (hasNavBar)
      navigate(`${RoutePath.privateArea}?typeAccount=${AccountType}`);
  };

  return (
    <HeaderContainer data-component="header">
      <HeaderFlex>
        <Flex align="center" gap="2.4rem">
          <Icon
            svgIconComponent={NegativeLogo}
            iconHeight={'2.4rem'}
            iconSize={'17.7rem'}
            cursor={hasNavBar ? 'pointer' : ''}
            onClick={navigateLogo}
          />
          {hasHeaderPlugin && <SelectLanguage />}
        </Flex>
        {!loggedIn && !hasHeaderPlugin && <SelectLanguage />}
        {loggedIn && hasNavBar && <Sidebar loggedIn={loggedIn} />}
        {hasHeaderPlugin && (
          <CloseButtonRoot
            onClick={() => {
              (window as any).parent.postMessage(
                JSON.stringify({ status: 'close' }),
                '*',
              );
              onLogout();
            }}
          >
            <Icon
              fill={colors.textColorGrey}
              svgIconComponent={X}
              iconSize="3.16rem"
              iconHeight="3.16rem"
            />
          </CloseButtonRoot>
        )}
      </HeaderFlex>
    </HeaderContainer>
  );
};

export default Header;
