import BadgeStatus from 'src/components/BadgeStatus/BadgeStatus.component';
import {
  RemodulationRequestsStatus,
  RemodulationRequestsStatusMap,
} from 'src/pages/remodulationRequests/RemodulationRequests.config';
import { ListTableRemodulationRequests } from 'src/pages/remodulationRequests/RemodulationRequests.types';
import React from 'react';

const StatusColumn: React.FC<ListTableRemodulationRequests> = ({ Status }) => {
  return (
    <>
      <BadgeStatus
        {...RemodulationRequestsStatusMap[
          Number(Status) as RemodulationRequestsStatus
        ]}
      />
    </>
  );
};

export default StatusColumn;
