import { Link } from 'react-router-dom';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { ResponsiveContainer } from 'src/style/Container.style';
import styled, { css } from 'styled-components';

export const SidebarContainer = styled.div`
  color: ${colors.textColorBlack};
  background-color: ${colors.primaryGreen};
  overflow: auto;
  position: fixed;
  inset: 0;
  z-index: 30;
  transition: transform 0.5s;
  transform: translateX(100%);
  &.open {
    transform: translateX(0%);
  }
`;

export const HamburgerContainer = styled.div`
  cursor: pointer;

  p {
    display: none;
  }
  ${media.tablet`
    p {
      display: block;
    }
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    :hover {
      border-width: 0.2rem;
    }
    padding: 0.4rem 1.2rem;
    gap: 0.4rem;
    border: 0.1rem solid ${colors.black};
    border-radius: 25rem;
  `}
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  p {
    display: none;
  }
  ${media.tablet`
    p {
      display: block;
    }
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
  `}
`;

export const SidebarHeaderContainer = styled.div`
  border-bottom: 1px solid ${colors.greyStatus};
  margin-bottom: 3.2rem;
`;

export const SidebarHeaderFlex = styled(ResponsiveContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6.4rem;
  ${media.tablet`
    min-height: 7.2rem; 
  `}
`;

export const OverlaySidebar = styled.div`
  position: fixed;
  inset: 0;
  z-index: 7;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  &.open {
    display: block;
  }
`;

export const LinkSidebar = styled(Link)<{ disabled?: boolean }>`
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : undefined}
`;

export const LinkSubNav = styled.span<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  & > h3 > svg {
    position: relative;
    top: 0.2em;
    height: 1em;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : undefined}
`;
