import React from 'react';
import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { useNavigate } from 'react-router-dom';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as PhoneSuccess } from 'src/images/pageStatus/phone-success.svg';
import { H4 } from 'src/style-utils/typographic';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { useAccountStore } from 'src/store/store';
import { userEndPoint } from 'src/services/user/user.config';
import { porfileMethodApi } from 'src/services/user/user.request';
import { useQuery } from 'react-query';

const SuccessChangeNumber: React.FC = () => {
  const navigate = useNavigate();
  const setAccount = useAccountStore((state) => state.setAccount);
  useQuery(userEndPoint, porfileMethodApi, {
    onSuccess: (res) => {
      setAccount(res?.data.ResultSet);
    },
  });
  return (
    <>
      <Icon
        svgIconComponent={PhoneSuccess}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 marginBottom="10rem" textAlign="center">
        <Translate id="text.modifiedPhone" />
      </H4>
      <ButtonContainer>
        <Button minWidth="100%" i18n onClick={() => navigate('/access-data')}>
          <Translate id="link.backToAccessData" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SuccessChangeNumber;
