import ProductCard from 'src/components/ProductCard/ProductCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { useLocation, useOutletContext } from 'react-router';
import { H4 } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { CategoryCodes, ProductCodes } from 'src/utils/types/common.types';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import ProductCardCST from 'src/components/ProductCard/ProductCardCST.component';
import { LinkFlowContext } from 'src/pages/createLinkFlow/CreateLinkFlow.helper';
import { Product } from 'src/services/orderLink/orderLink.types';
import { useProfileStore } from 'src/store/store';
import { AccountStatus } from 'src/utils/config/stateAccount';

function ChooseProduct() {
  const location = useLocation();
  const fromMonitoring = location?.state?.fromMonitoring;
  const availableProducts =
    useOutletContext<LinkFlowContext>().availableProducts;
  const pendingAccount: boolean =
    useProfileStore((state) => state.Status) !== AccountStatus.completed;

  return (
    <ResponsiveContainer>
      <GoBackButton label="lbl.back" />
      <H4 marginBottom="2.4rem">
        <Translate id="lbl.chooseProduct" />
      </H4>
      <div className="mb-8 flex flex-wrap gap-6">
        {availableProducts.map((product: Product, i: number) =>
          product.CustomDetails.Prospect.length > 0 ? (
            <ProductCardCST
              key={i}
              disabled={pendingAccount}
              productCode={product.Code as ProductCodes}
              categoryCode={product.CategoryCode as CategoryCodes}
              visual={product.Visual}
              customDetail={product.CustomDetails}
              fromMonitoring={fromMonitoring}
            />
          ) : (
            <ProductCard
              key={i}
              disabled={pendingAccount}
              productCode={product.Code as ProductCodes}
              categoryCode={product.CategoryCode as CategoryCodes}
              visual={product.Visual}
              fromMonitoring={fromMonitoring}
            />
          ),
        )}
      </div>
    </ResponsiveContainer>
  );
}

export default ChooseProduct;
