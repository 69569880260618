import { Resolver } from 'react-hook-form';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const wrongData = 'wrong-data';

export type WrongDataForm = {
  [wrongData]: string;
};

export const wrongDataFormConfig = () => {
  const initialValues: WrongDataForm = {
    [wrongData]: '',
  };
  const validate = validateFormSync({
    [wrongData]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
