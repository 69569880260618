import colors from 'src/style-utils/colors';
import styled, { css } from 'styled-components';
import media from 'src/style-utils/media-queries';
import { ResponsiveContainer } from 'src/style/Container.style';

export const TabsContainer = styled.div<{
  variant?: 'Primary' | 'Secondary';
  margin?: string | CSSGlobalRuleValue;
}>`
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin: 2.4rem auto;
        `}
  ${({ variant }) =>
    variant === 'Secondary'
      ? 'border-bottom: 0.1rem solid #e7e4e4'
      : undefined};
  ${media.tablet`
    max-width: 84.8rem;
  `}
  ${media.desktop`
    max-width: 104rem;
  `}
    ${media.desktopXl`
    max-width: 123.2rem;
  `}
`;

export const BtnContainer = styled(ResponsiveContainer)<{
  columnOnMobile: boolean;
  variant?: 'Primary' | 'Secondary';
}>`
  grid-auto-flow: ${({ columnOnMobile }) =>
    columnOnMobile ? 'row' : 'column'};
  ${({ variant = 'Primary' }) => TabsTheme[variant]};
  width: 100%;
`;

export const TabsBtn = styled.button`
  padding: 0.8rem 1.6rem;
  cursor: pointer;
`;

const TabsTheme = {
  Primary: css`
    display: grid;
    gap: 0.4rem;
    padding: 0.4rem;
    border: 0.1rem solid ${colors.greyBorder};
    border-radius: 0.8rem;
    text-align: center;
    grid-auto-columns: 1fr;
    ${media.tablet`
    grid-auto-flow: column;
    width: fit-content;
  `};
    button {
      border-radius: 0.4rem;
      background: ${colors.bgBtnTertiary};
      color: ${colors.textColorGrey};
      &[disabled] {
        pointer-events: none;
        font-weight: 700;
        background: ${colors.bgBtnSecondary};
        color: ${colors.white};
        cursor: not-allowed;
      }
    }
  `,
  Secondary: css`
    display: flex;
    justify-content: flex-start;
    text-align: center;
    color: ${colors.textBtnGrey};
    margin-bottom: -0.1rem;
    button {
      border-bottom: 0.2rem solid transparent;
      .tabCount {
        background: ${colors.textColorGrey};
      }
      &[disabled] {
        pointer-events: none;
        border-color: ${colors.textColorBlack};
        color: ${colors.textColorBlack};
        font-weight: 700;
        .tabCount {
          background: ${colors.textColorBlack};
        }
      }
      &[disabled] > p {
        font-weight: 700;
        cursor: not-allowed;
      }
      &:hover {
        background: ${colors.tabBtnHover};
        border-radius: 4px 4px 0 0;
      }
    }
  `,
};
