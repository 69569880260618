import Icon from 'src/components/Icon/Icon.component';
import { StyledModalHeader } from './ModalHeader.style';
import { ModalHeaderProps } from './ModalHeader.types';
import { ReactComponent as X } from 'src/images/icons/negative-feedback-point.svg';
import { CloseButtonRoot } from 'src/components/Modal/style/Modal.styles';
import colors from 'src/style-utils/colors';
import { H5 } from 'src/style-utils/typographic';

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  modalTitle,
  handleClose,
  ...otherProps
}) => {
  return (
    <StyledModalHeader {...otherProps}>
      <H5 colorGray>{modalTitle}</H5>
      <CloseButtonRoot onClick={handleClose}>
        <Icon
          fill={colors.textColorGrey}
          svgIconComponent={X}
          iconSize="4.4rem"
          iconHeight="4.4rem"
        />
      </CloseButtonRoot>
    </StyledModalHeader>
  );
};
