import styled from 'styled-components';
import colors from './colors';

export const Hr = styled.hr<{
  borderSize?: string;
  marginBlock?: string | CSSGlobalRuleValue;
}>`
  margin-block: ${({ marginBlock }) => (marginBlock ? marginBlock : ' 3.2rem')};
  ${({ borderSize }) => (borderSize ? `border: ${borderSize} solid;` : '')}
  border-color: ${colors.greyBorder};
`;
