import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const InstallmentCard = styled.div`
  background: ${colors.white};
  box-shadow: 0 0.2rem 0.4rem 0.1rem ${colors.cardShadowBlue};
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  outline: 0.1rem solid ${colors.greyBorder};
  &[data-focused='true'] {
    outline: 0.15rem solid ${colors.lightGreyBorder};
  }
`;

export const LabelCard = styled.span`
  display: inline-flex;
  min-width: fit-content;
  align-items: center;
  line-height: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  background: ${colors.bgBtnSecondary};
  color: #fff;
`;

export const CustomGridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  ${media.tablet`
    gap: 16px;
  `}
`;
