import { ReactComponent as Bill } from 'src/images/icons/bill.svg';
import { ReactComponent as NextInstallment } from 'src/images/icons/next_installment.svg';
import { IDs } from 'src/utils/config/ids';

enum EnumVariants {
  flexEq3 = 'FLX_EQ_3',
  flexEq6 = 'FLX_EQ_6',
  flexEq12 = 'FLX_EQ_12',
  singleDelayed30 = 'SGL_DLY_30',
  singleDelayed60 = 'SGL_DLY_60',
  singleDelayed90 = 'SGL_DLY_90',
  singleDelayed120 = 'SGL_DLY_120',
}

export type ProductVariantsCodes = `${EnumVariants}`;

export const VariantCardArray = {
  [EnumVariants.flexEq3]: {
    label: 'variant.flexEq3.title',
    icon1: Bill,
    icon2: NextInstallment,
    subtitle1: 'variant.installmentAmount',
    subtitle2: 'variant.installmentInterval',
    id: IDs.input3,
  },
  [EnumVariants.flexEq6]: {
    label: 'variant.flexEq6.title',
    icon1: Bill,
    icon2: NextInstallment,
    subtitle1: 'variant.installmentAmount',
    subtitle2: 'variant.installmentInterval',
    id: IDs.input6,
  },
  [EnumVariants.flexEq12]: {
    label: 'variant.flexEq12.title',
    icon1: Bill,
    icon2: NextInstallment,
    subtitle1: 'variant.installmentAmount',
    subtitle2: 'variant.installmentInterval',
    id: IDs.input12,
  },
  [EnumVariants.singleDelayed30]: {
    label: 'variant.singleDelayed30.title',
    icon1: NextInstallment,
    icon2: undefined,
    subtitle1: 'variant.paymentDate',
    subtitle2: '',
    id: undefined,
  },
  [EnumVariants.singleDelayed60]: {
    label: 'variant.singleDelayed60.title',
    icon1: NextInstallment,
    icon2: undefined,
    subtitle1: 'variant.paymentDate',
    subtitle2: '',
    id: undefined,
  },
  [EnumVariants.singleDelayed90]: {
    label: 'variant.singleDelayed90.title',
    icon1: NextInstallment,
    icon2: undefined,
    subtitle1: 'variant.paymentDate',
    subtitle2: '',
    id: undefined,
  },
  [EnumVariants.singleDelayed120]: {
    label: 'variant.singleDelayed120.title',
    icon1: NextInstallment,
    icon2: undefined,
    subtitle1: 'variant.paymentDate',
    subtitle2: '',
    id: undefined,
  },
};
