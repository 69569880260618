import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { LoginResponse } from 'src/services/login/loogin.types';
import {
  createOrderPluginCheckout,
  getPluginCheckout,
  sendOtpForRecoverGuestSession,
  sendOtpPluginCheckout,
  signInForPluginCheckout,
  verifyOtpForRecoverGuestSession,
  verifyOtpPluginCheckout,
} from './ecommerce.config';
import {
  CreateOrderPluginCheckoutData,
  CreateOrderPluginCheckoutResponse,
  PluginCheckoutData,
  PluginCheckoutResponse,
  SendOtpForRecoverGuestSessionData,
  SendOtpForRecoverGuestSessionResponse,
  SendOtpPluginCheckoutData,
  SignInForPluginCheckoutData,
  VerifyOtpForRecoverGuestSessionData,
  VerifyOtpForRecoverGuestSessionResponse,
  VerifyOtpPluginCheckoutData,
} from './ecommerce.types';

export const getPluginCheckoutMethodApi = async (data: PluginCheckoutData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getPluginCheckout,
    method: 'GET',
    params: data,
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: PluginCheckoutResponse }>(
    requestConfig,
  );
};

export const sendOtpPluginCheckoutMethodApi = async (
  data: SendOtpPluginCheckoutData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: sendOtpPluginCheckout,
    method: 'POST',
    data,
    params: {
      checkoutSessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request(requestConfig);
};

export const verifyOtpPluginCheckoutMethodApi = async (
  data: VerifyOtpPluginCheckoutData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifyOtpPluginCheckout,
    method: 'POST',
    data,
    params: {
      checkoutSessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request<LoginResponse>(requestConfig);
};

export const createOrderPluginCheckoutMethodApi = async (
  data: CreateOrderPluginCheckoutData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: createOrderPluginCheckout,
    method: 'POST',
    data,
    params: {
      checkoutSessionGuid: data.checkoutSessionGuid,
    },
    authenticated: true,
  });

  return await apiRequest.request<CreateOrderPluginCheckoutResponse>(
    requestConfig,
  );
};

export const signInForPluginCheckoutMethodApi = async (
  data: SignInForPluginCheckoutData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: signInForPluginCheckout,
    method: 'POST',
    data,
    params: {
      checkoutSessionGuid: data.SessionGuid,
    },
    authenticated: true,
  });

  return await apiRequest.request<LoginResponse>(requestConfig);
};

export const sendOtpForRecoverGuestSessionMethodApi = async (
  data: SendOtpForRecoverGuestSessionData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: sendOtpForRecoverGuestSession,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<SendOtpForRecoverGuestSessionResponse>(
    requestConfig,
  );
};
export const verifyOtpForRecoverGuestSessionMethodApi = async (
  data: VerifyOtpForRecoverGuestSessionData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifyOtpForRecoverGuestSession,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<VerifyOtpForRecoverGuestSessionResponse>(
    requestConfig,
  );
};
