import { Prospect } from '../ProductCard.types';
import StepperPercentage from 'src/components/Stepper/StepperPercentage.component';
import { ModalBody } from 'src/components/Modal/style/Modal.styles';

type ProductCardCSTModalProps = {
  prospect: Prospect[];
};
const ProductCardCSTModalBody: React.FC<ProductCardCSTModalProps> = ({
  prospect,
}) => {
  return (
    <ModalBody>
      <StepperPercentage dataPercentageStepper={prospect} />
    </ModalBody>
  );
};

export default ProductCardCSTModalBody;
