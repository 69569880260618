import { CategoriesEnum } from 'src/utils/types/common.types';
import { ReactComponent as Earnings } from 'src/images/icons/earnings.svg';
import { ReactComponent as Installments } from 'src/images/icons/installment-custom.svg';
import { ReactComponent as CreditAssigned } from 'src/images/icons/credit_assigned.svg';

export const mapCategories = {
  [CategoriesEnum.flexInstallment]: {
    title: 'lbl.titleFLX',
    description: 'text.descriptionFLX',
    label: 'lbl.labelFLX',
    SvgIcon: Installments,
  },
  [CategoriesEnum.singleInstallment]: {
    title: 'lbl.titleSGL',
    description: 'text.descriptionSGL',
    label: 'lbl.labelSGL',
    SvgIcon: Earnings,
  },
  [CategoriesEnum.advance]: {
    title: 'lbl.titleADV',
    description: 'text.descriptionADV',
    label: 'lbl.labelADV',
    SvgIcon: CreditAssigned,
  },
};
