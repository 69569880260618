import DisableWindowScroll from 'src/components/DisableWindowScroll/DisableWindowScroll.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as GoBackIcon } from 'src/images/icons/arrowLeft.svg';
import { ReactComponent as GoForwardIcon } from 'src/images/icons/arrow_back_right.svg';
import { ReactComponent as Close } from 'src/images/icons/hamburger-close.svg';
import { ReactComponent as Menu } from 'src/images/icons/hamburger-menu.svg';
import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  buyerMenu,
  collaboratorMenu,
  merchantMenu,
  RoutePath,
} from 'src/routers/routers.config';
import { useSession } from 'src/services/session/session.context';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { H3, H5, P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';

import ChangeAccountTypeCard from 'src/components/ChangeAccountTypeCard/ChangeAccountTypeCard.component';
import { ChangeAccountTypeCardArray } from 'src/components/ChangeAccountTypeCard/ChangeAccountTypeCard.config';
import SelectLanguage from 'src/components/Header/SelectLanguage/SelectLanguage.component';
import { useMutation } from 'react-query';
import {
  typeBuyerGuidKey,
  typeMerchantGuidKey,
} from 'src/services/session/session.config';
import {
  buyerGetInfoMethodApi,
  merchantGetInfoMethodApi,
} from 'src/services/user/user.request';
import { Flex } from 'src/style/flexbox.style';
import { navigationStatusMappings } from 'src/utils/config/stateAccount';
import { RoleEnum } from 'src/utils/types/common.types';
import {
  CloseContainer,
  HamburgerContainer,
  LinkSidebar,
  LinkSubNav,
  OverlaySidebar,
  SidebarContainer,
  SidebarHeaderContainer,
  SidebarHeaderFlex,
} from './style/sidebar.style';

type SidebarProps = {
  loggedIn?: boolean;
};

export const Sidebar: React.FC<SidebarProps> = ({ loggedIn }) => {
  const [posArray, setPosArray] = useState<number[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const {
    Status: statoAccount,
    AccountType: tipoAccount,
    Role,
  } = useProfileStore((state) => state);
  const { pathname } = useLocation();
  const { onLogout } = useSession();
  const navigate = useNavigate();
  const {
    CanGiveCredit,
    HasPluginProduct,
    setUser,
    setValue,
    AccountType,
    CanBeMerchant,
  } = useProfileStore();
  const { BuyerRoles, MerchantRoles } = useAccountStore();
  const { mutate: mutateMerchantInfo } = useMutation(merchantGetInfoMethodApi, {
    onSuccess: (res) => {
      setUser(res.data.ResultSet);
      setValue('AccountType', 'MERCHANT');
      setValue('Role', MerchantRoles && MerchantRoles[0].Role);
      const navigationPath =
        navigationStatusMappings[res.data.ResultSet.Status]?.['MERCHANT'];
      if (navigationPath) {
        navigate(navigationPath);
      }
    },
  });
  const { mutate: mutateBuyerInfo } = useMutation(buyerGetInfoMethodApi, {
    onSuccess: (res) => {
      setUser(res.data.ResultSet);
      setValue('AccountType', 'BUYER');
      setValue('Role', BuyerRoles && BuyerRoles[0].Role);
      navigate(RoutePath.privateArea);
    },
  });
  if (!AccountType) {
    return <Navigate to={RoutePath.login} />;
  }
  const { title, subTitle } =
    ChangeAccountTypeCardArray[AccountType as 'BUYER' | 'MERCHANT'];

  const showSwitch =
    (AccountType === 'MERCHANT' && BuyerRoles && BuyerRoles.length > 0) ||
    (AccountType === 'BUYER' &&
      MerchantRoles &&
      MerchantRoles.length > 0 &&
      CanBeMerchant);

  const showBannerRedirect =
    MerchantRoles &&
    MerchantRoles?.length < 1 &&
    CanBeMerchant &&
    AccountType === 'BUYER';

  let currentBranch =
    tipoAccount === 'MERCHANT'
      ? Role === RoleEnum.owner
        ? merchantMenu
        : collaboratorMenu
      : buyerMenu;
  let prev = '';
  for (let i = 0; i < posArray.length; i++) {
    const index = posArray[i];
    prev = currentBranch[index].label;
    currentBranch = currentBranch[index].child!;
  }

  function goBack() {
    setPosArray([...posArray.slice(0, -1)]);
  }

  const mainLevel = posArray.length === 0;

  function closeSideBar() {
    setIsVisible(false);
    setPosArray([]);
  }

  function openSideBar() {
    setIsVisible(true);
  }

  const switchAccount = () => {
    if (tipoAccount === 'BUYER' && MerchantRoles) {
      window.localStorage.removeItem(typeBuyerGuidKey);
      window.localStorage.setItem(typeMerchantGuidKey, MerchantRoles[0].Guid);
      mutateMerchantInfo();
    }
    if (tipoAccount === 'MERCHANT' && BuyerRoles) {
      window.localStorage.removeItem(typeMerchantGuidKey);
      window.localStorage.setItem(typeBuyerGuidKey, BuyerRoles[0].Guid);
      mutateBuyerInfo();
    }
    setIsVisible(false);
  };

  const goToOpyn = () => {
    (window as any).open('https://www.opyn.eu/landing-page-opl-b2m');
    setIsVisible(false);
  };

  return (
    <>
      <HamburgerContainer
        data-component="sidebar"
        onClick={openSideBar}
        id="IconMenu"
      >
        <P colorBlack>
          <Translate id="lbl.menu" />
        </P>
        <Icon svgIconComponent={Menu} iconHeight="3.2rem" iconSize="3.2rem" />
      </HamburgerContainer>

      <OverlaySidebar
        data-component="sidebar"
        className={isVisible ? 'open' : undefined}
      />
      {isVisible ? <DisableWindowScroll /> : null}
      <SidebarContainer
        data-component="sidebar"
        className={isVisible ? 'open' : undefined}
      >
        <SidebarHeaderContainer>
          <SidebarHeaderFlex>
            {!mainLevel ? (
              <>
                <button onClick={goBack}>
                  <Icon iconSize="6.4rem" svgIconComponent={GoBackIcon} />
                </button>
                <H5>
                  <Translate id={prev} />
                </H5>
              </>
            ) : (
              <SelectLanguage />
            )}
            <CloseContainer onClick={closeSideBar}>
              <P colorBlack>
                <Translate id="lbl.close" />
              </P>
              <Icon iconSize="3.2rem" svgIconComponent={Close} />
            </CloseContainer>
          </SidebarHeaderFlex>
        </SidebarHeaderContainer>
        <ResponsiveContainer>
          <Flex flexDirection="column" gap="3.2rem">
            {currentBranch.map((linkSidebar, index) => {
              const disabled =
                linkSidebar.viewState &&
                linkSidebar.viewState.includes(`${statoAccount}`);
              const showLink = !(
                (linkSidebar.hideByCantGiveCredit && !CanGiveCredit) ||
                (linkSidebar.hasPluginProduct && !HasPluginProduct)
              );
              const link = linkSidebar.url && showLink && (
                <LinkSidebar
                  key={index}
                  to={linkSidebar.url}
                  state={{ prevPath: pathname }}
                  disabled={disabled}
                  onClick={closeSideBar}
                  id={linkSidebar.label}
                >
                  <H3 light>
                    <Translate id={linkSidebar.label} />
                  </H3>
                </LinkSidebar>
              );

              const subLink = showLink && (
                <LinkSubNav
                  key={index}
                  disabled={disabled}
                  onClick={() => setPosArray([...posArray, index])}
                  id={linkSidebar.label}
                >
                  <H3 light>
                    <Translate id={linkSidebar.label} />
                    <GoForwardIcon />
                  </H3>
                </LinkSubNav>
              );
              return linkSidebar.url ? link : subLink;
            })}
            {mainLevel && (
              <LinkSidebar to="#" onClick={onLogout} id="lbl.exit">
                <H3 light>
                  <Translate id="lbl.exit" />
                </H3>
              </LinkSidebar>
            )}

            {showSwitch && mainLevel && (
              <Flex gap="3.2rem" maxWidth="41.6rem" flexDirection="column">
                <hr
                  style={{
                    width: '100%',
                    margin: '0',
                    border: '1px solid #35302F',
                  }}
                />
                <ChangeAccountTypeCard
                  title={title}
                  subtitle={subTitle}
                  onClickHandle={switchAccount}
                />
              </Flex>
            )}
            {showBannerRedirect && mainLevel && (
              <Flex gap="3.2rem" flexDirection="column" maxWidth="41.6rem">
                <hr
                  style={{
                    width: '100%',
                    margin: '0',
                    border: 'none',
                    height: '1px',
                    background: '#35302F',
                  }}
                />
                <ChangeAccountTypeCard
                  title="lbl.sellToOthersCompanies"
                  subtitle="lbl.sellToOthersCompaniesDescr"
                  onClickHandle={goToOpyn}
                  data-wa="become merchant"
                />
              </Flex>
            )}
          </Flex>
        </ResponsiveContainer>
      </SidebarContainer>
    </>
  );
};
