import ActionCardLight from 'src/components/ActionCardLight/ActionCardLight.component';
import { IconButton } from 'src/components/Button/IconButton.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import InputPhoneNumber from 'src/components/InputPhoneNumber/InputPhoneNumber.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ErrorIconSrc } from 'src/images/icons/input_error.svg';
import { ReactComponent as minus } from 'src/images/icons/minus.svg';
import { ReactComponent as plus } from 'src/images/icons/plus-blue.svg';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  installmentNumber,
  orderImport,
} from 'src/services/orderLink/orderLink.config';
import { getPlansFromTemplates } from 'src/services/template/template.config';
import { getPlansFromTemplatesMethodApi } from 'src/services/template/template.request';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { InstallmentConfigForm } from './StepInstallmentNumber.helpers';
import { InputCustom } from './StepInstallmentNumber.style';

function StepInstallmentNumber() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const { productCode, categoryCode } = useParams();
  const { remodulation, setValue, amount } = usePayByLinkStore();
  const { initialValues, resolver } = InstallmentConfigForm();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const value = parseInt(methods.watch(installmentNumber), 10);

  const { isLoading, data } = useQuery(
    getPlansFromTemplates,
    () => getPlansFromTemplatesMethodApi({ amount }),
    {
      onSuccess: (res) => setValue('templates', res?.data?.ResultSet),
    },
  );

  const onSubmit = async (data: any) => {
    data[orderImport] = amount;
    setValue('templateSelected', false);
    navigate(urlRedirectToFlow, {
      state: { totalInstallment: data[installmentNumber] },
    });
  };
  const invalidNumber = !methods.formState.isValid;

  const decrement = () => {
    methods.setValue(installmentNumber, value - 1);
    methods.trigger(installmentNumber);
  };

  const increment = () => {
    methods.setValue(installmentNumber, value ? value + 1 : 1);
    methods.trigger(installmentNumber);
  };

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.installmentNumberExt"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.pblInstallmentInfo" />
        </H4>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="mx-auto max-w-md"
          >
            <div className="flex items-center justify-center gap-8 py-6">
              <IconButton
                disabled={value === 1 || !value}
                sizeType="large"
                sizeTypeOnDesktop="large"
                type="button"
                onClick={decrement}
                id={IDs.btnMinus}
                IconSrc={minus}
              />
              <InputCustom
                name={installmentNumber}
                autoFocus
                inputComponent={InputPhoneNumber}
              />
              <IconButton
                disabled={value > 49}
                sizeType="large"
                sizeTypeOnDesktop="large"
                type="button"
                onClick={increment}
                id={IDs.btnPlus}
                IconSrc={plus}
              />
            </div>
            <div className="mt-5 flex items-center gap-2">
              {invalidNumber && <ErrorIconSrc width="20" height="20" />}
              <P textColor={invalidNumber ? 'red' : colors.textColorGrey}>
                <Translate
                  id="lbl.maxInstallment"
                  data={{ maxInstallment: 50 }}
                />
              </P>
            </div>
            {data?.data?.ResultSet &&
              data?.data?.ResultSet?.length > 0 &&
              !remodulation && (
                <>
                  <DividerOr bold />
                  <ActionCardLight
                    id={IDs.btnUseModel}
                    path={`${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSelectTemplates}`}
                    text="lbl.useSavedTemplate"
                  />
                </>
              )}
            <ButtonContainer>
              <SubmitButton
                disabled={invalidNumber}
                minWidth="100%"
                id={IDs.btnProceed}
                i18n
                translatedText="lbl.next"
              />
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
}

export default StepInstallmentNumber;
