import { Button } from 'src/components/Button/Button.component';
import { ModalFooterProps } from 'src/components/Modal/partials/ModalFooter/ModalFooterDefault.component';
import { ModalHeaderProps } from 'src/components/Modal/partials/ModalHeader/ModalHeader.types';
import { CloseButtonRoot } from 'src/components/Modal/style/Modal.styles';
import { ReactComponent as X } from 'src/images/icons/negative-feedback-point.svg';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import {
  ApplyButton,
  ButtonContainer,
  PaymentsFilterModalHeaderStyled,
} from './style/PaymentsFilterModalParts.style';

type PaymentsModalFooterProps = ModalFooterProps & {
  applyFilters: () => void;
};

export const PaymentsFilterModalHeader: React.FC<ModalHeaderProps> = ({
  modalTitle,
  handleClose,
}) => {
  return (
    <PaymentsFilterModalHeaderStyled>
      {modalTitle}
      <CloseButtonRoot onClick={handleClose}>
        <X width="4.4rem" height="4.4rem" />
      </CloseButtonRoot>
    </PaymentsFilterModalHeaderStyled>
  );
};

export const PaymentsFilterModalFooter: React.FC<PaymentsModalFooterProps> = ({
  applyFilters,
  buttonTitle,
}) => {
  const { reset, handleSubmit, formState } = useFormContext();
  return (
    <ButtonContainer flexDirection="column" align="center">
      <ApplyButton
        variant="Secondary"
        onClick={handleSubmit(applyFilters)}
        disabled={!formState.isValid}
      >
        <Translate id={buttonTitle} />
      </ApplyButton>
      <Button
        onClick={() => reset()}
        variant="LinkPrimary"
        i18n
        translatedText="text.cancel"
        padding="2.4rem 0 0"
        fontWeight="500"
      />
    </ButtonContainer>
  );
};
