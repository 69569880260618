import apiRequest from 'src/services/api/api.config';
import { fallbackLng, languagesByCode } from './i18n.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getCookie } from 'src/utils/functions/cookie';
import { LANGUAGE_URL } from 'src/settings';

function getDefaultLanguage() {
  const cookieLanguage = getCookie('language');
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const browserLanguage = navigator.language;
  return browserLanguage;
}

export const getI18nLanguageRequest = () => {
  const langFromSearchParams =
    new URLSearchParams(window.location.search).get('lang') ||
    getDefaultLanguage();

  // Simple validation to filter out blatantly invalid inputs.
  const language =
    langFromSearchParams &&
    /^[a-z_-]{2,10}$/i.test(langFromSearchParams) &&
    Object.keys(languagesByCode).includes(langFromSearchParams)
      ? langFromSearchParams
      : fallbackLng;

  return {
    language,
  };
};

export const getLocalizationsApi = async (language: string) => {
  const requestConfig = makeEndpointApiConfig({
    url: `${language}.json`,
    method: 'GET',
    language: true,
  });
  return await apiRequest.request({
    ...requestConfig,
    baseURL: LANGUAGE_URL,
  });
};
