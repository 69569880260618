import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { H5, P } from 'src/style-utils/typographic';
import { ContarctCardContainer, IconWrapper } from './AlertCard.style';

export type AlertCardProp = {
  icon?: React.FunctionComponent;
  title: string;
  content: string;
};

function AlertCard({ icon, title, content }: AlertCardProp) {
  return (
    <ContarctCardContainer>
      {icon && (
        <IconWrapper>
          <Icon
            svgIconComponent={icon}
            iconHeight="2.6rem"
            marginBottom="2.4rem"
            iconSize="auto"
          />
        </IconWrapper>
      )}
      <H5>
        <Translate id={title} />
      </H5>
      <P marginBottom="1.6rem" style={{ flexGrow: '1' }}>
        <Translate id={content} />
      </P>
    </ContarctCardContainer>
  );
}

export default AlertCard;
