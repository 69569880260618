const enum AccountType {
  merchant = 'MERCHANT',
  buyer = 'BUYER',
}

export const ChangeAccountTypeCardArray = {
  [AccountType.buyer]: {
    title: 'lbl.switchToSeller',
    subTitle: 'text.switchTosellerDescr',
  },
  [AccountType.merchant]: {
    title: 'lbl.switchToBuyer',
    subTitle: 'text.switchToBuyerDescr',
  },
};
