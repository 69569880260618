import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import required from 'src/utils/validation/required';
import { Resolver } from 'react-hook-form';

const flagCreditAssignment = 'flag-credit-assignment';

export const formConfigWelcomePage = () => {
  const initialValues = {
    [flagCreditAssignment]: false,
  };

  const validate = (values: any) => {
    return validateFormSync({
      [flagCreditAssignment]: validateFieldSync(required),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
