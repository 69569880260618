import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import {
  DataTypeArray,
  UpdateSignUpData,
  updateSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useProfileStore, useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import { formConfigRegistrationStepPhone } from './PhoneStepRegistration.helpers';
import { Privacy } from 'src/pages/registration/registrationMerchant/style/registrationMerchant.style';
import { SignInSendOtpGuestMethodApi } from 'src/services/login/login.request';
import { useState } from 'react';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';

const PhoneStepRegistration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const guest = location?.state?.guest;
  const [errorMessage, setErrorMessage] = useState('');
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const setValue = useRegistrationStore((state) => state.setValue);
  const buyerPhone = useProfileStore((state) => state.currentOrder?.BuyerPhone);
  const buyerPrefix = useProfileStore(
    (state) => state.currentOrder?.BuyerPhonePrefix,
  );
  const { initialValues, resolver } = formConfigRegistrationStepPhone(
    buyerPhone,
    buyerPrefix,
  );
  const { mutate, isLoading } = useMutation(updateSignUpSessionMethodApi, {
    onSuccess: (res) => {
      res.data.ResultSet
        ? navigate(RoutePath.registrationBuyer + RoutePath.otpConfirmPhoneStep)
        : navigate(RoutePath.registrationBuyer + RoutePath.phoneStep);
    },
    onError: (res: any) => {
      const errors = servicesErrorsMap(res.response, { phone: phoneField });
      for (const key in errors) {
        methods.setError(phoneField, { message: errors[key] });
      }
    },
  });

  const { mutate: mutateSendOtp, isLoading: isLoadingSendOtp } = useMutation(
    SignInSendOtpGuestMethodApi,
    {
      onSuccess: (res, variables) => {
        setValue('mobile', variables[phoneField]);
        setValue('prefix', variables[prefix]);
        setValue('guestSignInGuid', res.data.ResultSet);
        navigate(RoutePath.registrationBuyer + RoutePath.otpConfirmPhoneStep, {
          state: {
            guidTokeGuest: res.data.ResultSet,
          },
        });
      },
      onError: (error: any) => {
        const errorResponse: {
          errorInfos: {
            generic: 'error.not_found' | 'error.already_registered';
          };
        } = error.response.data;

        const errorCodeArray = {
          'error.not_found': {
            label: 'error.signUpWithoutPurchases',
          },
          'error.already_registered': {
            label: 'error.mobileAlreadyRegistered',
          },
        };
        if (errorResponse.errorInfos) {
          setErrorMessage(
            errorCodeArray[errorResponse.errorInfos.generic].label,
          );
          methods.setError(phoneField, {
            message: errorCodeArray[errorResponse.errorInfos.generic].label,
          });
          return;
        }
      },
    },
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    if (guest) {
      mutateSendOtp({ [phoneField]: data[phoneField], [prefix]: data[prefix] });
      return;
    }
    setValue('mobile', data[phoneField]);
    setValue('prefix', data[prefix]);
    const UpdateData: UpdateSignUpData = {
      DataType: DataTypeArray.phone,
      DataValue: data[phoneField],
      DataValueAdd: data[prefix],
      SessionGuid: sessionGuid,
    };
    mutate(UpdateData);
  };

  return (
    <>
      <FlowHeader
        label="lbl.mobileNumber"
        showWhiteSpaceRight
        stepNumber={1}
        stepMaxNumber={8}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.registrationBuyerPhone" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <PhoneField
                isPresentMessage
                name={phoneField}
                disabled={buyerPhone}
              />
              <GenericErrorForm
                textAlign="left"
                isPresentMessage
                errorMessageID={errorMessage}
              />
              <ButtonContainer>
                <SubmitButton
                  minWidth="100%"
                  disabled={isLoading || isLoadingSendOtp}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
                <Privacy>
                  <Translate
                    id="link.privacyLink"
                    extraTranslationProps={{
                      components: { u: <a /> },
                    }}
                  />
                </Privacy>
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
};

export default PhoneStepRegistration;
