import { ModalHeader } from 'src/components/Modal/partials/ModalHeader/ModalHeader.component';
import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import ChoosePlanModalBody from './ChoosePlanModalBody.component';
import ChoosePlanModalFooter from './ChoosePlanModalFooter.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'SortModal',
  headerComponent: ModalHeader,
  bodyComponent: ChoosePlanModalBody,
  footerComponent: ChoosePlanModalFooter,
});
