import radioBoxChecked from 'src/images/icons/radioBoxChecked.svg?url';
import radioBoxUnchecked from 'src/images/icons/radioUnchecked.svg?url';
import { withPadding } from 'src/style-utils/dimensionsMixins';
import media from 'src/style-utils/media-queries';
import styled, { css } from 'styled-components';
import { CheckedComponentProps, RadioWrapperProps } from '../RadioBox.types';

export const RadioWrapper = styled.div<RadioWrapperProps>`
  && {
    ${withPadding};
  }
  display: flex;
  align-items: center;
  width: 100%;
  ${({ viewMode }) =>
    viewMode === 'inline' &&
    css`
      width: auto;
    `};
`;

export const RadioContainer = styled.label<{ large: boolean }>`
  display: inline-block;
  height: ${({ large }) => `${large ? '4.8rem' : '3.2rem'}`};
  line-height: ${({ large }) => `${large ? '4.8rem' : '3.2rem'}`};
  position: relative;
  cursor: pointer;
  user-select: none;

  ${({ theme: { radiobox }, large }) => `
    padding-left: ${large ? radiobox.root.paddingLeft : '3.2rem'};
    margin: ${radiobox.root.margin};
    `}

  ${media.desktop`
    ${({ theme: { radiobox }, large }: any) => `
      padding-left: ${large ? radiobox.root.desktop.paddingLeft : '3.2rem'};
      margin: ${radiobox.root.desktop.margin};
    `}
  `};
`;

export const RadioLabel = styled.span<CheckedComponentProps>`
  display: inline-block;
  vertical-align: middle;
  ${({ theme: { radiobox } }) => `
    margin-top: ${radiobox.label.marginTop};
  `};
`;

export const Checkmark = styled.span<{ large: boolean }>`
  position: absolute;
  top: ${({ large }) => `${large ? '1.2rem' : '1rem'}`};
  height: ${({ large }) => `${large ? '2.4rem' : '1.6rem'}`};
  background-size: ${({ large }) =>
    `${large ? '5rem 2.4rem' : '2.4rem 1.6rem'}`};
  left: 0;
  width: ${({ large }) => `${large ? '5rem' : '2.4rem'}`};
  background-image: url(\"${radioBoxUnchecked}\");
  background-repeat: no-repeat;
`;

export const RadioInput = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    background-image: url(\"${radioBoxChecked}\");
  }

  &:checked ~ ${RadioLabel} {
    font-weight: bold;
  }
`;
