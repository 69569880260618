import { ListTableAdvPlanRequests } from 'src/pages/advancePlanRequests/AdvancePlanRequests.types';
import React from 'react';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

const CreationColumn: React.FC<ListTableAdvPlanRequests> = ({ Creation }) => {
  return <P textColor={colors.textTableBody}>{refactorFormatDate(Creation)}</P>;
};

export default CreationColumn;
