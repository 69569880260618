import { Resolver } from 'react-hook-form';
import { cfField } from 'src/services/orderLink/orderLink.config';
import cf from 'src/utils/validation/cf';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigCfRegistration = (fiscalOrVatCode: string) => {
  const initialValues = {
    [cfField]: fiscalOrVatCode,
  };

  const validate = validateFormSync({
    [cfField]: validateFieldSync(required, cf),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
