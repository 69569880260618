import { ReactComponent as SecondIcon } from 'src/images/icons/hand_sign.svg';
import { ReactComponent as UsersIcon } from 'src/images/icons/img-users.svg';
import { ReactComponent as InnoavtionIcon } from 'src/images/icons/innovation.svg';
import { ReactComponent as ThirdIcon } from 'src/images/icons/link.svg';
import { ReactComponent as FirstIcon } from 'src/images/icons/online.svg';
import { ReactComponent as OkIcon } from 'src/images/icons/step.svg';

export const collabStepperArray = [
  {
    title: 'text.collabStepperTitle1',
    subTitle: 'text.collabStepperDescr1',
    icon: FirstIcon,
  },
  {
    title: 'text.collabStepperTitle2',
    subTitle: 'text.collabStepperDescr2',
    icon: SecondIcon,
  },
  {
    title: 'text.collabStepperTitle3',
    subTitle: 'text.collabStepperDescr3',
    icon: ThirdIcon,
  },
];

export const collabListItems = [
  {
    SvgComponent: UsersIcon,
    label: 'text.collabAccordionTitle1',
    content: 'text.collabAccordionDescr1',
  },
  {
    SvgComponent: InnoavtionIcon,
    label: 'text.collabAccordionTitle2',
    content: 'text.collabAccordionDescr2',
  },
  {
    SvgComponent: OkIcon,
    label: 'text.collabAccordionTitle3',
    content: 'text.collabAccordionDescr3',
  },
];
