import colors from 'src/style-utils/colors';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const ContarctCardContainer = styled(Card)`
  flex-basis: 44.8rem;
  counter-increment: contacts;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  &::before {
    content: counter(contacts);
    color: ${colors.green10};
    line-height: 0.75;
    font-weight: 800;
    position: absolute;
    font-size: 30rem;
    bottom: -2rem;
    right: -2rem;
    z-index: -1;
  }
`;

export const IconWrapper = styled.div`
  svg {
    height: 2.6rem;
  }
`;
