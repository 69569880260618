import { ReactComponent as SecondStepIcon } from 'src/images/icons/earnings.svg';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getUWBuyer } from 'src/services/UWBuyer/UWBuyer.config';
import { getUWBuyerMethodApi } from 'src/services/UWBuyer/UWBuyer.request';
import { createOrderPluginCheckoutMethodApi } from 'src/services/ecommerce/ecommerce.request';
import { useCurrentTransactionStore } from 'src/store/store';
import { Flex } from 'src/style/flexbox.style';
import { ECommerceContainer } from './ECommerceCheckout.style';
import AccessCard from './partials/AccessCard/AccessCard.component';
import ChoosePlan from './partials/ChoosePlan/ChoosePlan.component';
import CompanyDataCard from './partials/CompanyDataCard/CompanyDataCard.component';
import PaymentCard from './partials/PaymentCard/PaymentCard.component';
import StepCard from './partials/StepCard/StepCard.component';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';

const ECommerceCheckout = () => {
  const setCurrentTransaction = useCurrentTransactionStore(
    (state) => state.setTransactions,
  );
  const [searchParams] = useSearchParams();
  const checkoutSessionGuid: string = searchParams.get('checkoutSessionGuid')!;
  const sessionData = useOutletContext<EcommerceContext>().sessionData;
  const [polling, setPolling] = useState(false);
  const navigate = useNavigate();

  const {
    mutate: mutateCreateOrder,
    isLoading: isLoadingCreateOrder,
    data: dataCreateOrder,
    isSuccess: isSuccessCreateOrder,
  } = useMutation(createOrderPluginCheckoutMethodApi, {
    onSuccess: async (res) => {
      const {
        OrderGuid,
        FirstInstallmentGuid,
        FirstInstallmentAmount,
        OrderId,
      } = res.data.ResultSet;
      setCurrentTransaction({
        orderGuid: OrderGuid,
        transactionsGuid: [FirstInstallmentGuid],
        amount: FirstInstallmentAmount,
        paymentMethodId: 0,
        orderId: OrderId,
        type: 'pay',
      });
    },
    onError: () => navigate(RoutePath.oopsPlugin),
  });

  const { data: dataGetUw } = useQuery(
    getUWBuyer,
    () =>
      getUWBuyerMethodApi({
        uwId: sessionData.UwId,
      }),
    {
      onSuccess: (res) => {
        const { Result, Status } = res?.data?.ResultSet ?? {};
        if (Result === 'OK' && Status === 1) {
          setPolling(false);
        } else if (Status !== 0) {
          setPolling(false);
          navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerErrorPlugin, {
            state: { plugin: true },
          });
        }
      },
      onError: () => navigate(RoutePath.oopsPlugin),
      refetchInterval: 2000,
      enabled: polling,
    },
  );

  return (
    <ECommerceContainer>
      <CompanyDataCard companyData={sessionData} />
      <Flex gap="2.4rem" flexDirection="column" flex="1" justify="flex-start">
        <AccessCard
          startPolling={setPolling}
          getPluginData={sessionData}
          checkoutSessionGuid={checkoutSessionGuid}
        />
        <ChoosePlan
          mutateCreateOrder={mutateCreateOrder}
          getPluginData={sessionData}
          checkoutSessionGuid={checkoutSessionGuid}
          isLoadingCreate={isLoadingCreateOrder}
          resultUw={dataGetUw?.data}
        />
        <StepCard
          icon={SecondStepIcon}
          stepNumber={3}
          stepTitle="text.paymentMethod"
        >
          <PaymentCard
            isSuccessCreateOrder={isSuccessCreateOrder}
            dataCreateOrder={dataCreateOrder?.data.ResultSet}
            getPluginData={sessionData}
            isLoading={isLoadingCreateOrder}
            isLoadingUW={polling}
          />
        </StepCard>
      </Flex>
    </ECommerceContainer>
  );
};

export default ECommerceCheckout;
