export const startDate = 'fromDate';
export const endDate = 'toDate';
export const fromAmount = 'fromAmount';
export const toAmount = 'toAmount';
export const clientList = 'buyers';
export const types = 'types';
export const skipField = 'skip';
export const takeField = 'take';
export const sortBy = 'sortBy';
export const sort = 'sort';
export const sortDirection = 'sortDirection';
export const buyerSearchText = 'buyerSearchText';
export const takeLinks = 20;
export const statuses = 'statuses';
export const Amounts = 'amounts';
export const flagCessioneCredito = 'flagCessioneCredito';
export const flagNonCessioneCredito = 'flagNonCessioneCredito';
export const madeFromPlugin = 'madeFromPlugin';
