import axios from 'axios';
import { languageLocalizationsEndpoint } from 'src/services/i18n/i18n.config';
import { forbidden_403 } from './api.statusCodes';
import { API_URL } from 'src/settings';

export const mapApiCall = {
  [languageLocalizationsEndpoint]: 'Languages',
};
export type ApiCallKey = Readonly<keyof typeof mapApiCall>;
// axios docs: https://github.com/axios/axios/blob/master/README.md
// Request config: https://github.com/axios/axios/blob/master/README.md#request-config
const apiRequest = (function createApiRequest() {
  let baseURL = API_URL;
  const axiosInstance = axios.create({
    baseURL,
    // In milliseconds.
    timeout: 40_000,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === forbidden_403) {
        localStorage.removeItem('auth-token');
        (window as any).location = '/login';
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    },
  );

  return axiosInstance;
})();

export default apiRequest;
