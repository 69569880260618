import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ResultPageIcon } from 'src/pages/onboardingFlow/variants/style/OnboardingFlow.style';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import colors from 'src/style-utils/colors';
import { H5 } from 'src/style-utils/typographic';
import {
  ResponsiveContainer,
  ColoredSection,
  Grid,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import AddUser from 'src/images/icons/add-user.svg?url';
import ToastCard from 'src/components/ToastCard/ToastCard.component';
import { ReactComponent as MailIcon } from 'src/images/icons/email.svg';
import { ReactComponent as SmartphoneIcon } from 'src/images/icons/smartphone.svg';
import { ReactComponent as AvatarIcon } from 'src/images/icons/avatar.svg';
import { useRegistrationStore } from 'src/store/store';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';

function RegistrationMerchantLandingPage() {
  const navigate = useNavigate();
  const setValues = useRegistrationStore((state) => state.setValues);
  return (
    <>
      <ResponsiveContainer padding="0 0 6rem">
        <Flex margin="4.2rem auto" justify="center">
          <ResultPageIcon src={AddUser} />
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          style={{ textAlign: 'center' }}
        >
          <TextDefaultPage
            title="text.startCreateAccountTitle"
            subTitle="text.startCreateAccountSubTitle"
          />
        </Flex>
        <ButtonContainer backgroundColor="white">
          <Button
            onClick={() => {
              setValues(resetRegistrationStore);
              navigate(RoutePath.registrationMerchant + RoutePath.stepCountry);
            }}
            variant="Primary"
          >
            <Translate id="lbl.createAccountOpyn" />
          </Button>
        </ButtonContainer>
      </ResponsiveContainer>
      <ColoredSection bgColor={colors.white} style={{ marginTop: 'auto' }}>
        <ResponsiveContainer>
          <H5 light marginBottom="4rem">
            <Translate id="lbl.whatWillYouNeed" />
          </H5>
          <Grid responsive gap="1.6rem">
            <ToastCard
              colorBlack
              title="lbl.cfOrVatCode"
              customIcon={AvatarIcon}
            />
            <ToastCard
              colorBlack
              title="lbl.registrationEmailAddress"
              customIcon={MailIcon}
            />
            <ToastCard
              colorBlack
              title="lbl.cellularNumber"
              customIcon={SmartphoneIcon}
            />
          </Grid>
        </ResponsiveContainer>
      </ColoredSection>
    </>
  );
}

export default RegistrationMerchantLandingPage;
