import FormEmail from 'src/pages/registration/partials/FormEmail/FormEmail.component';
import { RoutePath } from 'src/routers/routers.config';

function RegistrationGuestStepEmail() {
  return (
    <>
      <FormEmail
        nextPath={RoutePath.registrationGuest + RoutePath.otpConfirmEmailStep}
        step={4}
        stepMax={7}
        typeAccount="BUYER"
      />
    </>
  );
}

export default RegistrationGuestStepEmail;
