import Loader from 'src/components/Loader/Loader.component';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useMutation, useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getPaymentMethodsEndPoint } from 'src/services/payments/payments.config';
import {
  createSetupAttemptMethodApi,
  getPaymentMethodsApi,
  setBackupPaymentMethodApi,
  setPreferredPaymentMethodApi,
} from 'src/services/payments/payments.request';
import { useProfileStore } from 'src/store/store';

function PaymentMethodsFlow() {
  const navigate = useNavigate();
  const { changedMethod } = useProfileStore((state) => state);

  // const [changedMethod, setChangedMethod] = useState<
  //   PaymentMethodResult | undefined
  // >(undefined);

  const { mutate: setChangedMethodAsPreffered, isLoading: isSettingPreferred } =
    useMutation(() => setPreferredPaymentMethodApi(changedMethod?.Id ?? -1), {
      onSuccess: () => {
        const d = new Date();
        d.setTime(d.getTime() + 24 * 3600 * 1000);
        document.cookie = 'changed=true; expires=' + d.toUTCString() + 'path=/';
        navigate(RoutePath.methods + RoutePath.paymentMethods, {
          state: { changed: true },
        });
        refetch();
      },
      onError: () => navigate(RoutePath.oops),
    });

  const { data, isFetching, refetch } = useQuery(
    getPaymentMethodsEndPoint,
    () => getPaymentMethodsApi(),
    { onError: () => navigate(RoutePath.oops) },
  );

  const {
    mutate: mutateCreateSetup,
    isLoading: isLoadingStripeSetup,
    // isSuccess: isSuccessSetup,
  } = useMutation(createSetupAttemptMethodApi, {
    onSuccess: (res, variables) =>
      navigate(RoutePath.methods + RoutePath.addMethodStripe, {
        state: {
          clientSecretStripe: res.data.ResultSet,
          stateStripe: { isBackup: variables.isBackup },
        },
      }),
    // onError: () => navigate(RoutePath.oops),
  });

  const { mutate: mutateSetBackupMethod, isLoading: isLoadingSetBackupMethod } =
    useMutation(setBackupPaymentMethodApi, {
      onSuccess: () => {
        if (changedMethod === undefined) {
          refetch();
          const d = new Date();
          d.setTime(d.getTime() + 100 * 1000);
          document.cookie =
            'backup=true; expires=' + d.toUTCString() + 'path=/';
          return navigate(RoutePath.methods + RoutePath.paymentMethods);
        }
        setChangedMethodAsPreffered();
        return;
      },
    });

  if (
    isLoadingStripeSetup ||
    isFetching ||
    isLoadingSetBackupMethod ||
    isSettingPreferred
  )
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  const paymentMethods = data?.data.ResultSet ?? [];

  const paymentContext: MethodsContextType = {
    paymentMethods,
    backupMethod: paymentMethods.find((item) => item.IsBackup),
    refetchPaymentMethods: refetch,
    setBackupHandler: mutateSetBackupMethod,
    changedMethod: changedMethod,
    goToStripeFormHandler: (isBackup = false) =>
      mutateCreateSetup({ orderGuid: '', isBackup }),
  };

  return <Outlet context={paymentContext} />;
}

export default PaymentMethodsFlow;

//Types
export type MethodsContextType = {
  paymentMethods: PaymentMethodResult[];
  refetchPaymentMethods: (x?: any) => void;
  setBackupHandler: (x?: any) => void;
  goToStripeFormHandler: (isBackup?: boolean) => void;
  changedMethod?: PaymentMethodResult;
  backupMethod?: PaymentMethodResult;
};
