import { ModalHelperProps } from 'src/components/Modal/Modal.types';
import { loadablePage } from 'src/utils/loadable';

export type ConfirmModalProps = ModalHelperProps & {
  content: string;
  confirmLabel: string;
  confirmHandler: () => void;
};

export default loadablePage<React.FC<ConfirmModalProps>>(
  () => import('./ConfirmModal'),
);
