import { UsageSettingArrayType } from 'src/pages/usageSetting/usageSetting.types';
import { RoutePath } from 'src/routers/routers.config';

export const UsageSettingOptionsArray: UsageSettingArrayType = [
  {
    path: RoutePath.usageSettingOptionsLiquidity,
    text: 'text.liquidity',
  },
  {
    path: RoutePath.usageSettingOptionsRequirements,
    text: 'lbl.usageRequirements',
  },
];
