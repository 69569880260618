import { RoutePath } from 'src/routers/routers.config';
import FormFlag from '../../../partials/FormFlag/FormFlag.component';

const RegistrationGuestStepFlag: React.FC = () => {
  return (
    <>
      <FormFlag
        nextPath={RoutePath.registrationGuest + RoutePath.successRegistration}
        step={7}
        stepMax={7}
        typeAccount="BUYER"
      />
    </>
  );
};

export default RegistrationGuestStepFlag;
