import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputTextArea from 'src/components/InputTextArea/InputTextArea.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, PNote } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { formConfigOrderImportPayByLink } from './StepDescription.helpers';
import { IDs } from 'src/utils/config/ids';

function StepDescription() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const description = usePayByLinkStore((state) => state.description);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const { initialValues, resolver } =
    formConfigOrderImportPayByLink(description);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const charNum = (methods.watch(descriptionField) as string).length;
  const onSubmit = async (data: any) => {
    setValue('description', data[descriptionField]);
    navigate(urlRedirectToFlow);
    return;
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.description"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.payByLinkOrderDescription" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputTextArea
                name={descriptionField}
                placeholder="text.PBLTextAreaPlaceHolder"
                id={IDs.inputDescr}
              />
              <PNote style={{ color: charNum > 200 ? colors.redInput : '' }}>
                {charNum ? `${charNum}/200 ` : 'Max. 200 '}
                <Translate id="lbl.char" />
              </PNote>
              <ButtonContainer>
                <SubmitButton minWidth="100%" id={IDs.btnProceed}>
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
}

export default StepDescription;
