import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputPasswordField from 'src/components/InputPasswordField/InputPasswordField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { performRecoveryDataMethodApi } from 'src/services/recoveryData/recoveryData.request';
import { passwordField } from 'src/services/registration/registration.config';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import { formConfigInsertPassword } from './InsertPassword.helpers';

function InsertPassword() {
  const { initialValues, resolver } = formConfigInsertPassword();
  const { sessionGuid, activeOrder } = useOutletContext<RecoveryDataContext>();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(performRecoveryDataMethodApi, {
    onSuccess: (res) => {
      if (res.data.ResultSet)
        navigate(RoutePath.login, { state: { activeOrder } });
    },
  });
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const dataPerform = {
      sessionGuid,
      NewValue: data[passwordField],
    };
    mutate({ ...dataPerform });
  };
  return (
    <FlowContainer>
      <H4>
        <Translate id="text.changeAccessPassw" />
      </H4>
      <H4 light>
        <Translate id="text.changeAccessPasswExplain" />
      </H4>
      <div className="content">
        <FormProvider {...methods}>
          <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
            <InputPasswordField name={passwordField} />
            <ButtonContainer>
              <SubmitButton minWidth="100%" disabled={isLoading}>
                <Translate id="text.continue" />
              </SubmitButton>
            </ButtonContainer>
          </FormStyle>
        </FormProvider>
      </div>
    </FlowContainer>
  );
}

export default InsertPassword;
