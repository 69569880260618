import { RequestConfig } from './api.types';
import { produce } from 'immer';
import {
  authTokenKey,
  typeBuyerGuidKey,
  typeMerchantGuidKey,
} from 'src/services/session/session.config';

export function makeEndpointApiConfig(requestConfig: RequestConfig) {
  const authToken = window.localStorage.getItem(authTokenKey);
  const buyerGuid = window.localStorage.getItem(typeBuyerGuidKey);
  const merchantGuid = window.localStorage.getItem(typeMerchantGuidKey);

  const authHeaders = requestConfig.authenticated
    ? {
        authorization: `Bearer\u0020${authToken}`,
      }
    : null;
  const typeAccountHeaders = !requestConfig.language
    ? {
        [typeBuyerGuidKey]: buyerGuid,
        [typeMerchantGuidKey]: merchantGuid,
      }
    : null;
  const apiConfig = produce(requestConfig, (draft: RequestConfig) => {
    if (
      draft.method === 'POST' ||
      draft.method === 'post' ||
      draft.method === 'PATCH' ||
      draft.method === 'patch' ||
      draft.method === 'PUT' ||
      draft.method === 'put' ||
      draft.method === 'DELETE'
    ) {
      if (!draft.data) {
        draft.data = {};
      }
    } else if (
      draft.method === 'GET' ||
      draft.method === 'get' ||
      draft.method === 'HEAD' ||
      draft.method === 'head'
    ) {
      if (!draft.params) {
        draft.params = {};
      }
    }

    draft.headers = { ...draft.headers, ...authHeaders, ...typeAccountHeaders };
  }) as RequestConfig;
  return apiConfig;
}
