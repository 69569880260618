import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Canceled_link } from 'src/images/pageStatus/expired_link.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { ErrorPagePar } from '../style/failedPage.style';
import { H4 } from 'src/style-utils/typographic';

const CanceledLink: React.FC = () => {
  return (
    <>
      <Icon
        svgIconComponent={Canceled_link}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="lbl.canceledLink" />
      </H4>
      <ErrorPagePar>
        <Translate id="text.canceledLink" />
      </ErrorPagePar>
    </>
  );
};

export default CanceledLink;
