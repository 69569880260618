export type PluginCheckoutData = {
  checkoutSessionGuid?: string;
};

type Installment = {
  Expiration: string;
  Amount: number;
  NumRata: number;
};
export type Plan = {
  FlagCredit: boolean;
  Code: string;
  NumRate: number;
  NextInstallmentAmount: number;
  NextInstallmentExpiration: string;
  NextInstallmentToBePaid: boolean;
  InstallmentAmount: number;
  InstallmentMinAmount: number;
  InstallmentMaxAmount: number;
  Installments: Installment[];
};

export type PluginCheckoutResponse = {
  BuyerName: string;
  BuyerFiscalCode: string;
  BuyerPhone: string;
  BuyerPhonePrefix: string;
  AvailablePlans?: Plan[];
  OrderAmount: number;
  UwId: number;
  SearchId: number;
  CanPayWithSDD: boolean;
  BuyerCountry: string;
  BuyerVatCode: string;
  BuyerAddress: string;
  BuyerPostalCode: string;
  BuyerRegistrationStatus: BuyerRegistrationStatus;
  SameCompanyError: boolean;
  LenderName: boolean;
};
export const enum BuyerRegistrationStatus {
  notRegistered = 'not_registered',
  guest = 'guest',
  registered = 'registered',
}
export type SendOtpPluginCheckoutData = {
  SessionGuid?: string;
  BuyerPhone?: string;
  BuyerPhonePrefix?: string;
  BuyerLanguage: string;
};
export type VerifyOtpPluginCheckoutData = {
  SessionGuid?: string;
  BuyerPhoneOtp?: string;
};

export interface CreateOrderPluginCheckoutData {
  variantCode?: string;
  amount?: number;
  description?: string;
  currency?: string;
  flagCredit?: boolean;
  invoiceDate?: string;
  checkoutSessionGuid?: string;
  customInstallments?: Installment[];
}
export interface CreateOrderPluginCheckoutResponse {
  ResultSet: {
    PayByLinkUrl: string;
    OrderId: number;
    OrderGuid: string;
    FirstInstallmentGuid: string;
    FirstInstallmentToBePaid: boolean;
    FirstInstallmentAmount: number;
    CanSaveTemplate: boolean;
    CanPayWithSDD: boolean;
    Prospect: any[];
  };
}

export type SignInForPluginCheckoutData = {
  SessionGuid?: string;
  Email: string;
  Password: string;
};
export type SendOtpForRecoverGuestSessionData = {
  BuyerPhone?: string;
  BuyerPhonePrefix?: string;
};
export type SendOtpForRecoverGuestSessionResponse = {
  ResultSet: {
    OtpSent: boolean;
    ErrorCode: 'not_found' | 'already_registered';
  };
};
export type VerifyOtpForRecoverGuestSessionData = {
  BuyerPhone?: string;
  BuyerPhoneOtp?: string;
};
export type VerifyOtpForRecoverGuestSessionResponse = {
  ResultSet: {
    Success: boolean;
    PluginCheckoutSessionGuid: string;
  };
};
