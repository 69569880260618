import { AxiosResponse } from 'axios';
import Accordion from 'src/components/Accordion/Accordion.component';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import { PaySuccessCard } from 'src/components/PaySuccessCard/PaySuccessCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Amex } from 'src/images/icons/paymentMethods/amex.svg';
import { ReactComponent as AutomaticCharge } from 'src/images/icons/automatic-charge.svg';
import { ReactComponent as Bank } from 'src/images/icons/bank-icon.svg';
import { ReactComponent as Calendar } from 'src/images/icons/calendarSwipe.svg';
import { ReactComponent as CreditCard } from 'src/images/icons/credit-card.svg';
import { ReactComponent as Gpay } from 'src/images/icons/g-pay.svg';
import { ReactComponent as IconBill } from 'src/images/icons/iconBill.svg';
import { ReactComponent as RoundStepper } from 'src/images/icons/jump-stepper-round.svg';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import { ReactComponent as Percentage } from 'src/images/icons/percentage.svg';
import { ReactComponent as PostePay } from 'src/images/icons/postepay.svg';
import { ReactComponent as Sepa } from 'src/images/icons/sepa.svg';
import { ReactComponent as StepJumper } from 'src/images/icons/step-jumper.svg';
import { ReactComponent as Visa } from 'src/images/icons/paymentMethods/visa.svg';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { badRequest_400 } from 'src/services/api/api.statusCodes';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { SupportedLanguageKey } from 'src/services/i18n/i18n.types';
import { payOrderLinkEndPoint } from 'src/services/orderLink/orderLink.config';
import { payOrderLinkMethodApi } from 'src/services/orderLink/orderLink.request';
import {
  useCurrentTransactionStore,
  useProfileStore,
  useRegistrationStore,
} from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { checkBoxCreditAssignment } from './PurchaseFlow.config';
import { formCreditAssignmentConfig } from './PurchaseFlow.helpers';
import { HeaderPurchaseFlow } from './subcomponents/HeaderPurchaseFlow';
import HowDoesItWorkCard from './subcomponents/HowDoesItWorkCard';
import PurchaseFlowModal from './subcomponents/modal/PurhaseFlowModal.loadable';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';

const PaymentMethodWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex h-13 w-[9.8rem] rounded-xl border border-pgreyoutlined bg-white px-4 py-2 lg:h-16">
      {children}
    </div>
  );
};

const PurchaseFlow = ({ logged }: { logged?: boolean }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const order = searchParams.get('order');
  const { language, setLanguage } = useLanguage();
  const setValues = useRegistrationStore((state) => state.setValues);
  const setCurrentOrder = useProfileStore((state) => state.setCurrentOrder);
  const setTransactions = useCurrentTransactionStore(
    (state) => state.setTransactions,
  );
  const setUser = useProfileStore((state) => state.setUser);
  const [withCreditAssignment, setwithCreditAssignment] =
    useState<boolean>(true);
  const { initialValues, resolver } = formCreditAssignmentConfig();
  const [showModal, setShowModal] = useState(false);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { isFetching, isError, data } = useQuery(
    payOrderLinkEndPoint,
    () => payOrderLinkMethodApi({ guid: order }),
    {
      onSuccess: (res) => {
        const data = res?.data.ResultSet;
        if (data.FlagCessioneCredito) {
          methods.reset(checkBoxCreditAssignment, { keepIsValid: true });
          setwithCreditAssignment(false);
        } else {
          setwithCreditAssignment(true);
        }
        setCurrentOrder(data);
        if (data.BuyerLanguage !== '' && data.BuyerLanguage !== language) {
          setLanguage(data.BuyerLanguage as SupportedLanguageKey);
        }
        setTransactions({
          type: 'pay',
          orderGuid: data.OrderGuid,
          buyerRegistrationStatus: data.BuyerRegistrationStatus,
          orderPayment: false,
          transactionsGuid: [data.TransactionGuid],
          amount: data.FirstInstallmentAmount,
          paymentMethodId: 0,
          showPaymentSetupPage: true,
          canPayWithSDD: data.CanPayWithSDD,
          ChangedMethod: undefined,
          successData: undefined,
          lender: data.LenderName,
        });
      },
      onError: (error: any) => {
        const errorResponse = error.response as AxiosResponse;
        if (errorResponse?.status === badRequest_400) {
          for (const key in errorResponse.data.errorInfos) {
            if (key === 'generic') {
              switch (errorResponse.data.errorInfos[key]) {
                case 'error.paymentAlreadyPaid':
                  navigate('/thankyou-page-error/link-already-paid');
                  break;
                case 'error.expiredLink':
                  navigate('/thankyou-page-error/expired-link');
                  break;
                case 'error.canceledLink':
                  navigate('/thankyou-page-error/canceled-link');
                  break;
                default:
                  navigate(RoutePath.oops);
                  break;
              }
            }
          }
        }
      },
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    setValues(resetRegistrationStore);
    setUser({ Email: '', Mobile: '' });
  }, []);

  const {
    formatters: { formatCurrency },
  } = useI18n();

  if (isError) {
    return <Navigate to="/thankyou-page-error/expired-link" />;
  }
  if (isFetching) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const resultData = data?.data?.ResultSet;
  const paymentIntent: boolean | undefined =
    resultData?.FirstInstallmentToBePaid;
  const nextInstallment: boolean = resultData?.NextInstallmentAmount !== null;
  const canPayWithSDD: boolean | undefined = resultData?.CanPayWithSDD;

  const nextAmountToBePaid = paymentIntent
    ? formatCurrency(resultData?.NextInstallmentAmount ?? 0)
    : formatCurrency(resultData?.FirstInstallmentAmount ?? 0);
  const nextPaymentDueDate = paymentIntent
    ? refactorFormatDate(
        resultData?.NextInstallmentDueDate ?? '',
        'ddd. DD MMMM',
      )
    : refactorFormatDate(
        resultData?.FirstInstallmentDueDate ?? '',
        'ddd. DD MMMM',
      );

  const btnPaymentDueDate = refactorFormatDate(
    resultData?.FirstInstallmentDueDate ?? '',
    'DD/MM/YYYY',
  );

  if (resultData) {
    setTransactions({
      paymentIntent: paymentIntent,
    });
  }

  const userRegistered = resultData?.BuyerRegistrationStatus === 'registered';

  return (
    <>
      <div className="bg-white">
        <div className="container">
          {!resultData?.BuyerHasPaymentMethodBackup && (
            <div className="bg-white" style={{ paddingTop: '0.1rem' }}>
              <AlertComponent
                variant={'informative'}
                descriptionLbl={'text.infoBackupPayment'}
              />
            </div>
          )}

          <HeaderPurchaseFlow
            titleMerchant={resultData?.MerchantName ?? ''}
            titlePrice={formatCurrency(resultData?.FirstInstallmentAmount ?? 0)}
            date={resultData?.OrderExpirationDate ?? ''}
            paymentIntent={paymentIntent ?? false}
          />
          {(nextInstallment || !paymentIntent) && (
            <div className="mx-auto grid max-w-oxl gap-4">
              <PaySuccessCard
                icon={StepJumper}
                lbl="text.rataCount"
                value={resultData?.InstallmentsNumber ?? 0}
                note={
                  nextInstallment && !resultData?.OrderIsFlex ? (
                    <Translate id="text.everyThirtyDays" />
                  ) : undefined
                }
              />
              {nextInstallment && (
                <PaySuccessCard
                  icon={Calendar}
                  lbl={
                    paymentIntent
                      ? 'text.nextRata'
                      : 'text.firstInstallmentPayment'
                  }
                  value={nextAmountToBePaid}
                  note={nextPaymentDueDate}
                />
              )}
              <PaySuccessCard
                icon={IconBill}
                lbl={nextInstallment ? 'text.total' : 'text.import'}
                value={formatCurrency(resultData?.OrderAmount ?? 0)}
              />
              {!nextInstallment && !paymentIntent && (
                <PaySuccessCard
                  icon={Calendar}
                  lbl="text.paymentDate"
                  value={refactorFormatDate(
                    resultData?.FirstInstallmentDueDate ?? '',
                  )}
                />
              )}
              {resultData?.OrderDescription && (
                <div className="w-full rounded-xl border border-pblack-100 bg-bgreen p-4">
                  <P bold>
                    <Translate id="lbl.description" />
                  </P>
                  <P colorBlack>{resultData?.OrderDescription}</P>
                </div>
              )}
            </div>
          )}
          <button
            className="mx-auto mb-8 mt-4 block w-fit cursor-pointer underline"
            onClick={() => setShowModal(true)}
          >
            <P colorBlack>
              <Translate id="lbl.currencyModalButton" />
            </P>
          </button>
          <div className="flex">
            {logged ? (
              <ButtonContainer marginBottom="6.4rem" marginTop="2.4rem">
                <Button
                  i18n
                  type="submit"
                  variant="Primary"
                  translatedText={'lbl.next'}
                  onClick={() => navigate(RoutePath.welcomePageLogged)}
                />
              </ButtonContainer>
            ) : (
              <ButtonContainer marginBottom="6.4rem" marginTop="2.4rem">
                <Button
                  type="submit"
                  variant="Primary"
                  onClick={() =>
                    navigate('/is-your-company', {
                      state: {
                        creditAssignment: withCreditAssignment,
                        login: userRegistered,
                        guest: resultData?.BuyerRegistrationStatus === 'guest',
                      },
                    })
                  }
                >
                  <Translate
                    id={
                      userRegistered && paymentIntent
                        ? 'lbl.login&pay'
                        : !userRegistered && paymentIntent
                          ? 'lbl.register&pay'
                          : userRegistered && !paymentIntent
                            ? 'lbl.loginPayDate'
                            : !userRegistered && !paymentIntent
                              ? 'lbl.registerPayDate'
                              : 'lbl.register&pay'
                    }
                    data={{ date: btnPaymentDueDate }}
                  ></Translate>
                </Button>
                {!userRegistered && (
                  <Button
                    type="submit"
                    variant="Tertiary"
                    onClick={() =>
                      navigate(
                        RoutePath.registrationLight + RoutePath.stepCheckData,
                      )
                    }
                    i18n
                    translatedText="lbl.continueGuest"
                  />
                )}
              </ButtonContainer>
            )}
          </div>
        </div>
      </div>
      <div
        className="container mb-32 mt-16 bg-bgray data-[registered=true]:mb-16 lg:mb-16"
        data-registered={userRegistered}
      >
        <div className="my-10">
          <H4 light marginBottom="1.6rem">
            <Translate id="text.howDoesOPLworks" />
          </H4>
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            {nextInstallment ? (
              <HowDoesItWorkCard
                title="text.titlepay0interest"
                subTitle="text.pay0interest"
                icon={Percentage}
              />
            ) : null}
            {nextInstallment ? (
              <HowDoesItWorkCard
                title="text.installmentAmount"
                subTitle="text.toBePayedEveryThirtyDays"
                icon={RoundStepper}
              />
            ) : null}
            <HowDoesItWorkCard
              title="text.payByCard"
              subTitle={'text.allCardAccepted'}
              icon={CreditCard}
              requisition="text.prepaidExcluded"
            />
            {canPayWithSDD && (
              <HowDoesItWorkCard
                title="text.bank"
                subTitle="text.bankLabel"
                icon={Bank}
              />
            )}
            {nextInstallment && (
              <HowDoesItWorkCard
                title="text.autoCharge"
                subTitle="text.forgetAboutFutureRata"
                icon={AutomaticCharge}
              />
            )}
          </div>
        </div>
        <H4 light marginBottom="1.6rem">
          <Translate id="text.howCanItBePayed" />
        </H4>
        <div className="mb-14 flex flex-wrap gap-4">
          {[Visa, Mastercard, PostePay, Amex, Gpay].map((Item, i) => (
            <PaymentMethodWrapper key={i}>
              <Item width="100%" />
            </PaymentMethodWrapper>
          ))}
          {canPayWithSDD && (
            <PaymentMethodWrapper>
              <Sepa width="100%" />
            </PaymentMethodWrapper>
          )}
        </div>

        <H4 light marginBottom="1.6rem">
          <Translate id="text.FAQ" />
        </H4>
        <Accordion />
      </div>
      {showModal && (
        <PurchaseFlowModal
          buttonTitle="text.okUnderstand"
          modalTitle="lbl.currencyModalHeader"
          handleClose={() => setShowModal(false)}
          amount={`${resultData?.OrderAmount}`}
          firstAmount={`${resultData?.FirstInstallmentAmount}`}
          nextInstallment={nextInstallment}
          firstInstallmentToBePaid={
            data?.data.ResultSet.FirstInstallmentToBePaid ?? false
          }
          i18n
        />
      )}
      {!userRegistered && window.innerWidth <= 768 ? (
        <div style={{ marginBottom: '192px' }} />
      ) : (
        <div style={{ marginBottom: '132px' }} />
      )}
    </>
  );
};

export default PurchaseFlow;
