import { useEffect, useRef } from 'react';

export const otp1 = 'oi-1';
export const otp2 = 'oi-2';
export const otp3 = 'oi-3';
export const otp4 = 'oi-4';
export const otp5 = 'oi-5';
export const otp6 = 'oi-6';

export function usePrevious<T>(value?: T) {
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const otpInput1 = 'oi-1';
const otpInput2 = 'oi-2';
const otpInput3 = 'oi-3';
const otpInput4 = 'oi-4';

export type otpInputString =
  | typeof otpInput1
  | typeof otpInput2
  | typeof otpInput3
  | typeof otpInput4;

export default usePrevious;
