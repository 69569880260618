import { Button } from 'src/components/Button/Button.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { useState } from 'react';
import ClientButton from './partial/ClientButton.component';

type Client = {
  Name: string;
  Id: string;
};

type Props = {
  clients: Client[];
  fieldName: string;
};

const ClientsInput = ({ clients, fieldName }: Props) => {
  const maxClients = 8;
  const [showMore, setShowMore] = useState(clients.length <= maxClients);
  const label =
    fieldName === 'Merchants' ? 'lbl.showMoreMerchants' : 'lbl.showMoreClients';
  return (
    <>
      <div data-component="clients-input" className="flex flex-wrap gap-3">
        {clients.map(({ Id, Name }, i) => {
          if (showMore || i < maxClients) {
            return (
              <ClientButton
                key={Id}
                id={Id}
                label={Name}
                fieldName={fieldName}
              />
            );
          }
          return undefined;
        })}
      </div>
      {!showMore && (
        <Button
          variant="LinkPrimary"
          i18n
          underline
          padding="0"
          onClick={() => setShowMore(true)}
          translatedText={label}
          rightChild={<ArrowDown height="2rem" width="2rem" />}
        />
      )}
    </>
  );
};

export default ClientsInput;
