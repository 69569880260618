import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import AddUser from 'src/images/icons/add-user.svg?url';
import { ResultPageIcon } from 'src/pages/onboardingFlow/variants/style/OnboardingFlow.style';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { initSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';

function RegistrationCollaboratorLandingPage() {
  const [searchParams] = useSearchParams();
  const roleInviteGuid: string = searchParams.get('roleInviteGuid')!;
  const navigate = useNavigate();
  const setValues = useRegistrationStore((state) => state.setValues);
  const setValue = useRegistrationStore((state) => state.setValue);
  const { language } = useLanguage();
  const { mutate } = useMutation(initSignUpSessionMethodApi, {
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      navigate(RoutePath.registrationCollaborator + RoutePath.phoneStep);
    },
  });
  return (
    <>
      <ResponsiveContainer padding="0 0 6rem">
        <Flex margin="4.2rem auto" justify="center">
          <ResultPageIcon src={AddUser} />
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          style={{ textAlign: 'center' }}
        >
          <TextDefaultPage
            title="text.startCreateAccountTitle"
            subTitle="text.startCreateAccountSubTitle"
          />
        </Flex>
        <ButtonContainer backgroundColor="white">
          <Button
            onClick={() => {
              setValues(resetRegistrationStore);
              mutate({
                AccountType: 'MERCHANT',
                RoleInviteGuid: roleInviteGuid,
                Language: language,
                RegistrationType: 'full',
              });
            }}
            variant="Primary"
          >
            <Translate id="lbl.createAccountOpyn" />
          </Button>
        </ButtonContainer>
      </ResponsiveContainer>
    </>
  );
}

export default RegistrationCollaboratorLandingPage;
