import { DataFormPayments } from 'src/pages/paymentSummary/PaymentSummary.types';
import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  getTransactionsEndPoint,
  getTransactionDetailsEndPoint,
  getPlafondsEndPointMerchant,
  getTransactionsInitialEndPoint,
} from './transactions.config';

export const getTransactionsInitialDataMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getTransactionsInitialEndPoint,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};

export const getTransactionsMethodApi = async (data: DataFormPayments) => {
  const { sort, ...obj } = data;
  const requestConfig = makeEndpointApiConfig({
    url: getTransactionsEndPoint,
    method: 'POST',
    authenticated: true,
    data: { ...obj, sortBy: sort.sortBy, sortDirection: sort.sortDirection },
  });
  return await apiRequest.request(requestConfig);
};

export const getTransactionDetailsMethodApi = async (
  idTransazione: string | number,
  oldMethod: number | null,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getTransactionDetailsEndPoint,
    method: 'GET',
    authenticated: true,
    params: {
      idTransazione: idTransazione,
      oldMethod: oldMethod,
    },
  });
  return await apiRequest.request(requestConfig);
};

export const getPlafondsMerchantMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getPlafondsEndPointMerchant,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
