import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import styled from 'styled-components';

export const InfoLinkContainer = styled.div`
  border-radius: 0.8rem;
  padding: 1.6rem 1.2rem;
  border: 1px solid ${colors.borderbgBtnPressedSecondary};
  width: 100%;
  ${P} {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    color: ${colors.textColorBlack};
  }
`;

export const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;
