import FormOtpEmail from 'src/pages/registration/partials/FormOtpEmail/FormOtpEmail.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationGuestStepOtpEmail: React.FC = () => {
  return (
    <>
      <FormOtpEmail
        nextPath={RoutePath.registrationGuest + RoutePath.passwordStep}
        step={5}
        stepMax={7}
      />
    </>
  );
};

export default RegistrationGuestStepOtpEmail;
