import appTheme from 'src/appTheme';
import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { SessionProvider } from 'src/services/session/session.context';
import { SessionReducerShape } from 'src/services/session/session.types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nProvider } from 'src/services/i18n/i18n.context';

export type AppProvidersProps = {
  children?: ReactNode;
  sessionInfo: SessionReducerShape;
};

export type AppProvidersState = {
  basename: string;
};

class AppProviders extends React.PureComponent<
  AppProvidersProps,
  AppProvidersState
> {
  render() {
    const { children, sessionInfo } = this.props;
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: false,
        },
      },
    });

    return (
      <SessionProvider {...sessionInfo}>
        <ThemeProvider theme={appTheme}>
          <QueryClientProvider client={queryClient}>
            <I18nProvider>
              <>{children}</>
            </I18nProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SessionProvider>
    );
  }
}

export default AppProviders;
