import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { usePayByLinkStore } from 'src/store/store';
import { P } from 'src/style-utils/typographic';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createArrayCustomInstallment } from 'src/utils/functions/createArrayCustomInstallment';
import { RoutePath } from 'src/routers/routers.config';
import StepSelectTemplatesModalLoadable from './modal/StepSelectTemplatesModal.loadable';
import { Template } from 'src/services/template/template.types';
import { FlowContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { findMinMax } from '../StepCustomInstallmentForm/StepCustomInstallmentForm.helpers';

function StepSelectTemplates() {
  const {
    templates,
    amount,
    setValue,
    remodulation,
    customInstallments: oldCustomInstallments,
  } = usePayByLinkStore((state) => state);
  const [modalOpen, setModalOpen] = useState(false);
  const { productCode, categoryCode } = useParams();
  const setAllInstallment = (template: Template) => {
    const { min, max } = findMinMax(template.Installments);
    const customInstallments = {
      Code: 'BNPL-CUSTOM-RATE',
      FlagCessioneCredito: 0,
      NumRate: template.Installments.length,
      Installments: createArrayCustomInstallment(
        amount,
        template.Installments.length,
        remodulation,
        template.Installments,
      ),
      TemplateName: template.ProductTemplateName,
      InstallmentMinAmount: min,
      InstallmentMaxAmount: max,
      CanGiveCreditAtCreation: oldCustomInstallments?.CanGiveCreditAtCreation,
      UwMandatory: oldCustomInstallments?.UwMandatory,
    };
    setValue('customInstallments', customInstallments);
  };
  const navigate = useNavigate();
  const selectTemplate = (template: Template) => {
    setValue('templateSelected', true);

    setAllInstallment(template);
    navigate(
      `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCustomInstallmentForm}`,
      {
        state: {
          templateName: template.ProductTemplateName,
          NumRate: template.Installments.length,
        },
      },
    );
  };
  return (
    <>
      <PayByLinkHeader
        stepBack
        step={5}
        stepMax={10}
        title="lbl.savedTemplates"
      />
      <FlowContainer padding="0 0 2rem">
        <P colorBlack marginBottom="3.2rem">
          <Translate id="text.savedTemplates" />
        </P>
        <Flex flexDirection="column" gap="1.6rem">
          {templates.map((template: Template, index) => (
            <PayByLinkChoiceCard
              key={index}
              customTitle={template.ProductTemplateName}
              textLinkAction="lbl.showInstallmentPlan"
              onClick={() => selectTemplate(template)}
              openModal={setModalOpen}
              linkAction={() => setAllInstallment(template)}
            />
          ))}
        </Flex>
      </FlowContainer>
      {modalOpen && (
        <StepSelectTemplatesModalLoadable
          buttonTitle="text.okUnderstand"
          modalTitle="lbl.installmentDetail"
          handleClose={() => setModalOpen(false)}
          i18n
        />
      )}
    </>
  );
}

export default StepSelectTemplates;
