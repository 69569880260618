import { Resolver } from 'react-hook-form';
import { validateEndDate } from 'src/utils/validation/date';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import { DataFormPaymentsBuyerArea } from './BuyerArea.types';

export const takePayments = 30;

export const searchCriteriaPaymentsFormConfig = () => {
  const initialValues: DataFormPaymentsBuyerArea = {
    skip: 0,
    take: takePayments,
    sort: { sortBy: 'date', sortDirection: 'desc' },
    Statuses: ['0', '3'],
    Merchants: [],
    ToDate: '',
    FromDate: '',
  };

  const validate = (values: any) => {
    return validateFormSync({
      ToDate: validateFieldSync(validateEndDate(values.FromDate)),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};

export const DataFormAmounts = [
  { From: 0, To: 1000 },
  { From: 1001, To: 2000 },
  { From: 2001, To: 3000 },
  { From: 3001, To: 5000 },
  { From: 5001, To: 10000 },
  { From: 10000, To: 9999999 },
];
