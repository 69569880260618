import Translate from 'src/components/Translate/Translate.component';
import { PSmall } from 'src/style-utils/typographic';

type Props = {
  bold?: boolean;
};

const DividerOr = ({ bold }: Props) => {
  return (
    <div data-component="divider-or" className="my-6 flex w-full items-center">
      <hr className="w-full border-b border-pgrey" />
      <PSmall bold={bold} className="px-2">
        <Translate id="lbl.or" />
      </PSmall>
      <hr className="w-full border-b border-pgrey" />
    </div>
  );
};

export default DividerOr;
