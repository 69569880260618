import { createBrowserHistory } from '@remix-run/router';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { FlowContainer, ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';
import { IDs } from 'src/utils/config/ids';

export const ContainerQrCode = styled.div`
  background-color: ${colors.textColorBlack};
  border-radius: 1.6rem;
  padding: 2.4rem;
  text-align: center;
  width: fit-content;
  margin: 3.2rem auto 4rem;
`;

function ShowQrCode() {
  const navigate = useNavigate();
  const { setValue, qrCode, orderId, templateSelected } = usePayByLinkStore(
    (state) => state,
  );
  const location = useLocation();
  const CanSaveTemplate = location?.state?.CanSaveTemplate;
  useEffect(() => {
    setValue('stateFlow', false);
  }, []);
  const history = createBrowserHistory();

  return (
    <>
      <ResponsiveContainer>
        <Button
          variant="LinkPrimary"
          padding="0"
          minWidth="fit-content"
          onClick={() => {
            history.go(-1);
          }}
          fontWeight="400"
          leftChild={<Icon iconSize="4rem" svgIconComponent={Back} />}
        >
          <Translate id="text.linkDetail" />
        </Button>
      </ResponsiveContainer>
      <FlowContainer>
        <TextDefaultPage title="lbl.showQrCode" subTitle="text.showQrCode" />
        <ContainerQrCode>
          <Icon
            iconSize={'27.9rem'}
            iconHeight={'27.9rem'}
            iconSrc={'data:image/png;base64, ' + qrCode}
          />
        </ContainerQrCode>

        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            onClick={() => {
              navigate(RoutePath.linkDiPagamento);
            }}
            id={IDs.btnAllLinks}
          >
            <Translate id="lbl.showAllPayByLink" />
          </Button>
        </ButtonContainer>
      </FlowContainer>
      {CanSaveTemplate && !templateSelected && (
        <Toast
          variant="info"
          title="text.saveTemplateTitle"
          subTitle="text.saveTemplateSubtitle"
          buttonAction={() => {
            setValue('stateFlow', true);
            navigate(RoutePath.saveTemplate, { state: { orderId } });
          }}
          buttonText="lbl.saveTheTemplate"
        />
      )}
    </>
  );
}

export default ShowQrCode;
