/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext } from 'react-hook-form';
import { ClientButtonSelection } from '../style/ClientsInput.style';

interface Props {
  label: string;
  id: string;
  fieldName: string;
}

const ClientButton = ({ label, id, fieldName }: Props) => {
  const { register } = useFormContext();

  return (
    <ClientButtonSelection htmlFor={id}>
      <input type="checkbox" id={id} value={id} {...register(fieldName)} />
      <span>{label}</span>
    </ClientButtonSelection>
  );
};

export default ClientButton;
