import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const ToastContainer = styled(Card)`
  text-align: left;
  border-radius: 0.8rem;
  overflow: visible;
  gap: 1.2rem;
  outline: 1px solid ${colors.greyBorder};
  display: flex;
  ${media.tablet`
    padding: 1.6rem;
  `}
  padding: 1.6rem 1.2rem;
  color: ${colors.textColorBlack};
`;
