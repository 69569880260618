import styled from 'styled-components';

import {
  withPadding,
  withMargin,
  WithMarginProps,
  WithPaddingProps,
  withDesktopPadding,
  WithDesktopPaddingProps,
} from '../style-utils/dimensionsMixins';
import createExtractCssFromProps from '../style-utils/createExtractCssFromProps';
import media from 'src/style-utils/media-queries';

export type FlexProps = WithMarginProps &
  WithDesktopPaddingProps &
  WithPaddingProps & {
    inline?: boolean;
    justify?: CSSJustifyContent;
    align?: CSSAlignItems;
    alignContent?: CSSAlignContent;
    flexDirection?: CSSFlexDirection;
    flexWrap?: CSSFlexWrap;
    fluid?: boolean;
    fluidHeight?: boolean;
    hidden?: boolean;
    overflow?: CSSOverflow;
    backgroundStyle?: string;
    gap?: string;
    flex?: string;
    responsive?: boolean;
    maxWidth?:
      | CSSGlobalRuleValue
      | 'fit-content'
      | 'max-content'
      | 'min-content'
      | string;
  };

/**
 * Renders a flexbox container whose flex properties
 * can be controlled via props.
 * Use "as" prop to change the rendered "Element".
 * @see https://styled-components.com/docs/api#as-polymorphic-prop
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout
 */
export const Flex = styled.div<FlexProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  ${withPadding};
  ${withMargin};
  ${({ gap }) => (gap ? `gap: ${gap}` : null)};
  ${({ backgroundStyle }) =>
    backgroundStyle ? `background: ${backgroundStyle}` : null};
  ${({ flex }) => (flex ? `flex: ${flex}` : null)};
  ${({ fluid, fluidHeight }): string => {
    let output: string = '';

    if (fluid) {
      output += 'width: 100%;';
    }

    if (fluidHeight) {
      output += 'height: 100%;';
    }

    return output;
  }};
  ${({ responsive }) => responsive && 'flex-direction: column;'}
  ${({ responsive }) => responsive && media.tablet`flex-direction: row;`}
  ${createExtractCssFromProps({
    justify: 'justify-content',
    align: 'align-items',
    alignContent: 'align-content',
    flexDirection: 'flex-direction',
    flexWrap: 'flex-wrap',
    overflow: 'overflow',
    maxWidth: 'max-width',
  })}

  ${withDesktopPadding};
`;
Flex.defaultProps = {
  inline: false,
};

if (process.env.NODE_ENV !== 'production') {
  Flex.displayName = 'Flex';
}
