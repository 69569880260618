import { RoutePath } from 'src/routers/routers.config';
import { UsageSettingArrayType } from './usageSetting.types';
import { RoleEnum } from '../../utils/types/common.types';

export const UsageSettingArray = (Role: string): UsageSettingArrayType => [
  {
    path: RoutePath.usageSettingOptions,
    text: 'text.linkPaymentWAdvPayment',
    divider: true,
  },
  {
    path: RoutePath.usageSettingSavedTemplates,
    text: 'text.savedTemplates',
    divider: Role === RoleEnum.owner,
  },
  ...(Role === RoleEnum.owner
    ? [
        {
          path: RoutePath.loginStripe,
          text: 'lbl.goToStripe',
          divider: false,
          target: '_blank',
        },
      ]
    : []),
];
