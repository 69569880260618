export const Cash = 'R';
export const ServiceCost = 'FEE';
export const Payout = 'PAYOUT';

export const feeBill = 'Z3';
export const feeAcquiring = 'Z2';
export const invoiceDiscount = 'Z1';

export const configDetailMovementsTitle = {
  [Cash]: {
    label: 'lbl.proceeds',
  },
  [ServiceCost]: {
    label: 'lbl.serviceCost',
  },
  [Payout]: {
    label: 'lbl.withdrawal',
  },
};

export const configFees = {
  [feeBill]: {
    title: 'text.feeBillBalance&Movements',
  },
  [feeAcquiring]: {
    title: 'text.feeAcquiring',
  },
  [invoiceDiscount]: {
    title: 'lbl.debitRate',
  },
};
