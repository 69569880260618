import { SyncFieldValidator, TransformError } from './types';

export const errorMessageMinPrefix = 'error.number.minValue';
export const errorMessageMinNumber = 'error.number.minValueNumber5';

export const errorMessageMaxPrefix = 'error.number.maxValue';

const numberInRange = function numberInRange({
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
} = {}): SyncFieldValidator {
  return function validateLenth(value: any) {
    if (typeof parseInt(value, 10) !== 'number') {
      return errorMessageMinPrefix;
    }

    if (parseInt(value, 10) < min) {
      return `${errorMessageMinPrefix}.${min}`;
    }

    if (parseInt(value, 10) > max) {
      return `${errorMessageMaxPrefix}.${Math.floor(max)}`;
    }

    return undefined;
  };
};

export const minNumber = function minNumber({
  min = 0,
} = {}): SyncFieldValidator {
  return function validateLenth(value: any) {
    if (typeof parseInt(value, 10) !== 'number') {
      return errorMessageMinPrefix;
    }

    if (parseInt(value, 10) < min) {
      return `${errorMessageMinNumber}`;
    }

    return undefined;
  };
};

export const createTransformNumberRangeError =
  function createTransformNumberRangeError(
    formatNumber?: (arg: number) => string,
  ): TransformError {
    return function transformNumberRangeError(error) {
      const output = { id: error } as ReturnType<TransformError>;
      if (
        error.startsWith(errorMessageMinPrefix) ||
        error.startsWith(errorMessageMaxPrefix)
      ) {
        const offset = error.lastIndexOf('.');
        output.id = error.slice(0, offset);
        let value = error.slice(offset + 1);

        if (typeof formatNumber === 'function') {
          value = formatNumber(parseInt(error.slice(offset + 1), 10));
        }

        output.data = {
          value,
        };
      }
      return output;
    };
  };

export const transformNumberRangeError: TransformError =
  createTransformNumberRangeError();

export default numberInRange;
