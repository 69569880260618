import { SyncFieldValidator } from './types';

export const cfRegex = /^[a-zA-Z0-9]{11}(?:[a-zA-Z0-9]{5})?$/;
export const vatRegexLastTwoValue = /^(?=[\w\d]*[a-zA-Z]{2})[\w\d]*$/;

export const cfItalianRegex =
  /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;

export const cfNumericRegex = /^[0-9]{11}/;

export const isCf = (str: string) => {
  return cfRegex.test(str);
};

export const isNumericCf = (str: string) => {
  return cfNumericRegex.test(str);
};

export const isItalianCf = (str: string) => {
  return cfItalianRegex.test(str);
};

export const errorMessageCf = 'error.cf';

export const errorMessage11Cf = 'error.11cf';

export const errorMessage16Cf = 'error.16cf';

const cf: SyncFieldValidator = function cf(value: unknown): string | undefined {
  if (
    typeof value !== 'string' ||
    (value.length === 11 && !isNumericCf(value))
  ) {
    return errorMessage11Cf;
  }
  if (typeof value !== 'string' || !isCf(value)) {
    return errorMessageCf;
  }
  if (
    typeof value !== 'string' ||
    (value.length === 16 && !isItalianCf(value))
  ) {
    return errorMessage16Cf;
  }
  return undefined;
};

export default cf;
