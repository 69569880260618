export interface CopyToClipboard<T extends string> {
  (text: T): Promise<T>;
}

// It is actaully a ponyfill: https://github.com/sindresorhus/ponyfill
// Demo page: https://garron.net/code/clipboard-polyfill/
const lazyLoadedClipboardPonyfill = {
  esModule: null as null | any,
  loadModule: () =>
    import(
      /* webpackChunkName: "polyfill.clipboard" */ 'clipboard-polyfill'
    ).then((esModule) => {
      Object.defineProperty(lazyLoadedClipboardPonyfill, 'esModule', {
        value: esModule,
        writable: false,
      });

      return esModule;
    }),
};

/**
 * Asynchronously copy input 'text' to clipboard.
 * Returns a promise that resolves to the copied string on success.
 * @param text What will be copied to the clipboard.
 * @return A Promise that resolves to the copied text.
 */
export const writeToClipboard = <T extends string>(text: T) => {
  const writeTextInvokeOnlyIfModuleHasBeenLoaded: CopyToClipboard<T> = (
    text: T,
  ) => {
    if (!lazyLoadedClipboardPonyfill.esModule) {
      throw new Error(
        'invalid execution: clipboard ponyfill has not been loaded yet',
      );
    }

    return lazyLoadedClipboardPonyfill.esModule
      .writeText(text)
      .then(() => text);
  };

  if (!lazyLoadedClipboardPonyfill.esModule) {
    return lazyLoadedClipboardPonyfill
      .loadModule()
      .then(() => writeTextInvokeOnlyIfModuleHasBeenLoaded(text));
  }

  return writeTextInvokeOnlyIfModuleHasBeenLoaded(text);
};
