import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import required from 'src/utils/validation/required';
import { Resolver } from 'react-hook-form';
import createPasswordValidation from 'src/utils/validation/password';
import { passwordField } from 'src/services/registration/registration.config';

export const formConfigChangePassword = () => {
  const initialValues = {
    [passwordField]: '',
  };

  const validate = (values: any) => {
    return validateFormSync({
      [passwordField]: validateFieldSync(required, createPasswordValidation()),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
