import { Resolver } from 'react-hook-form';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const variantRadioBox = 'variant-radio-box';
export const InstallmentConfigForm = (InstallmentCode: string) => {
  const initialValues = {
    [variantRadioBox]: InstallmentCode,
  };

  const validate = validateFormSync({
    [variantRadioBox]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
