import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Returns the current search params as `{[key: string]; value}`.
 */
export default function useQueryParams() {
  const { search } = useLocation();

  const queryParams: Record<string, string> = React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);

    return Object.fromEntries(urlSearchParams.entries());
  }, [search]);

  return queryParams;
}
