export const initOrderCreate = 'Order/InitOrderCreationSession';
export const performOrderCreation = 'Order/PerformOrderCreationSession';
export const canGiveCreditForOrderCreation =
  'Order/CanGiveCreditForOrderCreationSession';
export const getCompanySearchCriteria = 'Account/GetCompanySearchCriteria';
export const createOrder = 'Order/CreateOrder';
export const getBuyers = 'Merchant/GetBuyersWithMovementsAndPlafondForMerchant';
export const payOrderLinkEndPoint = 'Order/GetLink';
export const stripeCreatePaymentIntent = 'Stripe/CreatePaymentIntent';
export const generateQrCode = 'Order/GenerateQrCode';
export const sendLinkToBuyer = 'Order/SendLinkToBuyer';
export const setOrderStatus = 'Order/setOrderStatus';
export const linkWrong = 'Order/SetLinkErrato';
export const getRatePlans = 'Merchant/GetRatePlans';
export const getEligibleOrders = 'Order/GetEligibleOrders';
export const orderGiveCredit = 'Order/GiveCredit';
export const setBuyerFlagCredit = 'Order/SetBuyerFlagCredit';
export const orderRenew = 'Order/Renew';
export const UpdateInvoice = 'Order/UpdateInvoice';
export const createAdvanceRequest = 'Order/CreateAdvanceRequest';
export const performAdvanceRequest = 'Order/PerformAdvanceRequest';
export const getLastInviteEmail = 'Account/GetLastInviteEmail';
export const getCompanyInfo = 'Buyer/GetCompanyInfo';
export const searchCompanyInfo = 'Account/SearchCompanyInfo';
export const performEvaluateBuyer = (orderGuid: string) =>
  `Order/EvaluateBuyer?orderGuid=${orderGuid}`;

//Products
export const getAvailableProducts = 'Merchant/GetProducts';
export const getVariantsWithPlan = 'Merchant/GetVariantsWithPlan';
export const setInvoiceDateAndFile = 'Order/SetInvoiceForOrderCreationSession';
export const editPlan = 'Order/EditPlan';
export const getEditPlanRequests = 'Merchant/GetEditPlanRequests';

export const cfField = 'cf-field';
export const descriptionField = 'description-field';
export const orderImport = 'order-import';
export const orderEmail = 'order-email';
export const orderBuyerName = 'order-buyer-name';
export const sendEmail = 'send-email';
export const flagCreditAssignment = 'flag-credit-assignment';
export const paymentCodeType = 'payment-type-code';
export const addressBuyer = 'address-buyer';
export const installmentNumber = 'installment-number';
export const installmentsArray = 'installments-arrays';
export const recipient = 'recipient';
export const invoiceDateField = 'invoice-date-field';
export const invoiceFileField = 'invoice-file-field';
export const invoiceHiddenInvoiceFileNameField =
  'invoice-hidden-file-name-field';
export const startDateField = 'start-date-field';
export const shippingDateField = 'shippingDate';
