import FormPassword from 'src/pages/registration/partials/FormPassword/FormPassword.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationStepPassword: React.FC = () => {
  return (
    <FormPassword
      nextPath={RoutePath.registrationMerchant + RoutePath.flagStep}
      step={8}
      stepMax={9}
    />
  );
};

export default RegistrationStepPassword;
