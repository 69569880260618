import colors from 'src/style-utils/colors';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const DocumentCardContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 1.4rem 0;
  background: ${colors.white};
  box-shadow: 0 0.2rem 0.4rem 0.1rem rgba(0, 59, 92, 0.12);
  border-radius: 1.2rem;
  padding: 1.6rem 1.2rem;
`;

export const WrapperTextPdf = styled(Flex)`
  gap: 1.2rem;
  justify-content: space-between;
  align-items: center;
`;
export const DocumentName = styled(P)`
  margin: 0;
  font-weight: 700;
  color: ${colors.textColorBlack};
  line-height: 1.6rem;
`;
export const DocumentDate = styled(PSmall)`
  margin: 0;
  font-weight: 400;
  color: ${colors.textColorGrey};
`;
