import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import media from 'src/style-utils/media-queries';

export const BodyContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 0 2.4rem;
  margin: 2.4rem auto 4rem auto;
  gap: 3.2rem;
  ${media.tablet`
  width: 60rem;
  padding: 0 2.4rem;
`}
`;
export const IconContainer = styled(Flex)`
  margin: auto;
`;

export const CopyContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin: auto;
  text-align: center;

  ${media.tablet`
  width: 56rem;
 `}
`;

export const ContentBodyContainer = styled(Flex)`
  width: 32.7rem;
  gap: 0.4rem;
  margin: auto;
  flex-direction: column;

  ${media.tablet`
    width: 56rem;
    gap: 1.6rem;
  `}
`;

export const OrderDetailTitleContainer = styled(Flex)`
  width: 100%;
  gap: 0.8rem;
  flex-direction: column;
  margin: auto auto 2.4rem auto;

  ${media.tablet`
    width: 84.8rem;
  `}
`;

export const OrderDetailCardContainer = styled(Flex)`
  width: 100%;
  gap: 1.6rem;
  margin: auto;
  flex-direction: column;

  ${media.tablet`
    width: 63.6rem;
  `}
`;
