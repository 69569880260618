import {
  SupportedLanguageCode,
  SupportedCurrency,
  SupportedLanguageKey,
} from './i18n.types';

export const fallbackLng: SupportedLanguageCode = 'it-IT';

export const currencyCode: SupportedCurrency = 'EUR';

export const languagesByCode: Record<
  SupportedLanguageKey,
  { name: string; code: string }
> = {
  'it-IT': { name: 'italian', code: 'it-IT' },
  'en-GB': { name: 'english', code: 'en-GB' },
};

export const supportedLngs: SupportedLanguageCode[] = [
  'en-GB',
  'it-IT',
  'it',
  'en',
];

export const DefaultRequestId = 'fdb39d0e-2b6e-4e62-b171-cc18dcec6dfe';

export const languageLocalizationsEndpoint =
  '/dictionary-service/ms/dictionary/getLoginLabels';
