import { Button } from 'src/components/Button/Button.component';
import { IconButton } from 'src/components/Button/IconButton.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { useState } from 'react';
import { OrderStats } from 'src/services/monitoring/monitoring.types';
import { PSmall } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import MonitoringDetailModalComponent from '../modal/MonitoringDetailModal.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import Translate from 'src/components/Translate/Translate.component';
import { Flex } from 'src/style/flexbox.style';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Star } from 'src/images/icons/star.svg';

export const linksTableColumnConfig = [
  {
    id: 'Id',
    header: 'lbl.number',
    RenderComponent: (props: OrderStats) => {
      return (
        <>
          <Flex flexDirection="column">
            {window.innerWidth < 1024 && (
              <PSmall>
                <Translate id="lbl.number" />
              </PSmall>
            )}

            <PSmall colorBlack>#{props.Id.toString().padStart(5, '0')}</PSmall>
          </Flex>
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'FirstInstallmentDate',
    header: 'text.rataPlanning',
    RenderComponent: (props: OrderStats) => {
      return (
        <PSmall colorBlack>
          <Translate id="text.startPeriod" />{' '}
          {refactorFormatDate(props.FirstInstallmentDate, 'DD/MM/YYYY')}{' '}
          {window.innerWidth >= 1024 && <br />}
          <Translate id="text.endPeriod" />{' '}
          {refactorFormatDate(props.LastInstallmentDate, 'DD/MM/YYYY')}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'Amount',
    header: 'lbl.import',
    RenderComponent: (props: OrderStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <PSmall bold colorBlack>
          {formatCurrency(props.Amount)}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'InstallmentsPaid',
    header: 'lbl.installmentsPaid',
    RenderComponent: (props: OrderStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <PSmall colorBlack>{formatCurrency(props.InstallmentsPaid)}</PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'InstallmentsCreated',
    header: 'lbl.installmentsToBePaid',
    RenderComponent: (props: OrderStats) => {
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <>
          <Flex>
            <PSmall colorBlack>
              {formatCurrency(props.InstallmentsCreated)}
            </PSmall>
            {props.InstallmentsProcessing > 0 && (
              <Icon
                svgIconComponent={Star}
                margin="auto 0"
                iconSize="1.6rem"
                iconHeight="1.6rem"
                marginLeft="0.4rem"
                color="black"
              />
            )}
          </Flex>
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'InstallmentsOverdue',
    header: 'lbl.overdueInstallments',
    RenderComponent: (props: OrderStats) => {
      const expired = props.InstallmentsOverdue > 0;
      const {
        formatters: { formatCurrency },
      } = useI18n();
      return (
        <PSmall
          className={expired ? 'font-bold text-danger' : 'text-pblack-400'}
          data-info={expired ? 'danger' : undefined}
        >
          {formatCurrency(props.InstallmentsOverdue)}
        </PSmall>
      );
    },
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'cta',
    RenderComponent: (props: OrderStats) => {
      const [showModal, setShowModal] = useState(false);
      return (
        <>
          <IconButton
            onClick={() => setShowModal(true)}
            className="hideOnMobile"
            IconSrc={ArrowRight}
            variant="LinkPrimary"
            sizeType="medium"
          />
          <Button
            onClick={() => setShowModal(true)}
            className="hideOnDesktop"
            variant="Tertiary"
            minWidthMobile="100%"
            margin="1.6rem 0 0 0"
            i18n
            translatedText="lbl.installmentDetail"
          />
          {showModal && (
            <MonitoringDetailModalComponent
              buttonTitle="lbl.close"
              modalTitle="lbl.installmentDetail"
              data={props.Installments}
              handleClose={() => setShowModal(false)}
              i18n
            />
          )}
        </>
      );
    },
    rowCellConfig: {
      w: '100%',
      maxWDesktop: '68px',
    },
  },
];
