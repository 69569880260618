import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Alert } from 'src/images/icons/input_error.svg';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import React from 'react';
import colors from 'src/style-utils/colors';
import { H5, PNote } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

type Props = {
  last4?: number | string;
  payIcon?: string;
  dueDate?: string;
  expired?: boolean;
  country?: string;
  type?: PaymentMethodResult['Type'];
};

const PaymentMethodDetail: React.FC<Props> = ({
  last4,
  dueDate,
  payIcon,
  expired,
  country,
  type,
}) => {
  return (
    <Flex data-component="payment-method-detail" align="center" gap="1.6rem">
      <Icon iconSrc={payIcon} iconSize="2.8rem" iconHeight="2.8rem" />
      <div>
        <H5>
          {type === 'sepa_debit' && country + ' '}
          &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
          &bull;&bull;&bull;&bull; {last4}
        </H5>
        {type !== 'sepa_debit' && (
          <Flex align="center">
            {expired && <Alert width="2rem" height="2rem" />}
            <PNote textColor={expired ? colors.redInput : undefined}>
              <Translate id="text.dueDate" /> {dueDate}
            </PNote>
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default PaymentMethodDetail;
