import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import {
  PayByLinkDetailsContext,
  resendLimit,
} from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { ContainerPadding, Grid } from 'src/style/Container.style';

const PayByLinkActions = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { OrderId, InviteEmailsSent, pathDetail } =
    useOutletContext<PayByLinkDetailsContext>();
  const navigateToSendLinkNow = () => {
    navigate(
      `${pathDetail}${OrderId}/${RoutePath.actionsSendLinkNow}?lang=${language}`,
    );
  };
  const navigateToShowQRCode = () => {
    navigate(
      `${pathDetail}${OrderId}/${RoutePath.actionsShowQrcode}?lang=${language}`,
    );
  };
  const resendLink = () => {
    navigate(
      `${pathDetail}${OrderId}/${RoutePath.actionsResendLink}?lang=${language}`,
    );
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        title="text.howToReportLink"
        idLink={OrderId}
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <Grid gap="2.4rem">
          {InviteEmailsSent.length !== 0 && (
            <PayByLinkChoiceCard
              title="text.sendAgainPaymentMail"
              subTitle="text.justOneTimeResend"
              onClick={resendLink}
              disabled={InviteEmailsSent.length >= resendLimit}
            />
          )}
          <PayByLinkChoiceCard
            title="text.sendAgainPaymentLink"
            subTitle="text.youMustReportYourClient"
            onClick={navigateToSendLinkNow}
          />
          <PayByLinkChoiceCard
            title="text.viewQRcode"
            subTitle="text.clientMustScanQRcode"
            onClick={navigateToShowQRCode}
          />
        </Grid>
      </ContainerPadding>
    </>
  );
};

export default PayByLinkActions;
