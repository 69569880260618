import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import { BadgeStatusContainerProps } from '../BadgeStatus.types';

export const BadgeStatusContainer = styled(Flex)<BadgeStatusContainerProps>`
  width: max-content;
  height: 2.4rem;

  border-radius: 0.4rem;
  background: ${({ bg = '#fff' }) => bg};
  color: ${({ color = '#fff' }) => color};
  border: 0.1rem solid ${({ colorBorder = '#fff' }) => colorBorder};
  & > span {
    font-weight: 400;
    font-size: 1rem;
  }
`;
