import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import Loader, {
  CreditWorthinessBody,
} from 'src/components/Loader/Loader.component';
import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { OrderEditPlanStatuses } from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import {
  evaluateBuyer,
  orderRenewMethodApi,
  setOrderStatusMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { ContainerPadding, Grid } from 'src/style/Container.style';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { getUWBuyer } from 'src/services/UWBuyer/UWBuyer.config';
import { getUWBuyerMethodApi } from 'src/services/UWBuyer/UWBuyer.request';
import useBeforeUnload from '../../../../utils/hooks/useBeforeUnload';

const PayByLinkDetailHelp: React.FC = () => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    OrderId,
    OrderGuid,
    refetchHandler,
    EditPlanAmount,
    Status,
    Installments,
    EditStartINo,
    EditPlanStatus,
    CanBeRenewed,
    CanBeCanceled,
    pathDetail,
    madeFromPlugin,
    CanEvaluateBuyer,
    CanEditPlan,
    EditPlanType,
    Description,
    BuyerName,
  } = useOutletContext<PayByLinkDetailsContext>();
  const showCardRemodulation = CanEditPlan;
  const showCardCheckCreditWorthiness = CanEvaluateBuyer;
  const [polling, setPolling] = useState(false);
  const [idUWBuyer, setIdUWBuyer] = useState<number | undefined>(undefined);
  useBeforeUnload(polling);

  const { mutate, isLoading } = useMutation(
    (values: { OrderGuid: string; Status: StatusPyByLinkValues }) =>
      setOrderStatusMethodApi(values),
    {
      onSuccess: () => {
        navigate(`${pathDetail}${RoutePath.actionsSuccessCanceled}`);
        refetchHandler();
      },
      onError: () => navigate(RoutePath.oops),
    },
  );
  const { mutate: mutateRenew, isLoading: isLoadingRenew } = useMutation(
    (values: any) => orderRenewMethodApi(values),
    {
      onSuccess: () => {
        navigate(`${pathDetail}${OrderId}/${RoutePath.actionsLinkChoice}`);
        refetchHandler();
      },
      onError: () => navigate(RoutePath.oops),
    },
  );

  const { mutate: evaluateBuyerMutation, isLoading: isLoadingEvaluateBuyer } =
    useMutation((values: string) => evaluateBuyer(values), {
      onSuccess: (res) => {
        setIdUWBuyer(res?.data.ResultSet);
        setPolling(true);
      },
      onError: () => {
        navigate(RoutePath.ThankyouPageError + RoutePath.internalError);
      },
    });

  useEffect(() => {
    const timer = setTimeout(pollingTimeout, 4 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [polling]);

  useQuery(getUWBuyer, () => getUWBuyerMethodApi({ uwId: idUWBuyer }), {
    onSuccess: (res) => {
      const Result = res.data.ResultSet.Result;
      const Status = res.data.ResultSet.Status;
      if (Status === 1) {
        setPolling(false);

        const evaluationResults: { [key: string]: string } = {
          KO: 'KO',
          LIGHT_OK: 'LIGHT_OK',
          OK: 'OK',
        };

        const evaluationResult = evaluationResults[Result];

        if (evaluationResult) {
          navigate(
            `${RoutePath.linkDiPagamentoDetail}${OrderId}/${RoutePath.actionsSuccessCheckCreditWorthiness}?EvaluationBuyer=${evaluationResult}`,
          );
        }
      }
    },
    onError: () =>
      navigate(
        `${RoutePath.ThankyouPageError}${RoutePath.internalError}?EvaluateBuyer=genericError&OrderId=${OrderId}`,
      ),
    refetchInterval: 2000,
    enabled: polling,
  });

  const pollingTimeout = () => {
    if (polling)
      navigate(
        `${RoutePath.ThankyouPageError}${RoutePath.internalError}?EvaluateBuyer=genericError&OrderId=${OrderId}`,
      );
  };

  const checkCreditWorthiness = () => {
    evaluateBuyerMutation(OrderGuid);
  };

  const deleteLink = () => mutate({ OrderGuid, Status });

  const navigateToActions = () =>
    navigate(`${pathDetail}${OrderId}/${RoutePath.actionsLinkChoice}`);

  const navigateToSupport = () =>
    navigate(`${pathDetail}${OrderId}/${RoutePath.actionsSupport}`);
  const remodulation = () => {
    setValue(
      'installmentsPaid',
      Installments.filter((item) => item.Number < EditStartINo),
    );
    setValue('Amount', EditPlanAmount);
    setValue('editPlanType', EditPlanType || 'default');
    navigate(`${pathDetail}${OrderId}/${RoutePath.beforeEditPlan}`);
  };
  const remodulationByShippingDate = () => {
    setValue('stateFlow', true);
    setValue('remodulation', true);
    setValue('amount', EditPlanAmount);
    setValue('orderId', OrderId);
    setValue('description', Description);
    setValue('buyerName', BuyerName);
    setValue('editStartINo', EditStartINo);
    setValue('editPlanType', EditPlanType || 'shipping_date');
    setValue(
      'installmentsPaid',
      Installments.filter((item) => item.Number < EditStartINo),
    );
    setValue('installments', Installments);
    navigate(
      `${RoutePath.createLink}FLX/RML/${RoutePath.editPlanShippingDate}`,
    );
    // navigate(`${pathDetail}${OrderId}/${RoutePath.editPlanShippingDate}`);
  };

  const renewLink = () => mutateRenew(OrderId);

  if (isLoading || isLoadingRenew || isLoadingEvaluateBuyer || polling)
    return (
      <Loader overlayViewMode="fullscreen" active viewMode="fluid">
        {(isLoadingEvaluateBuyer || polling) && <CreditWorthinessBody />}
      </Loader>
    );

  return (
    <>
      <PayByLinkHeader
        stepBack
        title="lbl.optionsAvailable"
        idLink={OrderId}
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <Grid gap="2.4rem">
          {showCardCheckCreditWorthiness && (
            <PayByLinkChoiceCard
              title="text.checkCreditWorthinessChoice"
              onClick={checkCreditWorthiness}
            />
          )}
          {showCardRemodulation && !madeFromPlugin && (
            <>
              {EditPlanType === 'default' && (
                <PayByLinkChoiceCard
                  title="text.extendRatePlan"
                  subTitle={
                    EditPlanStatus === OrderEditPlanStatuses.waiting
                      ? 'text.alreadyExtendedRatePlanDescrition'
                      : 'text.extendRatePlanDescrition'
                  }
                  subTitleData={
                    EditPlanAmount ? formatCurrency(EditPlanAmount) : ''
                  }
                  onClick={remodulation}
                  disabled={EditPlanStatus === OrderEditPlanStatuses.waiting}
                />
              )}
              {EditPlanType === 'shipping_date' && (
                <PayByLinkChoiceCard
                  title="text.editPlane.shippingDate"
                  onClick={remodulationByShippingDate}
                  disabled={EditPlanStatus === OrderEditPlanStatuses.waiting}
                />
              )}
            </>
          )}
          {CanBeRenewed && !madeFromPlugin && (
            <PayByLinkChoiceCard
              title="text.renewLink"
              subTitle="text.renewLinkSubtitle"
              onClick={renewLink}
            />
          )}
          {CanBeCanceled && !madeFromPlugin && (
            <>
              <PayByLinkChoiceCard
                title="text.undoPayByLink"
                onClick={() => setModalOpen(true)}
              />
            </>
          )}
          {Status === PayByLinkStatus.createdLink && !madeFromPlugin && (
            <>
              <PayByLinkChoiceCard
                title="text.sendAgainPayByLink"
                onClick={navigateToActions}
              />
            </>
          )}
          <PayByLinkChoiceCard
            title="lbl.iNeedHelp"
            onClick={navigateToSupport}
          />
        </Grid>
      </ContainerPadding>
      {modalOpen && (
        <DoubleChoicePopUp
          handleClose={() => setModalOpen(false)}
          handleSecondAction={deleteLink}
          modalTitle="text.titleAreYouSureUndoLink"
          mainText="text.areYouSureUndoLink"
          icon={AlertIcon}
          firstButtonLabel="lbl.yesUndoLink"
          lastButtonLabel="lbl.close"
        />
      )}
    </>
  );
};

export default PayByLinkDetailHelp;
