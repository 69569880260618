import React, { MouseEventHandler } from 'react';
import { Variant } from './Button.types';
import { IconButtonBase } from './style/IconButton.style';

type SizeVariant = 'small' | 'medium' | 'large';

export type ButtonIconBaseProps = {
  variant?: Variant;
  padding?: string;
  sizeType?: SizeVariant;
  sizeTypeOnDesktop?: SizeVariant;
  pointerEvents?: string;
  display?:
    | 'inline-flex'
    | 'inline-block'
    | 'block'
    | 'flex'
    | CSSGlobalRuleValue;
};

type IconButtonProps = ButtonIconBaseProps & {
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  type?: 'button' | 'submit';
  className?: string;
  id?: string;
  disabled?: boolean;
  IconSrc: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const IconButton: React.FC<IconButtonProps> = ({
  IconSrc,
  ...otherProps
}) => {
  return (
    <IconButtonBase data-component="icon-button" {...otherProps}>
      <IconSrc width={'100%'} />
    </IconButtonBase>
  );
};
