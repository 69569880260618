import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import { collaborators } from 'src/pages/monitoring/Monitoring.config';
import CheckBoxPopover from 'src/pages/monitoring/checkbox/CheckBoxPopover.component';
import { DataFormMonitoringBuyerStats } from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.helpers';
import {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
} from 'react';
import { CollaboratorsStats } from 'src/services/monitoring/monitoring.types';

type CollaboratorsPopoverProps = {
  collaboratorsList?: CollaboratorsStats[];
  submitHandler?: MouseEventHandler | undefined;
  setData: Dispatch<SetStateAction<CollaboratorsStats[] | undefined>>;
  initialData?: CollaboratorsStats[];
  dataFiltered: DataFormMonitoringBuyerStats;
  onCloseHandler?: () => void;
};
export const CollaboratorsPopover: React.FC<CollaboratorsPopoverProps> = ({
  collaboratorsList,
  submitHandler,
  setData,
  initialData,
  dataFiltered,
  onCloseHandler,
}) => {
  const onChangeCollabsHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newList = collaboratorsList?.filter(({ Description }) =>
      Description.toLowerCase().includes(value.toLowerCase()),
    );

    setData(value?.length > 0 ? newList : initialData);
  };
  const count =
    dataFiltered.collaborators.length > 0
      ? dataFiltered.collaborators.length
      : undefined;
  return (
    <SelectPopover
      onChangeHandler={onChangeCollabsHandler}
      submitHandler={submitHandler}
      label="lbl.collaborators"
      countFilters={count}
      onCloseHandler={onCloseHandler}
    >
      {collaboratorsList?.map(({ Description, RoleId }) => (
        <CheckBoxPopover
          key={RoleId}
          fieldName={collaborators}
          label={Description}
          id={RoleId.toString()}
        />
      ))}
    </SelectPopover>
  );
};

export default CollaboratorsPopover;
