import FormFlag from 'src/pages/registration/partials/FormFlag/FormFlag.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationCollaboratorStepFlag: React.FC = () => {
  return (
    <FormFlag
      typeAccount="MERCHANT"
      role="collaborator"
      nextPath={RoutePath.successRegistrationCollaborator}
      step={4}
      stepMax={4}
    />
  );
};

export default RegistrationCollaboratorStepFlag;
