import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { H4, P } from 'src/style-utils/typographic';
import { LandingPageContainer, LandingPageIcon } from './LandingPage.style';
import React from 'react';

interface Props {
  icon: string;
  title: string;
  content?: string;
  buttonLabel?: string;
  buttonClickPath?: string;
  titleParam?: string[];
}

const LandingPage = ({
  title,
  content,
  buttonLabel,
  buttonClickPath = '/',
  titleParam = [],
  icon,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const values: any = {};
  for (const param of titleParam) {
    values[param] = location?.state?.[param] ?? '';
  }

  return (
    <LandingPageContainer
      flex="1"
      flexDirection="column"
      padding="0 2.4rem 4rem"
      gap="1.6rem"
    >
      <LandingPageIcon src={icon} />
      <H4 className="mt-2">
        <Translate id={title} />
      </H4>
      {content !== undefined && (
        <P>
          <Translate id={content} />
        </P>
      )}

      {buttonLabel !== undefined && (
        <ButtonContainer>
          <Button onClick={() => navigate(buttonClickPath)}>
            <Translate id={buttonLabel} />
          </Button>
        </ButtonContainer>
      )}
    </LandingPageContainer>
  );
};

export default LandingPage;
