import { errorMessage } from 'src/utils/validation/required';
import { TransformError } from 'src/utils/validation/types';
import { InputFieldUIStates } from './forms.config';
import { FieldStateReducer, InputFieldStateData } from './forms.types';

export function defaultIsFieldErrorAlert(error: string): boolean {
  return error === errorMessage;
}

/**
 * Reducer of the InputFieldState state machine.
 * @param meta field meta from 'useField()'.
 * @param isFieldErrorAlert Predicate that given an error returns 'true' if it
 * is an alert.
 * @param transformError Transforms the provided error to another shape,
 * used for l10n reasons.
 * @param additionalInfoId l10n id used if the inputFieldState is 'idle'.
 * @param additionalInfoData l10n data of 'additionalInfoId'.
 * @return Current 'InputFieldStateData'.
 */
export function inputFieldStateReducer(
  { error, touched }: FieldStateReducer,
  isFieldErrorAlert: (error: string) => boolean = defaultIsFieldErrorAlert,
  transformError?: TransformError,
  additionalInfoId?: string,
  additionalInfoData?: Record<string, string>,
): InputFieldStateData {
  let output: InputFieldStateData = {
    inputFieldState: InputFieldUIStates.idle,
    messageProps: additionalInfoId
      ? { id: additionalInfoId, data: additionalInfoData }
      : null,
  };

  if (!(typeof error === 'string' && touched)) {
    return output;
  }

  if (isFieldErrorAlert(error)) {
    output.inputFieldState = InputFieldUIStates.alert;
  } else {
    output.inputFieldState = InputFieldUIStates.error;
  }

  if (typeof transformError === 'function') {
    output.messageProps = transformError(error);
  } else {
    output.messageProps = { id: error };
  }
  return output;
}
