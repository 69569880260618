import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { buyerGuid } from 'src/services/login/login.config';
import {
  PreLoginGuestMethodApi,
  SignInSendOtpGuestMethodApi,
} from 'src/services/login/login.request';
import { otp } from 'src/services/otp/otp.config';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import {
  OtpTypeArray,
  VerifySignUpSessionOtpData,
  refreshSignUpSessionOtpMethodApi,
  verifySignUpSessionOtpMethodApi,
} from 'src/services/registration/registration.request';
import { useProfileStore, useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import { formConfigRegistrationStepOtpPhone } from './FormOtpPhone.helpers';

const FormOtpPhone: React.FC<FormProps> = ({
  nextPath,
  step,
  stepMax,
  login,
}) => {
  const sessionGuid: string = useRegistrationStore(
    (state) => state.sessionGuid,
  );
  const guestSignInGuid = useRegistrationStore(
    (state) => state.guestSignInGuid,
  );
  const setValue = useRegistrationStore((state) => state.setValue);
  const mobileNumber = useRegistrationStore((state) => state.mobile);
  const prefixMobile = useRegistrationStore((state) => state.prefix);
  const BuyerGuid = useProfileStore((state) => state.currentOrder?.BuyerGuid);
  const prefixNumber = (
    useRegistrationStore((state) => state.prefix) ?? ''
  ).slice(0, -2);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { initialValues, resolver } = formConfigRegistrationStepOtpPhone();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation(
    (values: any) => verifySignUpSessionOtpMethodApi(values),
    {
      onSuccess: (res) => {
        res.data.ResultSet
          ? navigate(nextPath)
          : setErrorMessage('error.otpNotMatching');
      },
      onError: () => {},
    },
  );

  const { mutate: mutateRefresh, isLoading: isLoadingRefresh } = useMutation(
    refreshSignUpSessionOtpMethodApi,
    {
      onSuccess: () => {
        methods.reset();
        setErrorMessage('');
      },
    },
  );

  const {
    mutate: mutateSignInSendOtpGuest,
    isLoading: isLoadingSignInSendOtpGuest,
  } = useMutation(SignInSendOtpGuestMethodApi, {
    onSuccess: (res) => {
      setValue('guestSignInGuid', res.data.ResultSet);
    },
  });

  const { mutate: mutatePreLoginGuest, isLoading: isLoadingPreLoginGuest } =
    useMutation(PreLoginGuestMethodApi, {
      onSuccess: (res, variables) => {
        navigate(RoutePath.welcomePage, {
          state: {
            login: 'loginLight',
            otpPhone: variables.otp,
            guestSignInGuid,
          },
        });
      },
    });

  const onSubmit = async (data: any) => {
    if (login) {
      mutatePreLoginGuest({
        [otp]: Object.values(data).join(''),
        guestSignInGuid,
        [buyerGuid]: BuyerGuid,
      });
      return;
    }
    const dataOtp: VerifySignUpSessionOtpData = {
      OtpType: OtpTypeArray.phone,
      SessionGuid: sessionGuid,
      OtpValue: Object.values(data).join(''),
    };
    mutate(dataOtp);
  };

  if (
    isLoadingRefresh ||
    isLoadingSignInSendOtpGuest ||
    isLoadingPreLoginGuest
  ) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <FlowHeader
        label="text.insertCodeLite"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryOtp" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OtpForm
              sendAgainHandler={() => {
                if (login) {
                  mutateSignInSendOtpGuest({
                    [phoneField]: mobileNumber,
                    [prefix]: prefixMobile,
                  });
                  return;
                }
                mutateRefresh({
                  OtpType: OtpTypeArray.phone,
                  SessionGuid: sessionGuid,
                });
              }}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isLoadingRefresh={isLoadingRefresh}
              prefix={prefixNumber}
              mobile={maskPhoneNumber(mobileNumber)}
            />
            <ButtonContainer>
              <SubmitButton minWidth="100%" disabled={isLoading}>
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
};

export default FormOtpPhone;
