import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import Stepper from 'src/components/Stepper/Stepper.component';

const ChoosePlanModalBody = ({ data }: { data: any }) => {
  return (
    <ModalBody>
      <Stepper dataStepper={data} />
    </ModalBody>
  );
};

export default ChoosePlanModalBody;
