export const idViewSepa = 'isItSepa';
export const AccordionPageArray = [
  {
    title: 'text.whoIsOpyn',
    content: 'text.whoIsOpynAnswer',
    id: 'opynAnswer',
  },
  {
    title: 'text.whatIsOpynPayLater',
    content: 'text.whatIsOpynPayLaterAnswer',
    id: 'opynPayLaterAnswer',
  },
  {
    title: 'text.isItSafe',
    content: 'text.isItSafeAnswer',
    id: 'isItSafe',
  },
  {
    title: 'text.haveToRegisterOPL',
    content: 'text.haveToRegisterOPLAnswer',
    id: 'haveToRegister',
  },
  {
    title: 'text.isItSepa',
    content: 'text.isItSepaAnswer',
    id: idViewSepa,
  },
  {
    title: 'text.isItWorthy',
    content: 'text.isItWorthyAnswer',
    id: 'isItWorthy',
  },
  {
    title: 'text.howDoesPaymentWorks',
    content: 'text.howDoesPaymentWorksAnswer',
    id: 'paymentWorksAnswer',
  },
  {
    title: 'text.howDoesBillingWorks',
    content: 'text.howDoesBillingWorksAnswer',
    id: 'billingWorksAnswer',
  },
];
