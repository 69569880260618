export const itCountries = [
  {
    code: 'AF',
    prefix: 93,
    country_name: 'Afghanistan',
  },
  {
    code: 'AL',
    prefix: 355,
    country_name: 'Albania',
  },
  {
    code: 'DZ',
    prefix: 213,
    country_name: 'Algeria',
  },
  {
    code: 'AD',
    prefix: 376,
    country_name: 'Andorra',
  },
  {
    code: 'AO',
    prefix: 244,
    country_name: 'Angola',
  },
  {
    code: 'AI',
    prefix: 1,
    country_name: 'Anguilla',
  },
  {
    code: 'AG',
    prefix: 1,
    country_name: 'Antigua e Barbuda',
  },
  {
    code: 'SA',
    prefix: 966,
    country_name: 'Arabia Saudita',
  },
  {
    code: 'AR',
    prefix: 54,
    country_name: 'Argentina',
  },
  {
    code: 'AM',
    prefix: 374,
    country_name: 'Armenia',
  },
  {
    code: 'AW',
    prefix: 297,
    country_name: 'Aruba',
  },
  {
    code: 'AU',
    prefix: 61,
    country_name: 'Australia',
    format_excluding_country_prefix: 'X XXXX XXXX',
  },
  {
    code: 'AT',
    prefix: 43,
    country_name: 'Austria',
  },
  {
    code: 'AZ',
    prefix: 994,
    country_name: 'Azerbaigian',
  },
  {
    code: 'BS',
    prefix: 1,
    country_name: 'Bahamas',
  },
  {
    code: 'BH',
    prefix: 973,
    country_name: 'Bahrein',
  },
  {
    code: 'BD',
    prefix: 880,
    country_name: 'Bangladesh',
  },
  {
    code: 'BB',
    prefix: 1,
    country_name: 'Barbados',
  },
  {
    code: 'BE',
    prefix: 32,
    country_name: 'Belgio',
  },
  {
    code: 'BZ',
    prefix: 501,
    country_name: 'Belize',
  },
  {
    code: 'BJ',
    prefix: 229,
    country_name: 'Benin',
  },
  {
    code: 'BM',
    prefix: 1,
    country_name: 'Bermuda',
  },
  {
    code: 'BT',
    prefix: 975,
    country_name: 'Bhutan',
  },
  {
    code: 'BY',
    prefix: 375,
    country_name: 'Bielorussia',
  },
  {
    code: 'BO',
    prefix: 591,
    country_name: 'Bolivia',
  },
  {
    code: 'BQ',
    prefix: 599,
    country_name: 'Bonaire, Sint Eustatius, e Saba',
  },
  {
    code: 'BA',
    prefix: 387,
    country_name: 'Bosnia ed Erzegovina',
  },
  {
    code: 'BW',
    prefix: 267,
    country_name: 'Botswana',
  },
  {
    code: 'BR',
    prefix: 55,
    country_name: 'Brasile',
    format_excluding_country_prefix: '(XX) X XXXX-XXXX',
  },
  {
    code: 'BN',
    prefix: 673,
    country_name: 'Brunei',
  },
  {
    code: 'BG',
    prefix: 359,
    country_name: 'Bulgaria',
  },
  {
    code: 'BF',
    prefix: 226,
    country_name: 'Burkina Faso',
  },
  {
    code: 'BI',
    prefix: 257,
    country_name: 'Burundi',
  },
  {
    code: 'KH',
    prefix: 855,
    country_name: 'Cambogia',
  },
  {
    code: 'CM',
    prefix: 237,
    country_name: 'Camerun',
  },
  {
    code: 'CA',
    prefix: 1,
    country_name: 'Canada',
    format_excluding_country_prefix: '(XXX) XXX-XXXX',
  },
  {
    code: 'CV',
    prefix: 238,
    country_name: 'Capo Verde',
  },
  {
    code: 'CZ',
    prefix: 420,
    country_name: 'Repubblica Ceca',
  },
  {
    code: 'TD',
    prefix: 235,
    country_name: 'Ciad',
  },
  {
    code: 'CL',
    prefix: 56,
    country_name: 'Cile',
  },
  {
    code: 'CN',
    prefix: 86,
    country_name: 'Cina',
    format_excluding_country_prefix: 'XXX XXXX XXXX',
  },
  {
    code: 'CY',
    prefix: 357,
    country_name: 'Cipro',
  },
  {
    code: 'VA',
    prefix: 379,
    country_name: 'Città del Vaticano',
  },
  {
    code: 'CO',
    prefix: 57,
    country_name: 'Colombia',
  },
  {
    code: 'KM',
    prefix: 269,
    country_name: 'Comore',
  },
  {
    code: 'CG',
    prefix: 242,
    country_name: 'Congo',
  },
  {
    code: 'KR',
    prefix: 82,
    country_name: 'Corea del Sud',
  },
  {
    code: 'CI',
    prefix: 225,
    country_name: 'Costa d’Avorio',
  },
  {
    code: 'CR',
    prefix: 506,
    country_name: 'Costa Rica',
  },
  {
    code: 'HR',
    prefix: 385,
    country_name: 'Croazia',
  },
  {
    code: 'CU',
    prefix: 53,
    country_name: 'Cuba',
  },
  {
    code: 'CW',
    prefix: 599,
    country_name: 'Curaçao',
  },
  {
    code: 'DK',
    prefix: 45,
    country_name: 'Danimarca',
  },
  {
    code: 'CD',
    prefix: 243,
    country_name: 'Repubblica Democratica del Congo',
  },
  {
    code: 'DM',
    prefix: 1,
    country_name: 'Dominica',
  },
  {
    code: 'EC',
    prefix: 593,
    country_name: 'Ecuador',
  },
  {
    code: 'EG',
    prefix: 20,
    country_name: 'Egitto',
  },
  {
    code: 'SV',
    prefix: 503,
    country_name: 'El Salvador',
  },
  {
    code: 'AE',
    prefix: 971,
    country_name: 'Emirati Arabi Uniti',
  },
  {
    code: 'ER',
    prefix: 291,
    country_name: 'Eritrea',
  },
  {
    code: 'EE',
    prefix: 372,
    country_name: 'Estonia',
  },
  {
    code: 'ET',
    prefix: 251,
    country_name: 'Etiopia',
  },
  {
    code: 'FJ',
    prefix: 679,
    country_name: 'Figi',
  },
  {
    code: 'PH',
    prefix: 63,
    country_name: 'Filippine',
  },
  {
    code: 'FI',
    prefix: 358,
    country_name: 'Finlandia',
  },
  {
    code: 'FR',
    prefix: 33,
    country_name: 'Francia',
    format_excluding_country_prefix: 'X XX XX XX XX',
  },
  {
    code: 'GA',
    prefix: 241,
    country_name: 'Gabon',
  },
  {
    code: 'GM',
    prefix: 220,
    country_name: 'Gambia',
  },
  {
    code: 'GE',
    prefix: 995,
    country_name: 'Georgia',
  },
  {
    code: 'GS',
    prefix: 500,
    country_name: 'Georgia del Sud e Sandwich australi',
  },
  {
    code: 'DE',
    prefix: 49,
    country_name: 'Germania',
    format_excluding_country_prefix: 'XX XXXX XXXX',
  },
  {
    code: 'GH',
    prefix: 233,
    country_name: 'Ghana',
  },
  {
    code: 'JM',
    prefix: 1,
    country_name: 'Giamaica',
  },
  {
    code: 'JP',
    prefix: 81,
    country_name: 'Giappone',
    format_excluding_country_prefix: 'XX-XXXX-XXXX',
  },
  {
    code: 'GI',
    prefix: 350,
    country_name: 'Gibilterra',
  },
  {
    code: 'DJ',
    prefix: 253,
    country_name: 'Gibuti',
  },
  {
    code: 'JO',
    prefix: 962,
    country_name: 'Giordania',
  },
  {
    code: 'GR',
    prefix: 30,
    country_name: 'Grecia',
  },
  {
    code: 'GD',
    prefix: 1,
    country_name: 'Grenada',
  },
  {
    code: 'GL',
    prefix: 299,
    country_name: 'Groenlandia',
  },
  {
    code: 'GP',
    prefix: 590,
    country_name: 'Guadalupa',
  },
  {
    code: 'GU',
    prefix: 1,
    country_name: 'Guam',
  },
  {
    code: 'GT',
    prefix: 502,
    country_name: 'Guatemala',
  },
  {
    code: 'GG',
    prefix: 44,
    country_name: 'Guernsey',
  },
  {
    code: 'GN',
    prefix: 224,
    country_name: 'Guinea',
  },
  {
    code: 'GQ',
    prefix: 240,
    country_name: 'Guinea Equatoriale',
  },
  {
    code: 'GW',
    prefix: 245,
    country_name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    prefix: 592,
    country_name: 'Guyana',
  },
  {
    code: 'GF',
    prefix: 594,
    country_name: 'Guyana francese',
  },
  {
    code: 'HT',
    prefix: 509,
    country_name: 'Haiti',
  },
  {
    code: 'HN',
    prefix: 504,
    country_name: 'Honduras',
  },
  {
    code: 'HK',
    prefix: 852,
    country_name: 'Hong-Kong',
  },
  {
    code: 'IN',
    prefix: 91,
    country_name: 'India',
  },
  {
    code: 'ID',
    prefix: 62,
    country_name: 'Indonesia',
  },
  {
    code: 'IQ',
    prefix: 964,
    country_name: 'Iraq',
  },
  {
    code: 'IE',
    prefix: 353,
    country_name: 'Irlanda',
    format_excluding_country_prefix: '(XX) XXX XXXX',
  },
  {
    code: 'IS',
    prefix: 354,
    country_name: 'Islanda',
  },
  {
    code: 'CX',
    prefix: 61,
    country_name: 'Isola Christmas',
  },
  {
    code: 'IM',
    prefix: 44,
    country_name: 'Isola di Man',
  },
  {
    code: 'NF',
    prefix: 672,
    country_name: 'Isola Norfolk',
  },
  {
    code: 'AX',
    prefix: 358,
    country_name: 'Isole Åland',
  },
  {
    code: 'KY',
    prefix: 1,
    country_name: 'Isole Cayman',
  },
  {
    code: 'CC',
    prefix: 61,
    country_name: 'Isole Cocos',
  },
  {
    code: 'CK',
    prefix: 682,
    country_name: 'Isole Cook',
  },
  {
    code: 'FO',
    prefix: 298,
    country_name: 'Isole Fær Øer',
  },
  {
    code: 'FK',
    prefix: 500,
    country_name: 'Isole Falkland (Malvine)',
  },
  {
    code: 'MP',
    prefix: 1,
    country_name: 'Isole Marianne settentrionali',
  },
  {
    code: 'MH',
    prefix: 692,
    country_name: 'Isole Marshall',
  },
  {
    code: 'PN',
    prefix: 64,
    country_name: 'Isole Pitcairn',
  },
  {
    code: 'SB',
    prefix: 677,
    country_name: 'Isole Salomone',
  },
  {
    code: 'TC',
    prefix: 1,
    country_name: 'Isole Turks e Caicos',
  },
  {
    code: 'VI',
    prefix: 1,
    country_name: 'Isole Vergini Americane',
  },
  {
    code: 'VG',
    prefix: 1,
    country_name: 'Isole Vergini Britanniche',
  },
  {
    code: 'IL',
    prefix: 972,
    country_name: 'Israele',
  },
  {
    code: 'IT',
    prefix: 39,
    country_name: 'Italia',
    format_excluding_country_prefix: 'XXX XXXXXXX',
  },
  {
    code: 'JE',
    prefix: 44,
    country_name: 'Jersey',
  },
  {
    code: 'KZ',
    prefix: 7,
    country_name: 'Kazakistan',
  },
  {
    code: 'KE',
    prefix: 254,
    country_name: 'Kenya',
  },
  {
    code: 'KG',
    prefix: 996,
    country_name: 'Kirghizistan',
  },
  {
    code: 'KI',
    prefix: 686,
    country_name: 'Kiribati',
  },
  {
    code: 'XK',
    prefix: 383,
    country_name: 'Kosovo',
  },
  {
    code: 'KW',
    prefix: 965,
    country_name: 'Kuwait',
  },
  {
    code: 'LA',
    prefix: 856,
    country_name: 'Laos',
  },
  {
    code: 'LS',
    prefix: 266,
    country_name: 'Lesotho',
  },
  {
    code: 'LV',
    prefix: 371,
    country_name: 'Lettonia',
  },
  {
    code: 'LB',
    prefix: 961,
    country_name: 'Libano',
  },
  {
    code: 'LR',
    prefix: 231,
    country_name: 'Liberia',
  },
  {
    code: 'LY',
    prefix: 218,
    country_name: 'Libia',
  },
  {
    code: 'LI',
    prefix: 423,
    country_name: 'Liechtenstein',
  },
  {
    code: 'LT',
    prefix: 370,
    country_name: 'Lituania',
  },
  {
    code: 'LU',
    prefix: 352,
    country_name: 'Lussemburgo',
  },
  {
    code: 'MO',
    prefix: 853,
    country_name: 'Macao',
  },
  {
    code: 'MK',
    prefix: 389,
    country_name: 'Macedonia settentrionale',
  },
  {
    code: 'MG',
    prefix: 261,
    country_name: 'Madagascar',
  },
  {
    code: 'MW',
    prefix: 265,
    country_name: 'Malawi',
  },
  {
    code: 'MY',
    prefix: 60,
    country_name: 'Malaysia',
  },
  {
    code: 'MV',
    prefix: 960,
    country_name: 'Maldive',
  },
  {
    code: 'ML',
    prefix: 223,
    country_name: 'Mali',
  },
  {
    code: 'MT',
    prefix: 356,
    country_name: 'Malta',
  },
  {
    code: 'MA',
    prefix: 212,
    country_name: 'Marocco',
  },
  {
    code: 'MQ',
    prefix: 596,
    country_name: 'Martinica',
  },
  {
    code: 'MR',
    prefix: 222,
    country_name: 'Mauritania',
  },
  {
    code: 'MU',
    prefix: 230,
    country_name: 'Mauritius',
  },
  {
    code: 'YT',
    prefix: 262,
    country_name: 'Mayotte',
  },
  {
    code: 'MX',
    prefix: 52,
    country_name: 'Messico',
  },
  {
    code: 'FM',
    prefix: 691,
    country_name: 'Micronesia',
  },
  {
    code: 'MD',
    prefix: 373,
    country_name: 'Moldavia',
  },
  {
    code: 'MC',
    prefix: 377,
    country_name: 'Monaco',
  },
  {
    code: 'MN',
    prefix: 976,
    country_name: 'Mongolia',
  },
  {
    code: 'ME',
    prefix: 382,
    country_name: 'Montenegro',
  },
  {
    code: 'MS',
    prefix: 1,
    country_name: 'Montserrat',
  },
  {
    code: 'MZ',
    prefix: 258,
    country_name: 'Mozambico',
  },
  {
    code: 'MM',
    prefix: 95,
    country_name: 'Myanmar (Birmania)',
  },
  {
    code: 'NA',
    prefix: 264,
    country_name: 'Namibia',
  },
  {
    code: 'NR',
    prefix: 674,
    country_name: 'Nauru',
  },
  {
    code: 'NP',
    prefix: 977,
    country_name: 'Nepal',
  },
  {
    code: 'NI',
    prefix: 505,
    country_name: 'Nicaragua',
  },
  {
    code: 'NE',
    prefix: 227,
    country_name: 'Niger',
  },
  {
    code: 'NG',
    prefix: 234,
    country_name: 'Nigeria',
  },
  {
    code: 'NU',
    prefix: 683,
    country_name: 'Niue',
  },
  {
    code: 'NO',
    prefix: 47,
    country_name: 'Norvegia',
  },
  {
    code: 'NC',
    prefix: 687,
    country_name: 'Nuova Caledonia',
  },
  {
    code: 'NZ',
    prefix: 64,
    country_name: 'Nuova Zelanda',
    format_excluding_country_prefix: 'XX XXX XXXX',
  },
  {
    code: 'OM',
    prefix: 968,
    country_name: 'Oman',
  },
  {
    code: 'NL',
    prefix: 31,
    country_name: 'Paesi Bassi',
  },
  {
    code: 'PK',
    prefix: 92,
    country_name: 'Pakistan',
  },
  {
    code: 'PW',
    prefix: 680,
    country_name: 'Palau',
  },
  {
    code: 'PA',
    prefix: 507,
    country_name: 'Panamá',
  },
  {
    code: 'PG',
    prefix: 675,
    country_name: 'Papua Nuova Guinea',
  },
  {
    code: 'PY',
    prefix: 595,
    country_name: 'Paraguay',
  },
  {
    code: 'PE',
    prefix: 51,
    country_name: 'Perù',
  },
  {
    code: 'PF',
    prefix: 689,
    country_name: 'Polinesia francese',
  },
  {
    code: 'PL',
    prefix: 48,
    country_name: 'Polonia',
  },
  {
    code: 'PT',
    prefix: 351,
    country_name: 'Portogallo',
  },
  {
    code: 'PR',
    prefix: 1,
    country_name: 'Portorico',
  },
  {
    code: 'QA',
    prefix: 974,
    country_name: 'Qatar',
  },
  {
    code: 'GB',
    prefix: 44,
    country_name: 'Regno Unito',
    format_excluding_country_prefix: 'XXXX XXXXXX',
  },
  {
    code: 'CF',
    prefix: 236,
    country_name: 'Repubblica Centrafricana',
  },
  {
    code: 'DO',
    prefix: 1,
    country_name: 'Repubblica Dominicana',
  },
  {
    code: 'RE',
    prefix: 262,
    country_name: 'Riunione',
  },
  {
    code: 'RO',
    prefix: 40,
    country_name: 'Romania',
  },
  {
    code: 'RW',
    prefix: 250,
    country_name: 'Ruanda',
  },
  {
    code: 'RU',
    prefix: 7,
    country_name: 'Russia',
    format_excluding_country_prefix: 'XXX XXX-XX-XX',
  },
  {
    code: 'EH',
    prefix: 212,
    country_name: 'Sahara occidentale',
  },
  {
    code: 'KN',
    prefix: 1,
    country_name: 'Saint Kitts e Nevis',
  },
  {
    code: 'LC',
    prefix: 1,
    country_name: 'Saint Lucia',
  },
  {
    code: 'MF',
    prefix: 590,
    country_name: 'Saint Martin',
  },
  {
    code: 'VC',
    prefix: 1,
    country_name: 'Saint Vincent e Grenadine',
  },
  {
    code: 'BL',
    prefix: 590,
    country_name: 'Saint-Barthélemy',
  },
  {
    code: 'PM',
    prefix: 508,
    country_name: 'Saint-Pierre e Miquelon',
  },
  {
    code: 'WS',
    prefix: 685,
    country_name: 'Samoa',
  },
  {
    code: 'AS',
    prefix: 1,
    country_name: 'Samoa americane',
  },
  {
    code: 'SM',
    prefix: 378,
    country_name: 'San Marino',
  },
  {
    code: 'SH',
    prefix: 290,
    country_name: 'Sant’Elena',
  },
  {
    code: 'ST',
    prefix: 239,
    country_name: 'São Tomé e Príncipe',
  },
  {
    code: 'SN',
    prefix: 221,
    country_name: 'Senegal',
  },
  {
    code: 'RS',
    prefix: 381,
    country_name: 'Serbia',
  },
  {
    code: 'SC',
    prefix: 248,
    country_name: 'Seychelles',
  },
  {
    code: 'SL',
    prefix: 232,
    country_name: 'Sierra Leone',
  },
  {
    code: 'SG',
    prefix: 65,
    country_name: 'Singapore',
  },
  {
    code: 'SX',
    prefix: 1,
    country_name: 'Sint Maarten',
  },
  {
    code: 'SK',
    prefix: 421,
    country_name: 'Slovacchia',
  },
  {
    code: 'SI',
    prefix: 386,
    country_name: 'Slovenia',
  },
  {
    code: 'SO',
    prefix: 252,
    country_name: 'Somalia',
  },
  {
    code: 'ES',
    prefix: 34,
    country_name: 'Spagna',
    format_excluding_country_prefix: 'XXX XXX XXX',
  },
  {
    code: 'LK',
    prefix: 94,
    country_name: 'Sri Lanka',
  },
  {
    code: 'US',
    prefix: 1,
    country_name: 'Stati Uniti',
    format_excluding_country_prefix: '(XXX) XXX-XXXX',
  },
  {
    code: 'SS',
    prefix: 211,
    country_name: 'Sud Sudan',
  },
  {
    code: 'ZA',
    prefix: 27,
    country_name: 'Sudafrica',
  },
  {
    code: 'SD',
    prefix: 249,
    country_name: 'Sudan',
  },
  {
    code: 'SR',
    prefix: 597,
    country_name: 'Suriname',
  },
  {
    code: 'SJ',
    prefix: 47,
    country_name: 'Svalbard e Jan Mayen',
  },
  {
    code: 'SE',
    prefix: 46,
    country_name: 'Svezia',
  },
  {
    code: 'CH',
    prefix: 41,
    country_name: 'Svizzera',
  },
  {
    code: 'SZ',
    prefix: 268,
    country_name: 'Swaziland',
  },
  {
    code: 'TJ',
    prefix: 992,
    country_name: 'Tagikistan',
  },
  {
    code: 'TW',
    prefix: 886,
    country_name: 'Taiwan',
  },
  {
    code: 'TZ',
    prefix: 255,
    country_name: 'Tanzania',
  },
  {
    code: 'PS',
    prefix: 970,
    country_name: 'Territori palestinesi',
  },
  {
    code: 'IO',
    prefix: 246,
    country_name: 'Territorio britannico dell’Oceano Indiano',
  },
  {
    code: 'TH',
    prefix: 66,
    country_name: 'Thailandia',
  },
  {
    code: 'TL',
    prefix: 670,
    country_name: 'Timor Est',
  },
  {
    code: 'TG',
    prefix: 228,
    country_name: 'Togo',
  },
  {
    code: 'TK',
    prefix: 690,
    country_name: 'Tokelau',
  },
  {
    code: 'TO',
    prefix: 676,
    country_name: 'Tonga',
  },
  {
    code: 'TT',
    prefix: 1,
    country_name: 'Trinidad e Tobago',
  },
  {
    code: 'TN',
    prefix: 216,
    country_name: 'Tunisia',
  },
  {
    code: 'TR',
    prefix: 90,
    country_name: 'Turchia',
  },
  {
    code: 'TM',
    prefix: 993,
    country_name: 'Turkmenistan',
  },
  {
    code: 'TV',
    prefix: 688,
    country_name: 'Tuvalu',
  },
  {
    code: 'UA',
    prefix: 380,
    country_name: 'Ucraina',
  },
  {
    code: 'UG',
    prefix: 256,
    country_name: 'Uganda',
  },
  {
    code: 'HU',
    prefix: 36,
    country_name: 'Ungheria',
  },
  {
    code: 'UY',
    prefix: 598,
    country_name: 'Uruguay',
  },
  {
    code: 'UZ',
    prefix: 998,
    country_name: 'Uzbekistan',
  },
  {
    code: 'VU',
    prefix: 678,
    country_name: 'Vanuatu',
  },
  {
    code: 'VE',
    prefix: 58,
    country_name: 'Venezuela',
  },
  {
    code: 'VN',
    prefix: 84,
    country_name: 'Vietnam',
  },
  {
    code: 'WF',
    prefix: 681,
    country_name: 'Wallis e Futuna',
  },
  {
    code: 'YE',
    prefix: 967,
    country_name: 'Yemen',
  },
  {
    code: 'ZM',
    prefix: 260,
    country_name: 'Zambia',
  },
  {
    code: 'ZW',
    prefix: 263,
    country_name: 'Zimbabwe',
  },
];
