import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { RadioboxProps } from './RadioBox.types';
import {
  Checkmark,
  RadioContainer,
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from './style/RadioBox.style';

const RadioBox = React.memo(function RadioBox({
  viewMode,
  label,
  defaultChecked,
  checked,
  description,
  i18n,
  onClick,
  className,
  onFocus,
  onBlur: onBlurField,
  onChange: onChangeField,
  name,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  style,
  id,
  value,
  large = true,
  i18nData,
  ...otherProps
}: RadioboxProps) {
  const { control } = useFormContext();
  const {
    field: { value: valueField, ...ref },
  } = useController({
    name,
    control,
  });

  return (
    <RadioWrapper
      data-component="radio-box"
      viewMode={viewMode}
      className={className}
      padding={padding}
      style={style}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
    >
      <RadioContainer large={large}>
        <RadioInput
          id={id}
          value={value}
          checked={value === valueField}
          defaultChecked={defaultChecked}
          type="radio"
          onClick={onClick}
          onFocus={onFocus}
          {...ref}
          {...otherProps}
        ></RadioInput>
        <RadioLabel className={`${className ? className : ''}`}>
          {i18n ? <Translate id={label} data={i18nData} /> : label}
        </RadioLabel>
        <Checkmark large={large} />
      </RadioContainer>
    </RadioWrapper>
  );
});

export default RadioBox;
