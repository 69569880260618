import { createBrowserHistory } from '@remix-run/router';
import { Button } from 'src/components/Button/Button.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import {
  generateQrCodeMethodApi,
  performOrderCreationMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { PerformOrderCreationData } from 'src/services/orderLink/orderLink.types';
import { usePayByLinkStore } from 'src/store/store';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import MailPrevModalLoadable from './modal/MailPrevModal.loadable';

function StepSendLink() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const [modalOpen, setModalOpen] = useState(false);
  const { productCode, categoryCode } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const history = createBrowserHistory();
  const {
    amount,
    description,
    customInstallments,
    setValue,
    variantCode,
    inviteEmail,
    flagCredit,
    invoiceDate,
    sessionGuid,
    firstInstallmentDate,
    isDeferred,
  } = usePayByLinkStore();
  const data: PerformOrderCreationData = {
    amount,
    inviteEmail,
    description,
    currency: 'EUR',
    variantCode,
    orderCreationSessionGuid: sessionGuid,
    customInstallments: customInstallments?.Installments,
    flagCredit,
    firstInstallmentDate: isDeferred ? firstInstallmentDate : undefined,
    isDeferred,
    ...(invoiceDate && flagCredit && { invoiceDate }),
  };

  const { mutate: mutateQrCode, isLoading: isLoadingQrCode } = useMutation(
    (values: any) => generateQrCodeMethodApi(values),
    {
      onSuccess: async (res, variables) => {
        await setValue('qrCode', res.data.ResultSet);
        navigate(
          `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.showQrCode}?lang=${language}`,
          {
            state: {
              CanSaveTemplate: variables.CanSaveTemplate,
            },
          },
        );
      },
      onError: () => {
        navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerError);
      },
    },
  );

  const { mutate, isLoading } = useMutation(performOrderCreationMethodApi, {
    onSuccess: (res, variables) => {
      setValue('orderId', res.data.ResultSet.OrderId);
      setValue('template', res.data.ResultSet.Prospect);
      history.push(
        `${RoutePath.linkDiPagamentoDetail}${res.data.ResultSet.OrderId}/${RoutePath.info}`,
        {
          link: true,
        },
      );

      switch (variables.typeCreate) {
        case 'sendEmail':
          navigate(urlRedirectToFlow + `?lang=${language}`, {
            state: {
              CanSaveTemplate: res.data.ResultSet.CanSaveTemplate,
            },
          });
          break;
        case 'sendNow':
          navigate(
            `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.sendLinkNow}?lang=${language}`,
            {
              state: {
                createLinkUrl: res.data.ResultSet.PayByLinkUrl,
                CanSaveTemplate: res.data.ResultSet.CanSaveTemplate,
              },
            },
          );
          break;

        case 'qrCode':
          mutateQrCode({
            idOrdine: res?.data.ResultSet.OrderId,
            CanSaveTemplate: res?.data.ResultSet.CanSaveTemplate,
          });
          break;
        default:
          break;
      }
    },
    onError: () => navigate(RoutePath.uwBuyerError),
  });

  const createOrder = async (
    typeCreate: 'sendEmail' | 'qrCode' | 'sendNow',
  ) => {
    data.sendInviteEmail = typeCreate === 'sendEmail' ? true : false;

    mutate({ ...data, typeCreate });
  };

  if (isLoading || isLoadingQrCode) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.sendLink"
      />
      <FlowContainer>
        <div className="content">
          <PayByLinkChoiceCard
            labelChoice
            title="lbl.payByLinkSendAuto"
            subTitle="text.payByLinkSendAuto"
            onClick={() => createOrder('sendEmail')}
            openModal={setModalOpen}
            btnId={IDs.btnSendAut}
            showEmail
          />
          <DividerOr />
          <Button
            display="block"
            variant="LinkPrimary"
            onClick={() => {
              createOrder('sendNow');
            }}
            fontSize="1.8rem"
            underline
            fontWeight="400"
          >
            <Translate id="lbl.payByLinkSendYou" />
          </Button>
          <Button
            display="block"
            variant="LinkPrimary"
            onClick={() => {
              createOrder('qrCode');
            }}
            underline
            fontSize="1.8rem"
            fontWeight="400"
          >
            <Translate id="text.payByLinkQrCode" />
          </Button>
        </div>
      </FlowContainer>
      {modalOpen && (
        <MailPrevModalLoadable
          buttonTitle="lbl.sendEmail"
          modalTitle="lbl.whatSendToCustomer"
          amount={amount}
          description={description}
          sendLink={() => {
            createOrder('sendEmail');
            setModalOpen(false);
          }}
          handleClose={() => {
            setModalOpen(false);
          }}
          i18n
        />
      )}
    </>
  );
}
export default StepSendLink;
