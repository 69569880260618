import { Resolver } from 'react-hook-form';
import { validateEndDate } from 'src/utils/validation/date';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import {
  buyers,
  collaborators,
  endDate,
  link,
  plugin,
  startDate,
} from './Monitoring.config';

export type DataFormMonitoringStats = {
  [buyers]: string[];
  [collaborators]: string[];
  [startDate]: string;
  [endDate]: string;
  [link]: boolean;
  [plugin]: boolean;
  custom?: boolean;
};

export const searchMonitoringStatsFormConfig = () => {
  const initialValues: DataFormMonitoringStats = {
    [buyers]: [],
    [collaborators]: [],
    [startDate]: '',
    [endDate]: '',
    [link]: false,
    [plugin]: false,
  };
  const validate = (values: any) => {
    return validateFormSync({
      [endDate]: validateFieldSync(validateEndDate(values[startDate])),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
