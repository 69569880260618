import ModalFooterDefault from 'src/components/Modal/partials/ModalFooter/ModalFooterDefault.component';
import { ModalHeader } from 'src/components/Modal/partials/ModalHeader/ModalHeader.component';
import withConnectedModal from 'src/components/Modal/WithConnectedModal';
import ProductCardCSTModalBody from './ProductCardCSTModalBody.component';

export default withConnectedModal({
  showOnMount: true,
  hideOnUnmount: true,
  modalName: 'SortModal',
  headerComponent: ModalHeader,
  bodyComponent: ProductCardCSTModalBody,
  footerComponent: ModalFooterDefault,
});
