import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type TranslateProps = {
  id?: string;
  data?: { [x: string]: string | number | undefined };
  extraTranslationProps?: {
    components?:
      | readonly React.ReactElement[]
      | { readonly [tagName: string]: React.ReactElement };
  };
};

const Translate: React.FC<TranslateProps> = ({
  id,
  data,
  extraTranslationProps,
}) => {
  const { t } = useTranslation();

  return (
    <Trans
      data-component="translate"
      t={t}
      i18nKey={id}
      values={data}
      components={{ u: <u />, span: <span />, a: <a />, b: <b /> }}
      {...extraTranslationProps}
    />
  );
};

export default Translate;
