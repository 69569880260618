import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import Loader from 'src/components/Loader/Loader.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { Privacy } from 'src/pages/registration/registrationGuest/style/registrationGuest.style';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { SignInSendOtpGuestMethodApi } from 'src/services/login/login.request';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import { useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { formConfigPhoneRegistration } from './RegistrationGuestStepPhone.helpers';

function RegistrationGuestStepPhone() {
  const mobileNum = useRegistrationStore((state) => state.mobile);
  const prefixNum = useRegistrationStore((state) => state.prefix);
  const [errorMessage, setErrorMessage] = useState('');
  const { initialValues, resolver } = formConfigPhoneRegistration(
    mobileNum,
    prefixNum,
  );
  const navigate = useNavigate();
  const setValue = useRegistrationStore((state) => state.setValue);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(SignInSendOtpGuestMethodApi, {
    onSuccess: (res, variables) => {
      setValue('mobile', variables[phoneField]);
      setValue('prefix', variables[prefix]);
      setValue('guestSignInGuid', res.data.ResultSet);
      navigate(RoutePath.registrationGuest + RoutePath.otpConfirmPhoneStep);
    },
    onError: (error: any) => {
      const errorResponse: {
        errorInfos: {
          generic: 'error.not_found' | 'error.already_registered';
        };
      } = error.response.data;

      const errorCodeArray = {
        'error.not_found': {
          label: 'error.signUpWithoutPurchases',
        },
        'error.already_registered': {
          label: 'error.mobileAlreadyRegistered',
        },
      };
      if (errorResponse.errorInfos) {
        setErrorMessage(errorCodeArray[errorResponse.errorInfos.generic].label);
        methods.setError(phoneField, {
          message: errorCodeArray[errorResponse.errorInfos.generic].label,
        });
        return;
      }
    },
  });

  const onSubmit = (data: any) => {
    mutate({ [phoneField]: data[phoneField], [prefix]: data[prefix] });
  };

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <>
      <FlowHeader
        label="lbl.mobileNumber"
        showWhiteSpaceRight
        stepNumber={1}
        stepMaxNumber={7}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.insertPhoneNumber" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PhoneField autoFocus name={phoneField} />
              <GenericErrorForm
                textAlign="left"
                isPresentMessage
                errorMessageID={errorMessage}
              />
              <ButtonContainer>
                <SubmitButton
                  id={IDs.btnProceed}
                  minWidth="100%"
                  disabled={isLoading}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
                <Privacy>
                  <Translate
                    id="link.privacyLink"
                    extraTranslationProps={{
                      components: { u: <a /> },
                    }}
                  />
                </Privacy>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
}

export default RegistrationGuestStepPhone;
