import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import { endDate, startDate } from '../../../SearchCriteriaForm.config';
import { useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { FilteredButtonContainer } from '../style/FilteredList.style';
import { DataFormEligibleOrders } from '../../../SearchCriteriaForm.helpers';

interface Props {
  filteredName?: string;
  fromDate?: string;
  toDate?: string;
  buyerId?: string;
  amountFrom?: number;
  amountTo?: number;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormEligibleOrders;
  disabled: boolean;
}

const FilteredButtonEligibleOrder = ({
  filteredName,
  fromDate,
  toDate,
  buyerId,
  handleClick,
  dataForm,
  setDataFilter,
  disabled,
}: Props) => {
  const { resetField } = useFormContext();
  const {
    formatters: { formatDate },
  } = useI18n();
  const onClick = () => {
    if (fromDate || toDate) {
      resetField(startDate);
      resetField(endDate);
      dataForm[startDate] = '';
      dataForm.toDate = '';
    }

    if (buyerId) {
      dataForm.buyers = dataForm?.buyers?.filter(
        (id) => id !== buyerId.toString(),
      );
    }
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <FilteredButtonContainer onClick={onClick} data-disabled={disabled}>
      {fromDate && fromDate.length > 0 && (
        <>
          <Translate id="text.startPeriod" />
          &nbsp;{formatDate(new Date(fromDate))}
        </>
      )}
      {toDate && toDate.length > 0 && (
        <>
          <Translate id="text.endPeriod" />
          &nbsp;{formatDate(new Date(toDate))}
        </>
      )}
      {buyerId && filteredName}
      <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
    </FilteredButtonContainer>
  );
};

export default FilteredButtonEligibleOrder;
