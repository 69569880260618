export const initSignUpSession = 'Account/InitSignUpSession';
export const updateSignUpSessionData = 'Account/UpdateSignUpSessionData';
export const verifySignUpSessionOtp = 'Account/VerifySignUpSessionOtp';
export const refreshSignUpSessionOtp = 'Account/RefreshSignUpSessionOtp';
export const performSignUpSession = 'Account/PerformSignUpSession';

export const emailField = 'email-field';
export const passwordField = 'password-field';
export const cfField = 'CF-field';
export const phoneField = 'phone-field';
export const prefix = 'prefix';
export const checkboxFieldOne = 'checkboxOne-field';
export const checkboxFieldTwo = 'checkboxTwo-field';
export const checkboxFieldThree = 'checkboxThree-field';
export const checkboxFieldFour = 'checkboxFour-field';
