import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as noData } from 'src/images/icons/nodata-payment-link.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import colors from 'src/style-utils/colors';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

const ContainerNoData = styled(Flex)`
  max-width: 56rem;
`;

const NoDataRequests: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ContainerNoData
      margin="7.2rem auto 0"
      align="center"
      flexDirection="column"
    >
      <Icon iconSize="16rem" svgIconComponent={noData} />
      <H4 textAlign="center" textColor={colors.textTableBody} margin="2.4rem 0">
        <Translate id={'advPlanRequest.NoData'} />
      </H4>
      <Button
        variant="Primary"
        onClick={() => navigate(`${RoutePath.advancePaymentInfo}`)}
      >
        <Translate id={'advPlanRequest.NoData.btn'} />
      </Button>
    </ContainerNoData>
  );
};

export default NoDataRequests;
