import FormPhone from 'src/pages/registration/partials/FormPhone/FormPhone.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationCollaboratorStepPhone: React.FC = () => {
  return (
    <FormPhone
      nextPath={
        RoutePath.registrationCollaborator + RoutePath.otpConfirmPhoneStep
      }
      step={1}
      stepMax={4}
    />
  );
};

export default RegistrationCollaboratorStepPhone;
