import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import React, { useState } from 'react';
import { MaxWidthContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { InstallmentsTabsProps } from '../TabsInstallments.types';
import { P } from 'src/style-utils/typographic';
import Translate from 'src/components/Translate/Translate.component';
import InstallmentsModalLodable from '../modal/InstallmentsModal.lodable';
import { Installment } from 'src/services/installments/installments.types';
import { NoTabsData } from '../NoTabsData.component';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';

export const InstallmentsPaid: React.FC<InstallmentsTabsProps> = ({
  installments,
}) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [dataInstallment, setDataInstallment] = useState<
    Installment | undefined
  >();
  const onClickHandler = (installment: Installment) => {
    setDataInstallment(installment);
    setShowModal(true);
  };
  const navigateToOrder = () => {
    navigate(
      `${RoutePath.orderDetail}${dataInstallment?.OrderGuid}/${RoutePath.info}`,
    );
  };
  return (
    <MaxWidthContainer maxWidth="66.8rem" noXMargin marginBottom="3.2rem">
      <Flex gap="1.6rem" flexDirection="column">
        {installments?.ResultSet.InstallmentsPaid.map((installment) => (
          <InstallmentCard
            key={installment.Guid}
            name={installment.MerchantName}
            date={installment.Expiration}
            amount={installment.Amount}
            NumRata={installment.INo}
            orderStatus={installment.OrderStatus}
            onClickHandler={() => onClickHandler(installment)}
            status="paid"
            shadows
          />
        ))}
        {installments &&
          installments?.ResultSet.InstallmentsPaidCount === 0 && (
            <NoTabsData
              title="lbl.noInstallmentsToPay"
              subtitle="text.allInstallmentsPaid"
            />
          )}
        {showModal && (
          <InstallmentsModalLodable
            buttonTitle="lbl.goToOrder"
            modalTitle="text.installmentDetail"
            i18n
            ctaHandler={navigateToOrder}
            data={dataInstallment}
            handleClose={() => setShowModal(false)}
          />
        )}
      </Flex>
      {installments && installments?.ResultSet.InstallmentsPaidCount > 0 && (
        <div className="mb-10 mt-8 border-t border-pgreyoutlined pt-4 text-center">
          <P>
            <Translate id="text.nothingElseToShow" />
          </P>
        </div>
      )}
    </MaxWidthContainer>
  );
};
