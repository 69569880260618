import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import media from 'src/style-utils/media-queries';

const handleBgColor = (variant: string) => {
  switch (variant) {
    case 'default':
      return colors.alertDefaultBg;
    case 'positive':
      return colors.alertPositiveBg;
    case 'error':
      return colors.alertErrorBg;
    case 'warning':
      return colors.alertWarningBg;
    case 'informative':
      return colors.alertInformativeBg;
    default:
      return colors.alertDefaultBg;
  }
};

const handleBorderColor = (variant: string) => {
  switch (variant) {
    case 'default':
      return colors.alertDefaultBorder;
    case 'positive':
      return colors.alertPositiveBorder;
    case 'error':
      return colors.alertErrorBorder;
    case 'warning':
      return colors.alertWarningBorder;
    case 'informative':
      return colors.alertInformativeBorder;
    default:
      return colors.alertDefaultBorder;
  }
};

export const Toast = styled(Flex)<{ variant: string }>`
  padding: 1.6rem 1.6rem;
  margin: 2.4rem auto 2.4rem auto;
  border-radius: 0.8rem;
  gap: 1.2rem;
  align-self: stretch;
  border: 0.1rem solid ${({ variant }) => handleBorderColor(variant)};
  background: ${({ variant }) => handleBgColor(variant)};
`;

export const IconContainer = styled(Flex)`
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  align-self: stretch;
  padding: auto;
`;

export const TextContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.4rem;
`;

export const BodyContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.2rem;
  align-self: stretch;
`;

export const CtaLink = styled.a`
  color: ${colors.textColorBlack};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const AlertContainer = styled(Flex)`
  margin: 0 2.4rem;

  ${media.tablet`
      margin: 0 8.8rem;
  `}
`;
