import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import required from 'src/utils/validation/required';
import { Resolver } from 'react-hook-form';
import { checkBoxCreditAssignment } from './PurchaseFlow.config';

export const formCreditAssignmentConfig = () => {
  const initialValues = {
    [checkBoxCreditAssignment]: false,
  };
  const validate = validateFormSync({
    [checkBoxCreditAssignment]: validateFieldSync(required),
  });
  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
