import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getPayoutDetails } from './payoutDetails.config';
import {
  GetPayoutDetailsData,
  GetPayoutDetailsResponse,
} from './payoutDetails.types';

export const getPayoutDetailsMethodApi = async (
  params: GetPayoutDetailsData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: getPayoutDetails,
    method: 'GET',
    params,
    authenticated: true,
  });
  return await apiRequest.request<GetPayoutDetailsResponse>(requestConfig);
};
