import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputDate from 'src/components/InputDate/InputDate.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { startDateField } from 'src/services/orderLink/orderLink.config';
import {
  getVariantsData,
  getVariantsWithPlanMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { ProductCodes } from 'src/utils/types/common.types';
import { formConfigStartDatePayByLink } from './DateInstallmentPlan.helpers';

function DateInstallmentPlan() {
  const { productCode } = useParams();
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const { firstInstallmentDate, amount } = usePayByLinkStore((state) => state);
  const { mutate } = useMutation(getVariantsWithPlanMethodApi, {
    onSuccess: (res) => {
      setValue('variants', res.data.ResultSet);
      setValue('customInstallments', res.data.ResultSet[0]);
      navigate(urlRedirectToFlow);
    },
  });

  const { initialValues, resolver } =
    formConfigStartDatePayByLink(firstInstallmentDate);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: { [startDateField]: string }) => {
    setValue('firstInstallmentDate', data[startDateField]);
    setValue('isDeferred', true);
    const dataVariant: getVariantsData = {
      code: productCode as ProductCodes,
      amount: amount,
      isDeferred: true,
      firstInstallmentDate: data[startDateField],
    };
    mutate(dataVariant);
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="text.insertDateInstallmentPlan"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.insertDateInstallmentPlanSubtitle" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="content">
            <InputDate name={startDateField} />
            <P margin="1.6rem 0">
              <Translate id="text.intervalStartDateInstallment" />
            </P>
            <ButtonContainer>
              <SubmitButton minWidth="100%">
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
}

export default DateInstallmentPlan;
