import { IconProps } from 'src/components/Icon/Icon.component';
import { ReactComponent as DropdownIndicator } from 'src/images/icons/arrow_down.svg';
import { memo } from 'react';
import {
  ButtonIconWrapper,
  IconWrapper,
  Label,
  ListboxButtonContent,
  ListboxButtonRoot,
  StyledIcon,
} from '../style/Listbox.style';

type ListboxButtonProps = {
  label: string;
  iconSrc?: string;
  iconComponent: React.FC<IconProps>;
  'data-active-option-index': string | number;
};

const ListboxButton: React.FC<ListboxButtonProps> = memo(
  ({
    label,
    iconSrc,
    iconComponent: IconComponent,
    'data-active-option-index': activeOption,
  }) => {
    return (
      <ListboxButtonRoot data-active-option-index={activeOption}>
        <ListboxButtonContent>
          {iconSrc && (
            <IconWrapper>
              <IconComponent iconSrc={iconSrc} aria-hidden="true" />
            </IconWrapper>
          )}
          <Label>{label}</Label>
        </ListboxButtonContent>
        <ButtonIconWrapper>
          <StyledIcon svgIconComponent={DropdownIndicator} />
        </ButtonIconWrapper>
      </ListboxButtonRoot>
    );
  },
);

export default ListboxButton;
