// Based on https://getbootstrap.com/docs/4.4/layout/overview/#responsive-breakpoints

const breakpoints = {
  xs: 375,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
  xxxl: 1920,
};

export const desktop: number = breakpoints.xl;
export const tablet: number = breakpoints.lg;

if (process.env.NODE_ENV !== 'production') {
  Object.freeze(breakpoints);
}

export default breakpoints;

export type Breakpoints = Readonly<typeof breakpoints>;
export type BreakpointsKeys = keyof typeof breakpoints;
