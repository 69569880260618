import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputFieldUIStates } from 'src/services/forms/forms.config';
import { inputFieldStateReducer } from 'src/services/forms/forms.helpers';
import { FormGroup, LabelInput } from 'src/style/styleInput.style';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import Translate from 'src/components/Translate/Translate.component';
import { ContainerMessage, MessageError } from './style/input.style';
import { ReactComponent as Upload } from 'src/images/icons/upload.svg';
import { Accept, useDropzone } from 'react-dropzone';
import InputFilePreview from './InputFilePreview/InputFilePreview.component';
import colors from '../../style-utils/colors';
import Icon from '../Icon/Icon.component';
import { InputFileProps } from './InputFile.types';
import { t } from 'i18next';
import { invoiceHiddenInvoiceFileNameField } from '../../services/orderLink/orderLink.config';
import InputField from '../InputField/InputField.component';

/**
 * @component
 *
 * The field itself has validation (react-hook-form validation) on top of it.
 * The unique validation apart is the required (validateFieldSync(requiredFile)) that should be added into resolver
 * */

const InputFile = React.memo(function InputFileArea({
  label,
  name,
  isPresentMessage,
  id,
  tracingId,
  hidden,
  transformError,
  className,
  disabled,
  additionalInfoId,
  isFieldErrorAlert,
  additionalInfoData,
  inputComponentExtraProps,
  acceptLabel,
  accept,
  maxSizeInMB,
  multiple,
  required,
  minFiles,
  maxFiles,
  fileCount,
  selectedFileName,
}: InputFileProps) {
  const {
    formState: { errors, touchedFields },
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useFormContext();
  const [remoteFileName, setRemoteFileName] = useState<string>(
    selectedFileName || '',
  );
  const [selectedFiles, setSelectedFiles] = useState<
    { file: File; errors: string[] }[]
  >([]);

  const validateFile = (file: File) => {
    let fileErrors: string[] = [];
    if (maxSizeInMB && file.size > (maxSizeInMB || 10) * 1024 * 1024) {
      fileErrors.push(t('error.file.size', { x: maxSizeInMB }));
    }
    if (accept && !accept.includes(file.type)) {
      fileErrors.push(t('error.file.type'));
    }

    return fileErrors;
  };

  const validateFiles = (files: File[]) => {
    let fileErrors: string[] = [];

    if (minFiles && files.length < minFiles) {
      fileErrors.push(t('error.file.minFiles', { x: minFiles }));
    }
    if (maxFiles && files.length > maxFiles) {
      fileErrors.push(t('error.file.maxFiles', { x: maxFiles }));
    }

    if (fileCount && files.length !== fileCount) {
      fileErrors.push(t('error.file.fileCount', { x: fileCount }));
    }

    if (!multiple && files.length > 1) {
      fileErrors.push(t('error.file.multiple'));
    }

    return files.map((file) => ({
      file,
      errors: fileErrors.length > 0 ? fileErrors : validateFile(file),
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const fileValidated = validateFiles(files);
    setSelectedFiles(fileValidated);

    const errorsExist = fileValidated.some((file) => file.errors.length > 0);
    if (errorsExist) {
      clearErrors(name);
      setError(name, { type: 'manual', message: 'error.file.generic' });
    } else {
      clearErrors(name);
      setValue(name, files, { shouldValidate: true, shouldTouch: true });
      trigger(name);
    }

    // Create a DataTransfer object to simulate a file input
    const inputElement = document.getElementById(domId) as HTMLInputElement;
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    if (inputElement) {
      inputElement.files = dataTransfer.files;
    }
  };

  const onRemoveFile = (
    file:
      | File
      | {
          size: number;
          name: string;
        },
  ) => {
    const remainingFiles =
      selectedFiles.filter(
        (_) =>
          (file as File).name !== _.file.name &&
          (file as File).lastModified !== _.file.lastModified,
      ) || [];
    setSelectedFiles(remainingFiles);

    const event = {
      target: {
        files: remainingFiles?.map(({ file }) => file) || [],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

  const onRemoveRemoteFile = () => {
    setValue(invoiceHiddenInvoiceFileNameField, '');
    trigger(invoiceHiddenInvoiceFileNameField);
    setRemoteFileName('');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      typeof accept === 'string'
        ? accept.split(',').reduce((acc, mimeType) => {
            acc[mimeType.trim()] = [];
            return acc;
          }, {} as Accept)
        : accept,
    maxSize: (maxSizeInMB || 10) * 1024 * 1024, // Convert MB to bytes
    multiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const allFiles = [
        ...acceptedFiles,
        ...rejectedFiles?.flatMap(({ file }) => file),
      ];
      // Simulate an input change event
      const event = {
        target: {
          files: allFiles,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;

      handleChange(event);
    },
  });

  const touched = touchedFields[name];
  const error = (errors && errors[name]?.message) || errors[name];
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || tracingId || compId;

  const { inputFieldState, messageProps } = inputFieldStateReducer(
    { error, touched },
    isFieldErrorAlert,
    transformError,
    additionalInfoId,
    additionalInfoData,
  );

  return (
    <div data-component="input-file" hidden={hidden} className={className}>
      {label && (
        <LabelInput htmlFor={domId}>
          <Translate id={label} />
        </LabelInput>
      )}
      <FormGroup
        isAlert={inputFieldState === InputFieldUIStates.alert}
        isError={inputFieldState === InputFieldUIStates.error}
        isDisabled={disabled}
      >
        <div
          className={`flex justify-center rounded-lg border-2 border-pgreyoutlined p-10 ${isDragActive ? 'border-solid' : 'border-dashed'}`}
          style={{
            backgroundColor: isDragActive
              ? colors.backgroundBlue
              : colors.white,
          }}
          {...getRootProps()}
        >
          <div className="text-center">
            <div className="mx-auto h-12 w-12 text-gray-300">
              <Icon
                svgIconComponent={Upload}
                iconSize="4rem"
                marginBottom="1.2rem"
                color={colors.textColorGrey}
              />
            </div>
            <div className="mt-6 text-sm leading-6 text-gray-600 md:text-base">
              <p className="inline-block pr-1 text-pblack-400">
                <span className="block md:hidden">
                  <Translate id="inputFileArea.dragndropor.label.short" />
                </span>
                <span className="hidden md:inline">
                  <Translate id="inputFileArea.dragndropor.label" />
                </span>
              </p>
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md text-info underline focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>
                  <Translate id="inputFileArea.dragndropor.link" />
                </span>
                <InputField
                  hidden={true}
                  name={invoiceHiddenInvoiceFileNameField || ''}
                />
                <input
                  id={domId}
                  name={name}
                  type="file"
                  className="sr-only hidden"
                  disabled={disabled}
                  {...inputComponentExtraProps}
                  required={required}
                  {...getInputProps()}
                  multiple={multiple}
                />
              </label>
            </div>
            <p className="text-xs leading-5 text-pgrey md:text-sm">
              <Translate id="supported.formats" /> {acceptLabel}{' '}
              <Translate id="label.up.to" /> {maxSizeInMB}MB
            </p>
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <InputFilePreview
            selectedFiles={selectedFiles}
            onRemoveFile={onRemoveFile}
          />
        )}

        {selectedFiles.length <= 0 && remoteFileName && (
          <InputFilePreview
            selectedFiles={[
              {
                file: {
                  size: 0,
                  name: remoteFileName,
                },
                errors: [],
              },
            ]}
            onRemoveFile={onRemoveRemoteFile}
          />
        )}
      </FormGroup>

      {messageProps && (
        <ContainerMessage className="mb-4" isPresentMessage={isPresentMessage}>
          <MessageError>
            <Translate {...messageProps} />
          </MessageError>
        </ContainerMessage>
      )}
    </div>
  );
});

export default InputFile;
