import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ContainerInfo } from 'src/pages/registration/registrationGuest/style/registrationGuest.style';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import {
  InitSignUpData,
  initSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';

function RegistrationStepCheckData() {
  const navigate = useNavigate();
  const {
    codiceFiscale,
    name,
    vatCode,
    address,
    setValue,
    companySearchId,
    results,
  } = useRegistrationStore();
  const { language } = useLanguage();
  const { mutate, isLoading } = useMutation(initSignUpSessionMethodApi, {
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      navigate(RoutePath.registrationMerchant + RoutePath.emailStep);
    },
    onError: () => {
      setValue('sessionGuid', '');
      navigate(RoutePath.oops);
    },
  });
  const onSubmit = () => {
    const initSignUpData: InitSignUpData = {
      SearchId: companySearchId,
      AccountType: 'MERCHANT',
      Language: language,
      RegistrationType: 'full',
    };
    mutate(initSignUpData);
  };

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <FlowHeader
        label="lbl.dataConfirmation"
        showWhiteSpaceRight
        stepNumber={3}
        stepMaxNumber={9}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4>
          <Translate id="lbl.areCompanyDataCorrect" />
        </H4>
        <div className="content">
          <ContainerInfo>
            {name && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkFlowBusinessName" />
                </P>
                <P bold colorBlack>
                  {name}
                </P>
              </div>
            )}
            {codiceFiscale && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkCf" />
                </P>
                <P bold colorBlack>
                  {codiceFiscale}
                </P>
              </div>
            )}
            {vatCode && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkVatCode" />
                </P>
                <P bold colorBlack>
                  {vatCode}
                </P>
              </div>
            )}
            {address && (
              <div>
                <P>
                  <Translate id="text.address" />
                </P>
                <P bold colorBlack>
                  {address}
                </P>
              </div>
            )}
          </ContainerInfo>
        </div>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.correctData"
            onClick={onSubmit}
            id={IDs.btnConfirm}
          />
          <Button
            variant="Tertiary"
            disabled={isLoading}
            minWidth="100%"
            i18n={true}
            translatedText={
              results.length > 1 ? 'text.showMoreCompanies' : 'lbl.wrongData'
            }
            onClick={() =>
              results.length > 1
                ? navigate(
                    RoutePath.registrationMerchant + RoutePath.chooseCompany,
                  )
                : navigate(-1)
            }
            id={IDs.btnDecline}
          />
        </ButtonContainer>
      </FlowContainer>
    </>
  );
}

export default RegistrationStepCheckData;
