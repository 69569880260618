import React from 'react';
import { FormatCurrencyOptions } from './i18n.types';
import { currencyCode, fallbackLng } from './i18n.config';
import { createDateTimeFormatters, createFormatCurrency } from './i18n.helpers';

/**
 * Returns the I18n context
 * @see CurrencyFormatter in './i18n.types.d.ts'
 * @example
 * ```typescript
 * const MyComp: React.FC<{ money: string }> = ({ money }) => {
 *   const { formatters: { formatDate }} = useI18n();
 *   const now = new Date();
 *   return <span>{formatDate(now)}</span>;
 * }
 * ```
 */
export const useI18n = () => {
  const languageCode = fallbackLng;

  const contextValue = React.useMemo(
    () => ({
      formatters: {
        formatCurrency: createFormatCurrency(languageCode),
        ...createDateTimeFormatters(languageCode),
      },
      info: {
        currencyCode,
        locale: languageCode,
        decimalSeparator: languageCode === 'en-GB' ? '.' : ',',
        thousandSeparator: languageCode === 'en-GB' ? ',' : '.',
      },
    }),
    [languageCode],
  );

  return contextValue;
};

/**
 * Formats a number to 'EUR' according to the current locale.
 * @param money number to format.
 * @param options optional additional configuration.
 * @param options.hideCents If 'true' does not display cents. Default 'false'.
 * @param options.isNumberExpressedInCents If 'true' input number gets divided
 * by '100' before being formatted. Default 'true'.
 *  * @example
 * ```typescript
 * const MyComp: React.FC<{ money: string }> = ({ money }) => {
 *   const formattedCurrency = useFormatCurrency(money);
 *
 *   return <span>{formattedCurrency}</span>;
 * }
 * ```
 * @see CurrencyFormatter in './i18n.types.d.ts'
 */
export const useFormatCurrency = (
  money: number,
  options?: Partial<FormatCurrencyOptions>,
) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const formattedCurrency = React.useMemo(
    () => formatCurrency(money, options),
    [formatCurrency, money, options],
  );

  return formattedCurrency;
};
