import typographicScale from 'src/style-utils/typographic-scale';
import colors from 'src/style-utils/colors';

const selectTheme = {
  select: {
    typography: {
      color: colors.textInputColor,
      fontSize: typographicScale.input,
      fontWeight: '500',
      desktop: {
        fontSize: typographicScale.input,
      },
    },
    root: {
      flex: '0 1 auto',
      width: 'unset',
      minWidth: 'unset',
    },
    listboxRoot: {
      minWidth: '5rem',
      maxWidth: '18rem',
      height: '3.6rem',
      margin: '0',
      desktop: {
        minWidth: '5rem',
        maxWidth: '18rem',
        height: '4.4rem',
        margin: '0',
      },
      tablet: {
        height: '4.4rem',
      },
    },
    button: {
      iconColor: 'currentColor',
      justify: 'space-between',
      borderRadius: '4px',
      border: `0.1rem solid ${colors.inputBorderColor}`,
      borderRight: `0.1rem solid ${colors.inputBorderColor}`,
      boxShadow: 'none',
      padding: '0',
      contentPadding: '0 0 0 1.3rem',
      desktop: {
        padding: '0 1rem',
        contentPadding: '0 0 0 1.3rem',
      },
    },
    buttonWrapper: {
      right: '.6rem',
    },
    list: {
      marginTop: '4.35rem',
      borderRadius: '0 0 8px 8px',
      background: colors.white,
      // It is 'rootHeight' + 4rem.
      padding: '0',
      desktop: {
        padding: '0',
      },
      expanded: {
        // Controls the amount of visible options when it is expanded.
        height: '28rem',
        maxHeight: '28rem',
      },
    },
    listItem: {
      padding: '0 1.2rem',
      height: '3.2rem',
      margin: '0',
      selected: {
        background: colors.white,
      },
    },
    icon: {
      color: colors.midGrey,
    },
  },
};

export default selectTheme;
