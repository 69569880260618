import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { useState } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { H4, H5 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  AccordionArrow,
  AccordionBody,
  AccordionContainer,
  ListContainer,
  ResponsiveAccordionFlex,
} from './style/ResponsiveAccordion.style';

interface Props {
  listItems: {
    SvgComponent: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    label: string;
    content: string;
  }[];
}

function ResponsiveAccordion({ listItems }: Props) {
  const [selected, setSelected] = useState(0);
  function clickHandler(index: number) {
    setSelected(selected !== index ? index : -1);
  }
  return (
    <ListContainer data-component="responsive-accordion">
      {listItems.map(({ SvgComponent, content, label }, index) => (
        <AccordionContainer
          key={index}
          onClick={() => clickHandler(index)}
          data-expanded={selected === index}
        >
          <Flex fluid justify="space-between" align="center">
            <ResponsiveAccordionFlex>
              <SvgComponent />
              <H4>
                <Translate id={label} />
              </H4>
            </ResponsiveAccordionFlex>
            <AccordionArrow svgIconComponent={ArrowDown} iconSize="4rem" />
          </Flex>
          <AccordionBody>
            <div style={{ overflow: 'hidden' }}>
              <H5 colorGray marginTop="1.6rem" light>
                <Translate id={content} />
              </H5>
            </div>
          </AccordionBody>
        </AccordionContainer>
      ))}
    </ListContainer>
  );
}

export default ResponsiveAccordion;
