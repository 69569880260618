import SuccessPage from 'src/pages/registration/partials/SuccessPage/SuccessPage.component';
import { RoutePath } from 'src/routers/routers.config';

function RegistrationGuestSuccess() {
  return (
    <SuccessPage
      nextPath={`${RoutePath.privateArea}?typeAccount=BUYER`}
      title="text.successAccountCreatedTitle"
      buttonLabel="lbl.enterReservedArea"
      subTitle="lbl.successRegistrationDescr"
      showSuccessData
    />
  );
}

export default RegistrationGuestSuccess;
