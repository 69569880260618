import FormPassword from 'src/pages/registration/partials/FormPassword/FormPassword.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationCollaboratorStepPassword: React.FC = () => {
  return (
    <FormPassword
      nextPath={RoutePath.registrationCollaborator + RoutePath.flagStep}
      step={3}
      stepMax={4}
    />
  );
};

export default RegistrationCollaboratorStepPassword;
