import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  initDataChange,
  performDataChange,
  refreshAuthOtpForDataChange,
  refreshPerformOtpForDataChange,
  updateNewValueForDataChange,
  verifyOtpForDataChange,
} from './dataChange.config';
import {
  DataChangeResponse,
  InitDataChangeRequest,
  InitDataChangeResponse,
  PerformDataChangeRequest,
  RefreshOtpForDataChangeRequest,
  UpdateNewValueForDataChangeRequest,
  VerifyOtpForDataChangeRequest,
} from './dataChange.types';

export const initDataChangeMethodApi = async (data: InitDataChangeRequest) => {
  const requestConfig = makeEndpointApiConfig({
    url: initDataChange,
    method: 'POST',
    data,
    authenticated: true,
  });
  return await apiRequest.request<InitDataChangeResponse>(requestConfig);
};

export const verifyOtpForDataChangeMethodApi = async (
  data: VerifyOtpForDataChangeRequest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifyOtpForDataChange,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<DataChangeResponse>(requestConfig);
};

export const refreshAuthOtpForDataChangeMethodApi = async (
  params: RefreshOtpForDataChangeRequest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: refreshAuthOtpForDataChange,
    method: 'POST',
    params,
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
export const updateNewValueForDataChangeMethodApi = async (
  data: UpdateNewValueForDataChangeRequest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: updateNewValueForDataChange,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<DataChangeResponse>(requestConfig);
};
export const refreshPerformOtpForDataChangeMethodApi = async (
  params: RefreshOtpForDataChangeRequest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: refreshPerformOtpForDataChange,
    method: 'POST',
    params,
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
export const performDataChangeMethodApi = async (
  data: PerformDataChangeRequest,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: performDataChange,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.sessionGuid,
    },
    authenticated: true,
  });
  return await apiRequest.request<DataChangeResponse>(requestConfig);
};
