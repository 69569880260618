import { ListTableAdvPlanRequests } from 'src/pages/advancePlanRequests/AdvancePlanRequests.types';
import React from 'react';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';

export const FeeColumn: React.FC<ListTableAdvPlanRequests> = ({ Fee }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return <P textColor={colors.textTableBody}>-&nbsp;{formatCurrency(Fee)}</P>;
};

export default FeeColumn;
