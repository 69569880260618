import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Fishes } from 'src/images/icons/fishes.svg';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useSession } from 'src/services/session/session.context';
import { useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import IsYourCompanyModalLodable from './BuyerFiscalCodeErrorModal/IsYourCompanyModal.lodable';

const BuyerFiscalCodeError: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const plugin = location?.state?.plugin;
  const navigate = useNavigate();
  const AccountType = useProfileStore((state) => state.AccountType);
  const { onLogout } = useSession();
  return (
    <>
      <Icon
        svgIconComponent={Fishes}
        iconHeight="19.5rem"
        iconSize="26.6rem"
        margin="2.6rem auto 2.7rem"
        display="flex"
      />
      <div className="max-w-olg text-center">
        <H4>
          <Translate
            id={plugin ? 'error.ecommerceBuyerFiscalCode' : 'text.buyerCfError'}
          />
        </H4>
        {!plugin && (
          <P>
            <Translate id={'text.subTitleBuyerCfError'} />
          </P>
        )}
        {plugin && (
          <ButtonContainer marginTop="4rem">
            <Button
              onClick={() => {
                if (plugin) {
                  (window as any).parent.postMessage(
                    JSON.stringify({ status: 'close' }),
                    '*',
                  );
                  onLogout();
                } else
                  navigate(
                    `${RoutePath.privateArea}?typeAccount=${AccountType}`,
                  );
              }}
              minWidth="100%"
              variant="Primary"
            >
              <Translate id="lbl.backToEcommerce" />
            </Button>
          </ButtonContainer>
        )}
        <ButtonContainer>
          <NeedHelpButton />
        </ButtonContainer>
      </div>
      {modalOpen && (
        <IsYourCompanyModalLodable
          buttonTitle="text.okUnderstand"
          modalTitle="Da dove abbiamo ricavato i tuoi dati aziendali?"
          handleClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default BuyerFiscalCodeError;
