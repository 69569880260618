import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { Buyer } from 'src/services/orderLink/orderLink.types';
import { getBuyersWithMovements } from './buyersWithMovements.config';

export const getBuyersWithMovementsMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getBuyersWithMovements,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<{ ResultSet: Buyer[] }>(requestConfig);
};
