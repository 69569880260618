import FormPhone from 'src/pages/registration/partials/FormPhone/FormPhone.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationStepPhone: React.FC = () => {
  return (
    <FormPhone
      nextPath={RoutePath.registrationMerchant + RoutePath.otpConfirmPhoneStep}
      step={6}
      stepMax={9}
    />
  );
};

export default RegistrationStepPhone;
