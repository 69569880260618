import { flexAmount } from 'src/components/FlexInstallmentCard/FlexInstallmentCard.config';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Bill } from 'src/images/icons/bill.svg';
import { ReactComponent as NegativeFeed } from 'src/images/icons/negative-feedback-point-bg.svg';
import { ReactComponent as PositiveFeed } from 'src/images/icons/positive-feed.svg';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

const TotalInstallmentForm: React.FC<{
  NumRate: number;
  ImportoOrdine: number;
  residualAmount: number;
  setResidualAmount: React.Dispatch<React.SetStateAction<number>>;
}> = ({ NumRate, setResidualAmount, ImportoOrdine, residualAmount }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const values = useWatch();
  const inputs = [...Array(NumRate)];
  useEffect(() => {
    const amounts = inputs.map((item, index) =>
      parseFloat(values[`${flexAmount}-${index}`] || 0),
    );
    const total = ImportoOrdine - amounts.reduce((acc, val) => acc + val, 0);
    setResidualAmount(total);
  }, [values]);
  return (
    <Flex
      data-component="total-installment-form"
      justify="center"
      align="center"
      gap="0.5rem"
    >
      {residualAmount > 0 && <Icon svgIconComponent={Bill} />}
      {residualAmount === 0 && <Icon svgIconComponent={PositiveFeed} />}
      {residualAmount < 0 && <Icon svgIconComponent={NegativeFeed} />}
      <P
        textColor={
          residualAmount === 0
            ? colors.greenInput
            : residualAmount < 0
              ? colors.redInput
              : colors.textColorBlack
        }
      >
        {residualAmount > 0 && (
          <Translate
            id="lbl.flexOrderCustomTotal"
            data={{ residualAmount: formatCurrency(residualAmount) }}
          />
        )}
        {residualAmount === 0 && <Translate id="lbl.flexOrderCustomTotalOk" />}
        {residualAmount < 0 && (
          <Translate
            id="lbl.flexOrderCustomTotalKo"
            data={{ ImportoOrdine: formatCurrency(ImportoOrdine) }}
          />
        )}
      </P>
    </Flex>
  );
};

export default TotalInstallmentForm;
