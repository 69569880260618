import { SyncFieldValidator } from './types';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

export const isEmail = (str: string) => emailRegex.test(str);

export const errorMessage = 'error.email';

const email: SyncFieldValidator = function email(
  value: unknown,
): string | undefined {
  if (typeof value !== 'string' || !isEmail(value)) {
    return errorMessage;
  }

  return undefined;
};

export default email;
