import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled, { css } from 'styled-components';
import { ButtonIconBaseProps } from '../IconButton.component';

export const IconButtonBase = styled.button<ButtonIconBaseProps>`
  /*FIXED STYLES*/
  cursor: pointer;
  outline: none;
  outline: 0;
  background: ${colors.white};
  color: ${colors.textBtn};
  border-radius: 99rem;
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    color: ${colors.textBtnGrey};
  }
  &:hover {
    background: ${colors.bgBtnHoverPrimary};
    color: ${colors.textBtnHoverPrimary};
  }
  /* STYLES FROM PROPS */
  ${({
    padding = '1.6rem',
    display = 'flex',
    pointerEvents,
  }: ButtonIconBaseProps) => css`
    padding: ${padding};
    display: ${display};
    ${pointerEvents ? `pointer-events:${pointerEvents}` : ''};
  `}

  /* DYNAMIC STYLES */
  ${({ variant = 'Primary' }) => ButtonTheme[variant]}
  ${({ sizeType = 'small' }) => ButtonSizeTheme[sizeType]}
  
  /*MEDIA QUERIES */
  ${media.tablet`
   ${({
     padding = '1.6rem',
     sizeTypeOnDesktop = 'medium',
   }: ButtonIconBaseProps) => css`
     padding: ${padding};
     ${ButtonSizeTheme[sizeTypeOnDesktop]}
   `};
  `}
`;

const ButtonSizeTheme = {
  small: css`
    padding: 0.4rem;
    width: 4.4rem;
    height: 4.4rem;
  `,
  medium: css`
    padding: 0.7rem;
    width: 5.4rem;
    height: 5.4rem;
  `,
  large: css`
    padding: 1.2rem;
    width: 6.4rem;
    height: 6.4rem;
  `,
};

const ButtonTheme = {
  Primary: css`
    background-color: ${colors.bgBtnPrimary};
    &[disabled] {
      background-color: ${colors.bgBtnDisabledPrimary};
      color: ${colors.textBtnDisabledPrimary};
    }
    &:hover {
      background-color: ${colors.bgBtnHoverPrimary};
      color: ${colors.textBtnHoverPrimary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedPrimary};
    }
    &:active {
      outline: solid 2px ${colors.bgBtnPressedPrimary};
    }
  `,
  Secondary: css`
    background-color: ${colors.bgBtnSecondary};
    color: ${colors.white};
    &[disabled] {
      background-color: ${colors.bgBtnDisabledSecondary};
      color: ${colors.textBtnDisabledSecondary};
    }
    &:hover {
      background-color: ${colors.bgBtnHoverSecondary};
      color: ${colors.textBtnHoverSecondary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedSecondary};
    }
    &:active {
      outline: solid 2px ${colors.bgBtnPressedSecondary};
    }
  `,
  Tertiary: css`
    background-color: ${colors.bgBtnTertiary};
    outline: solid 0.1rem ${colors.borderbgBtnTertiary};
    color: ${colors.textBtnHoverTertiary};
    &[disabled] {
      background-color: ${colors.bgBtnDisabledTertiary};
      color: ${colors.textBtnDisabledTertiary};
      outline: none;
    }
    &:hover {
      background-color: ${colors.bgBtnHoverTertiary};
      color: ${colors.textBtnHoverTertiary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedTertiary};
      color: ${colors.textBtnFocusedTertiary};
    }
    &:active {
      outline: solid 0.2rem ${colors.bgBtnPressedTertiary};
    }
  `,
  Quaternary: css`
    background-color: ${colors.bgBtnQuaternary};
    border-radius: 99rem;
    color: ${colors.textBtnHoverQuaternary};
    &[disabled] {
      cursor: not-allowed;
      background-color: ${colors.bgBtnDisabledQuaternary};
      color: ${colors.textBtnDisabledQuaternary};
      outline: none;
      width: auto;
    }
    &:hover {
      background-color: ${colors.bgBtnHoverQuaternary};
      color: ${colors.textBtnHoverQuaternary};
    }
    &:focus {
      color: ${colors.textBtnHoverQuaternary};
    }
    &:active {
      outline: solid 0.2rem ${colors.borderbgBtnQuaternary};
    }
  `,
  LinkPrimary: css`
    background: none;
    outline: none;
    color: ${colors.textBtnLinkPrimary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverPrimary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedPrimary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedPrimary};
    }
  `,
  LinkSecondary: css`
    background: none;
    outline: none;
    color: ${colors.textBtnLinkSecondary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverSecondary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedSecondary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedSecondary};
    }
  `,
  TextButton: css`
    background: none;
    outline: none;
    color: ${colors.textBtnLinkPrimary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverPrimary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedPrimary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedPrimary};
    }
  `,
};
