import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { FindOutMoreEnum } from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.config';
import FindOutMoreModalLoadable from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.loadable';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getDocumentsToSign } from 'src/services/onboarding/onboarding.config';
import {
  getDocumentPreviewMethodApi,
  getDocumentsToSignMethodApi,
  openSigningSupportMethodApi,
} from 'src/services/onboarding/onboarding.request';
import {
  Contract,
  GetDocumentPreviewData,
  OpenSigningSupportData,
} from 'src/services/onboarding/onboarding.types';
import { H4, P, PLegal } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { openPdfInNewTab } from 'src/utils/functions/download';
import { wrongData, wrongDataFormConfig } from './ReadContract.helpers';
import ContractCard from './partials/ContractCard.component';
import IncorrectDataModal from './partials/modal/IncorrectDataModal';

function ReadContract() {
  const [showModal, setShowModal] = useState(false);
  const [showModalHeader, setShowModalHeader] = useState(false);
  const location = useLocation();
  const alreadyReading = location?.state?.alreadyReading;
  const navigate = useNavigate();
  const { isLoading, data: dataDocuments } = useQuery(
    getDocumentsToSign,
    () => getDocumentsToSignMethodApi(),
    {
      onError: () => {
        navigate(RoutePath.internalError);
      },
    },
  );

  const { initialValues, resolver } = wrongDataFormConfig();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const [viewedContracts, setViewedContracts] = useState<number[]>([]);
  const contractsRead =
    viewedContracts?.length === dataDocuments?.data.ResultSet?.length ||
    alreadyReading;

  const { mutate, isLoading: isLoadingSupport } = useMutation(
    (params: OpenSigningSupportData) => openSigningSupportMethodApi(params),
    {
      onSuccess: () => {
        navigate(RoutePath.onboarding + RoutePath.assistance);
      },
      onError: () => {
        navigate(RoutePath.internalError);
      },
    },
  );

  const { isLoading: isLoadingDocument, mutate: mutateDocument } = useMutation(
    (values: GetDocumentPreviewData) => getDocumentPreviewMethodApi(values),

    {
      onSuccess: (res) => {
        openPdfInNewTab(
          res.data.ResultSet.Content,
          res.data.ResultSet.ContentType,
        );
      },
      onError: () => {
        navigate(RoutePath.internalError);
      },
    },
  );

  function viewClickHandler(index: number, contract: Contract) {
    if (!viewedContracts?.includes(index)) {
      setViewedContracts([...viewedContracts, index]);
    }
    mutateDocument({ documentId: contract.DocumentId });
  }

  const onSubmit = (data: any) => {
    if (data[wrongData] === 'incorrectDataModal.checkbox2') {
      navigate(RoutePath.onboarding + RoutePath.signWithSpid, {
        state: { wrongData: true },
      });
      return;
    }
    const assistanceData: OpenSigningSupportData = {
      hasSpid: false,
      representativeIsWrong: true,
      otherWrongData:
        data[wrongData] === 'incorrectDataModal.checkbox3' ? true : false,
    };
    mutate({ ...assistanceData });
    setShowModal(false);
  };

  if (isLoading || isLoadingDocument || isLoadingSupport) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <FlowHeader
        label="lbl.readContract"
        showWhiteSpace
        stepNumber={1}
        stepMaxNumber={3}
        showInfoButton
        infoButtonAction={() => setShowModalHeader(true)}
      />
      <FlowContainer padding="0 0 2rem">
        <FormProvider {...methods}>
          <H4>
            <Translate id="text.readContractTitle" />
          </H4>
          <P className="centerOnDesktop">
            <Translate id="text.readContractContent" />
          </P>
          <div className="content">
            {dataDocuments?.data.ResultSet.map((contract, index) => (
              <ContractCard
                name={contract.DocumentName}
                key={index}
                number={index + 1}
                viewButtonClick={() => viewClickHandler(index, contract)}
              />
            ))}
          </div>
          <ButtonContainer>
            <Button
              type="button"
              variant="Primary"
              disabled={!contractsRead}
              minWidth="100%"
              i18n={true}
              translatedText="lbl.procedeToSign"
              onClick={() => {
                navigate(RoutePath.onboarding + RoutePath.signWithSpid);
              }}
            />
            <Button
              type="button"
              variant="Tertiary"
              disabled={!contractsRead}
              minWidth="100%"
              i18n={true}
              translatedText="lbl.someDataAreWrong"
              onClick={() => setShowModal(true)}
            />
            {!contractsRead && (
              <PLegal className="centerOnDesktop">
                <Translate id="text.readContractAlert" />
              </PLegal>
            )}
          </ButtonContainer>
          {showModal && (
            <IncorrectDataModal
              buttonTitle="lbl.close"
              modalTitle="incorrectDataModal.title"
              applyForm={onSubmit}
              handleClose={() => setShowModal(false)}
              i18n
            />
          )}
        </FormProvider>
        {showModalHeader && (
          <FindOutMoreModalLoadable
            buttonTitle="lbl.close"
            modalTitle="text.findOutMoreNamirialTitlePage"
            type={FindOutMoreEnum.namirial}
            handleClose={() => setShowModalHeader(false)}
            i18n
          />
        )}
      </FlowContainer>
    </>
  );
}

export default ReadContract;
