import RadioBox from 'src/components/RadioBox/RadioBox.component';
import moment from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

type RadioBoxPopoverProps = {
  label: string;
  name: string;
  value: string;
  id: string;
  setStateHandler: Dispatch<SetStateAction<boolean>>;
};
export const RadioBoxPopover: React.FC<RadioBoxPopoverProps> = ({
  label,
  name,
  value,
  id,
  setStateHandler,
}) => {
  const { setValue } = useFormContext();
  const onClick = () => {
    const year = +id;
    setStateHandler(false);
    setValue('custom', '');
    setValue('endDate', moment.utc({ year }).endOf('year').toISOString());
  };

  return (
    <RadioBox
      label={label}
      name={name}
      i18n
      value={value}
      id={id}
      onClick={onClick}
      large={false}
    />
  );
};
