import { Button } from 'src/components/Button/Button.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const SpanInfo = styled.span`
  color: ${colors.textColorGrey};
  font-weight: 700;
`;

export const LabelAmountMax = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: 0.6rem;
  padding: 0.4rem 0.8rem;
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
`;

export const ButtonNext = styled(Button)`
  height: 4.4rem;
  width: 4.4rem;
  border-radius: 50%;
  ${media.tablet`
    height: 6.4rem;
    width: 6.4rem;
  `}
`;
