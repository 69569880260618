import Translate from 'src/components/Translate/Translate.component';
import colors from 'src/style-utils/colors';
import { PLegal } from 'src/style-utils/typographic';
import styled from 'styled-components';

const CustomUl = styled.ul`
  margin-top: 1.6rem;
  padding: 1.6rem;
  background-color: ${colors.backgroundGreen};
  display: grid;
  gap: 0.8rem;
  color: ${colors.textP};
  border-radius: 0.8rem;
  li {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
`;

type Props = {
  sdd?: boolean;
};

const LegalInfos = ({ sdd = false }: Props) => {
  return (
    <CustomUl>
      <li>
        &bull;
        <PLegal>
          <Translate id="text.payWithoutInterest" />
        </PLegal>
      </li>
      <li>
        &bull;
        <PLegal>
          <Translate id="text.flexPayment" />
        </PLegal>
      </li>
      <li>
        &bull;
        <PLegal>
          <Translate
            id={sdd ? 'text.credit&DebitCard&SDD' : 'text.credit&DebitCard'}
          />
        </PLegal>
      </li>
    </CustomUl>
  );
};

export default LegalInfos;
