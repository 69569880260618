import React from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';
import { Input } from 'src/style/styleInput.style';

const formatInput = (value: string) => {
  return value.trim().slice(0, 20);
};

const InputPhoneNumber: React.FC<NumberFormatBaseProps> = React.forwardRef(
  ({ customInput = Input, ...otherProps }, ref) => {
    return (
      <NumberFormatBase
        data-component="input-phone-number"
        {...otherProps}
        valueIsNumericString
        inputMode="numeric"
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        getInputRef={ref}
        customInput={customInput}
        format={formatInput}
      />
    );
  },
);

export default InputPhoneNumber;
