import styled from 'styled-components';
import colors from 'src/style-utils/colors';

export const PopupDiv = styled.section`
  [data-radix-popper-content-wrapper] {
    transform: none !important;
    position: unset;
  }
`;

export const InstallmentsProcessingLbl = styled.a`
  color: ${colors.textColorGrey};
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  line-height: 18px;
  text-decoration-line: underline;
  font-weight: 400;
  cursor: pointer;

  .highlighted {
    font-weight: 700;
  }
`;

export const CustomInstallmentsProcessingDisclaimer = styled.span`
  color: ${colors.textColorGrey};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
`;
