import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Geomanist';
    src: url('/fonts/geomanist-bold-webfont.woff2') format('woff2'),
    url('/fonts/geomanist-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: 'Geomanist';
    src: url('/fonts/geomanist-medium-webfont.woff2') format('woff2'),
    url('/fonts/geomanist-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: medium;
}

@font-face {
    font-family: 'Geomanist';
    src: url('/fonts/geomanist-regular-webfont.woff2') format('woff2'),
    url('/fonts/geomanist-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: regular;
}

`;
export default GlobalStyle;
