import styled from 'styled-components';
import { withMargin, WithMarginProps } from 'src/style-utils/dimensionsMixins';

export type IconRootProps = {
  iconSize?: string;
  iconHeight?: string;
  iconWidth?: string;
  className?: string;
  iconSrc?: string;
  inlineIconSrc?: boolean;
  display?: string;
  cursor?: string;
} & WithMarginProps;

export const IconRoot = styled.div.attrs<IconRootProps>(
  ({ iconSrc, inlineIconSrc, color, display }) => ({
    // Use style directly as a performance optimization when you need to render
    // a alot of icons:
    // inlined styles can be overridden only with '!important'.
    style: {
      backgroundImage:
        iconSrc && inlineIconSrc ? `url("${iconSrc}")` : undefined,
      color: color,
      display: display,
    },
  }),
)<IconRootProps>`
  /* display: inline-flex; */
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ${({ iconSize = '24px', iconHeight, iconWidth }) => `
    width: ${iconWidth || iconSize};
    height: ${iconHeight || iconSize};
  `};
  ${({ cursor = '' }) => `
    cursor: ${cursor};
  `};
  ${({ iconSrc, inlineIconSrc }): string => {
    if (iconSrc && !inlineIconSrc) {
      return `background-image: url("${iconSrc}")`;
    }
    return '';
  }};

  ${withMargin};
  svg {
    max-height: 100%;
    max-width: 100%;
  }
`;
IconRoot.defaultProps = {
  margin: '0',
  inlineIconSrc: false,
  'aria-hidden': true,
};
