import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import Loader from 'src/components/Loader/Loader.component';
import PaymentMethodElementButton from 'src/components/PaymentMethodElement/PaymentMethodElementButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { MethodsContextType } from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  removePaymentMethodApi,
  setBackupPaymentMethodApi,
  setPreferredPaymentMethodApi,
} from 'src/services/payments/payments.request';
import { useProfileStore } from 'src/store/store';
import { H4, H5, P } from 'src/style-utils/typographic';
import { cardIsExpired } from 'src/utils/functions/cardExpired';
import ConfirmModalLoadable from './confirmModal/ConfirmModal.loadable';
import OptionsModal from './optionsModal/OptionsModal.loadable';
import Toast from 'src/components/Toast/Toast.component';
import { getCookie } from 'src/utils/functions/cookie';
import ErrorModalLoadable from './modalError/ErrorModal.loadable';

function PaymentMethodsPage() {
  const [confirmPreferredOpen, setConfirmPreferredOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmBackupOpen, setConfirmBackupOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const {
    refetchPaymentMethods,
    paymentMethods,
    goToStripeFormHandler,
    backupMethod,
  } = useOutletContext<MethodsContextType>();

  const { setValue, CanPayWithSDD } = useProfileStore((state) => state);

  useEffect(() => {
    setValue('changedMethod', selectedMethod);
  }, []);

  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<
    PaymentMethodResult | undefined
  >(undefined);
  const selectedId = selectedMethod?.Id ?? -1;
  const goToChangeBackup = () =>
    navigate(RoutePath.methods + RoutePath.setBackup);

  const closeConfirm = () => {
    setConfirmPreferredOpen(false);
    setConfirmBackupOpen(false);
    setConfirmDeleteOpen(false);
    setErrorModalOpen(false);
    setSelectedMethod(undefined);
  };

  const { mutate: mutatePreferred, isLoading: isSettingPreferred } =
    useMutation(() => setPreferredPaymentMethodApi(selectedId), {
      onError: () => navigate(RoutePath.oops),
      onSuccess: () => {
        const d = new Date();
        d.setTime(d.getTime() + 100 * 1000);
        document.cookie = 'changed=true; expires=' + d.toUTCString() + 'path=/';
        setSelectedMethod(undefined);
        refetchPaymentMethods();
      },
    });

  const { mutate: mutateRemoveMethod, isLoading: isRemovingMethod } =
    useMutation(() => removePaymentMethodApi(selectedId), {
      onSuccess: () => {
        const d = new Date();
        d.setTime(d.getTime() + 100 * 1000);
        document.cookie = 'removed=true; expires=' + d.toUTCString() + 'path=/';
        setSelectedMethod(undefined);
        refetchPaymentMethods();
      },
      onError: () => setErrorModalOpen(true),
    });

  const { mutate: mutateSetBackupMethod, isLoading: isLoadingSetBackupMethod } =
    useMutation(
      () => setBackupPaymentMethodApi({ BackupPaymentMethodId: selectedId }),
      {
        onSuccess: () => {
          const d = new Date();
          d.setTime(d.getTime() + 100 * 1000);
          document.cookie =
            'backup=true; expires=' + d.toUTCString() + 'path=/';
          setSelectedMethod(undefined);
          refetchPaymentMethods();
        },
      },
    );

  if (isSettingPreferred || isRemovingMethod || isLoadingSetBackupMethod)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  const paymentMethodBackup = paymentMethods.find((item) => item.IsBackup);

  const confirmPreferredHandler = () => {
    if (selectedMethod?.Type === 'sepa_debit' && backupMethod === undefined) {
      setValue('changedMethod', selectedMethod);
      navigate(RoutePath.methods + RoutePath.addBackupMethod);
    } else {
      mutatePreferred();
    }
  };
  const changedAlert = getCookie('changed');
  const backupAlert = getCookie('backup');
  const removedAlert = getCookie('removed');
  const addedAlert = getCookie('added');

  return (
    <>
      {addedAlert === 'true' && (
        <Toast
          variant="success"
          title="lbl.addedAlert.title"
          subTitle="lbl.addedAlert.content"
          slideIn
          timeout={3000}
          cookieName="added"
        />
      )}
      {changedAlert === 'true' && (
        <Toast
          variant="success"
          title="lbl.preferredAlert.title"
          subTitle="lbl.preferredAlert.content"
          slideIn
          timeout={3000}
        />
      )}
      {backupAlert === 'true' && (
        <Toast
          variant="success"
          title="lbl.backupAlert.title"
          subTitle="lbl.backupAlert.content"
          slideIn
          timeout={3000}
          cookieName="backup"
        />
      )}
      {removedAlert === 'true' && (
        <Toast
          variant="success"
          title="lbl.removedAlert.title"
          subTitle="lbl.removedAlert.content"
          slideIn
          timeout={3000}
          cookieName="removed"
        />
      )}
      <div className="container py-8">
        <section>
          <div className="max-w-oxl">
            <H4>
              <Translate id="menu.paymentmethods" />
            </H4>
            <P>
              <Translate id="text.paymentMethods.subtitle1" />
            </P>
          </div>
          <div className="mx-auto my-6 grid max-w-oxl gap-6">
            {paymentMethods.map((method) => (
              <PaymentMethodElementButton
                key={method.Id}
                payCard={method}
                onClickHandler={() => setSelectedMethod(method)}
                arrow
                preferred={method.IsPreferred}
              />
            ))}
            <ButtonCard
              createPaymentIntentHandler={() => goToStripeFormHandler()}
              type="change"
            />
          </div>
        </section>
        {CanPayWithSDD && (
          <section className="mt-12">
            <div className="max-w-oxl">
              <H5 colorGray>
                <Translate id="lbl.orderPaymentMethod2" />
              </H5>
              <P>
                <Translate id="text.paymentMethods.subtitle2" />
              </P>
            </div>
            <div className="mx-auto my-6 grid max-w-oxl gap-6">
              {paymentMethodBackup !== undefined ? (
                <PaymentMethodElementButton
                  payCard={paymentMethodBackup}
                  onClickHandler={() => setSelectedMethod(paymentMethodBackup)}
                  arrow
                />
              ) : (
                <ButtonCard
                  createPaymentIntentHandler={() => goToStripeFormHandler(true)}
                  type="change"
                />
              )}
            </div>
          </section>
        )}
      </div>
      {selectedMethod !== undefined &&
        !confirmPreferredOpen &&
        !confirmDeleteOpen && (
          <OptionsModal
            i18n
            handleClose={() => setSelectedMethod(undefined)}
            buttonTitle="lbl.close"
            modalTitle="lbl.optionsAvailable"
            isBackup={selectedMethod.IsBackup}
            isCard={selectedMethod.Type === 'card'}
            isExpired={cardIsExpired(selectedMethod)}
            setPreferredHandler={() => setConfirmPreferredOpen(true)}
            removeMethodHandler={() => setConfirmDeleteOpen(true)}
            setBackupHandler={() => setConfirmBackupOpen(true)}
            goToChangeBackup={goToChangeBackup}
          />
        )}
      {!errorModalOpen && confirmPreferredOpen && (
        <ConfirmModalLoadable
          i18n
          confirmHandler={confirmPreferredHandler}
          modalTitle="text.confirmPreferredModal.title"
          content="text.confirmPreferredModal.content"
          confirmLabel="text.confirmPreferredModal.button"
          handleClose={closeConfirm}
        />
      )}
      {!errorModalOpen && confirmBackupOpen && (
        <ConfirmModalLoadable
          i18n
          confirmHandler={mutateSetBackupMethod}
          modalTitle="text.confirmBackupModal.title"
          content="text.confirmBackupModal.content"
          confirmLabel="text.confirmBackupModal.button"
          handleClose={closeConfirm}
        />
      )}
      {!errorModalOpen && confirmDeleteOpen && (
        <ConfirmModalLoadable
          i18n
          confirmHandler={mutateRemoveMethod}
          modalTitle="text.confirmDeleteModal.title"
          content="text.confirmDeleteModal.content"
          confirmLabel="text.confirmDeleteModal.button"
          handleClose={closeConfirm}
        />
      )}
      {errorModalOpen && (
        <ErrorModalLoadable
          i18n
          modalTitle="text.errorModalRemove.title"
          buttonTitle="lbl.close"
          handleClose={closeConfirm}
        />
      )}
    </>
  );
}

export default PaymentMethodsPage;
