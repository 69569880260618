export const resetRegistrationStore = {
  guid: '',
  idUtente: 0,
  tipoAccount: '',
  statoAccount: 0,
  email: '',
  codiceFiscale: '',
  mobile: '',
  flagCessioneCreditoMerchant: true,
  hasSpid: false,
  representativeIsWrong: false,
  otherWrongData: false,
  name: '',
  vatCode: '',
  address: '',
  sessionGuid: '',
  postalCode: '',
  companySearchId: '',
  longRegistration: false,
  results: [],
  guestSignInGuid: '',
};
