import media from 'src/style-utils/media-queries';
import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import { P } from 'src/style-utils/typographic';

export const Header = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const TitleContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  ${media.tablet`
    display: flex;
justify-content: center;
align-items: center;
gap: 12px;
align-self: stretch;
        `}
`;

export const Title = styled(P)`
  color: #35302f;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;

  ${media.tablet`
color: #35302f;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 48px;
        `}
`;

export const Subtitle = styled(P)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: start;

  ${media.tablet`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
    text-align: center;

        `}
`;
