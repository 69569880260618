import { Button } from 'src/components/Button/Button.component';
import React, { PropsWithChildren } from 'react';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { H2 } from 'src/style-utils/typographic';
import styled from 'styled-components';
import { ReactComponent as BrandBG } from 'src/images/icons/brand_mini.svg';

export const CustomGrid: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="container grid gap-6 text-center lg:grid-cols-2 lg:text-left">
      {children}
    </div>
  );
};

export const CustomSeparator: React.FC = () => {
  return (
    <hr className="block h-10 w-full rounded-b-[50%] border-none bg-white lg:h-16" />
  );
};

export const ButtonCustom = styled(Button)`
  width: min(100%, 50ch);
  margin: 0 auto;
  ${media.tablet`
    margin: 0;
    max-width: 28rem;
  `}
`;

export const AnimatedBg = styled(BrandBG)`
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  pointer-events: none;
  position: absolute;
  width: 44rem;
  height: 44rem;
  right: -15rem;
  bottom: -8rem;
  z-index: -1;
  animation: rotate 50s linear infinite;
  ${media.tablet`
    bottom: -32rem;
    height: 88rem;
    width: 88rem;
  `}
`;

export const CustomHomeH2 = styled(H2)`
  ${media.tablet`
    font-size: 4.6rem;
    line-height: 6.6rem;
  `}
  ${media.desktopXl`
    font-size: 6.4rem;
    line-height: 9rem;
  `}
  .titleColored {
    color: ${colors.primaryGreen};
  }
`;
