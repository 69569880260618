import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as expired_link } from 'src/images/icons/broken_link_background.svg';
import { PayByLinkDetailsPageProps } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { ContainerPadding } from 'src/style/Container.style';

const PayByLinkSuccessCanceled: React.FC<PayByLinkDetailsPageProps> = ({
  madeFromPlugin,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <PayByLinkHeader stepBack={false} title="lbl.linkCancelling" idLink={0} />
      <ContainerPadding style={{ textAlign: 'center' }}>
        <Icon
          svgIconComponent={expired_link}
          iconHeight="20rem"
          iconSize="max-content"
          margin="6.6rem auto 6rem"
        />
        <TextDefaultPage
          title="lbl.linkHasBeenCanceled"
          subTitle="text.canCreateNewPayByLink"
        />
        <ButtonContainer>
          <Button
            onClick={() =>
              navigate(
                madeFromPlugin
                  ? RoutePath.linkOnline
                  : RoutePath.linkDiPagamento,
              )
            }
            variant="Primary"
            minWidth="100%"
          >
            <Translate id={'text.seeAllPayByLink'} />
          </Button>
          <NeedHelpButton />
        </ButtonContainer>
      </ContainerPadding>
    </>
  );
};

export default PayByLinkSuccessCanceled;
