import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import TableLinks from '../tableLinks/TableLinks.component';
import TableInstallments from '../tableInstallments/TableInstallments.component';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import Tabs from 'src/components/Tabs/Tabs.component';
import EvaluationCards from '../evaluationCards/EvaluationCards.component';

type TabsMonitoringProps = {
  buyerStats?: GetBuyerStatsDataResponse;
  isLoading: boolean;
};
export const TabsMonitoring: React.FC<TabsMonitoringProps> = ({
  buyerStats,
  isLoading,
}) => {
  const tabsMonitoring: ObjectTab[] = [
    {
      tabTitle: 'lbl.orders',
      content: <TableLinks data={buyerStats} isLoading={isLoading} />,
    },
    {
      tabTitle: 'lbl.installmentsToBePaid',
      content: <TableInstallments data={buyerStats} isLoading={isLoading} />,
    },
    {
      tabTitle: 'lbl.customerEvaluation',
      content: <EvaluationCards buyerStats={buyerStats} />,
    },
  ];

  return (
    <div className="py-10">
      <Tabs tabs={tabsMonitoring} variant="Secondary" />
    </div>
  );
};
