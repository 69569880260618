import { desktop, tablet } from './breakpoints';

type ReadonlyKeyListOf<T> = Readonly<Array<keyof T>>;

type CreateExtractCssFromPropsOptions = Partial<{
  media: 'desktop' | 'tablet';
}>;

/**
 * Generates a function that extracts props with value 'string' and
 * creates a semicolon separated list of css rules.
 * @param mapPropNameToCssRuleName Maps a prop names to the correspondent
 * css rule.
 * @param options.media Applies rules only to the correspondent breakpoint,
 * if present.
 *
 * @example
 * ```javascript
 * const mapMarginPropNamesToCssRules = {
 *   margin: 'margin',
 *   marginTop: 'margin-top',
 *   marginRight: 'margin-right',
 *   marginBottom: 'margin-bottom',
 *   marginLeft: 'margin-left',
 * };
 *
 * const P = styled.p`
 *   ${createExtractCssFromProps(mapMarginPropNamesToCssRules)};
 * `;
 *
 * const pElement = <P marginTop="10px" />
 *  ```
 * Note: the order of the rendered rules, hence the specificity,
 * depends on the order of 'mapPropNameToCssRuleName'
 * according to the standard iteration order of js objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
 */
export default function createExtractCssFromProps<
  M extends Record<string, string>,
>(mapPropNameToCssRuleName: M, options?: CreateExtractCssFromPropsOptions) {
  // We have to cast to preserve the 'keyof M' type.
  const propNameList = Object.keys(
    mapPropNameToCssRuleName,
  ) as ReadonlyKeyListOf<M>;

  const propNameListLength = propNameList.length;
  let breakpoint: number = 0;

  if (options?.media) {
    switch (options?.media) {
      case 'desktop':
        breakpoint = desktop;
        break;
      case 'tablet':
        breakpoint = tablet;
        break;
      default:
        if (process.env.NODE_ENV !== 'production') {
          throw new TypeError(
            `createExtractCssFromProps: unsupported media option, "${options?.media}"`,
          );
        }
    }
  }

  return <P extends Partial<Record<keyof M, string | number>>>(props: P) => {
    const outputCssRuleList = [];

    for (let i = 0; i < propNameListLength; i++) {
      const propName = propNameList[i];
      const { [propName]: optionalPropValue } = props;

      if (optionalPropValue || optionalPropValue === 0) {
        const { [propName]: cssRuleKey } = mapPropNameToCssRuleName;

        outputCssRuleList.push(`${cssRuleKey}: ${optionalPropValue};`);
      }
    }

    if (breakpoint) {
      return `
        @media all and (min-width: ${breakpoint}px) {
            ${outputCssRuleList.join('')}
        }
      `;
    }

    return outputCssRuleList.join('');
  };
}
