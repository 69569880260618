import DataTable from 'src/components/DataTable/DataTable';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import { P, PSmall } from 'src/style-utils/typographic';
import { linksTableColumnConfig } from './TableLinks.config';
import { Flex } from 'src/style/flexbox.style';
import Icon from 'src/components/Icon/Icon.component';
import { CustomInstallmentsProcessingDisclaimer } from '../../../monitoring/Monitoring.style';
import { ReactComponent as Star } from 'src/images/icons/star.svg';
import colors from 'src/style-utils/colors';

type CardProps = {
  label: string;
  content?: string | number;
  className?: string;
};
const CardCounter: React.FC<CardProps> = ({
  label,
  content,
  className = 'text-success',
}) => {
  return (
    <div className="flex w-1/3 flex-col gap-2 rounded-xl border border-pblack-100 bg-white p-3 lg:w-fit lg:flex-row lg:items-center">
      <PSmall>
        <Translate id={label} />
      </PSmall>
      <P
        bold
        className={className}
        style={{
          color:
            className === 'text-danger'
              ? `${colors.redInput}`
              : `${colors.green50}`,
        }}
      >
        {content}
      </P>
    </div>
  );
};

interface Props {
  data?: GetBuyerStatsDataResponse;
  isLoading: boolean;
}

const TableLinks = ({ data, isLoading }: Props) => {
  const tabProps = {
    data: data?.ResultSet.OrdersStats ?? [],
    hideHeader: false,
    NoDataComponent: () => {},
    columns: linksTableColumnConfig,
    customTableTheme: {
      cells: {
        style: {
          ':first-child': {
            background: '#fff',
            '::before': {
              display: 'none',
            },
          },
          ':last-child': {
            background: '#fff',
            '::before': {
              display: 'none',
            },
          },
        },
      },
    },
  };

  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  return (
    <>
      <div className="my-8 flex gap-2 lg:gap-8">
        <CardCounter
          label="lbl.currentOrders"
          content={data?.ResultSet.OrdersRegular}
        />
        <CardCounter
          label="lbl.completedOrders"
          content={data?.ResultSet.OrdersComplete}
        />
        <CardCounter
          label="lbl.lateOrders"
          content={data?.ResultSet.OrdersNotRegular}
          className="text-danger"
        />
      </div>

      {data?.ResultSet.InstallmentsProcessing
        ? data?.ResultSet.InstallmentsProcessing > 0 && (
            <Flex>
              <Icon
                svgIconComponent={Star}
                margin="auto 0"
                iconSize="1.6rem"
                iconHeight="1.6rem"
                color="black"
              />
              <CustomInstallmentsProcessingDisclaimer>
                <Translate id="lbl.PBMonitoringDisclaimer" />
              </CustomInstallmentsProcessingDisclaimer>
            </Flex>
          )
        : null}

      <DataTable {...tabProps} />
    </>
  );
};

export default TableLinks;
