// import { UserProfile } from 'src/services/user/user.types';
import required from './required';
// import escapeRegexSpecialCharacter from 'src/utils/functions/escapeRegexSpecialCharacters';
import { SyncFieldValidator } from './types';
import createLength, {
  errorMessageMaxPrefix,
  errorMessageMinPrefix,
} from './length';

export const passwordRange = { min: 8, max: 20 };

if (process.env.NODE_ENV !== 'production') {
  Object.freeze(passwordRange);
}

// export const validateConfirmPassword = function validateConfirmPassword(
//   password: string | undefined,
// ): SyncFieldValidator {
//   return function validateLength(confirmPass: unknown) {
//     if (
//       typeof password !== 'string' ||
//       typeof confirmPass !== 'string' ||
//       password !== confirmPass
//     ) {
//       return errorPasswordMatch;
//     }
//     return undefined;
//   };
// };

// export const createHasPersonalDataRegex = (userProfile: UserProfile) => {
//   const { name, surname, dateOfBirth } = userProfile;

//   return new RegExp(
//     [name, surname, dateOfBirth.slice(-4)]
//       .filter(Boolean)
//       .map(escapeRegexSpecialCharacter)
//       .join('|'),
//     'i',
//   );
// };

// export const createHasPhoneRegex = (userProfile: UserProfile) => {
//   const { accessData } = userProfile;

//   return new RegExp(accessData.phoneNumber);
// };

// export const createHasUsernameRegex = (userProfile: UserProfile) => {
//   const { accessData } = userProfile;

//   return new RegExp(accessData.username, 'i');
// };

export const hasUpperCase = (str: unknown) =>
  typeof str === 'string' && /[A-Z]/.test(str);

export const hasNumber = (str: unknown) =>
  typeof str === 'string' && /\d/.test(str);

export const hasLower = (str: unknown) =>
  typeof str === 'string' && /[a-z]/.test(str);

export const hasSpecialChar = (str: unknown) =>
  typeof str === 'string' && /[!"£#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(str);

export const hasValidLength = (str: unknown) =>
  typeof str === 'string' && str.length >= 8 && str.length <= 20;

export const hasConsecutiveChars = (str: unknown) =>
  typeof str === 'string' && /.*(?:(.)\1).*(?:(.)\2).*(?:(.)\3).*/.test(str);

export const hasThreeConsecutiveChars = (str: unknown) =>
  typeof str === 'string' && !/^(?!.*(.)\1{2}).+$/.test(str);

export const errorMessageNoSpecialChar = 'error.password.specialChars';

export const errorMessageMissingUpper = 'error.password.upper';

export const errorMessageMissingLower = 'error.password.lower';

export const errorMessageMissingNumber = 'error.password.number';

export const errorPasswordMatch = 'error.password.match';

// export const errorMessageHasPersonalData = 'error.password.hasPersonalData';

// export const errorMessageHasPhoneNumber = 'err.password.phoneNumber';

// export const errorMessageHasUsername = 'err.password.username';

// export const errorMessageHasThreeConsecutiveChars =
//   'error.password.consecutiveChar';

// export const errorMessageConsecutiveChars =
//   'error.password.consecutiveSequence';

/**
 * Checks if the input provided is an error message that is not meant to be
 * displayed using validation errors.
 * It is however accounted for when we need to decide if form submission
 * is allowed.
 * @param str param to check
 */
export function isSubmissionOnlyError(str: unknown) {
  if (typeof str === 'string') {
    return (
      str === errorPasswordMatch ||
      str === errorMessageNoSpecialChar ||
      str === errorMessageMissingUpper ||
      str === errorMessageMissingNumber ||
      str === errorMessageMissingLower ||
      str.startsWith(errorMessageMinPrefix) ||
      str.startsWith(errorMessageMaxPrefix)
    );
  }

  return false;
}

const createPasswordValidation = (): // userProfile: UserProfile,
SyncFieldValidator => {
  // const personalDataRegex = createHasPersonalDataRegex(userProfile);
  // const usernameRegex = createHasUsernameRegex(userProfile);
  // const phoneRegex = createHasPhoneRegex(userProfile);
  const validLength = createLength(passwordRange);

  return function validatePassword(val: unknown): string | undefined {
    const requiredError = required(val);

    if (requiredError) {
      return requiredError;
    }

    if (!hasSpecialChar(val)) {
      return errorMessageNoSpecialChar;
    }

    // if (personalDataRegex.test(val as string)) {
    //   return errorMessageHasPersonalData;
    // }
    // if (phoneRegex.test(val as string)) {
    //   return errorMessageHasPhoneNumber;
    // }
    // if (usernameRegex.test(val as string)) {
    //   return errorMessageHasUsername;
    // }
    // if (hasConsecutiveChars(val)) {
    //   return errorMessageConsecutiveChars;
    // }
    // if (hasThreeConsecutiveChars(val)) {
    //   return errorMessageHasThreeConsecutiveChars;
    // }
    // if (hasSpecialChar(val)) {
    //   return errorMessageNoSpecialChar;
    // }

    const lengthError = validLength(val);
    if (lengthError) {
      return lengthError;
    }
    if (!hasUpperCase(val)) {
      return errorMessageMissingUpper;
    }

    if (!hasLower(val)) {
      return errorMessageMissingLower;
    }

    if (!hasNumber(val)) {
      return errorMessageMissingNumber;
    }

    return undefined;
  };
};

export default createPasswordValidation;
