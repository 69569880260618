import Icon from 'src/components/Icon/Icon.component';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const DoubleChoiceOverlay = styled(Flex)`
  inset: 0;
  position: fixed;
  background-color: rgba(0, 59, 92, 0.4);
  backdrop-filter: blur(3px);
  z-index: 100;
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  align-self: right;
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
`;

export const LeaveStayBox = styled(Card)`
  display: grid;
  gap: 1.6rem;
  z-index: 101;
  max-width: 65rem;
  margin: 0 2.4rem;
  position: absolute;
  padding: 2.4rem;
  width: unset;
  top: 2rem;
  ${media.tablet`
  padding: 4rem;
  `}
`;
