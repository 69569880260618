import React from 'react';
import { LabelSelectProps } from 'src/components/Select/Select.types';
import { LabelSelectStyle } from './style/LabelSelect.style';
import Translate from 'src/components/Translate/Translate.component';

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  id,
  i18nLabel,
  ...otherProps
}) => {
  return (
    <LabelSelectStyle htmlFor={id} {...otherProps}>
      {i18nLabel ? <Translate id={label} /> : label}
    </LabelSelectStyle>
  );
};

export default LabelSelect;
