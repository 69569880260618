import { IconButton } from 'src/components/Button/IconButton.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const MovementsCardsContainer = styled(Flex)`
  overflow-x: auto;
  gap: 0.8rem;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ${media.tablet`
    gap: 2.4rem;
  `}
`;

export const Filter = styled(IconButton)`
  position: fixed;
  right: 2.4rem;
  bottom: 7rem;
`;

export const EndOrders = styled(P)`
  color: ${colors.textBtnGrey};
  width: 100%;
  padding-top: 2.4rem;
  margin-top: 2.4rem;
  border-top: 1px solid ${colors.greyBorder};
  text-align: center;
`;

export const DateLine = styled(PSmall)`
  border-bottom: 0.1rem solid ${colors.greyBorder};
  width: 100%;
  padding-bottom: 0.6rem;
  line-height: 2rem;
  margin: 2.3rem 0 1.6rem;
  text-transform: capitalize;
`;
