import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StepperProps } from './Stepper.types';
import { CircularProgress, FlexWrapper } from './style/stepper.style';

const Stepper: React.FC<StepperProps> = ({ dataStepper }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <>
      {dataStepper?.map((item, i) => {
        return (
          <FlexWrapper
            data-component="stepper"
            gap="1.6rem"
            align="center"
            flexDirection="row"
            key={i}
            length={i}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <CircularProgress outlineColor={colors.primaryGreen}>
                {i + 1}
              </CircularProgress>
            </div>
            <div>
              <H4
                light={i !== 0}
                style={{
                  textAlign: 'center',
                }}
              >
                {formatCurrency(item.Amount)}
              </H4>
            </div>
            <div>
              <P bold={i === 0} style={{ color: colors.textColorGrey }}>
                {moment().isSame(item.Expiration, 'day') ? (
                  <Translate id="text.today" />
                ) : (
                  refactorFormatDate(item.Expiration, 'DD MMMM YYYY')
                )}
              </P>
            </div>
          </FlexWrapper>
        );
      })}
    </>
  );
};

export default Stepper;
