import { setCookie } from 'src/utils/functions/cookie';

export const setRememberCookie = (
  cookieName: string,
  flag?: boolean,
  email?: string,
) => {
  if (flag) {
    const rememberMeData = {
      email: email,
      flag: flag,
    };
    setCookie(cookieName, JSON.stringify(rememberMeData), 30);
  } else {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};
