import { RoutePath } from 'src/routers/routers.config';

// MERCHANT:
// Stato=0, utente appena creato in attesa di verifica email
// Stato=2, utente verificato via email, in attesa di verifica numero di telefono
// Stato=3, utente verificato via sms, in attesa di conferma contratto firmato
// Stato=30, ** ONBOARDING ** firma Namirial completata
// Stato=31, ** ONBOARDING ** errore Admin KO
// Stato=32, ** ONBOARDING ** utente in attesa di assistenza
// Stato=33, ** ONBOARDING ** contratto utente ricaricato dall'operatore
// Stato=4, utente con contratto firmato, in attesa di registrazione su stripe
// Stato=1 utente attivato completamente

// BUYER:
// Stato=0, utente appena creato in attesa di verifica email
// Stato=2, utente verificato via email, in attesa di verifica numero di telefono
// Stato=3, utente verificato via sms, in attesa di conferma contratto firmato
// Stato=1 utente attivato completamente

// export enum AccountStatus {
//   'verificationMail',
//   'completed',
//   'verificationPhone',
//   'pendingContract',
//   'pendingStripe',
// }

export enum AccountStatus {
  verificationMail = 0,
  completed = 1,
  verificationPhone = 2,
  pendingContract = 3,
  pendingStripe = 4,
  successNamirial = 30,
  koAdmin = 31,
  assistance = 32,
  uploadedContract = 33,
  uploadedContractExt = 34,
}

export type NavigationStatusMappings = Record<
  AccountStatus,
  Partial<Record<keyof TypeAccount, string>>
>;
export type TypeAccount = {
  BUYER: string;
  MERCHANT: string;
};

export const navigationStatusMappings: NavigationStatusMappings = {
  [AccountStatus.verificationMail]: {
    BUYER: `${RoutePath.privateArea}?typeAccount=BUYER`,
    MERCHANT: `${RoutePath.privateArea}?typeAccount=MERCHANT`,
  },
  [AccountStatus.verificationPhone]: {
    BUYER: `${RoutePath.privateArea}?typeAccount=BUYER`,
    MERCHANT: `${RoutePath.privateArea}?typeAccount=MERCHANT`,
  },
  [AccountStatus.pendingContract]: {
    BUYER: `${RoutePath.privateArea}?typeAccount=BUYER`,
    MERCHANT: RoutePath.continueRegistration,
  },
  [AccountStatus.successNamirial]: {
    MERCHANT: RoutePath.onboarding + RoutePath.successNamirial,
  },
  [AccountStatus.pendingStripe]: {
    MERCHANT: RoutePath.onboarding + RoutePath.signStripe,
  },
  [AccountStatus.koAdmin]: {
    MERCHANT: RoutePath.onboarding + RoutePath.errorKoAdmin,
  },
  [AccountStatus.assistance]: {
    MERCHANT: RoutePath.onboarding + RoutePath.assistance,
  },
  [AccountStatus.uploadedContract]: {
    MERCHANT: RoutePath.onboarding + RoutePath.uploadedContract,
  },
  [AccountStatus.uploadedContractExt]: {
    MERCHANT: RoutePath.onboarding + RoutePath.successNamirial,
  },
  [AccountStatus.completed]: {
    BUYER: `${RoutePath.privateArea}?typeAccount=BUYER`,
    MERCHANT: `${RoutePath.privateArea}?typeAccount=MERCHANT`,
  },
};
