import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

const InvoiceDateColumn: React.FC<ListRequestsResultsTable> = ({
  InvoiceDate,
}) => {
  return (
    <P textColor={colors.textTableBody}>{refactorFormatDate(InvoiceDate)}</P>
  );
};

export default InvoiceDateColumn;
