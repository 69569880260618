import Icon from 'src/components/Icon/Icon.component';
import moment from 'moment';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StepperProps } from './Stepper.types';
import { CircularProgress, FlexWrapper } from './style/stepper.style';
import { StepperRemodulationArray } from './Stepper.config';
import { H4, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ReactComponent as Bill } from 'src/images/icons/bill.svg';

const StepperRemodulation: React.FC<StepperProps> = ({
  dataStepper,
  onlyToPay,
  amountRemodulation,
  numInstallmentToShow,
}) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const arrayInstallments = onlyToPay
    ? dataStepper?.filter(
        (item) => numInstallmentToShow && item.Number >= numInstallmentToShow,
      )
    : dataStepper;

  return (
    <>
      {arrayInstallments?.map((item, i) => {
        const { icon, label, outlineColor, iconColor } =
          StepperRemodulationArray[item.Status as InstallmentStatus];
        return (
          <FlexWrapper
            gap="1.6rem"
            align="center"
            flexDirection="row"
            key={i}
            length={i}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <CircularProgress
                outlineColor={outlineColor}
                colorGrey={item.Status === InstallmentStatus.paid || onlyToPay}
              >
                {onlyToPay ? item?.Number : i + 1}
              </CircularProgress>
            </div>
            <div>
              <H4
                light
                colorGray={item.Status === InstallmentStatus.paid || onlyToPay}
              >
                {formatCurrency(item.Amount)}
              </H4>
              <P style={{ color: colors.textColorGrey }}>
                {moment().isSame(item.Expiration, 'day') ? (
                  <Translate id="text.today" />
                ) : (
                  refactorFormatDate(item.Expiration, 'DD MMMM YYYY')
                )}
              </P>
            </div>
            <Flex align="center">
              <P>
                <Translate id={label} />
              </P>
              <Icon
                svgIconComponent={icon}
                marginLeft="0.4rem"
                color={iconColor}
                height="2.4rem"
                width="auto"
              />
            </Flex>
          </FlexWrapper>
        );
      })}
      {amountRemodulation && (
        <FlexWrapper gap="1.6rem" align="center" flexDirection="row" length={1}>
          <div
            style={{
              position: 'relative',
            }}
          >
            <CircularProgress outlineColor={colors.primaryGreen}>
              {
                <Icon
                  iconHeight="auto"
                  iconSize="3.2rem"
                  svgIconComponent={Bill}
                />
              }
            </CircularProgress>
          </div>
          <div>
            <H4>{formatCurrency(amountRemodulation)}</H4>
          </div>
          <Flex align="center">
            <P>
              <Translate id="text.totalAmount" />
            </P>
          </Flex>
        </FlexWrapper>
      )}
    </>
  );
};

export default StepperRemodulation;
