import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card, ResponsiveContainer } from 'src/style/Container.style';
import styled, { css, keyframes } from 'styled-components';

const EnterAnim = keyframes`
    from{
      transform: translateY(var(--startPos));
    }
`;

export const ToastWrapper = styled(ResponsiveContainer)`
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  height: 0;
  inset: auto 0 12.4rem 0;
  z-index: 3;
  --startPos: 100%;
  ${media.tablet`
    bottom: 2.4rem;
    --startPos: calc(100% + 6rem);
  `}
`;

export const CloseIcon = styled.button`
  color: ${colors.textP};
  background-color: white;
  outline: 1px solid currentColor;
  width: 2.4rem;
  height: 2.4rem;
  top: 0;
  right: 0;
  position: absolute;
  transform: translate3d(50%, -50%, 0);
  padding: 0.4rem;
  border-radius: 99rem;
  cursor: pointer;
  :hover {
    background-color: aliceblue;
  }
`;

type CardProps = {
  animation?: boolean;
};

export const ToastContainer = styled(Card)<CardProps>`
  position: absolute;
  bottom: 0;
  z-index: 20;
  text-align: left;
  flex-basis: 41.6rem;
  border-radius: 0.8rem;
  overflow: visible;
  gap: 1.2rem;
  outline: 1px solid;
  outline-color: currentColor;

  button {
    float: right;
  }

  ${media.tablet`
    display: flex;
    align-items: center;
  `}

  &[data-type='primary'] {
    outline-color: ${colors.greyBorder};
    color: ${colors.textColorBlack};
  }
  &[data-type='success'] {
    color: ${colors.greenStatus};
  }
  &[data-type='warning'] {
    color: ${colors.yellowStatus};
  }
  &[data-type='info'] {
    color: ${colors.lightBlueBorder};
  }
  &[data-type='error'] {
    color: ${colors.redStatus};
  }

  ${({ animation }) =>
    animation &&
    css`
      animation: 750ms ease-out ${EnterAnim};
    `}
`;
