import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as BackRight } from 'src/images/icons/arrow_back_right_white_bg.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PSmall } from 'src/style-utils/typographic';
import { CardContainer } from './style/actionCardLight.style';

type ActionCardLightProps = {
  path: string;
  text: string;
  id?: string;
};
const ActionCardLight: React.FC<ActionCardLightProps> = ({
  path,
  text,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <CardContainer data-component="action-card-light">
      <PSmall bold colorBlack>
        <Translate id={text} />
      </PSmall>
      <button
        type="button"
        id={id}
        onClick={() => {
          navigate(path as never);
        }}
      >
        <Icon
          iconSize={'3.6rem'}
          iconHeight={'auto'}
          svgIconComponent={BackRight}
        />
      </button>
    </CardContainer>
  );
};

export default ActionCardLight;
