import { ModalHelperProps } from 'src/components/Modal/Modal.types';
import { loadablePage } from 'src/utils/loadable';

export type OptionsModalProps = {
  isBackup?: boolean;
  isCard?: boolean;
  isExpired?: boolean;
  setPreferredHandler: () => void;
  setBackupHandler: () => void;
  removeMethodHandler: () => void;
  goToChangeBackup: () => void;
};

export default loadablePage<React.FC<ModalHelperProps & OptionsModalProps>>(
  () => import('./OptionsModal'),
);
