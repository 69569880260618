import { Button } from 'src/components/Button/Button.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { P } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const RefreshButtonLink = styled(Button)`
  font-weight: 400;
  color: ${colors.textColorGrey};
`;

export const Privacy = styled(P)`
  box-sizing: border-box;
  color: ${colors.textColorGrey};
  font-size: 1.2rem;
  text-align: center;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.textColorGrey};
  }
`;

export const ResultPageIcon = styled.img`
  margin: 4.8rem 0;
  height: 19rem;
  ${media.tablet`
    height: 28rem;
  `}
`;

export const NeedHelpButtonContainer = styled.div`
  ${media.mobileSm`
    text-align: center;
  `}
`;

export const ContainerInfo = styled(Card)`
  background-color: white;
  border-radius: 1.2rem;
  padding: 3.2rem;
  display: grid;
  gap: 1.6rem;
  margin: 2.4rem 0;
`;
