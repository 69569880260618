import { AxiosResponse } from 'axios';

/**
 * Generates a
 * @param scope A valid scope.
 * @example
 * ```typescript
 *   const errorhandling = Promise
 *     .reject(42)
 *     .catch(createScopedErrorHandler('api'));
 * ```
 *
 */
export const createScopedErrorHandler = (scope: string) => {
  return function handleError(...args: any) {
    if (args[0] && args[0]?.response && args[0].response.status) {
      const { status, data } = args[0].response as AxiosResponse<any>;
      /* eslint-disable no-console */
      console.error(
        `[${scope}][status:${status}]`,
        data,
        args[0].message,
        ...args.slice(1),
      );
      /* eslint-enable no-console */
    } else {
      /* eslint-disable no-console */
      console.error(`[${scope}]`, ...args);
      /* eslint-enable no-console */
    }
  };
};
