import StatsCard from 'src/components/StatsCard/StatsCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { t } from 'i18next';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import { useProfileStore } from 'src/store/store';
import { PNote } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

export const EvaluationCards: React.FC<{
  buyerStats?: GetBuyerStatsDataResponse;
}> = ({ buyerStats }) => {
  const paymentMethodLabel = {
    card: 'lbl.paymentMethodCard',
    sepa_debit: 'lbl.paymentMethodSEPA',
  };
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const CanGiveCredit = useProfileStore((state) => state.CanGiveCredit);
  const date = refactorFormatDate(
    buyerStats?.ResultSet.BuyerLastUWResultDate ?? '',
    'DD MMMM YYYY',
  );
  const BuyerLastUWResult = buyerStats?.ResultSet.BuyerLastUWResult;
  const GivableAmount = formatCurrency(
    buyerStats?.ResultSet.GivableAmount ?? 0,
  );
  const contentGivableAmount =
    BuyerLastUWResult === 'OK' &&
    buyerStats?.ResultSet.GivableAmount &&
    buyerStats?.ResultSet.GivableAmount > 0
      ? 'text.availableUpTo'
      : 'lbl.notAvailable';
  const BuyerPaymentMethodsAllowed =
    buyerStats?.ResultSet.BuyerPaymentMethodsAllowed.map((item) =>
      t(paymentMethodLabel[item as 'card' | 'sepa_debit']),
    ).join(` ${t('lbl.and')} `);
  const className =
    BuyerLastUWResult !== 'OK'
      ? 'border-danger text-danger'
      : 'border-pblack-400 text-pblack-400';
  return (
    <>
      <div className="grid gap-4 lg:grid-cols-3">
        <StatsCard
          label="text.creditRating"
          contentText={
            BuyerLastUWResult === 'OK'
              ? 'lbl.suitable'
              : 'advPlanRequestStatus.unsuitable'
          }
          className={className}
          tooltipText="text.analysisCreditTooltip"
          variant="plain"
          i18n
        />
        {CanGiveCredit && (
          <StatsCard
            label="lbl.labelADV"
            contentText={contentGivableAmount}
            contentData={{ amount: GivableAmount }}
            className={className}
            tooltipText="text.advPaymentTooltip"
            variant="plain"
            i18n
          />
        )}
        <StatsCard
          label="lbl.UWOkSDD"
          contentText={BuyerPaymentMethodsAllowed}
          tooltipText="text.paymentMethodsTooltip"
          className="border-pblack-400 text-pblack-400"
          variant="plain"
          i18n
        />
      </div>
      <PNote marginTop="1.2rem">
        <Translate id="lbl.lastUWDate" data={{ date }} />
      </PNote>
    </>
  );
};

export default EvaluationCards;
