import { GetOrderBuyerDetails } from 'src/services/getOrderDetails/getOrderDetails.types';

export type OrdersTabsProps = {
  order?: GetOrderBuyerDetails;
};
export type StatusCardKey = number;
export const statusCard: Record<
  StatusCardKey,
  'default' | 'paid' | 'expired' | 'processing'
> = {
  0: 'default',
  1: 'paid',
  4: 'processing',
  3: 'expired',
};
