import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { ChangeMethodFormData } from 'src/services/payments/payments.types';
import { Hr } from 'src/style-utils/hr';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { useIsAtLeastTabletBreakpoint } from 'src/utils/hooks/media-queries';
import CheckboxStripe from '../partials/CheckboxStripe/CheckboxStripe.component';
import { t } from 'i18next';

type Props = { pContext: pContextType };

const ChoosePaymentMethod = ({ pContext }: Props) => {
  const isTablet = useIsAtLeastTabletBreakpoint();
  const [checked, setChecked] = useState(false);
  const {
    paymentMethods,
    goToStripeFormHandler,
    orderGuid,
    selectedMethodId,
    paymentDate,
    guidParam,
  } = pContext;
  const {
    confirmOrderHandler,
    sessionData: { LenderName },
  } = useOutletContext<EcommerceContext>();

  const methods = useForm({
    defaultValues: {
      orderGuid: orderGuid,
      paymentMethodId: selectedMethodId.toString(),
    } as ChangeMethodFormData,
    mode: 'onSubmit',
  });
  const onSubmit = (values: ChangeMethodFormData) => {
    confirmOrderHandler({ ...values, guidParam });
  };
  const buttonLabel = 'lbl.payNowAlt';
  const buttonDateLabel = 'lbl.confirm&PayDate';
  const index =
    paymentMethods &&
    paymentMethods.map((item) => item.Id).indexOf(selectedMethodId);
  if (index > -1) {
    const obj = paymentMethods[index];
    paymentMethods.splice(index, 1);
    paymentMethods.unshift(obj);
  }

  return (
    <div style={{ marginTop: '2.4rem', marginBottom: '2.4rem' }}>
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Flex fluid flexDirection="column" gap="2.4rem">
            {paymentMethods.map((payCard, i) => {
              return <PaymentMethodElement {...payCard} key={i} />;
            })}
          </Flex>
          <DividerOr />
          <ButtonCard
            createPaymentIntentHandler={goToStripeFormHandler}
            type={'change'}
            withMarginBottom={false}
          />
          <Hr />
          <CheckboxStripe
            description={t('text.okCreditAssignment', { x: LenderName })}
            checked={checked}
            setChecked={() => setChecked(!checked)}
          />
          {isTablet && <Hr />}
          <ButtonContainer>
            <Button
              variant="Primary"
              height="5.4rem"
              type="submit"
              minWidth="100%"
              disabled={!methods.formState.isValid || !checked}
            >
              <Translate
                id={
                  moment().isSame(paymentDate, 'day')
                    ? buttonLabel
                    : buttonDateLabel
                }
                data={{ paymentDate: refactorFormatDate(paymentDate, 'DD/MM') }}
              />
            </Button>
          </ButtonContainer>
        </form>
      </FormProvider>
    </div>
  );
};

export default ChoosePaymentMethod;
