import FormOtpPhone from 'src/pages/registration/partials/FormOtpPhone/FormOtpPhone.component';
import { RoutePath } from 'src/routers/routers.config';

const RegistrationCollaboratorStepOtpPhone: React.FC = () => {
  return (
    <FormOtpPhone
      nextPath={RoutePath.registrationCollaborator + RoutePath.passwordStep}
      step={2}
      stepMax={4}
    />
  );
};

export default RegistrationCollaboratorStepOtpPhone;
