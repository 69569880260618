export const getPaymentMethodsEndPoint = 'Buyer/GetPaymentMethods';
export const changePaymentMethodEndPoint = 'Order/ChangePaymentMethod';
export const setBackupPaymentMethodEndPoint = 'Buyer/SetBackupPaymentMethod';
export const getPaymentSuccessEndPoint = '/Order/GetPaymentSuccess';
export const createSetupAttemptEndPoint = 'Stripe/CreateSetupAttempt';
export const createInstantPaymentEndPoint = 'Stripe/CreateInstantPayment';
export const confirmSetupAttemptEndPoint = 'Stripe/ConfirmSetupAttempt';
export const confirmOrderEndPoint = 'Order/ConfirmOrder';
export const setPreferredMethodEndpoint = 'Buyer/SetPreferredPaymentMethod';
export const removeMethodEndpoint = 'Buyer/RemovePaymentMethod';
