import Translate from 'src/components/Translate/Translate.component';
import { H4, P, PLegal } from 'src/style-utils/typographic';
import React, { ReactElement } from 'react';

export type PSCardProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  lbl: string;
  value: number | string | ReactElement;
  note?: string | JSX.Element;
  variant?: string;
};

export const PaySuccessCard: React.FC<PSCardProps> = ({
  icon: IconComponent,
  lbl,
  value,
  note,
  variant,
}) => (
  <div
    data-component="pay-success-card"
    className="relative flex w-full justify-between overflow-hidden rounded-xl border border-pblack-100 bg-bgreen p-4"
    style={{
      flexDirection: variant === 'column' ? 'column' : 'row',
      alignItems: variant === 'column' ? 'start' : 'center',
    }}
  >
    <IconComponent className="absolute inset-0 left-1/2 h-full" />
    <P bold>
      <Translate id={lbl} />
    </P>
    <div>
      <H4 textAlign="right">{value}</H4>
      {note && <PLegal textAlign="right">{note}</PLegal>}
    </div>
  </div>
);
