import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import CheckBoxPopover from 'src/pages/monitoring/checkbox/CheckBoxPopover.component';
import { DataFormMonitoringBuyerStats } from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.helpers';
import { MouseEventHandler } from 'react';

type LinkPopoverProps = {
  submitHandler?: MouseEventHandler | undefined;
  dataFiltered: DataFormMonitoringBuyerStats;
  onCloseHandler?: () => void;
};
export const LinkPopover: React.FC<LinkPopoverProps> = ({
  submitHandler,
  dataFiltered,
  onCloseHandler,
}) => {
  const count =
    dataFiltered.link && dataFiltered.plugin
      ? 2
      : dataFiltered.link || dataFiltered.plugin
        ? 1
        : undefined;
  return (
    <SelectPopover
      submitHandler={submitHandler}
      label="lbl.salesMode"
      countFilters={count}
      onCloseHandler={onCloseHandler}
    >
      <CheckBoxPopover
        fieldName={'link'}
        label={'advPlanRequestsHeaderTable.OrderId'}
        typeBoolean
        i18n
      />
      <CheckBoxPopover
        fieldName={'plugin'}
        label={'menu.onlineShop'}
        typeBoolean
        i18n
      />
    </SelectPopover>
  );
};

export default LinkPopover;
