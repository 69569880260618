export const rechargeFormName = 'recharge';

export const withdrawalFormName = 'withdrawal';

export const serializedFormValuesKey = 'sValues';

export const genericErrorId = 'error.generic';

export const formErrorKey = 'generic';

export enum InputFieldUIStates {
  idle,
  error,
  alert,
}
