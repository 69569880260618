import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  border-bottom: 0.1rem solid ${colors.greyBorder};
  background-color: #fff;
`;

export const HeaderFlex = styled(ResponsiveContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.4rem;
  ${media.tablet`
    min-height: 7.2rem; 
  `}
`;

export const CloseButtonRoot = styled.button.attrs(() => ({
  type: 'button',
  'aria-label': 'close',
  title: 'close',
}))`
  color: ${colors.white};
  background: transparent;
  border: none;
  cursor: pointer;
`;
