import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import { DataFormBalanceMovements } from 'src/pages/balanceMovementsPage/BalanceMovementsPage.types';
import {
  clientList,
  endDate,
  skipField,
  startDate,
  typesField,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { FilteredButtonContainer } from '../style/FilteredList.style';

interface FilteredButtonProps {
  filteredName?: string;
  fromDate?: string;
  toDate?: string;
  buyerId?: number;
  type?: string;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormBalanceMovements;
  disabled: boolean;
}

const FilteredButton = ({
  filteredName,
  fromDate,
  toDate,
  buyerId,
  type,
  handleClick,
  dataForm,
  setDataFilter,
  disabled,
}: FilteredButtonProps) => {
  const { resetField, watch, setValue } = useFormContext();

  const clientsArray = watch(clientList);
  const typesArray = watch(typesField);
  const fieldName = buyerId ? clientList : typesField;

  const { remove } = useFieldArray({
    name: fieldName,
  });
  const {
    formatters: { formatDate },
  } = useI18n();

  const onClick = () => {
    if (fromDate || toDate) {
      resetField(startDate);
      resetField(endDate);
      dataForm.fromDate = '';
      dataForm.toDate = '';
    }
    if (buyerId) {
      remove(clientsArray.indexOf(buyerId));
      dataForm.buyers = dataForm.buyers.filter(
        (id) => id !== buyerId.toString(),
      );
    }
    if (type) {
      remove(typesArray.indexOf(type));
      dataForm.types = dataForm.types.filter((idType) => idType !== type);
    }
    setValue(skipField, 0);
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <FilteredButtonContainer onClick={onClick} data-disabled={disabled}>
      {fromDate && fromDate.length > 0 && (
        <>
          <Translate id="text.startPeriod" />
          &nbsp;{formatDate(new Date(fromDate))}
        </>
      )}
      {toDate && toDate.length > 0 && (
        <>
          <Translate id="text.endPeriod" />
          &nbsp;{formatDate(new Date(toDate))}
        </>
      )}
      {buyerId && filteredName}
      {type && <Translate id={filteredName} />}
      <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
    </FilteredButtonContainer>
  );
};

export default FilteredButton;
