import { Button } from 'src/components/Button/Button.component';
import { ModalHelperProps } from 'src/components/Modal/Modal.types';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { ModalFooterRoot } from './style/ModalFooterDefault.style';
import Icon from '../../../Icon/Icon.component';
import { CustomButtons } from '../../../PendingBalanceInfoModal/PendingBalanceInfoModal.style';
import { ReactComponent as chatIcon } from 'src/images/icons/chat-icon.svg';
import { RoutePath } from '../../../../routers/routers.config';
import { useNavigate } from 'react-router-dom';

export type ModalFooterProps = ModalHelperProps & {
  handleClose: () => void;
  buttonTitle: string;
  supportButton?: boolean;
};

const ModalFooterDefault: React.FC<ModalFooterProps> = ({
  handleClose,
  buttonTitle,
  supportButton,
  ...otherProps
}) => {
  const navigate = useNavigate();
  const handleClickSupport = () => {
    navigate(RoutePath.support);
  };

  return (
    <ModalFooterRoot>
      <Button
        minWidthMobile="100%"
        sizeOnDesktop="medium"
        {...otherProps}
        onClick={handleClose}
      >
        <Translate id={buttonTitle} />
      </Button>
      {supportButton && (
        <CustomButtons
          variant="Tertiary"
          i18n
          translatedText="supportModal.Button"
          leftChild={<Icon iconSize="2rem" svgIconComponent={chatIcon} />}
          onClick={handleClickSupport}
        />
      )}
    </ModalFooterRoot>
  );
};

export default ModalFooterDefault;
