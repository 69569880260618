import { Resolver } from 'react-hook-form';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import required from 'src/utils/validation/required';
import telephone from 'src/utils/validation/telephone';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigPhonePluginCheckout = () => {
  const initialValues = {
    [phoneField]: '',
    [prefix]: '39IT',
  };

  const validate = validateFormSync({
    [phoneField]: validateFieldSync(required, telephone),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
