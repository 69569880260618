import media from 'src/style-utils/media-queries';
import { FlowContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  gap: 1.6rem;
  ${media.tablet`
    gap: 3.2rem;
    margin-top: 3.2rem;
  `}
`;
export const FlowContainerCustom = styled(FlowContainer)`
  h4 {
    margin-bottom: 0.8rem;
  }
  ${media.tablet`
    h4{
      margin-bottom: 1.6rem;
    }
  
  `}
`;
