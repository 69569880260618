import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';

{
  /*>>> TO DO: check where used, mapping is not correct*/
}
export const StatusLinkContainer = styled(Flex)<{ statusLink: number }>`
  width: max-content;
  height: 2rem;
  border-radius: 0.4rem;
  background: #fff;
  ${function manageLinkBorder({ statusLink }) {
    switch (statusLink) {
      default:
        return `
        border: 0.1rem solid ${colors.greyStatus};
        color:  ${colors.greyStatus};
        `;
      case PayByLinkStatus.createdLink:
        return `
          border: 0.1rem solid ${colors.blueStatus};
          color: ${colors.blueStatus};
          `;
      case PayByLinkStatus.fullCashed:
        return `
        border: 0.1rem solid ${colors.greenStatus};
        color: ${colors.greenStatus};
            `;
      case PayByLinkStatus.expiredLink:
      case PayByLinkStatus.expiredPayment:
        return `
        border: 0.1rem solid ${colors.redStatus};
        color: ${colors.redStatus};
              `;
      case PayByLinkStatus.refund:
        return `
        border: 0.1rem solid ${colors.yellowStatus};
        color: ${colors.yellowStatus};
          `;
    }
  }};
`;

export const LinkText = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  min-width: max-content;
  margin: 0;
`;

export const StatusInstallmentContainer = styled(Flex)<{
  statusInstallment: number;
}>`
  width: max-content;
  height: 2rem;
  border-radius: 0.4rem;
  background: #fff;
  ${function manageLinkBorder({ statusInstallment }) {
    switch (statusInstallment) {
      default:
        return `
        border: 0.1rem solid ${colors.greyStatus};
        color:  ${colors.greyStatus};
        `;
      case InstallmentStatus.toPay:
        return `
          border: 0.1rem solid ${colors.greyStatus};
          color: ${colors.greyStatus};
          `;
      case InstallmentStatus.paid:
        return `
        border: 0.1rem solid ${colors.greenStatus};
        color: ${colors.greenStatus};
            `;
      case InstallmentStatus.expired:
        return `
        border: 0.1rem solid ${colors.redStatus};
        color: ${colors.redStatus};
              `;
      case InstallmentStatus.cancelled:
        return `
        border: 0.1rem solid ${colors.yellowStatus};
        color: ${colors.yellowStatus};
          `;
      case InstallmentStatus.processing:
        return `
        border: 0.1rem solid ${colors.greyStatus};
        color:  ${colors.greyStatus};
          `;
    }
  }};
`;
