import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  GetUrlToIdentify,
  GetUrlToSignContract,
  buyerGetInfo,
  getDocument,
  getDocuments,
  getLegalInfo,
  merchantGetInfo,
  updateLanguage,
  userEndPoint,
} from './user.config';
import {
  BuyerGetInfoResponse,
  GetLegalInfoResponse,
  MerchantGetInfoResponse,
  ProfileResponse,
  SignContractResponse,
  UpdateLanguageData,
  UpdateLanguageResponse,
  getDocumentsResponse,
} from './user.types';
// import { DataUserType } from './user.types';

export const porfileMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: userEndPoint,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<ProfileResponse>(requestConfig);
};

export const merchantGetInfoMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: merchantGetInfo,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<MerchantGetInfoResponse>(requestConfig);
};
export const buyerGetInfoMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: buyerGetInfo,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<BuyerGetInfoResponse>(requestConfig);
};

export const GetUrlToIdentifyMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: GetUrlToIdentify,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request(requestConfig);
};

export const GetUrlToSignContractMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: GetUrlToSignContract,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<SignContractResponse>(requestConfig);
};

export const getDocumentsMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getDocuments,
    method: 'GET',
    authenticated: true,
  });

  return await apiRequest.request<getDocumentsResponse>(requestConfig);
};
export type getDocumentData = {
  documentId?: number;
  documentName: string;
};

export const getDocumentMethodApi = async (data: getDocumentData) => {
  const requestConfig = makeEndpointApiConfig({
    url: getDocument,
    method: 'GET',
    authenticated: true,
    params: data,
  });
  return await apiRequest.request(requestConfig);
};

export const getLegalInfoMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getLegalInfo,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<GetLegalInfoResponse>(requestConfig);
};

export const updateLanguageMethodApi = async (data: UpdateLanguageData) => {
  const requestConfig = makeEndpointApiConfig({
    url: updateLanguage,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request<UpdateLanguageResponse>(requestConfig);
};
