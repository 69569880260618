export enum ProductsEnum {
  advancePayment = 'ADV_3_67',
  singleInstant = 'SGL_INS',
  singleDelayed = 'SGL_DLY',
  flexEqual = 'FLX_EQ',
  flexCustom = 'FLX_CUSTOM',
  custom = 'CST',
  remodulation = 'RML',
}

export enum CategoriesEnum {
  advance = 'ADV',
  singleInstallment = 'SGL',
  flexInstallment = 'FLX',
}

export enum RoleEnum {
  owner = 'owner',
  collaborator = 'collaborator',
}

export type ProductCodes = `${ProductsEnum}`;
export type CategoryCodes = `${CategoriesEnum}`;

export interface Installment {
  Expiration: string;
  Amount: number;
  Number: number;
  INo: number;
  Status: number;
}
