import Translate from 'src/components/Translate/Translate.component';
import { BadgeStatusContainer } from './style/BadgeStatus.style';
import { BadgeStatusProps } from './BadgeStatus.types';
import Icon from 'src/components/Icon/Icon.component';

const BadgeStatus = ({ label, style, i18n = true, icon }: BadgeStatusProps) => {
  return (
    <BadgeStatusContainer
      data-component="badge-status"
      justify="center"
      align="center"
      padding="0 0.6rem"
      gap={'0.4rem'}
      {...style}
    >
      {icon && (
        <Icon iconSize="1.2rem" iconHeight="auto" svgIconComponent={icon} />
      )}
      <span>{i18n ? <Translate id={label} /> : label}</span>
    </BadgeStatusContainer>
  );
};

export default BadgeStatus;
