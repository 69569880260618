import {
  Description,
  FrameContainer,
  FrameContent,
  FrameIcon,
  FrameMain,
  FrameText,
  Title,
} from './ResultPage.style';
import Icon from '../Icon/Icon.component';
import Translate from '../Translate/Translate.component';

export interface ResultPageProps {
  svgIconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  titleId: string;
  descriptionId: string;
  children?: React.ReactNode;
}

export const ResultPage = ({
  svgIconComponent,
  titleId,
  descriptionId,
  children,
}: ResultPageProps) => {
  return (
    <>
      <FrameContainer data-component="result-page">
        <FrameMain>
          <FrameContent>
            <FrameIcon>
              <Icon svgIconComponent={svgIconComponent} iconSize="100%" />
            </FrameIcon>
            <FrameText>
              <Title>
                <Translate id={titleId} />
              </Title>
              <Description>
                <Translate id={descriptionId} />
              </Description>
            </FrameText>
            {children}
          </FrameContent>
        </FrameMain>
      </FrameContainer>
    </>
  );
};
