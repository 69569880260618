import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import {
  PaymentMethodElementProps,
  getPaymentIcon,
} from './PaymentMethodElement.helpers';

export default function useParamsPayCard(
  payCard: PaymentMethodResult,
): PaymentMethodElementProps {
  const { ExpMonth, ExpYear, Last4, Network, Country } = payCard.Details;

  const d = new Date();
  const currentAbsMonth = d.getFullYear() * 12 + d.getMonth() + 1;

  const data: PaymentMethodElementProps = {
    payIcon: getPaymentIcon(Network),
    last4: Last4,
    dueDate: ExpMonth?.toString().padStart(2, '0') + '/' + ExpYear!,
    paymentMethodId: payCard.Id,
    expired: currentAbsMonth! - ExpYear! * 12 - ExpMonth! > 0,
    fieldName: 'paymentMethodId',
    type: payCard.Type,
    country: Country,
  };

  return data as PaymentMethodElementProps;
}
