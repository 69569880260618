import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const StepCardContainer = styled(Card)`
  position: relative;
  min-width: 20rem;
  padding: 1.6rem;
  display: flex;
  height: fit-content;
  flex-direction: column;
  width: 32.6rem;
  gap: 2.4rem;
  ${media.tablet`
    padding: 2.4rem;
    width: 44.8rem;
  `}
  &[data-checked='true'] {
    outline: 0.15rem solid ${colors.greenInput};
  }
`;
