import InputField from 'src/components/InputField/InputField.component';
import { FormGroup } from 'src/style/styleInput.style';
import styled from 'styled-components';

export const InputPhone = styled(InputField)`
  width: 100%;
  & ${FormGroup} {
    height: unset;
  }
`;

export const PhoneFieldWrapper = styled.div`
  margin-inline: auto;
  select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
