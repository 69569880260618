import breakpoints from 'src/style-utils/breakpoints';
import styled from 'styled-components';

export const PopoverWrapper = styled.div`
  @media only screen and (max-width: ${breakpoints.lg - 1}px) {
    [data-radix-popper-content-wrapper] {
      transform: none !important;
      position: unset !important;
      inset: unset !important;
      will-change: unset !important;
    }
  }
`;
