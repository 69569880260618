import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AvatarIcon } from 'src/images/icons/avatar.svg';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';

const Avatar: React.FC<{ title: string; flexDirection?: 'row' | 'column' }> = ({
  title,
  flexDirection = 'row',
}) => {
  return (
    <Flex
      data-component="avatar"
      justify="center"
      align="center"
      flexWrap="nowrap"
      gap={flexDirection === 'column' ? '' : '1.6rem'}
      fluid
      marginBottom="3.2rem"
      flexDirection={flexDirection}
    >
      <Icon svgIconComponent={AvatarIcon} iconSize={'4.8rem'} />
      <H4>
        <Translate id={title} />
      </H4>
    </Flex>
  );
};

export default Avatar;
