import moment from 'moment';
import { Installment } from 'src/services/template/template.types';

export function calculateInstallment(
  totalAmount: number,
  numInstallments: number,
): { InstallmentAmount: number; firstInstallment: number } {
  const InstallmentAmount = parseFloat(
    (totalAmount / numInstallments).toFixed(2),
  );
  const totalInstallmentAmount = InstallmentAmount * numInstallments;
  const firstInstallment = parseFloat(
    (totalAmount - totalInstallmentAmount + InstallmentAmount).toFixed(2),
  );

  return { InstallmentAmount, firstInstallment };
}

export const createArrayCustomInstallment = (
  amount: number,
  totalInstallment: number,
  remodulationFlow: boolean,
  installments?: Installment[],
) => {
  const result = [];
  const { InstallmentAmount, firstInstallment } = calculateInstallment(
    amount,
    totalInstallment,
  );

  for (let i = 0; i < totalInstallment; i++) {
    const element = {
      Expiration:
        installments && installments.length > 0
          ? installments[i].Expiration
          : moment()
              .utc()
              .startOf('day')
              .add(30 * i + (remodulationFlow ? 3 : 0), 'd')
              .hour(12)
              .toISOString(),
      Amount:
        installments && installments.length > 0
          ? installments[i].Amount
          : i > 0
            ? InstallmentAmount
            : firstInstallment,
      Status: 0,
    };
    result.push(element);
  }
  return result;
};
