import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as InfoIcon } from 'src/images/icons/information.svg';
import colors from 'src/style-utils/colors';
import { PLegal } from 'src/style-utils/typographic';
import { TooltipStyled } from './Tooltip.style';

type Props = {
  tooltipText?: string;
};

const Tooltip: React.FC<Props> = ({ tooltipText }) => {
  return (
    <TooltipStyled data-component="tooltip">
      <Icon
        svgIconComponent={InfoIcon}
        color={colors.lightBlueBorder}
        iconSize="2.2rem"
      />
      <div className="tooltip">
        <PLegal textColor="unset">
          <Translate id={tooltipText} />
        </PLegal>
      </div>
    </TooltipStyled>
  );
};

export default Tooltip;
