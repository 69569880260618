import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { ReactComponent as Close } from 'src/images/icons/hamburger-close.svg';
import { ReactComponent as Info } from 'src/images/icons/information-icon.svg';
import { H5, PSmall } from 'src/style-utils/typographic';
import { ColoredSection } from 'src/style/Container.style';
import {
  CustomResponsiveContainer,
  ContainerBar,
  WhiteSpace,
} from './style/FlowHeader.style';
import colors from 'src/style-utils/colors';

interface Props {
  label: string;
  stepNumber?: number;
  stepMaxNumber?: number;
  showWhiteSpace?: boolean;
  showWhiteSpaceRight?: boolean;

  showBackButton?: boolean;
  backButtonAction?: () => void;

  showInfoButton?: boolean;
  infoButtonAction?: () => void;

  showCloseButton?: boolean;
  closeButtonAction?: () => void;
}

const FlowHeader: React.FC<Props> = ({
  label,
  stepNumber = -1,
  stepMaxNumber = -1,
  showWhiteSpace,
  showInfoButton,
  infoButtonAction,
  showBackButton,
  backButtonAction,
  showCloseButton,
  closeButtonAction,
  showWhiteSpaceRight,
}) => {
  return (
    <>
      <ColoredSection
        data-component="flow-header"
        bgColor="white"
        padding="2.4rem 0"
      >
        <CustomResponsiveContainer>
          {showWhiteSpace && <WhiteSpace />}
          {showBackButton && (
            <Button
              variant="LinkPrimary"
              padding="0"
              minWidth="fit-content"
              onClick={backButtonAction}
              leftChild={<Icon iconSize="4rem" svgIconComponent={Back} />}
            />
          )}
          <div>
            {stepNumber > 0 && (
              <PSmall>
                <strong>
                  <Translate id="lbl.step" /> {stepNumber}
                </strong>
              </PSmall>
            )}
            <H5>
              <Translate id={label} />
            </H5>
          </div>
          {showInfoButton && (
            <Button
              onClick={infoButtonAction}
              variant="LinkPrimary"
              gap="0"
              padding="0"
              minWidth="fit-content"
              rightChild={
                <Icon
                  iconSize="3.6rem"
                  svgIconComponent={Info}
                  color={colors.lightBlueBorder}
                />
              }
            />
          )}
          {showWhiteSpaceRight && <WhiteSpace />}
          {showCloseButton && (
            <Button
              onClick={closeButtonAction}
              variant="LinkPrimary"
              gap="0"
              padding="0"
              minWidth="fit-content"
              rightChild={<Icon iconSize="3.6rem" svgIconComponent={Close} />}
            />
          )}
        </CustomResponsiveContainer>
      </ColoredSection>
      <ContainerBar data-component="flow-header">
        <div style={{ width: `${stepNumber * (100 / stepMaxNumber)}%` }} />
      </ContainerBar>
    </>
  );
};

export default FlowHeader;
