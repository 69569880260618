import { Button } from 'src/components/Button/Button.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const ECommerceContainer = styled.div`
  margin-inline: auto;
  max-width: 75.2rem;
  padding-block: 2.4rem;
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row-reverse;
  `}
`;

export const RefreshButtonLink = styled(Button)`
  margin: 1.6rem 0;
  font-weight: 400;
  color: ${colors.textColorGrey};
`;

export const PhoneFieldGroup = styled.div`
  display: grid;
  border-radius: 0.4rem;
  outline: 1px solid ${colors.lightGrey};
  overflow: hidden;
  align-items: center;
  margin: 0.8rem 0;
  > div {
    height: 100%;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    * {
      border-radius: 0;
    }
  }
  > button {
    letter-spacing: 0;
    border-radius: 0;
    min-height: auto;
    white-space: nowrap;
    background-color: ${colors.backgroundBlue};
  }
`;
