import styled from 'styled-components';
import { Flex } from '../../style/flexbox.style';
import colors from '../../style-utils/colors';
import { P } from '../../style-utils/typographic';
import media from '../../style-utils/media-queries';

export const FundTrasferCardContainer = styled(Flex)`
  background-color: ${colors.white};
  box-shadow: 0rem 0.2rem 0.4rem 0.1rem ${colors.cardShadowBlue};
  border-radius: 0.8rem;
  gap: 2rem;
  line-height: 2.8rem;
  color: ${colors.textColorBlack};
  flex-direction: column;
  margin: 2.4rem 0 4rem;
  ${Flex} {
    min-width: 0;
  }
  ${P} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${media.tablet`
    margin: 2.4rem 8rem 4rem;
  `}
`;
