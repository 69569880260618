import { Resolver } from 'react-hook-form';
import { orderImport } from 'src/services/orderLink/orderLink.config';
import numberInRange from 'src/utils/validation/numberInRange';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigOrderImportPayByLink = (remodulationImport?: number) => {
  const initialValues = {
    [orderImport]: remodulationImport ? remodulationImport : '',
  };

  const validate = validateFormSync({
    [orderImport]: validateFieldSync(
      required,
      numberInRange({ min: 2, max: undefined }),
    ),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
