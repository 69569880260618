import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  initSignUpSession,
  updateSignUpSessionData,
  verifySignUpSessionOtp,
  performSignUpSession,
  refreshSignUpSessionOtp,
} from './registration.config';
import { LoginResultSet } from 'src/services/login/loogin.types';

export type InitSignUpData = {
  SearchId?: number;
  BuyerGuid?: string;
  RoleInviteGuid?: string;
  AccountType: string;
  Language: string;
  RegistrationType: 'full' | 'light';
};
export type InitSignUpResponse = {
  ResultSet: {
    Guid: string;
    Email: string;
    IsEmailVerified: boolean;
    Phone: string;
    IsPhoneVerified: string;
  };
};
export const initSignUpSessionMethodApi = async (data: InitSignUpData) => {
  const requestConfig = makeEndpointApiConfig({
    url: initSignUpSession,
    method: 'POST',
    data,
  });
  return await apiRequest.request<InitSignUpResponse>(requestConfig);
};
export const enum DataTypeArray {
  email = 'email',
  phone = 'phone',
  password = 'password',
}
export type UpdateSignUpData = {
  DataType: DataTypeArray;
  DataValue: string;
  DataValueAdd?: string;
  SessionGuid: string;
};
export type UpdateSignUpResponse = {
  ResultSet: boolean;
};
export const updateSignUpSessionMethodApi = async (data: UpdateSignUpData) => {
  const requestConfig = makeEndpointApiConfig({
    url: updateSignUpSessionData,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request<UpdateSignUpResponse>(requestConfig);
};
export const enum OtpTypeArray {
  email = 'email',
  phone = 'phone',
}
export type VerifySignUpSessionOtpData = {
  OtpType: OtpTypeArray;
  OtpValue: string;
  SessionGuid: string;
};
export type VerifySignUpSessionOtpResponse = {
  ResultSet: boolean;
};
export const verifySignUpSessionOtpMethodApi = async (
  data: VerifySignUpSessionOtpData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: verifySignUpSessionOtp,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request<VerifySignUpSessionOtpResponse>(
    requestConfig,
  );
};
export type RefreshSignUpSessionOtpData = {
  OtpType: OtpTypeArray;
  SessionGuid: string;
};
export type RefreshSignUpSessionOtpResponse = {
  ResultSet: boolean;
};
export const refreshSignUpSessionOtpMethodApi = async (
  data: RefreshSignUpSessionOtpData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: refreshSignUpSessionOtp,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request<RefreshSignUpSessionOtpResponse>(
    requestConfig,
  );
};
export type PerformSignUpSessionData = {
  FlagPrivacy: boolean;
  FlagB: boolean;
  FlagC: boolean;
  SessionGuid: string;
  Language: string;
};
export type PerformSignUpSessionResponse = {
  ResultSet: LoginResultSet;
};
export const performSignUpSessionMethodApi = async (
  data: PerformSignUpSessionData,
) => {
  const requestConfig = makeEndpointApiConfig({
    url: performSignUpSession,
    method: 'POST',
    data,
    params: {
      sessionGuid: data.SessionGuid,
    },
  });
  return await apiRequest.request<PerformSignUpSessionResponse>(requestConfig);
};
