import { useLocation } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useSession } from 'src/services/session/session.context';

export const useCheckErrorPageLoggedIn = () => {
  const location = useLocation();
  const path = location.pathname;
  const { auth } = useSession();
  const authToken = auth.authToken;

  return (path === RoutePath.oops && authToken) as boolean;
};
