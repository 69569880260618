import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_right.svg';
import React from 'react';
import { useProfileStore } from 'src/store/store';
import { Hr } from 'src/style-utils/hr';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { AccountPageArray } from './Account.config';
import { LinkAccount } from './style/LinkAccount.style';

const Account: React.FC = () => {
  const tipoAccount = useProfileStore((state) => state.AccountType);
  const Role = useProfileStore((state) => state.Role);

  return (
    <div className="mx-auto max-w-md pt-14">
      <TextDefaultPage title="text.profile" />
      {AccountPageArray.map(
        (account, index) =>
          account.tipoAccount.includes(tipoAccount) &&
          account.tipoAccount.includes(Role) && (
            <React.Fragment key={index}>
              <LinkAccount to={account.path}>
                <Flex align="center">
                  <Icon
                    svgIconComponent={account.icon}
                    marginRight="1.5rem"
                    iconSize="3rem"
                  />
                  <P>
                    <Translate id={account.title} />
                  </P>
                </Flex>
                <Icon iconSize="2.8rem" svgIconComponent={ArrowRight} />
              </LinkAccount>
              {account.divider && <Hr />}
            </React.Fragment>
          ),
      )}
    </div>
  );
};

export default Account;
