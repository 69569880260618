import { createGlobalStyle } from 'styled-components';

export const FreezeScreen = createGlobalStyle`
  body {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
`;

if (process.env.NODE_ENV !== 'production') {
  FreezeScreen.displayName = 'FreezeScreen';
}
