import AmountColumn from './partials/partialsTable/AmountColumn.component';
import CreationColumn from './partials/partialsTable/CreationColumn.component';
import OrderIdColumn from './partials/partialsTable/OrderIdColumn.component';
import StatusColumn from './partials/partialsTable/StatusColumn.componen';

import { ReactComponent as Created } from 'src/images/advPlanRequestsStatus/created.svg';
import { ReactComponent as Approved } from 'src/images/advPlanRequestsStatus/approved.svg';
import { ReactComponent as Unsuitable } from 'src/images/advPlanRequestsStatus/unsuitable.svg';
import InstallmentPlanColumn from './partials/partialsTable/InstallmentPlanColumn';

export const remodulationRequestsTableColumnConfig = [
  {
    id: 'order-id',
    header: 'remodulationRequestsHeaderTable.OrderId',
    RenderComponent: OrderIdColumn,
    rowCellConfig: {
      w: '100%',
      minWDesktop: '37.4rem',
    },
  },
  {
    id: 'creation',
    header: 'remodulationRequestsHeaderTable.Creation',
    RenderComponent: CreationColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'amount',
    header: 'remodulationRequestsHeaderTable.Amount',
    RenderComponent: AmountColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'installmentPlan',
    header: 'remodulationRequestsHeaderTable.InstallmentPlan',
    RenderComponent: InstallmentPlanColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'status',
    header: 'remodulationRequestsHeaderTable.Status',
    RenderComponent: StatusColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
];

export enum RemodulationRequestsStatus {
  created = 0,
  approved = 1,
  unsuitable = 2,
}

export const RemodulationRequestsStatusMap = {
  [RemodulationRequestsStatus.created]: {
    label: 'remodulationRequestStatus.created',
    icon: Created,
    style: {
      bg: '#636E82',
      color: '#fff',
      colorBorder: '#636E82',
    },
  },
  [RemodulationRequestsStatus.approved]: {
    label: 'remodulationRequestStatus.approved',
    icon: Approved,
    style: {
      bg: '#009E6B',
      color: '#fff',
      colorBorder: '#009E6B',
    },
  },
  [RemodulationRequestsStatus.unsuitable]: {
    label: 'remodulationRequestStatus.unsuitable',
    icon: Unsuitable,
    style: {
      bg: '#F13D3E',
      color: '#fff',
      colorBorder: '#F13D3E',
    },
  },
};
