import { SyncFieldValidator } from './types';

export const isVat = (str: string, regex: RegExp) => {
  return regex.test(str);
};

export const errorMessageVat = 'error.vat';

export const vat = function vat({
  regex,
}: {
  regex?: string;
}): SyncFieldValidator {
  const reg = new RegExp(regex ?? '');
  return function validateLenth(value: any) {
    if (typeof value !== 'string' || !isVat(value, reg)) {
      return errorMessageVat;
    }

    return undefined;
  };
};
