import { ReactComponent as Second } from 'src/images/icons/IconSend.svg';
import { ReactComponent as First } from 'src/images/icons/call.svg';
import { ReactComponent as Third } from 'src/images/icons/repeat.svg';
import { FunctionComponent, SVGProps } from 'react';

export type CardProps = {
  SvgIcon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  content: string;
};

export const InfoCards: CardProps[] = [
  {
    SvgIcon: First,
    title: 'avoidExp.Title1',
    content: 'avoidExp.Content1',
  },
  {
    SvgIcon: Second,
    title: 'avoidExp.Title2',
    content: 'avoidExp.Content2',
  },
  {
    SvgIcon: Third,
    title: 'avoidExp.Title3',
    content: 'avoidExp.Content3',
  },
];

export const InfoCardsResend: CardProps[] = [
  {
    SvgIcon: First,
    title: 'avoidExp.Title1',
    content: 'avoidExp.Content1',
  },
  {
    SvgIcon: Third,
    title: 'avoidExp.Title3',
    content: 'avoidExp.Content3',
  },
];
