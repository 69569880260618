import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { fallbackLng, supportedLngs } from './i18n.config';

i18n.use(initReactI18next).init({
  fallbackLng,
  supportedLngs,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  debug: true, // !!import.meta.env.VITE_APP_USE_MOCK_SERVER,
  load: 'all',
  resources: {},
});

export default i18n;
