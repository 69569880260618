import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { DataFormPayByLink } from '../../SearchCriteriaForm.helpers';
import {
  flagCessioneCredito,
  flagNonCessioneCredito,
} from '../../SearchCriteriaForm.config';
import MoveDetailCheckboxBoolean from 'src/components/MoveDetailCheckboxBoolean/MoveDetailCheckboxBoolean.component';
import { pblPageArray } from '../../../payByLinkPage.config';

type CollectionMethodPopoverProps = {
  madeFromPlugin: boolean;
  submitHandler?: MouseEventHandler | undefined;
  setData: Dispatch<SetStateAction<any | undefined>>;
  dataFiltered: DataFormPayByLink;
  onCloseHandler?: () => void;
};
export const CollectionMethodPopover: React.FC<
  CollectionMethodPopoverProps
> = ({
  madeFromPlugin,
  dataFiltered,
  submitHandler,
  setData,
  onCloseHandler,
}) => {
  const label =
    pblPageArray[madeFromPlugin.toString() as keyof typeof pblPageArray];
  return (
    <SelectPopover
      submitHandler={submitHandler}
      label="lbl.colletionMethod"
      countFilters={
        [
          dataFiltered?.flagCessioneCredito,
          dataFiltered?.flagNonCessioneCredito,
        ].filter(Boolean).length || undefined
      }
      onCloseHandler={onCloseHandler}
    >
      <MoveDetailCheckboxBoolean
        formFieldName={flagCessioneCredito}
        titleId={label.modalCheckbox.adv}
        subtitleId={'lbl.withAdvancePaymentSubtitle'}
        fullWidth={true}
      />
      <MoveDetailCheckboxBoolean
        formFieldName={flagNonCessioneCredito}
        titleId={label.modalCheckbox.noAdv}
        subtitleId={'lbl.withoutAdvancePaymentSubtitle'}
        fullWidth={true}
      />
    </SelectPopover>
  );
};

export default CollectionMethodPopover;
