import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import {
  createInviteRole,
  listInviteRole,
  updateRole,
} from './collaborator.config';
import {
  CreateInviteRoleData,
  ListInviteRoleResponse,
  UpdateRoleData,
  UpdateRoleResponse,
} from './collaborator.types';

export const createInviteRoleMethodApi = async (data: CreateInviteRoleData) => {
  const requestConfig = makeEndpointApiConfig({
    url: createInviteRole,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request(requestConfig);
};

export const getListInviteRoleMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: listInviteRole,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request<ListInviteRoleResponse>(requestConfig);
};

export const updateRoleMethodApi = async (data: UpdateRoleData) => {
  const requestConfig = makeEndpointApiConfig({
    url: updateRole,
    method: 'POST',
    authenticated: true,
    data,
  });
  return await apiRequest.request<UpdateRoleResponse>(requestConfig);
};
