import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import PersonalDataCard from 'src/components/PersonalDataCard/PersonalDataCard.component';
import { ReactComponent as PhoneIcon } from 'src/images/icons/call.svg';
import { ReactComponent as MailIcon } from 'src/images/icons/email.svg';
import { useEffect, useState } from 'react';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import OTPInput from './Otp.component';
import Translate from 'src/components/Translate/Translate.component';
import Tooltip from 'src/components/Tooltip/Tooltip.component';
import { PNote } from 'src/style-utils/typographic';

const TIMER_VALUE = 60;

type Props = {
  prefix?: string;
  email?: string;
  mobile?: string;
  sendAgainHandler: () => void;
  errorMessage: string;
  setErrorMessage?: (value: string) => void;
  isLoadingRefresh: boolean;
  autoFocus?: boolean;
};

const OtpForm: React.FC<Props> = ({
  sendAgainHandler,
  prefix,
  mobile,
  email,
  errorMessage,
  setErrorMessage,
  isLoadingRefresh,
  autoFocus,
}) => {
  const [timer, setTimer] = useState(TIMER_VALUE);
  const buttonText =
    timer === 0 ? 'text.sendCodeAgain' : 'text.sendCodeAgainWait';

  useEffect(() => {
    if (timer > 0) {
      const timerId = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timerId);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timer]);

  const onClickHandler = () => {
    setTimer(TIMER_VALUE);
    if (setErrorMessage) {
      setErrorMessage('');
    }
    sendAgainHandler();
  };

  return (
    <div className="mx-auto grid w-fit gap-8">
      <PersonalDataCard
        icon={email ? MailIcon : PhoneIcon}
        value={email ? email : prefix + ' ' + maskPhoneNumber(mobile ?? '')}
        label={email ? 'lbl.emailAddress' : 'lbl.phoneNumber'}
      />
      <div>
        <OTPInput length={6} autoFocus={autoFocus} />
        {errorMessage && (
          <GenericErrorForm isPresentMessage errorMessageID={errorMessage} />
        )}
      </div>
      <div className="flex">
        <button
          className="mr-auto cursor-pointer text-pblack-400 underline hover:text-pgrey disabled:cursor-not-allowed disabled:text-pgrey disabled:no-underline"
          disabled={timer > 0 || isLoadingRefresh}
          type="button"
          onClick={onClickHandler}
        >
          <PNote textColor={'inherit'}>
            <Translate id={buttonText} data={{ x: timer }} />
          </PNote>
        </button>
        <span className="flex items-center text-info">
          <PNote textColor={'inherit'}>
            {timer > 0 && <Translate id="text.codeSent" />}
          </PNote>
          <Tooltip tooltipText="text.sendCodeAgainTooltip" />
        </span>
      </div>
    </div>
  );
};

export default OtpForm;
