import FormEmail from 'src/pages/registration/partials/FormEmail/FormEmail.component';
import { RoutePath } from 'src/routers/routers.config';

function RegistrationStepEmail() {
  return (
    <FormEmail
      nextPath={RoutePath.registrationMerchant + RoutePath.otpConfirmEmailStep}
      step={4}
      stepMax={9}
    />
  );
}

export default RegistrationStepEmail;
