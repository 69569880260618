import { Button } from 'src/components/Button/Button.component';
import { Link } from 'react-router-dom';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const ErrorPagePar = styled(P)`
  color: ${colors.textColorGrey};
  height: max-content;
  line-height: 2.4rem;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ContainerErrorPage = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 4rem;
`;

export const ButtonPaymentError = styled(Button)`
  min-width: 32.7rem;
`;

export const ButtonTertiary = styled(Button)`
  margin-top: 1.6rem;
  min-width: 32.7rem;
`;

export const ContainerLink = styled.div`
  border-radius: 0.8rem;
  padding: 1.6rem 1.2rem;
  border: 1px solid ${colors.borderbgBtnPressedSecondary};
  width: 100%;
  ${P} {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    color: ${colors.textColorBlack};
  }
`;

export const TextLink = styled(Link)`
  color: ${colors.textColorBlack};
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: underline;
  & div {
    vertical-align: sub;
  }
`;
