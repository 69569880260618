import { ReactComponent as OpynNamirial } from 'src/images/icons/logo_opyn_namirial.svg';
import { ReactComponent as OpynStripe } from 'src/images/icons/logo_opyn_stripe.svg';
import { Flex } from 'src/style/flexbox.style';
import AlertCard, { AlertCardProp } from './AlertCard.component';

function AlertCardsContainer({ isIta }: { isIta: boolean }) {
  const cards: Array<AlertCardProp> = [
    {
      icon: isIta ? OpynNamirial : undefined,
      title: 'text.cardNamirialTitle',
      content: isIta
        ? 'text.cardNamirialContent'
        : 'text.cardNamirialContentExt',
    },
    {
      icon: OpynStripe,
      title: 'text.cardStripeTitle',
      content: 'text.cardStripeContent',
    },
  ];
  return (
    <>
      <Flex flexWrap="wrap" gap="2rem">
        {cards.map((card, i) => (
          <AlertCard {...card} key={i} />
        ))}
      </Flex>
    </>
  );
}

export default AlertCardsContainer;
