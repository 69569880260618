import { SyncFieldValidator } from './types';

/**
 * Composes synchronous validators.
 * @param validators synchronous validators.
 * @see SyncFieldValidator
 * @example
 * ```typescript
 * const fieldValidator = validateSync(required, email);
 * ```
 */
export function validateFieldSync(
  ...validators: SyncFieldValidator[]
): SyncFieldValidator {
  return function validateSync(value: unknown) {
    let output: string | undefined;

    for (let i = 0, len = validators.length; i < len; i++) {
      output = validators[i](value);

      if (typeof output === 'string') {
        return output;
      }
    }

    return output;
  };
}

/**
 * Generates a synchronous form validator.
 * @param formValidators an object that maps form names to validators.
 * @see SyncFieldValidator
 * @example
 * ```typescript
 * const emailValidator = validateFieldSync(required, email);
 * const genericInputValidator = validateFieldSync(required);
 *
 * const formValidator = validateFormSync({
 *   email: validateFieldSync(required, email),
 *   firstName: required,
 *   lastName:  required,
 * });
 * ```
 */
export function validateFormSync<T = any>(
  formValidators: Record<string, SyncFieldValidator>,
) {
  return function validateFormSync(
    values: Record<string, T>,
  ): Record<string, string | undefined> {
    const errors = {} as Record<string, undefined | string>;

    const entries = Object.entries(values);

    for (const [name, value] of entries) {
      const error =
        typeof formValidators[name] === 'function'
          ? formValidators[name](value)
          : undefined;

      // We need to add it to 'errors' only if the error is present
      // Does not submit if there's a '{ [fieldName]: undefined }'.
      if (error) {
        errors[name] = error;
      }
    }

    return errors;
  };
}
