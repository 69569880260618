import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  CheckBoxWrapper,
  CheckboxContainer,
  CheckboxInput,
  Checkmark,
} from './checkboxStripe.style';

type CheckboxStripeProps = {
  description: string;
  checked: boolean;
  disabled?: boolean;
  setChecked?: any;
};
function CheckboxStripe({
  description,
  checked,
  setChecked,
  disabled,
}: CheckboxStripeProps) {
  return (
    <Flex justify="space-between" align="center" flexWrap="nowrap" gap="2rem">
      <P marginTop="0">
        <Translate id={description} />
      </P>
      <CheckBoxWrapper>
        <CheckboxContainer>
          <CheckboxInput
            checked={checked}
            type="checkbox"
            onClick={() => setChecked(!checked)}
            disabled={disabled}
          />
          <Checkmark />
        </CheckboxContainer>
      </CheckBoxWrapper>
    </Flex>
  );
}

export default CheckboxStripe;
