import styled from 'styled-components';

interface ContainerMessageProps {
  isPresentMessage: boolean | undefined;
}

export const ContainerMessage = styled.div<ContainerMessageProps>`
  display: block;
  font-size: 1rem;
  line-height: 1.1875;
  ${({ isPresentMessage }) =>
    isPresentMessage &&
    `
        & > ${MessageError} {
          display:block;
        }
      `}
  padding: 0.5rem 0 0;
`;

export const MessageError = styled.span`
  color: inherit;
  display: none;
  font-size: inherit;
  font-weight: 300;
`;
