import FormFlagToPay from 'src/pages/registration/partials/FormFlagToPay/FormFlagToPay.component';
import { RoutePath } from 'src/routers/routers.config';

const FlagStepRegistration: React.FC = () => {
  return (
    <FormFlagToPay
      step={6}
      stepMax={7}
      nextPath={RoutePath.paymentFlow + RoutePath.payWithOpyn}
    />
  );
};

export default FlagStepRegistration;
