import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import required from 'src/utils/validation/required';
import { Resolver } from 'react-hook-form';
import {
  FlagB,
  FlagC,
  FlagPrivacyPolicy,
} from 'src/services/marketing/marketing.config';

export const MarketingChoiceConfig = (
  flagPrivacyPolicy: boolean,
  flagFinalitaB: boolean,
  flagFinalitaC: boolean,
) => {
  const initialValues = {
    [FlagPrivacyPolicy]: flagPrivacyPolicy,
    [FlagB]: flagFinalitaB,
    [FlagC]: flagFinalitaC,
  };

  const validate = validateFormSync({
    [FlagPrivacyPolicy]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
