import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as errorIcon } from 'src/images/pageStatus/error_icon.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { H4 } from 'src/style-utils/typographic';
import { ErrorPagePar } from '../style/failedPage.style';
import { RoutePath } from 'src/routers/routers.config';
import { useSession } from 'src/services/session/session.context';

const LoginBuyerError: React.FC = () => {
  const { onLogout } = useSession();
  const navigate = useNavigate();

  const handleClick = () => {
    onLogout();
    navigate(`${RoutePath.login}`);
  };

  return (
    <>
      <Icon
        svgIconComponent={errorIcon}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.somethingWentWrong" />
      </H4>
      <ErrorPagePar>
        <Translate id="text.errorFiscalCodeLoginBuyer" />
      </ErrorPagePar>
      <ButtonContainer>
        <Button onClick={handleClick} minWidth="100%" variant="Primary">
          <Translate id="lbl.close" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default LoginBuyerError;
