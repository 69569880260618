import Translate from 'src/components/Translate/Translate.component';

const NeedHelpInline: React.FC<any> = (props) => {
  return (
    <button
      {...props}
      style={{ fontWeight: '700', textDecoration: 'underline' }}
    >
      <Translate id={'supportModal.Button'} />
    </button>
  );
};

export default NeedHelpInline;
