import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  invoiceDateField,
  invoiceFileField,
} from 'src/services/orderLink/orderLink.config';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { H4, PNote } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { formConfigInvoiceDatePayByLink } from './StepInvoiceDate.helpers';
import DatePickerField from 'src/components/DatePickerField/DatePickerField.component';
import React, { useState } from 'react';
import { AlertComponent } from '../../../../components/ORION/Alert/Alert.component';
import InputFile from '../../../../components/InputFile/InputFile.component';
import { Button } from '../../../../components/Button/Button.component';
import { useMutation } from 'react-query';
import { setInvoiceDateAndFileMethodApi } from '../../../../services/orderLink/orderLink.request';
import Loader from '../../../../components/Loader/Loader.component';
import DoubleChoicePopUp from '../../../../components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';

function StepInvoiceDate() {
  const [showPopUp, setShowPopUp] = useState(false);
  const { productCode, categoryCode } = useParams();
  const orderInvoiceRange = useProfileStore((state) => state.OrderInvoiceRange);

  const minDate = moment(new Date()).subtract(
    orderInvoiceRange.DaysBefore,
    'days',
  );
  const maxDate = moment(new Date()).add(orderInvoiceRange.DaysAfter, 'days');

  const { initialValues, resolver } = formConfigInvoiceDatePayByLink(
    '',
    orderInvoiceRange.DaysBefore,
    orderInvoiceRange.DaysAfter,
  );
  const navigate = useNavigate();
  const { sessionGuid, setValue } = usePayByLinkStore((state) => state);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation(setInvoiceDateAndFileMethodApi, {
    onSuccess: () => {
      setValue('flagCredit', true);

      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
      );
    },
    onError: () => {
      setValue('flagCredit', false);
      setShowPopUp(true);
    },
  });

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append(
      'invoiceDate',
      moment(data[invoiceDateField]).format('YYYY-MM-DD'),
    );
    for (const file of data[invoiceFileField]) {
      formData.append('invoiceFile', file);
    }
    mutate({ formData, orderCreationSessionGuid: sessionGuid });
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={12}
        stepMax={13}
        title="lbl.invoiceDateInvoice"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.step.invoice" />
        </H4>
        <AlertComponent
          variant="warning"
          titleLbl="text.step.invoice.alert.title"
          descriptionLbl="text.step.invoice.alert.content"
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-10">
                <DatePickerField
                  className={'w-100'}
                  name={invoiceDateField}
                  minDate={new Date(minDate.toString())}
                  maxDate={new Date(maxDate.toString())}
                  label={'text.step.invoice.date'}
                  popperModifiers={[
                    {
                      name: 'arrow',
                      options: { padding: 24 },
                    },
                  ]}
                  isPresentMessage
                />
              </div>
            </div>
            <PNote className="mt-1">
              <Translate
                id="text.invoiceDate"
                data={{
                  invoiceDate: minDate.format('DD MMMM yyyy'),
                }}
              />
            </PNote>

            <div className="mb-44 mt-10 lg:mb-0">
              <InputFile
                name={invoiceFileField}
                label={'text.step.invoice.file'}
                acceptLabel={'PDF, PNG, JPG, XML'}
                accept={
                  'application/pdf, image/png, image/jpeg, application/xml'
                }
                required={true}
                maxSizeInMB={10}
                id={invoiceFileField}
                isPresentMessage={true}
              />
            </div>

            <ButtonContainer>
              <SubmitButton minWidth="100%" id={IDs.btnProceed}>
                <Translate id="lbl.next" />
              </SubmitButton>
              <Button
                minWidth="100%"
                variant="LinkPrimary"
                onClick={() => {
                  setValue('flagCredit', true);
                  navigate(
                    `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
                  );
                }}
              >
                <Translate id={'text.step.invoice.next'} />
              </Button>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
      {isLoading && (
        <Loader overlayViewMode="content" active={true} viewMode="fullSize">
          <div className="mt-8 text-xl font-bold text-pblack-400 lg:mt-16 lg:text-3xl">
            <Translate id="text.loaderStepLoadingInvoice" />
          </div>
        </Loader>
      )}
      {showPopUp && (
        <DoubleChoicePopUp
          handleSecondAction={() => {
            setValue('flagCredit', false);
            setShowPopUp(false);
          }}
          handleClose={() => {
            setValue('flagCredit', false);
            setShowPopUp(false);
          }}
          modalTitle="text.loaderStepInvoice.error"
          firstButtonLabel="text.retry"
          icon={AlertIcon}
        />
      )}
    </>
  );
}

export default StepInvoiceDate;
