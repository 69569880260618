export const getUWBuyer = 'Account/GetUWBuyer';
export const notifyBuyerCreated = 'Account/StartUWBuyer';
export const getUWADV = 'Account/GetUWResultForAdvanceRequest';

export enum ReasonUw {
  orderCreation = 'orderCreation',
  postPluginCheck = 'postPluginCheck',
  postGiveCredit = 'postGiveCredit',
  orderCreationPlugin = 'orderCreationPlugin',
}
