import { SupportedLanguageCode } from 'src/services/i18n/i18n.types';
import { itCountries as countries_it } from 'src/services/geo/countries_it';
import { enCountries as countries_en } from 'src/services/geo/countries_en';

/**
 * Get countries by language
 * @param language String of output language
 */
export default function getCountries(language: SupportedLanguageCode): {
  code: string;
  prefix: number;
  country_name: string;
  format_excluding_country_prefix?: string;
}[] {
  const lang = language.substring(0, 2);

  switch (lang) {
    case `it`:
      return countries_it;
    case `en`:
      return countries_en;
    default:
      return countries_en;
  }
}
