import FormPassword from 'src/pages/registration/partials/FormPassword/FormPassword.component';
import { RoutePath } from 'src/routers/routers.config';

const PasswordStepRegistration: React.FC = () => {
  return (
    <>
      <FormPassword
        nextPath={RoutePath.registrationBuyer + RoutePath.flagStep}
        step={5}
        stepMax={6}
      />
    </>
  );
};

export default PasswordStepRegistration;
