import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const PayByLinkIconQR = styled.div`
  margin: 3.2rem auto;
  background-color: ${colors.textColorBlack};
  border-radius: 1.6rem;
  padding: 2.4rem;
  width: fit-content;
  text-align: center;
`;
