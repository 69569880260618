import { KeyboardEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalClosebehaviour, ModalHelperProps } from './Modal.types';

export const useModalHelper = (
  props: ModalHelperProps & ModalClosebehaviour,
) => {
  //CHECK IF NEED TO CLOSE MODAL WHEN ESC KEY IS PRESSED
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (props.enableCloseOnEscKey) {
      if (event.key === 'Escape') {
        props.handleClose();
      }
    }
  };
  //CHECK IF NEED TO CLOSE MODAL WHEN CLICKED OUTSIDE THE ModalContentRoot
  const handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (!props.preventCloseOnClickOutside) {
      const { target, currentTarget } = event;
      if (target === currentTarget) {
        props.handleClose();
      }
    }
  };
  //TRANSLATE MODAL TITLE IF NECESSARY
  const translate = useTranslation().t;
  const processHeaderTranslation = (): string | undefined => {
    if (props.i18n && typeof props.modalTitle === 'string') {
      return translate(
        props.modalTitle,
        typeof props.i18nData === 'object' && props.i18nData !== null
          ? props.i18nData
          : '',
      ) as string;
    }
    return props.modalTitle;
  };
  return { handleKeyDown, handleClickOutside, processHeaderTranslation };
};
