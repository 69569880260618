import Accordion from 'src/components/Accordion/Accordion.component';
import Translate from 'src/components/Translate/Translate.component';
import Info from 'src/images/ecommerces/info.png';
import { ReactComponent as Amex } from 'src/images/icons/paymentMethods/amex.svg';
import { ReactComponent as Gplay } from 'src/images/icons/g-pay.svg';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import { ReactComponent as PostePay } from 'src/images/icons/postepay.svg';
import { ReactComponent as Sepa } from 'src/images/icons/sepa.svg';
import { ReactComponent as Visa } from 'src/images/icons/paymentMethods/visa.svg';
import { PropsWithChildren } from 'react';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H4, H5, P } from 'src/style-utils/typographic';

const PaymentMethodWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex h-16 w-[9.8rem] rounded-lg border border-pgreyoutlined bg-white p-2">
      {children}
    </div>
  );
};

const PluginInfo = () => {
  return (
    <div className="container py-10">
      <div className="grid gap-10">
        <div className="grid gap-2 text-center">
          <H5>
            <Translate id="lbl.buyNowSolution" />
          </H5>
          <P>
            <Translate id="text.buyNowSolution" />
          </P>
        </div>
        <div className="grid gap-4 text-center">
          <P bold colorBlack>
            <Translate id="lbl.valuePurchase" />
          </P>
          <div className="grid gap-4 lg:grid-cols-2">
            <PlanCard
              installmentsNumber={3}
              installmentAmount={300}
              duration={3}
              today
            />
            <PlanCard
              installmentsNumber={6}
              installmentAmount={150}
              duration={7}
            />
          </div>
          <P>
            <Translate id="text.installmentOptions" />
          </P>
        </div>
        <hr className="w-full" />
        <div className="grid gap-8 lg:grid-cols-2 lg:gap-10">
          <img
            height={672}
            width={792}
            className="h-auto w-full"
            src={Info}
            alt="placeholder"
          />
          <div className="my-6 flex flex-col gap-8 self-center">
            <H4>
              <Translate id="text.pluginInfoTitle" />
            </H4>
            <div className="flex flex-wrap gap-4">
              {[Visa, Mastercard, PostePay, Amex, Gplay, Sepa].map(
                (Item, i) => (
                  <PaymentMethodWrapper key={i}>
                    <Item width="100%" />
                  </PaymentMethodWrapper>
                ),
              )}
            </div>
          </div>
        </div>
        <div>
          <H5 light className="mb-4">
            <Translate id="text.FAQ" />
          </H5>
          <Accordion />
        </div>
      </div>
    </div>
  );
};

type PlanCardProps = {
  installmentsNumber: number;
  duration: number;
  installmentAmount: number;
  today?: boolean;
};
const PlanCard: React.FC<PlanCardProps> = ({
  installmentsNumber,
  installmentAmount,
  duration,
  today,
}) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  return (
    <div className="flex-auto items-center gap-3 rounded-lg bg-white p-4 outline outline-1 outline-pgreyoutlined">
      <div className="mb-4 flex items-center justify-between">
        <H5>
          <Translate
            id="lbl.installment"
            data={{
              rateNumber: installmentsNumber,
            }}
          />
        </H5>
        <P className="text-pblack-400">
          <span className="font-bold">
            <Translate
              id="lbl.payValue"
              data={{
                value: formatCurrency(installmentAmount, { hideCents: true }),
              }}
            />
          </span>
          &nbsp;
          <Translate id={today ? 'lbl.now' : 'lbl.inAMonth'} />
        </P>
      </div>
      <div className="grid gap-2">
        <div className="flex justify-between bg-bblue px-2 py-1">
          <P>
            <Translate id="lbl.installmentAmount" />
          </P>
          <P bold>{installmentAmount}€</P>
        </div>
        <div className="flex justify-between bg-bblue px-2 py-1">
          <P>
            <Translate id="lbl.infoDuration" />
          </P>
          <P bold>
            <Translate id="lbl.Xmonths" data={{ x: duration }} />
          </P>
        </div>
        <div className="flex justify-between bg-bblue px-2 py-1">
          <P>
            <Translate id="lbl.interest" />
          </P>
          <P bold>0€</P>
        </div>
      </div>
    </div>
  );
};

export default PluginInfo;
