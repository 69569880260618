import Loader from 'src/components/Loader/Loader.component';
import React, { lazy, Suspense } from 'react';

interface Props {
  fallback: React.ReactNode | null;
}

const loadable = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
  { fallback = null }: Props = { fallback: null },
) => {
  const LazyComponent = lazy(importFunc);

  return (props: React.ComponentProps<T>) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

const loadablePageOptions = {
  fallback: (
    <Loader
      viewMode="fluid"
      overlayViewMode="fullscreen"
      active
      animationDelayMs={100}
    />
  ),
};

export const loadablePage = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
) => loadable<T>(importFunc, loadablePageOptions);

export default loadable;
