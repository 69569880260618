export const loginEndPoint = 'Account/Token';
export const loginEndPointGuest = 'Account/TokenGuest';
export const preLoginEndPoint = 'Account/BuyerCheckIdentity';
export const preLoginEndPointGuest = 'Account/BuyerCheckIdentityGuest';
export const signInSendOtpGuest = 'Account/GuestSignInSendOtp';

export const emailField = 'email-field' as const;
export const passwordField = 'password-field';
export const buyerGuid = 'buyer-guid';
export const cfBuyer = 'cf-buyer';
export const rememberMe = 'remember-me';
