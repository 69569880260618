import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ClientEmailCard from 'src/components/ClientEmailCard/ClientEmailCard.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CreatedLinkSuccess } from 'src/images/pageStatus/created_link_success.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import {
  MaxWidthContainer,
  ResponsiveContainer,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { IDs } from 'src/utils/config/ids';
import CreateLinkInfo from '../CreateLinkInfo/CreateLinkInfo.component';

function SuccessSendLink() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setValue, orderId, templateSelected, inviteEmail } =
    usePayByLinkStore((state) => state);
  const CanSaveTemplate = location?.state?.CanSaveTemplate;
  useEffect(() => {
    setValue('stateFlow', false);
  }, []);

  return (
    <>
      <ResponsiveContainer>
        <Flex margin="4.2rem auto" justify="center">
          <Icon
            iconSize="26.4rem"
            iconHeight="19.3rem"
            svgIconComponent={CreatedLinkSuccess}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          style={{ textAlign: 'center' }}
        >
          <MaxWidthContainer maxWidth="56rem">
            <TextDefaultPage
              title="lbl.payByLinkSendSuccess"
              subTitle="text.payByLinkSendLinkSuccess"
            />
          </MaxWidthContainer>
        </Flex>
        <ClientEmailCard
          label="lbl.payByLinkDetailEmailAddress"
          email={inviteEmail}
        />
        <ButtonContainer>
          <Button
            onClick={() => navigate(RoutePath.linkDiPagamento)}
            variant="Primary"
            id={IDs.btnAllLinks}
          >
            <Translate id="lbl.showAllPayByLink" />
          </Button>
        </ButtonContainer>
      </ResponsiveContainer>
      <CreateLinkInfo />
      {CanSaveTemplate && !templateSelected && (
        <Toast
          variant="info"
          title="text.saveTemplateTitle"
          subTitle="text.saveTemplateSubtitle"
          buttonAction={() => {
            setValue('stateFlow', true);
            navigate(RoutePath.saveTemplate, { state: { orderId } });
          }}
          buttonText="lbl.saveTheTemplate"
        />
      )}
    </>
  );
}

export default SuccessSendLink;
