import { Button } from 'src/components/Button/Button.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { PSmall } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const SvgContainer = styled.div`
  position: absolute;
  color: ${colors.green10};
  top: -6rem;
  width: 30rem;
  rotate: -12deg;
  align-self: center;
  z-index: -1;
  pointer-events: none;
`;

export const CategoryCardWrapper = styled(Card)`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  position: relative;
  z-index: 0;
`;

export const Label = styled(PSmall)`
  background-color: ${colors.textColorBlack};
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  width: fit-content;
`;

export const Action = styled(Button)`
  height: 4.4rem;
  width: 4.4rem;
  border-radius: 50%;
  min-width: unset;
  align-self: end;
  ${media.tablet`
    height: 5.4rem;
    width: 5.4rem;
  `}
`;
