import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const ResponsiveFunnelWrapper = styled.div`
  margin: 3.6rem auto;
  width: min(52rem, 100% - 6.4rem);
  ${media.tablet`
    text-align: center;
  `}
`;

export const ContainerInfo = styled(Card)`
  margin: auto;
  text-align: left;
  > hr {
    margin: 0.8rem 0;
    border-color: ${colors.backgroundOutlined};
  }
`;

export const ContainerBar = styled.div`
  width: 100%;
  height: 0.4rem;
  background-color: ${colors.backgroundOutlined};
  > div {
    height: 100%;
    background: ${colors.bgBtnSecondary};
    border-radius: 10rem;
  }
`;
