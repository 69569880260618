export const toCSV = 'toCSV';
export const toPDF = 'toPDF';
export const toXSL = 'toXLS';

type FileType = typeof toCSV | typeof toPDF | typeof toXSL;

const configFile = {
  [toPDF]: 'data:application/pdf;base64',
  [toCSV]: 'data:text/csv;base64',
  [toXSL]:
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64',
};

/**
 * Expect a blob as input (generally from a request's response data)
 * This function will create an URL for the resource and automatically
 * access it to download the final file.
 */
export const downloadFile = async (
  fileBlob: any,
  fileName: string,
  isBase64: boolean = false,
  FileType: FileType = toPDF,
) => {
  let blob = fileBlob;
  if (isBase64) {
    blob = await fetch(`${configFile[FileType]},${fileBlob}`).then((result) =>
      result.blob(),
    );
  }
  let file_path = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = file_path;
  a.target = '_blank';
  a.download = fileName ?? 'Document';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const openPdfInNewTab = (fileBase64: string, mimeType: string) => {
  const bytes = new Uint8Array(
    atob(fileBase64)
      .split('')
      .map((char) => char.charCodeAt(0)),
  );

  const blob = new Blob([bytes], { type: mimeType });

  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl, '_blank');
};
