import { createBrowserHistory } from '@remix-run/router';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import CopiedLinkToast from 'src/components/CopiedLinkToast/CopiedLinkToast.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { PSmall } from 'src/style-utils/typographic';
import { FlowContainer, ResponsiveContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { writeToClipboard } from 'src/utils/functions/clipboard';
import CreateLinkInfo from '../CreateLinkInfo/CreateLinkInfo.component';
import { LinkUrlGroup } from './SendLinkNow.style';

function SendLinkNow() {
  const location = useLocation();
  const createLinkUrl = location?.state?.createLinkUrl;
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [copied, setCopied] = useState(false);
  const { setValue, templateSelected, orderId } = usePayByLinkStore(
    (state) => state,
  );
  const { AccountType } = useProfileStore((state) => state);
  const CanSaveTemplate = location?.state?.CanSaveTemplate;
  useEffect(() => {
    setValue('stateFlow', false);
  }, []);
  const history = createBrowserHistory();

  return (
    <>
      <ResponsiveContainer>
        <Button
          variant="LinkPrimary"
          padding="0"
          minWidth="fit-content"
          onClick={() => {
            history.go(-1);
          }}
          fontWeight="400"
          leftChild={<Icon iconSize="4rem" svgIconComponent={Back} />}
        >
          <Translate id="text.linkDetail" />
        </Button>
      </ResponsiveContainer>
      <FlowContainer>
        <TextDefaultPage
          title="lbl.payByLinkSendNow"
          subTitle="text.payByLinkSendNow"
        />
        <div className="content">
          <PSmall colorBlack>
            <Translate id="lbl.linkPayment" />
          </PSmall>
          <LinkUrlGroup>
            <div>{createLinkUrl}</div>
            <Button
              className="text-nowrap"
              onClick={() => {
                setCopied(true);
                setOpenToast(true);
                setTimeout(() => setOpenToast(false), 3000);
                writeToClipboard(createLinkUrl);
              }}
              variant="LinkPrimary"
              height="100%"
              i18n
              translatedText="link.copyLinkStrong"
              id={IDs.btnCopyLink}
            />
          </LinkUrlGroup>
          <ButtonContainer>
            <Button
              disabled={!copied}
              variant="Tertiary"
              minWidth="100%"
              onClick={() =>
                navigate(`${RoutePath.privateArea}?typeAccount=${AccountType}`)
              }
              id={IDs.btnClose}
            >
              <Translate id={'lbl.close'} />
            </Button>
          </ButtonContainer>
          {openToast && <CopiedLinkToast />}
          {CanSaveTemplate && !templateSelected && (
            <Toast
              variant="info"
              title="text.saveTemplateTitle"
              subTitle="text.saveTemplateSubtitle"
              buttonAction={() => {
                setValue('stateFlow', true);
                navigate(RoutePath.saveTemplate, { state: { orderId } });
              }}
              buttonText="lbl.saveTheTemplate"
            />
          )}
        </div>
      </FlowContainer>
      <CreateLinkInfo />
    </>
  );
}

export default SendLinkNow;
