import Icon from 'src/components/Icon/Icon.component';
import styled, { StyledProps } from 'styled-components';

interface ContainerMessageProps {
  isPresentMessage: boolean | undefined;
}

export const IconInsideInput = styled(Icon)`
  position: absolute;

  right: 0;
  margin-right: 10px;
  bottom: 1rem;
  display: none;
`;

export const MessageError = styled.span`
  color: inherit;
  display: none;
  font-size: inherit;
  font-weight: 300;
`;

export const ContainerMessage = styled.div<ContainerMessageProps>`
  display: block;
  font-size: 1rem;
  line-height: 1.1875;
  ${({ isPresentMessage }) =>
    isPresentMessage &&
    `
        & > ${MessageError} {
          display:block;
        }
      `}
  padding: 0.5rem 0 0;
`;
type EyeIconProps = {
  iconSrc: string;
};

export const EyeIcon = styled.div<StyledProps<EyeIconProps>>`
  background: ${({ iconSrc }) => `url("${iconSrc}") no-repeat center`};
  width: 2.6rem;
  background-size: contain;
  height: 2.4rem;
`;

type EyeIconWrapperProps = {
  hasErrorIcon: boolean;
};

export const EyeIconWrapper = styled.div<StyledProps<EyeIconWrapperProps>>`
  cursor: pointer;
  position: absolute;
  top: 0.1rem;
  padding: 0;
  right: ${({ hasErrorIcon }) => (hasErrorIcon ? '3.6rem' : '1rem')};
  display: flex;
  align-items: center;
  height: 100%;
`;
