import { PNote } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';
import media from 'src/style-utils/media-queries';
import { Button } from 'src/components/Button/Button.component';

interface BulletTextProps {
  innerColor: string;
  borderColor: string;
}

export const ContainerWrapper = styled.div`
  background: white;
  padding: 2.4rem;
  border-radius: 1.2rem;
`;

export const BulletText = styled(Flex)<BulletTextProps>`
  align-items: center;

  ::before {
    content: '';
    margin-right: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${(props) => props.innerColor || 'none'};
    border-radius: 50%;
    flex-shrink: 0;
    border: 1px solid ${(props) => props.borderColor || 'none'};
  }
`;

export const PNoteStyled = styled(PNote)`
  display: block;
  height: 1.25em;

  ::before {
    content: '';
    position: relative;
    display: inline-block;
    height: 100%;
    width: 0.8rem;
    translate: 5% -18% 0;
    border-color: currentColor;
    border-left: 1px solid;
    border-bottom: 1px solid;
  }
`;

export const BtnHelpContainer = styled(Flex)`
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const BtnHelp = styled(Button)`
  width: 327px;
  height: 54px;

  ${media.tablet`
  width: 280px;
  height: 64px;
  `};
`;
