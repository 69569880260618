import Icon from 'src/components/Icon/Icon.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import {
  clientList,
  endDate,
  skipField,
  startDate,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import {
  Amounts,
  statuses,
} from 'src/pages/payByLinkPage/partials/SearchCriteriaForm.config';
import { DataFormPayByLink } from 'src/pages/payByLinkPage/partials/SearchCriteriaForm.helpers';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { FilteredButtonContainer } from '../style/FilteredList.style';

interface Props {
  filteredName?: string;
  fromDate?: string;
  toDate?: string;
  buyerId?: string;
  state?: PayByLinkStatus;
  amountFrom?: number;
  amountTo?: number;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormPayByLink;
  disabled: boolean;
}

const FilteredButtonPayByLink = ({
  filteredName,
  fromDate,
  toDate,
  buyerId,
  state,
  handleClick,
  dataForm,
  amountFrom,
  amountTo,
  setDataFilter,
  disabled,
}: Props) => {
  const { resetField, watch, setValue } = useFormContext();

  const clientsArray = watch(clientList);
  const statusArray = watch(statuses) ?? [];
  const fieldName = buyerId
    ? clientList
    : amountTo !== undefined
      ? Amounts
      : statuses;
  const amountsArray: { From: number; To: number }[] =
    watch('Amounts') ?? watch('amounts');
  const { remove } = useFieldArray({
    name: fieldName,
  });
  const {
    formatters: { formatDate, formatCurrency },
  } = useI18n();
  const onClick = () => {
    if (fromDate || toDate) {
      resetField(startDate);
      resetField(endDate);
      dataForm.fromDate = '';
      dataForm.toDate = '';
    }

    if (buyerId) {
      remove(clientsArray.indexOf(buyerId));
      dataForm.buyers = dataForm.buyers.filter(
        (id) => id !== buyerId.toString(),
      );
    }

    if (state !== undefined) {
      remove(statusArray.indexOf(state.toString()));
      dataForm.statuses = dataForm.statuses.filter(
        (idType) => Number(idType).toString() !== state.toString(),
      );
    }

    if (amountTo !== undefined && amountFrom !== undefined) {
      remove(
        amountsArray.findIndex(
          (i) => i.From === amountFrom && i.To === amountTo,
        ),
      );
      dataForm.amounts = dataForm.amounts?.filter(
        (x) =>
          x.From.toString() !== amountFrom.toString() &&
          x.To.toString() !== amountTo.toString(),
      );
    }
    setValue(skipField, 0);
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <FilteredButtonContainer onClick={onClick} data-disabled={disabled}>
      {fromDate && fromDate.length > 0 && (
        <>
          <Translate id="text.startPeriod" />
          &nbsp;{formatDate(new Date(fromDate))}
        </>
      )}
      {toDate && toDate.length > 0 && (
        <>
          <Translate id="text.endPeriod" />
          &nbsp;{formatDate(new Date(toDate))}
        </>
      )}
      {buyerId && filteredName}
      {state !== undefined && <Translate id={filteredName} />}
      {amountTo &&
        ((amountTo ?? -1) < 999999 ? (
          <Translate
            id="lbl.fromXtoY"
            data={{
              x: formatCurrency(amountFrom ?? 0, { hideCents: true }),
              y: formatCurrency(amountTo ?? 0, { hideCents: true }),
            }}
          />
        ) : (
          <Translate
            id="lbl.moreThanX"
            data={{
              x: formatCurrency(amountFrom ?? 0, { hideCents: true }),
            }}
          />
        ))}
      <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
    </FilteredButtonContainer>
  );
};

export default FilteredButtonPayByLink;
