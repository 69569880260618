import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ClientEmailCard from 'src/components/ClientEmailCard/ClientEmailCard.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AddCollaborator } from 'src/images/pageStatus/add-collaborator.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  MaxWidthContainer,
  ResponsiveContainer,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';

function SuccessAddCollaborator() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;

  return (
    <ResponsiveContainer>
      <Flex margin="4.2rem auto" justify="center">
        <Icon
          iconSize="26.4rem"
          iconHeight="19.3rem"
          svgIconComponent={AddCollaborator}
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignContent="center"
        style={{ textAlign: 'center' }}
      >
        <MaxWidthContainer maxWidth="56rem">
          <TextDefaultPage
            title="text.successAddCollaboratorsTitle"
            subTitle="text.successAddCollaboratorsSubtitle"
          />
        </MaxWidthContainer>
      </Flex>
      <ClientEmailCard label="lbl.collaboratorEmail" email={email} />
      <ButtonContainer>
        <Button
          onClick={() => navigate(RoutePath.collaborators)}
          variant="Primary"
        >
          <Translate id="lbl.viewCollaborators" />
        </Button>
      </ButtonContainer>
    </ResponsiveContainer>
  );
}

export default SuccessAddCollaborator;
