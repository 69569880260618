import InstallmentCardCheckbox from 'src/components/InstallmentCardCheckbox/InstallmentCardCheckbox.component';
import { useState } from 'react';
import { Installment } from 'src/services/installments/installments.types';

type Props = {
  installments: Installment[];
  setCheckedHandler: React.Dispatch<React.SetStateAction<number>>;
  checkedId: number;
};

const InstallmentCheckboxList: React.FC<Props> = ({
  checkedId,
  installments,
  setCheckedHandler,
}) => {
  const [hoveredId, setHoveredId] = useState(-1);

  const mouseLeaveHandler = () => setHoveredId(-1);

  const onClickHandler = (index: number) => {
    setCheckedHandler((prev) => (prev >= index ? index - 1 : index));
  };

  return (
    <div
      className="grid w-full cursor-pointer"
      onMouseLeave={mouseLeaveHandler}
    >
      {installments.map((installment, index) => (
        <InstallmentCardCheckbox
          key={index}
          index={index}
          onMouseEnter={() => setHoveredId(index)}
          onClick={index > 0 ? () => onClickHandler(index) : undefined}
          NumRata={installment.INo}
          amount={installment.Amount}
          date={installment.Expiration}
          hoveredId={hoveredId}
          checkedId={checkedId}
        />
      ))}
    </div>
  );
};

export default InstallmentCheckboxList;
