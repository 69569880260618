import Icon from 'src/components/Icon/Icon.component';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const BackIcon = styled(Icon)`
  height: 2.4rem;
  width: 2.4rem;
  ${media.tablet`
    height: 4.8rem;
    width: 4.8rem;
  `}
`;
