import { useState } from 'react';
import { FilteredContainer } from './style/FilteredList.style';
import { Buyers } from 'src/services/linksFilterInitialData/linksFilterInitialData.types';
import Translate from 'src/components/Translate/Translate.component';
import { Button } from 'src/components/Button/Button.component';
import { useFormContext } from 'react-hook-form';
import { DataFormEligibleOrders } from '../../SearchCriteriaForm.helpers';
import FilteredButtonEligibleOrder from './partial/FilteredButtonEligibleOrder.component';

type ClientNamesType = { name: string; id: string };

type Props = {
  clients: Buyers[];
  dataForm: DataFormEligibleOrders;
  initialData: DataFormEligibleOrders;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
};

const FilteredListEligibleOrder = ({
  clients,
  dataForm,
  initialData,
  handleClick,
  setDataFilter,
}: Props) => {
  const { reset } = useFormContext();
  const clientNames: ClientNamesType[] = [];

  if (dataForm) {
    dataForm?.buyers?.forEach((buyerId: string) => {
      const client = clients.find(({ Id }) => Id.toString() === buyerId);
      clientNames.push({ name: client!.Name, id: client!.Id.toString() });
    });
  }

  //Carousel behaviour
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX;
    if (walk > 20 || walk < -20) {
      if (!isScrolling) setIsScrolling(true);
    }
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const handleLeave = () => {
    setIsDragging(false);
    setIsScrolling(false);
  };

  const resetFilter = () => {
    reset();
    handleClick(initialData);
  };

  if (clientNames.length === 0 && !(dataForm.fromDate || dataForm.toDate))
    return <></>;

  return (
    <FilteredContainer
      margin="0 -2.4rem"
      padding="2.4rem 2.4rem 0"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleLeave}
      onMouseLeave={handleLeave}
    >
      {(dataForm.fromDate || dataForm.toDate) && (
        <FilteredButtonEligibleOrder
          disabled={isScrolling}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          fromDate={dataForm.fromDate}
          toDate={dataForm.toDate}
        />
      )}
      {clientNames.map(({ name, id }) => (
        <FilteredButtonEligibleOrder
          disabled={isScrolling}
          dataForm={dataForm}
          setDataFilter={setDataFilter}
          handleClick={handleClick}
          key={id}
          buyerId={id}
          filteredName={name}
        />
      ))}
      <Button
        minWidthMobile="max-content"
        minWidth="max-content"
        variant="TextButton"
        height="3.6rem"
        onClick={resetFilter}
      >
        <Translate id="lbl.deleteAll" />
      </Button>
    </FilteredContainer>
  );
};

export default FilteredListEligibleOrder;
