import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const TriggerForm: React.FC = () => {
  const {
    trigger,
    formState: { isDirty },
  } = useFormContext();

  const values = useWatch();
  useEffect(() => {
    if (isDirty) {
      trigger();
    }
  }, [values]);
  return null;
};

export default TriggerForm;
