import { ButtonContainer } from 'src/components/Button/style/Button.style';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import { FormProvider, useForm } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { H4, H5, PSmall } from 'src/style-utils/typographic';
import {
  FormSaveTemplate,
  ResponsiveFunnelWrapper,
} from './SaveTemplate.style';
import InputField from 'src/components/InputField/InputField.component';
import { formConfigSaveTemplate } from './SaveTemplate.helpers';
import { addProductTemplateMethodApi } from 'src/services/template/template.request';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { addProductTemplateData } from 'src/services/template/template.types';
import { RoutePath } from 'src/routers/routers.config';
import Loader from 'src/components/Loader/Loader.component';
import styled from 'styled-components';
import media from 'src/style-utils/media-queries';
import { createBrowserHistory } from '@remix-run/router';
import { usePayByLinkStore } from 'src/store/store';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import StepperPercentage from 'src/components/Stepper/StepperPercentage.component';

export const templateNameField = 'template-name';

export const BackArrow = styled(Back)`
  cursor: pointer;
  height: 4rem;
  width: auto;
  ${media.tablet`
    height: 6rem;
    `}
`;

function SaveTemplate() {
  const { initialValues, resolver } = formConfigSaveTemplate();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const template = usePayByLinkStore((state) => state.template);
  const location = useLocation();
  const orderId: any = location?.state?.orderId;

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation(
    (values: addProductTemplateData) => addProductTemplateMethodApi(values),
    {
      onSuccess: (res) => {
        navigate(RoutePath.templateSaved);
        setValue('stateFlow', false);
      },
      onError: (error: any) => {
        navigate(RoutePath.oops);
      },
    },
  );

  const onSubmit = (data: any) => {
    const params: addProductTemplateData = {
      Name: data[templateNameField],
      OrderId: orderId,
    };
    mutate({ ...params });
  };
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <ResponsiveFunnelWrapper>
      <BackArrow onClick={() => history.go(-1)} />
      <H4 light textAlign="center" marginBottom="1.6rem">
        <Translate id="text.saveTemplateDescription" />
      </H4>

      <FormProvider {...methods}>
        <FormSaveTemplate onSubmit={methods.handleSubmit(onSubmit)}>
          <InputField
            name={templateNameField}
            maxLength={25}
            placeholder="lbl.templatePlaceholder"
          />
          <PSmall margin="-2rem 0 3rem" textAlign="left">
            <Translate id="lbl.maxChar" data={{ char: 25 }} />
          </PSmall>
          <H5>
            <Translate id="lbl.installmentDetail" />
          </H5>
          <StepperPercentage dataPercentageStepper={template?.Installments} />
          <ButtonContainer>
            <SubmitButton minWidth="100%">
              <Translate id="lbl.saveTemplate" />
            </SubmitButton>
          </ButtonContainer>
        </FormSaveTemplate>
      </FormProvider>
    </ResponsiveFunnelWrapper>
  );
}

export default SaveTemplate;
