import { useState } from 'react';
import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { Flex } from 'src/style/flexbox.style';
import { ReactComponent as Plus } from 'src/images/icons/circled_plus.svg';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import PayByLinkModalLodable from '../PayByLinkModal/PayByLinkModal.lodable';
import { ButtonContainer } from 'src/components/Button/style/Button.style';

const PayByLinkHeader = ({
  madeFromPlugin,
  title,
}: {
  madeFromPlugin: boolean;
  title: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state: any) => state.setValue);

  return (
    <>
      <Flex
        marginBottom="2.4rem"
        flexDirection="row"
        align="center"
        justify="space-between"
      >
        <TextDefaultPage
          title={title}
          modal={!madeFromPlugin}
          margin="0"
          onClickModal={() => setModalOpen(true)}
        />
        {!madeFromPlugin && (
          <ButtonContainer margin="0">
            <Button
              onClick={() => {
                setValue('stateFlow', true);
                navigate(`${RoutePath.createLink}${RoutePath.chooseProduct}`);
              }}
              sizeOnDesktop="medium"
              variant="Primary"
              rightChild={<Icon iconSize="2.1rem" svgIconComponent={Plus} />}
              type="button"
            >
              <Translate id="text.createLink" />
            </Button>
          </ButtonContainer>
        )}
      </Flex>
      {modalOpen && (
        <PayByLinkModalLodable
          buttonTitle="text.okUnderstand"
          modalTitle="Link di pagamento"
          handleClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default PayByLinkHeader;
