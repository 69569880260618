import Icon from 'src/components/Icon/Icon.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { t } from 'i18next';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { ReactComponent as Search } from 'src/images/icons/search.svg';
import { PopoverWrapper } from 'src/pages/monitoring/partials/popover/popover.style';
import { MouseEventHandler, PropsWithChildren, useState } from 'react';
import { Buyers } from 'src/services/monitoring/monitoring.types';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/shad/components/ui/popover';
import { PSmall } from 'src/style-utils/typographic';
import { Input } from 'src/style/styleInput.style';

type Props = {
  buyersList?: Buyers[];
  submitHandler?: MouseEventHandler | undefined;
  onChangeHandler?: any;
  label: string;
  countFilters?: number;
  onCloseHandler?: () => void;
};

const SelectPopover: React.FC<PropsWithChildren & Props> = ({
  children,
  onChangeHandler,
  submitHandler,
  label,
  countFilters,
  onCloseHandler,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <PopoverWrapper data-component="select-popover">
      <Popover modal open={open} onOpenChange={() => setOpen(!open)}>
        <PopoverTrigger asChild>
          <button
            className={
              'border-1 flex items-center justify-between gap-2 whitespace-nowrap rounded-full border px-3 py-2 hover:cursor-pointer hover:border-pblack-400 ' +
              (open ? 'border-pblack-400' : 'border-gray-400')
            }
          >
            <span className="flex items-center gap-1">
              <PSmall className="text-pblack-400">
                <Translate id={label} />
              </PSmall>
              {!!countFilters && countFilters > 0 && (
                <span className="flex h-4 w-4 items-center justify-center rounded-full bg-pblack-400 text-[10px] text-white">
                  {countFilters}
                </span>
              )}
            </span>
            <Icon
              svgIconComponent={ArrowDown}
              iconSize="2.4rem"
              className={open ? 'rotate-180' : 'rotate-0'}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent
          onInteractOutside={onCloseHandler}
          className="fixed bottom-0 left-0 lg:top-0"
        >
          <div className="pointer-events-none fixed inset-0 -z-50 bg-black/40 backdrop-blur-sm lg:hidden"></div>
          <div className="w-dvw rounded-t-2xl bg-white shadow-md outline outline-1 outline-gray-400 lg:w-[30.4rem] lg:rounded-2xl">
            {onChangeHandler && (
              <div className="relative border-b border-pgreyoutlined p-4">
                <Input
                  name="name"
                  placeholder={t('lbl.search')}
                  onChange={onChangeHandler}
                />
                <div className="flex-end pointer-events-none absolute inset-0 flex h-full w-full items-center p-4 text-pblack-400">
                  <Search className="ml-auto mr-2 block h-6 w-6" />
                </div>
              </div>
            )}
            <div className="grid max-h-[28rem] gap-4 overflow-auto p-2">
              {children}
            </div>
            <hr />
            <div className="px-3 py-4">
              <SubmitButton
                variant="Tertiary"
                minWidth="100%"
                onClick={(event) => {
                  setOpen(false);
                  submitHandler && submitHandler(event);
                }}
                i18n
                sizeOnDesktop="medium"
                translatedText="lbl.apply"
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </PopoverWrapper>
  );
};

export default SelectPopover;
