const radioboxTheme = {
  radiobox: {
    label: {
      marginTop: '0',
      fontSize: '1.4rem',
      fontFamily: 'Muli, Roboto, sans-serif',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    root: {
      margin: '',
      paddingLeft: '5rem',
      desktop: {
        margin: '',
        paddingLeft: '5.2rem',
      },
    },
  },
};

export default radioboxTheme;
