import Checkbox from 'src/components/Checkbox/Checkbox.component';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { performSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { useSession } from 'src/services/session/session.context';
import {
  useAccountStore,
  useProfileStore,
  useRegistrationStore,
} from 'src/store/store';
import colors from 'src/style-utils/colors';
import { Hr } from 'src/style-utils/hr';
import { P, PNote } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { FormStyle } from 'src/style/styleInput.style';
import { flagB, flagC, flagPrivacy } from './FormFlag.config';
import { formConfigRegistrationStepFlag } from './FormFlag.helpers';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { useLanguage } from 'src/services/i18n/i18n.context';
import {
  buyerGetInfo,
  merchantGetInfo,
  userEndPoint,
} from 'src/services/user/user.config';
import {
  buyerGetInfoMethodApi,
  merchantGetInfoMethodApi,
  porfileMethodApi,
} from 'src/services/user/user.request';
import {
  typeBuyerGuidKey,
  typeMerchantGuidKey,
} from 'src/services/session/session.config';

const FormFlag: React.FC<FormProps> = ({
  typeAccount,
  nextPath,
  step,
  stepMax,
  role,
}) => {
  const { initialValues, resolver } =
    formConfigRegistrationStepFlag(typeAccount);
  const navigate = useNavigate();
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const setValue = useRegistrationStore((state) => state.setValue);
  const setValueProfile = useProfileStore((state) => state.setValue);
  const setUser = useProfileStore((state) => state.setUser);
  const setAccount = useAccountStore((state) => state.setAccount);
  const { language } = useLanguage();
  const { onLogin } = useSession();

  const { mutate, isLoading, isSuccess } = useMutation(
    performSignUpSessionMethodApi,
    {
      onSuccess: (res) => {
        onLogin(res.data.ResultSet.AccessToken);
      },
      onError: () => {
        setValue('sessionGuid', '');
        navigate(RoutePath.oops);
      },
    },
  );

  const {
    isLoading: isLoadingInfo,
    isSuccess: isSuccessAccountInfo,
    data: dataAccountInfo,
  } = useQuery(userEndPoint, porfileMethodApi, {
    onSuccess: (res) => {
      setAccount(res?.data.ResultSet);
      if (res.data.ResultSet?.MerchantRoles?.length > 0) {
        window.localStorage.setItem(
          typeMerchantGuidKey,
          res.data.ResultSet.MerchantRoles[0].Guid,
        );
      } else if (res.data.ResultSet?.BuyerRoles?.length > 0) {
        window.localStorage.setItem(
          typeBuyerGuidKey,
          res.data.ResultSet.BuyerRoles[0].Guid,
        );
      }
    },
    enabled: isSuccess,
  });

  const { isLoading: isLoadingMerchantInfo } = useQuery(
    merchantGetInfo,
    merchantGetInfoMethodApi,
    {
      onSuccess: (res) => {
        setUser(res?.data.ResultSet);
        setValueProfile('AccountType', typeAccount);
        setValue('Role', dataAccountInfo?.data.ResultSet.MerchantRoles[0].Role);
        navigate(nextPath);
      },
      enabled: isSuccessAccountInfo && typeAccount === 'MERCHANT',
    },
  );
  const { isLoading: isLoadingBuyerInfo } = useQuery(
    buyerGetInfo,
    buyerGetInfoMethodApi,
    {
      onSuccess: (res) => {
        setValueProfile('AccountType', typeAccount);
        setValue('Role', dataAccountInfo?.data.ResultSet.BuyerRoles[0].Role);
        setUser(res?.data.ResultSet);
        navigate(nextPath);
      },
      enabled: isSuccessAccountInfo && typeAccount === 'BUYER',
    },
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    const performData = {
      FlagPrivacy: true,
      FlagB: data[flagB],
      FlagC: data[flagC],
      SessionGuid: sessionGuid,
      Language: language,
    };
    setValueProfile('AccountType', typeAccount);
    setValueProfile(
      'AccountStatus',
      typeAccount === 'MERCHANT' && role !== 'collaborator' ? 3 : 1,
    );
    mutate({ ...performData });
  };

  if (isLoading || isLoadingInfo || isLoadingMerchantInfo || isLoadingBuyerInfo)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return (
    <>
      <FlowHeader
        label="lbl.flagOpynPayLater"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              {typeAccount === 'MERCHANT' && (
                <>
                  <PNote lineHeight="1.6rem" textColor={colors.textColorGrey}>
                    <Translate id="lbl.obbligatoryStrong" />
                  </PNote>
                  <Flex
                    justify="space-between"
                    align="center"
                    flexWrap="nowrap"
                  >
                    <P marginRight="2rem" marginTop="0">
                      <Translate id={'text.marketingChooseGdpr'} />
                    </P>
                    <Checkbox viewMode="inline" name={flagPrivacy} />
                  </Flex>
                  <Hr />
                </>
              )}
              <Flex
                paddingBottom="2.4rem"
                justify="space-between"
                align="center"
                flexWrap="nowrap"
              >
                <P marginRight="2rem">
                  <Translate id={'text.marketingChooseDirect'} />
                </P>
                <Checkbox viewMode="inline" name={flagB} />
              </Flex>
              <Flex
                paddingBottom="2.4rem"
                justify="space-between"
                align="center"
                flexWrap="nowrap"
              >
                <P marginRight="2rem">
                  <Translate id={'text.marketingChooseCommercial'} />
                </P>
                <Checkbox viewMode="inline" name={flagC} />
              </Flex>
              <ButtonContainer>
                <SubmitButton minWidth="100%" disabled={isLoading}>
                  <Translate id="lbl.createAccount" />
                </SubmitButton>
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
};

export default FormFlag;
