import { create, StoreApi } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  AccountStoreState,
  PayByLinkStoreState,
  ProfileStoreState,
  RegistrationStoreState,
  StoreState,
  transactionActions,
  transactionState,
} from './store.types';
import {
  accountStoreName,
  currentTransactionName,
  languageStoreName,
  payByLinkStoreName,
  profileStoreName,
} from '../services/session/session.config';

//settare il type
let store = (
  set: StoreApi<StoreState>['setState'],
  get: StoreApi<StoreState>['getState'],
) => ({
  STORE_NAME: 'LANGUAGE_STORE',
  language: '',
  setLanguage: (language: string) =>
    set(() => ({
      language: language,
    })),
});

export let profileStore = (set: StoreApi<ProfileStoreState>['setState']) => ({
  STORE_NAME: 'PROFILE_STORE',
  currentOrder: null,
  transactions: null,
  transactionDetail: null,
  FiscalCode: '',
  PostalCode: '',
  VatCode: '',
  Name: '',
  Address: '',
  DataFirma: '',
  HasOrders: false,
  CanGiveCredit: false,
  CanReceiveSDD: false,
  CanPayWithSDD: false,
  HasPluginProduct: false,
  CanBeMerchant: true,
  OrderInvoiceRange: {
    DaysBefore: 0,
    DaysAfter: 0,
  },
  Guid: '',
  Id: 0,
  AccountType: '',
  Status: 0,
  stripeLink: '',
  stripeLoginLink: '',
  Role: '',
  Country: '',
  setUser: (params: ProfileStoreState) => set(() => ({ ...params })),
  setTransactions: (params: any[]) =>
    set(() => ({
      transactions: Object.fromEntries(
        params.map((obj) => [obj.IdTransazione, { ...obj }]),
      ),
    })),
  //TODO
  setTransactionDetail: (params: ProfileStoreState) =>
    set(() => ({ transactionDetail: params })),
  setCurrentOrder: (params: ProfileStoreState) =>
    set(() => ({ currentOrder: { ...params } })),
  setValue: (key: string, value: string | number | boolean) =>
    set(() => ({
      [key]: value,
    })),
  setValues: (params: ProfileStoreState) => set(() => ({ ...params })),
});

let accountStore = (set: StoreApi<AccountStoreState>['setState']) => ({
  STORE_NAME: 'ACCOUNT_STORE',
  Guid: '',
  Email: '',
  Phone: '',
  PhonePrefix: '',
  FlagPrivacyPolicy: false,
  FlagB: false,
  FlagC: false,
  Language: '',
  Status: '',
  setAccount: (params: AccountStoreState) => set(() => ({ ...params })),
  setValue: (key: string, value: string | number | boolean) =>
    set(() => ({
      [key]: value,
    })),
  setValues: (params: AccountStoreState) => set(() => ({ ...params })),
});

let registrationStore = (set: StoreApi<RegistrationStoreState>['setState']) =>
  ({
    STORE_NAME: 'REGISTRATION_STORE',
    guid: '',
    idUtente: 0,
    tipoAccount: '',
    statoAccount: 0,
    email: '',
    codiceFiscale: '',
    mobile: '',
    prefix: '',
    flagCessioneCreditoMerchant: true,
    hasSpid: false,
    representativeIsWrong: false,
    otherWrongData: false,
    name: '',
    vatCode: '',
    postalCode: '',
    companySearchId: undefined,
    address: '',
    sessionGuid: '',
    longRegistration: false,
    country: 'IT',
    results: [],
    guestSignInGuid: '',
    setValue: (key: string, value: string | number) =>
      set(() => ({
        [key]: value,
      })),
    setValues: (params: RegistrationStoreState) => set(() => ({ ...params })),
  }) as RegistrationStoreState;

const payByLinkStore = (set: StoreApi<PayByLinkStoreState>['setState']) =>
  ({
    STORE_NAME: 'PAY_BY_LINK_STORE',
    CAP: 0,
    guid: '',
    buyerFiscalCode: '',
    buyerName: '',
    amount: 0,
    description: '',
    inviteEmail: '',
    orderId: 0,
    qrCode: '',
    buyerAddress: '',
    stateFlow: false,
    customInstallments: undefined,
    variantCode: '',
    variants: undefined,
    qrFlow: false,
    flagCredit: false,
    invoiceDate: '',
    invoiceFile: undefined,
    firstInstallmentDate: '',
    isDeferred: false,
    amountGivable: 0,
    remodulation: false,
    installmentsPaid: undefined,
    uwId: undefined,
    templates: [],
    templateSelected: false,
    template: undefined,
    canPayWithSDD: false,
    VatCode: '',
    country: 'IT',
    OrderInvoiceRange: {
      DaysBefore: 0,
      DaysAfter: 0,
    },
    postalCode: '',
    companySearchId: undefined,
    sessionGuid: '',
    results: [],
    setValue: (key: string, value: string | number) =>
      set(() => ({
        [key]: value,
      })),
    setValues: (params: PayByLinkStoreState) => set(() => ({ ...params })),
  }) as PayByLinkStoreState;

const currentTransactionStore: transactionState = {
  STORE_NAME: 'CURRENT_TRANSACTION_STORE',
  orderGuid: '',
  transactionsGuid: [],
  amount: 0,
  paymentMethodId: 0,
  showPaymentSetupPage: false,
  canPayWithSDD: false,
  orderPayment: false,
  buyerRegistrationStatus: undefined,
};

export const useStores = create<StoreState>()(
  devtools(persist(store, { name: languageStoreName })),
);
export const useProfileStore = create<ProfileStoreState>()(
  devtools(persist(profileStore, { name: profileStoreName })),
);
export const useAccountStore = create<AccountStoreState>()(
  devtools(persist(accountStore, { name: accountStoreName })),
);
export const useRegistrationStore = create<RegistrationStoreState>()(
  devtools(persist(registrationStore, { name: 'registration-store' })),
);
export const usePayByLinkStore = create<PayByLinkStoreState>()(
  devtools(persist(payByLinkStore, { name: payByLinkStoreName })),
);

export const useCurrentTransactionStore = create<
  transactionState & transactionActions
>()(
  devtools(
    persist(
      (set) => ({
        ...currentTransactionStore,
        setTransaction: (key: keyof transactionState, value: any) =>
          set((state) => ({
            ...state,
            [key]: value,
          })),
        setTransactions: (updatedValues) =>
          set((state) => ({
            ...state,
            ...updatedValues,
          })),
      }),
      { name: currentTransactionName },
    ),
  ),
);
