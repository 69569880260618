import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import InternalErrorIcon from 'src/images/pageStatus/internal_error.svg?url';
import {
  LandingPageContainer,
  LandingPageIcon,
} from 'src/pages/landingPage/LandingPage.style';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';

const InternalError: React.FC = () => {
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state: any) => state.setValue);
  const [searchParams] = useSearchParams();
  const evaluationBuyer = searchParams.get('EvaluateBuyer');
  const orderId = searchParams.get('OrderId');

  return (
    <LandingPageContainer
      flex="1"
      flexDirection="column"
      padding="4.8rem 2.4rem"
      gap="1.6rem"
    >
      <LandingPageIcon src={InternalErrorIcon} />
      <H4>
        <Translate id="lbl.internalError" />
      </H4>
      <P>
        <Translate id="text.internalError" />
      </P>
      <ButtonContainer>
        {evaluationBuyer ? (
          <Button
            onClick={() =>
              navigate(
                `${RoutePath.linkDiPagamentoDetail}${orderId}/${RoutePath.info}`,
              )
            }
            height="5.4rem"
            type="submit"
            minWidth="100%"
          >
            <Translate id="lbl.backOrderDetail" />
          </Button>
        ) : (
          <Button
            variant="Primary"
            onClick={() => {
              setValue('stateFlow', true);
              navigate(`${RoutePath.createLink}${RoutePath.chooseProduct}`, {
                replace: true,
              });
            }}
          >
            <Translate id="lbl.createNewPayByLink" />
          </Button>
        )}
        <NeedHelpButton />
      </ButtonContainer>
    </LandingPageContainer>
  );
};

export default InternalError;
