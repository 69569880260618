import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { RecoveryDataContext } from './RecoveryData.types';

function RecoveryData() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const sessionGuid: string = searchParams.get('sessionGuid')!;
  const type = location?.state?.type;
  const activeOrder = location?.state?.activeOrder;
  const recoveryDataContext: RecoveryDataContext = {
    type,
    sessionGuid,
    activeOrder,
  };
  return <Outlet context={recoveryDataContext} />;
}

export default RecoveryData;
