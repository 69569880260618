import styled, { StyledProps } from 'styled-components';
import { withOneLineEllipsis } from 'src/style-utils/overflow';
import greenIconCheckSrc from 'src/images/icons/green_check_circle.svg?url';
import redIconSrc from 'src/images/icons/negative feedback_point_red.svg?url';
import greyCirclekSrc from 'src/images/icons/feedback-point-empty.svg?url';

import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';

type EyeIconWrapperProps = {
  hasErrorIcon: boolean;
  iconColor: string;
};

export const EyeIconWrapper = styled.div<StyledProps<EyeIconWrapperProps>>`
  cursor: pointer;
  position: absolute;
  top: 0.1rem;
  padding: 0;
  right: ${({ hasErrorIcon }) => (hasErrorIcon ? '3.6rem' : '1rem')};
  color: ${(bleah) => bleah.iconColor};
  display: flex;
  align-items: center;
  height: 100%;
`;

export const PasswordStatusDisplay = styled.p`
  font-size: 1.2rem;
  text-align: left;
  ${withOneLineEllipsis};
  color: ${colors.redInput};
  line-height: 1.2;
  margin: 0 0 1.2rem;
  &:before {
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    float: left;
    margin: 0 0.5rem 0 0;
    background: url('${redIconSrc}') no-repeat center;
    background-size: contain;
  }

  &[data-valid='true'] {
    color: ${colors.greenInput};
    &:before {
      background: url('${greenIconCheckSrc}') no-repeat center;
      background-size: contain;
    }
  }

  ${media.tablet`
    width:unset;
  `}

  &[data-touched='false'] {
    color: ${colors.textColorGrey};
    &:before {
      background: url('${greyCirclekSrc}') no-repeat center;
      background-size: contain;
    }
  }
`;
