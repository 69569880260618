import { Resolver } from 'react-hook-form';
import { startDateField } from 'src/services/orderLink/orderLink.config';
import { validateStartDate } from 'src/utils/validation/date';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigStartDatePayByLink = (firstInstallmentDate: string) => {
  const initialValues = {
    [startDateField]: firstInstallmentDate,
  };

  const validate = validateFormSync({
    [startDateField]: validateFieldSync(required, validateStartDate()),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
