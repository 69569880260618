import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import { skipField } from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { useFormContext } from 'react-hook-form';
import Icon from 'src/components/Icon/Icon.component';
import { link, plugin } from 'src/pages/monitoring/Monitoring.config';
import { DataFormMonitoringBuyerStats } from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.helpers';
import { FilteredButtonContainer } from '../style/FilteredList.style';

interface FilteredButtonBooelanProps {
  label: string;
  fieldName: typeof link | typeof plugin;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormMonitoringBuyerStats;
  disabled: boolean;
}

const FilteredButtonBooleanMonitoring = ({
  label,
  fieldName,
  handleClick,
  dataForm,
  setDataFilter,
  disabled,
}: FilteredButtonBooelanProps) => {
  const { setValue } = useFormContext();

  const onClickHandler = () => {
    setValue(fieldName, false);
    dataForm[fieldName] = false;
    setValue(skipField, 0);
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <FilteredButtonContainer onClick={onClickHandler} data-disabled={disabled}>
      <Translate id={label} />
      <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
    </FilteredButtonContainer>
  );
};

export default FilteredButtonBooleanMonitoring;
