import { ReactComponent as CheckMark } from 'src/images/icons/feedback-point-checked.svg';
import { ReactComponent as Alert } from 'src/images/icons/alert_currentColor.svg';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import colors from 'src/style-utils/colors';

export const StepperRemodulationArray = {
  [InstallmentStatus.paid]: {
    label: 'text.pay',
    icon: CheckMark,
    iconColor: colors.greenStatus,
    outlineColor: colors.bgBtnHoverPrimary,
  },
  [InstallmentStatus.toPay]: {
    label: 'lbl.toPay',
    icon: undefined,
    iconColor: '',
    outlineColor: colors.primaryGreen,
  },
  [InstallmentStatus.expired]: {
    label: 'text.expired',
    icon: Alert,
    iconColor: colors.redStatus,
    outlineColor: colors.redStatus,
  },
  [InstallmentStatus.cancelled]: {
    label: '',
    icon: undefined,
    iconColor: '',
    outlineColor: '',
  },
  [InstallmentStatus.processing]: {
    label: 'lbl.processing',
    icon: undefined,
    iconColor: '',
    outlineColor: colors.primaryGreen,
  },
};
