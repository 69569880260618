import React from 'react';
import { FreezeScreen } from './style/DisableWindowScroll.style';

const DisableWindowScroll = React.memo(function DisableWindowScroll() {
  const scrollPositionRef = React.useRef<Record<string, number> | null>(null);

  if (!scrollPositionRef.current) {
    scrollPositionRef.current = {
      scrollXPositionBeforeRender: window.scrollX ?? window.pageXOffset,
      scrollYPositionBeforeRender: window.scrollY ?? window.pageYOffset,
    };
  }

  // This complicated method of blocking the body scroll is required due to the
  // way `Safari` mobile handles scroll.
  // We invoke `useLayoutEffect` instead of useEffect`,
  // to avoid a lousy transition (the page appearing to change the
  // `scrollY` twice).
  // https://it.reactjs.org/docs/hooks-reference.html#uselayouteffect
  React.useLayoutEffect(() => {
    if (!scrollPositionRef.current) {
      return;
    }

    const {
      current: { scrollXPositionBeforeRender, scrollYPositionBeforeRender },
    } = scrollPositionRef;

    document.body.style.top = `-${scrollYPositionBeforeRender}px`;
    document.body.style.left = `-${scrollXPositionBeforeRender}px`;

    return () => {
      document.body.style.left = '';
      document.body.style.top = '';

      window.requestAnimationFrame(() => {
        window.scrollTo(
          scrollXPositionBeforeRender,
          scrollYPositionBeforeRender,
        );
      });
    };
  }, []);

  return <FreezeScreen data-component="disable-window-scroll" />;
});

export default DisableWindowScroll;
