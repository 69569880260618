import { SyncFieldValidator, TransformError } from './types';

export const errorMessageMinPrefix = 'error.minLength';
export const errorMessageMaxPrefix = 'error.maxLength';

export const telephoneMinErrorMessage = 'error.telephone.min';
export const telephoneMaxErrorMessage = 'error.telephone.max';

const createLength = function createLength({
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  phone = false,
} = {}): SyncFieldValidator {
  return function validateLength(value: unknown) {
    if (value && typeof (value as { length?: number }).length !== 'number') {
      return undefined;
    }

    const length = (value as { length: number }).length;

    if (length < min) {
      return `${
        phone ? telephoneMinErrorMessage : errorMessageMinPrefix
      }.${min}`;
    }

    if (length > max) {
      return `${
        phone ? telephoneMaxErrorMessage : errorMessageMaxPrefix
      }.${max}`;
    }
    return undefined;
  };
};

export const transformLengthError: TransformError =
  function transformNumberRangeError(error) {
    const output = { id: error } as ReturnType<TransformError>;

    if (
      error.startsWith(errorMessageMinPrefix) ||
      error.startsWith(errorMessageMaxPrefix) ||
      error.startsWith(telephoneMinErrorMessage) ||
      error.startsWith(telephoneMaxErrorMessage)
    ) {
      let offset = error.lastIndexOf('.');

      output.id = error.slice(0, offset);
      output.data = { value: error.slice(offset + 1) };
    }

    return output;
  };

export default createLength;
