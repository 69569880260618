import BadgeStatus from 'src/components/BadgeStatus/BadgeStatus.component';
import Translate from 'src/components/Translate/Translate.component';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { Grid, ResponsiveContainer } from 'src/style/Container.style';
import {
  CollaboratorStatus,
  CollaboratorStatusMap,
} from './Collaborator.config';
import { Flex } from 'src/style/flexbox.style';
import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Edit } from 'src/images/icons/edit.svg';
import { BackIcon } from './collaborator.style';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { RoutePath } from 'src/routers/routers.config';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { updateRoleMethodApi } from 'src/services/collaborator/collaborator.request';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import CollaboratorModalComponent from './partials/modal/CollaboratorModal.component';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigCollaboratorDescription } from './partials/modal/CollaboratorModal.helpers';
import { FormStyle } from 'src/style/styleInput.style';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import Toast from 'src/components/Toast/Toast.component';
import Loader from 'src/components/Loader/Loader.component';
import { useProfileStore } from 'src/store/store';
import { RoleEnum } from 'src/utils/types/common.types';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';

function Collaborator() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const role = useProfileStore((state) => state.Role);
  const location = useLocation();
  const detail = location?.state?.detail;
  const showToast = location?.state?.showToast;
  const navigate = useNavigate();
  const { initialValues, resolver } = formConfigCollaboratorDescription(
    detail?.Description,
  );
  const { mutate, isLoading } = useMutation(updateRoleMethodApi, {
    onSuccess: (res, variables) => {
      setShowModal(false);
      setShowPopUp(false);
      navigate(RoutePath.collaborator, {
        state: {
          detail: res.data.ResultSet,
          showToast: variables.Edit,
        },
      });
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });
  const { isActive, popupTitle, popupSubTitle, buttonLabel } =
    CollaboratorStatusMap[detail.RoleStatus as CollaboratorStatus];
  const onSubmitDescription = (data: any) => {
    mutate({
      RoleId: detail.RoleId,
      Description: data[descriptionField],
      IsActive: isActive,
      Edit: true,
    });
  };
  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  if (!detail || role !== RoleEnum.owner)
    return <Navigate to={RoutePath.collaborators} />;
  return (
    <>
      {showToast && (
        <Toast
          variant="success"
          title="text.successChangeCollaboratorDescription"
          timeout={5000}
        />
      )}

      <ResponsiveContainer>
        <Button
          variant="LinkPrimary"
          padding="0"
          fontWeight="400"
          margin="0 0 2.4rem"
          onClick={() => navigate(RoutePath.collaborators)}
          leftChild={<BackIcon svgIconComponent={Back} />}
        >
          <P colorBlack>
            <Translate id="menu.managementCollaborators" />
          </P>
        </Button>
        <Grid gap="4rem" gapOnDesktop="5.6rem">
          <Flex flexDirection="column" gap="2.4rem">
            <div>
              <H5>
                <Translate id="menu.profile" />
              </H5>
              <hr />
            </div>
            <div>
              <PSmall marginBottom="0.4rem">
                <Translate id="lbl.linkStatus" />
              </PSmall>
              <BadgeStatus
                {...CollaboratorStatusMap[
                  detail.RoleStatus as CollaboratorStatus
                ]}
              />
            </div>
            <Flex align="center" justify="space-between">
              <div>
                <PSmall>
                  <Translate id="text.collaboratorDescription" />
                </PSmall>
                <P bold colorBlack>
                  <Translate id={detail.Description} />
                </P>
              </div>
              <Button
                className="float-r"
                variant="TextButton"
                fontWeight="500"
                i18n
                disabled={detail.RoleStatus !== 'active'}
                translatedText="lbl.change"
                leftChild={
                  <Icon
                    iconSize="2rem"
                    iconHeight="2rem"
                    svgIconComponent={Edit}
                  />
                }
                onClick={() => setShowModal(true)}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="2.4rem">
            <div>
              <H5>
                <Translate id="lbl.accountDetails" />
              </H5>
              <hr />
            </div>
            <div>
              <PSmall>
                <Translate id="lbl.payByLinkEmailAddress" />
              </PSmall>
              <P bold colorBlack>
                <Translate id={detail.Email} />
              </P>
            </div>
          </Flex>
          <Button
            variant="Tertiary"
            margin="2.4rem auto 3.6rem"
            sizeOnDesktop="medium"
            sizeOnMobile="medium"
            minWidthMobile="100%"
            i18n
            disabled={detail.RoleStatus === 'waiting'}
            display="flex"
            translatedText={buttonLabel}
            onClick={() => setShowPopUp(true)}
          />
        </Grid>
      </ResponsiveContainer>
      <FormProvider {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmitDescription)}>
          {showModal && (
            <CollaboratorModalComponent
              buttonTitle="text.confirm"
              modalTitle="lbl.changeCollaboratorDescription"
              applyForm={onSubmitDescription}
              i18n
              handleClose={() => setShowModal(false)}
            />
          )}
        </FormStyle>
      </FormProvider>
      {showPopUp && (
        <DoubleChoicePopUp
          handleSecondAction={() =>
            mutate({
              RoleId: detail.RoleId,
              Description: detail.Description,
              IsActive: !isActive,
              Edit: false,
            })
          }
          modalTitle={popupTitle}
          handleClose={() => setShowPopUp(false)}
          firstButtonLabel="lbl.confirm"
          lastButtonLabel="lbl.cancel"
          mainText={popupSubTitle}
          icon={AlertIcon}
        />
      )}
    </>
  );
}

export default Collaborator;
