import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe, Stripe } from '@stripe/stripe-js';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Translate from 'src/components/Translate/Translate.component';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { H4, H5 } from 'src/style-utils/typographic';
import CheckoutForm from './partials/CheckoutForm.component';
import { STRIPE_PROMISE } from 'src/settings';
import Icon from '../../../../components/Icon/Icon.component';
import { ErrorPagePar } from '../../../failedPage/style/failedPage.style';
import { ButtonContainer } from '../../../../components/Button/style/Button.style';
import { Button } from '../../../../components/Button/Button.component';
import NeedHelpButton from '../../../../components/NeedHelpButton/NeedHelpButton.component';
import { ReactComponent as StripeError } from 'src/images/pageStatus/stripe-error.svg';

const AddMethodStripePage: React.FC = () => {
  const location = useLocation();
  const clientSecretStripe = location.state?.clientSecretStripe;
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [error, setError] = useState(false);
  const { language } = useLanguage();

  const options: StripeElementsOptions = {
    clientSecret: clientSecretStripe,
    locale: language,
  };

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeInstance = await loadStripe(`${STRIPE_PROMISE}`);
        setStripe(stripeInstance);
      } catch (error) {
        setError(true);
      }
    };
    initializeStripe();
  }, []);

  return (
    <div className="w-full p-6 lg:px-12 lg:py-9">
      <GoBackButton noLabel />
      {!error && (
        <H5 marginBottom="2.4rem">
          <Translate id="text.addData" />
        </H5>
      )}
      {error && (
        <>
          <Icon
            svgIconComponent={StripeError}
            iconHeight="auto"
            iconSize="min(100%, 25.6rem)"
            margin="4.2rem auto"
          />
          <H4 textAlign="center">
            <Translate id={'stripe.notLoading.title'} />
          </H4>
          <ErrorPagePar>
            <Translate id={'stripe.notLoading.text'} />
          </ErrorPagePar>
          <ButtonContainer>
            <Button
              minWidth="32.7rem"
              i18n
              onClick={() => window.location.reload()}
            >
              <Translate id={'reload.page'} />
            </Button>
            <NeedHelpButton />
          </ButtonContainer>
        </>
      )}
      {stripe && (
        <Elements stripe={stripe} options={options} data-cookieconsent="ignore">
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default AddMethodStripePage;
