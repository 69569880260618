import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import { Outlet } from 'react-router';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { ContainerPadding } from 'src/style/Container.style';
import { ChangeModes, DataChangeContext } from './ChangeAccessData.types';

function ChangeAccessData() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const sessionGuid: string = searchParams.get('sessionGuid')!;
  const type: ChangeModes = location?.state?.type;
  const changeContext: DataChangeContext = {
    type,
    sessionGuid,
  };

  if (!sessionGuid) {
    <Navigate to={RoutePath.access} />;
  }
  return (
    <ContainerPadding>
      <GoBackButton label="lbl.accessData" url={RoutePath.access} />
      <Outlet context={changeContext} />
    </ContainerPadding>
  );
}
export default ChangeAccessData;
