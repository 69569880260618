import React from 'react';

import { v4 as uuid } from 'uuid';

let idCount = 0;

type ComponentIdType = 'counter' | 'uuid' | 'hexTime';

/**
 * Returns the unique id of the component output.
 * It has the same value throughout a component lifecycle.
 * @param counterType The type used to generate the id.
 */
export default function useGetComponentRenderUniqueId(
  counterType?: ComponentIdType,
): string {
  const idRef = React.useRef<string | null>(null);

  if (typeof idRef.current !== 'string') {
    const type: ComponentIdType = counterType || 'hexTime';

    switch (type) {
      case 'hexTime':
        idRef.current = Date.now().toString(16).toLowerCase();
        break;
      case 'uuid':
        idRef.current = uuid();
        break;
      case 'counter':
        idRef.current = 'comp-id-' + ++idCount;
    }
  }

  return idRef.current;
}
