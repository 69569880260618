import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import { FlowContainer, Grid } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';

function StepCollectionMethod() {
  const { productCode, categoryCode } = useParams();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);

  const navigateToResume = () => {
    setValue('flagCredit', true);
    navigate(
      `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCreditResume}`,
    );
  };

  const onSubmit = async () => {
    setValue('flagCredit', false);
    navigate(
      `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
    );
  };
  return (
    <>
      <PayByLinkHeader
        stepBack
        step={10}
        stepMax={12}
        title="lbl.colletionMethod"
      />
      <FlowContainer>
        <div className="content">
          <Grid gap="1.6rem">
            <PayByLinkChoiceCard
              title="lbl.continueCreating"
              subTitle="text.continueCreating"
              onClick={onSubmit}
              btnId={IDs.btnProceedFlx}
            />
            <PayByLinkChoiceCard
              light
              title="lbl.getFlagCredit"
              subTitle="text.getFlagCredit"
              onClick={navigateToResume}
              btnId={IDs.btnProceedAdv}
            />
          </Grid>
        </div>
      </FlowContainer>
    </>
  );
}

export default StepCollectionMethod;
