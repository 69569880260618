import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import styled from 'styled-components';
import { Button } from 'src/components/Button/Button.component';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  background: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(14px);
  /* Note: backdrop-filter has minimal browser support */
  box-shadow: 0 0.4rem 1.6rem 0.4rem ${colors.cardShadowBlue};
  border-radius: 3.2rem;
  padding: 3.2rem;
  text-align: center;
  ${media.tablet`
    text-align: start;
    padding: 6.4rem;
  `}
`;

export const CustomCTAButton = styled(Button)`
  svg {
    display: none;
  }
  ${media.tablet`
    min-width: auto;
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    background: unset!important;
    color: ${colors.primaryGreen};
    font-size: 6.4rem;
    height: 9rem;
    &:disabled{
      color: ${colors.bgBtnDisabledPrimary};
    }
    &:hover{
      color: ${colors.bgBtnHoverPrimary};
    }
    & svg {
      display: unset;
    }
  `};
`;
