import colors from 'src/style-utils/colors';
import styled from 'styled-components';

export const InputDisabledContainer = styled.div`
  position: relative;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 2rem;
`;

export const PastedLabel = styled.span`
  height: 2.5rem;
  width: 5.9rem;
  border-radius: 0.4rem;
  background-color: ${colors.bgCheckboxChecked};
  position: absolute;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  right: -2.3rem;
  top: -3.5rem;
  opacity: 0;
  transition: 0.4s all;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.bgCheckboxChecked};
    position: absolute;
    left: 2rem;
    top: 2.5rem;
  }
  &.show {
    opacity: 1;
  }
`;
