import React, { MouseEvent } from 'react';
import { Button } from 'src/components/Button/Button.component';
import { ButtonProps } from 'src/components/Button/Button.types';
import { useFormState } from 'react-hook-form';

export type SubmitButtonProps = ButtonProps & {
  enableDisabledOnPristine?: boolean;
};

const SubmitButton: React.FC<SubmitButtonProps> = React.memo(
  function SubmitButton({
    onClick,
    disabled: disabledFromProps,
    enableDisabledOnPristine = false,
    ...otherProps
  }) {
    const { isSubmitting, isDirty, isValid } = useFormState();
    // const pristine = !isDirty;
    // const values = getValues();
    // const amountOfErrors = Object.keys(errors).length;
    // const debouncedValidation = React.useMemo(
    //   () => debounce(trigger, 400, { leading: true }),
    //   [trigger],
    // );
    // Triggers debounced validation on values change.
    // React.useEffect(() => {
    //   debouncedValidation();
    // }, [debouncedValidation, values]);

    // Prevents memory leaks:
    // we should not trigger the validation if the component gets unmounted.
    // React.useEffect(
    //   () => () => debouncedValidation.cancel(),
    //   [debouncedValidation],
    // );

    // We want to allow submission if the user has a 'formErrorKey' error
    // because it might be due to a network error or a random server hiccup.
    // const isErrorEmpty =
    //   amountOfErrors === 0 ||
    //   (amountOfErrors === 1 && typeof errors[formErrorKey] === 'string');

    const disabledIfPristine = enableDisabledOnPristine ? !isDirty : false;

    // Use 'props.disabled' to override hook form logic.
    const disabled =
      disabledFromProps || isSubmitting || !isValid || disabledIfPristine;

    const handleClick = React.useCallback(
      (domEvent: MouseEvent) => {
        if (isSubmitting || disabled) {
          domEvent.preventDefault();
        }

        // In ButtonBase we have set  '&[disabled] { pointer-events: none; }'
        // but just to be more resilient we add a check here.
        if (!disabled && typeof onClick === 'function') {
          onClick(domEvent);
        }
      },
      [disabled, isSubmitting, onClick],
    );

    return (
      <Button
        {...otherProps}
        onClick={handleClick}
        disabled={disabled}
        type="submit"
      />
    );
  },
);

export default SubmitButton;
