import Loader from 'src/components/Loader/Loader.component';
import NativeSelectLanguage from 'src/components/NativeSelect/NativeSelectLanguage.component';
import SelectField from 'src/components/SelectField/SelectField.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { SupportedLanguageKey } from 'src/services/i18n/i18n.types';
import { optionsLanguage } from './SelectLanguage.config';

function SelectLanguage() {
  const { language, setLanguage, isLoadingUpLang, isLoadingLang } =
    useLanguage();

  const changeLanguage = (data: any) => {
    const lng: SupportedLanguageKey = data['lang'];
    setLanguage(lng);
  };

  const methods = useForm({
    defaultValues: {
      lang: language,
    },
    mode: 'onChange',
  });

  if (isLoadingLang || isLoadingUpLang) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <FormProvider {...methods}>
      <form onChange={methods.handleSubmit(changeLanguage)}>
        <SelectField
          id="1"
          name="lang"
          i18n={false}
          enableModal={true}
          options={optionsLanguage}
          component={NativeSelectLanguage}
        />
      </form>
    </FormProvider>
  );
}

export default SelectLanguage;
