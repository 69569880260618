import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ViewFile } from 'src/images/icons/view_file.svg';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ContractCounter, CustomCard } from './ContractCard.style';
import moment from 'moment';

export interface IContractCard {
  name: string;
}

interface Props extends IContractCard {
  number: number;
  viewButtonClick?: () => void;
}

const ContractCard = ({ name, number, viewButtonClick }: Props) => {
  return (
    <CustomCard>
      <Flex padding="1.6rem 1.2rem" gap="1.2rem" align="center">
        <ContractCounter>{number}</ContractCounter>
        <div>
          <P bold colorBlack>
            {name}
          </P>

          <PSmall>
            <Translate id={'lbl.linkCreationDate'} />
            :&nbsp;
            {moment().format('DD MMMM yyyy')}
          </PSmall>
        </div>
      </Flex>
      <div className="cardFooter">
        <Button
          variant="LinkPrimary"
          onClick={viewButtonClick}
          margin="0 0 0 auto"
          height="0"
          minWidth="0"
          i18n
          translatedText="lbl.view"
          rightChild={<Icon iconSize={'2rem'} svgIconComponent={ViewFile} />}
        />
      </div>
    </CustomCard>
  );
};

export default ContractCard;
