import BadgeStatus from 'src/components/BadgeStatus/BadgeStatus.component';
import {
  AdvPlanRequestsStatus,
  AdvPlanRequestsStatusMap,
} from 'src/pages/advancePlanRequests/AdvancePlanRequests.config';
import { ListTableAdvPlanRequests } from 'src/pages/advancePlanRequests/AdvancePlanRequests.types';
import React from 'react';

const StatusColumn: React.FC<ListTableAdvPlanRequests> = ({ Status }) => {
  return (
    <BadgeStatus
      {...AdvPlanRequestsStatusMap[Number(Status) as AdvPlanRequestsStatus]}
    />
  );
};

export default StatusColumn;
