import { useMediaQuery } from 'react-responsive';
import { desktop, tablet } from 'src/style-utils/breakpoints';
/**
 * Returns 'true' if the viewport is at least as big as the breakpoint
 * we label 'tablet'.
 */
export function useIsAtLeastTabletBreakpoint() {
  return useMediaQuery({ minWidth: tablet });
}

/**
 * Returns 'true' if the viewport is at least as big as the breakpoint
 * we label 'desktop'.
 */
export function useIsAtLeastDesktopBreakpoint() {
  return useMediaQuery({ minWidth: desktop });
}
