import { InstallmentStatusMap } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Translate from 'src/components/Translate/Translate.component';
import {
  LinkText,
  StatusInstallmentContainer,
} from './style/StatusInstallment';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';

interface StatusInstallmentProps {
  statusInstallment: number;
}

export const StatusInstallment: React.FC<StatusInstallmentProps> = ({
  statusInstallment,
}: StatusInstallmentProps) => {
  const { label } =
    InstallmentStatusMap[statusInstallment as InstallmentStatus];

  return (
    <StatusInstallmentContainer
      data-component="status-installment"
      justify="center"
      align="center"
      padding="0 0.6rem"
      statusInstallment={statusInstallment}
    >
      <LinkText>
        <strong>
          <Translate id={label} />
        </strong>
      </LinkText>
    </StatusInstallmentContainer>
  );
};
