import React from 'react';
import {
  hasSpecialChar,
  hasValidLength,
  hasNumber,
  hasUpperCase,
  hasLower,
} from 'src/utils/validation/password';

export function useCheckValue(value: any) {
  return React.useMemo(
    () => ({
      hasSpecialChar: hasSpecialChar(value),
      hasNumber: hasNumber(value),
      hasUpperCase: hasUpperCase(value),
      hasLowerCase: hasLower(value),
      hasValidLength: hasValidLength(value),
    }),
    [value],
  );
}
