export const IDs = {
  btnFlxEq: 'btnFlxEq',
  btnFlxCustom: 'btnFlxCustom',
  btn1StartNow: 'btn1StartNow',
  btn2StartNow: 'btn2StartNow',
  btnNewBuyer: 'btnNewBuyer',
  btnBack: 'btnBack',
  btnClose: 'btnClose',
  inputCf: 'inputCf',
  btnProceed: 'btnProceed',
  btnConfirm: 'btnConfirm',
  btnDecline: 'btnDecline',
  inputAmount: 'inputAmount',
  input3: 'input3',
  input6: 'input6',
  input12: 'input12',
  btnMinus: 'btnMinus',
  btnPlus: 'btnPlus',
  btnUseModel: 'btnUseModel',
  inputAmountInstall: 'inputAmountInstall',
  btnClean: 'btnClean',
  inputDescr: 'inputDescr',
  btnSendEmail: 'btnSendEmail',
  btnCreateQr: 'btnCreateQr',
  inputEmail: 'inputEmail',
  btnSummary: 'btnSummary',
  btnProceedQr: 'btnProceedQr',
  btnProceedFlx: 'btnProceedFlx',
  btnProceedAdv: 'btnProceedAdv',
  inputDate: 'inputDate',
  btnSendAut: 'btnSendAut',
  btnSendMan: 'btnSendMan',
  btnCopyLink: 'btnCopyLink',
  btnAllLinks: 'btnAllLinks',
};
