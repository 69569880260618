import FormOtpPhone from 'src/pages/registration/partials/FormOtpPhone/FormOtpPhone.component';
import { RoutePath } from 'src/routers/routers.config';
import { useProfileStore } from 'src/store/store';

const RegistrationLightStepOtpPhone: React.FC = () => {
  const BuyerRegistrationStatus = useProfileStore(
    (state) => state.currentOrder?.BuyerRegistrationStatus,
  );
  return (
    <FormOtpPhone
      nextPath={RoutePath.registrationLight + RoutePath.flagStep}
      step={2}
      stepMax={6}
      login={BuyerRegistrationStatus === 'guest'}
    />
  );
};

export default RegistrationLightStepOtpPhone;
