import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { cfField } from 'src/services/orderLink/orderLink.config';
import { searchCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import { useRegistrationStore } from 'src/store/store';
import { H4, PSmall } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import toTitleCase from 'src/utils/functions/formatString';
import { formConfigCfRegistration } from './RegistrationStepCf.helpers';

function RegistrationStepCf() {
  const location = useLocation();
  const fiscalOrVatCode = location?.state?.fiscalOrVatCode;
  const { initialValues, resolver } = formConfigCfRegistration(fiscalOrVatCode);
  const navigate = useNavigate();
  const setValue = useRegistrationStore((state) => state.setValue);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation(searchCompanyInfoMethodApi, {
    onSuccess: (res, variables) => {
      if (res.data.ResultSet.Items[0].MerchantExists) {
        methods.setError(cfField, {
          message: 'error.alreadyRegistered',
        });
        return;
      }
      if (res.data.ResultSet.Result === 'not_found') {
        navigate(RoutePath.ThankyouPageError + RoutePath.fiscalCodeMatchError);
        return;
      }
      setValue('name', toTitleCase(res.data.ResultSet.Items[0].Name));
      setValue('address', toTitleCase(res.data.ResultSet.Items[0].Address));
      setValue('vatCode', toTitleCase(res.data.ResultSet.Items[0].VatCode));
      setValue('codiceFiscale', res.data.ResultSet.Items[0].FiscalCode);
      setValue('companySearchId', res.data.ResultSet.Items[0].SearchId);
      navigate(RoutePath.registrationMerchant + RoutePath.checkDataStep, {
        state: {
          fiscalOrVatCode: variables.vatCode,
        },
      });
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });

  const onSubmit = (data: any) => {
    mutate({ vatCode: data[cfField], country: 'IT' });
  };

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <>
      <FlowHeader
        label="lbl.yourCompanyData"
        showWhiteSpaceRight
        stepNumber={2}
        stepMaxNumber={9}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.stepCfMerchant" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField
                name={cfField}
                isPresentMessage
                maxLength={16}
                id={IDs.inputCf}
              />
              <PSmall marginTop="1.6rem">
                <Translate id="text.stepCfSubtitle" />
              </PSmall>
              <ButtonContainer>
                <SubmitButton
                  id={IDs.btnProceed}
                  minWidth="100%"
                  disabled={isLoading}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </>
  );
}

export default RegistrationStepCf;
