import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useRegistrationStore } from 'src/store/store';

const RegistrationBuyer: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  useEffect(() => {
    if (!sessionGuid) {
      navigate(RoutePath.oops);
    }
  }, [pathname]);
  return <Outlet />;
};

export default RegistrationBuyer;
