import { Dispatch, SetStateAction, KeyboardEvent, MouseEvent } from 'react';
import { produce } from 'immer';
import { memoize } from 'lodash-es';
import toggle from 'src/utils/functions/toggle';
import { AppTheme } from 'src/appTheme';
import { FieldValues, UseFormTrigger } from 'react-hook-form';
import { errorMessage } from 'src/utils/validation/required';
import { FieldStateReducer } from 'src/services/forms/forms.types';
import { SelectFieldStateData } from './Select.types';
import { SelectFieldUIStates } from './Select.config';

function defaultIsFieldErrorAlert(error: string): boolean {
  return error === errorMessage;
}

export function selectFieldStateReducer(
  { error, touched }: FieldStateReducer,
  isFieldErrorAlert: (error: string) => boolean = defaultIsFieldErrorAlert,
): SelectFieldStateData {
  let output: SelectFieldStateData = {
    selectFieldState: SelectFieldUIStates.idle,
    messageProps: null,
  };
  if (!(typeof error === 'string' && touched)) {
    return output;
  }

  if (isFieldErrorAlert(error)) {
    output.selectFieldState = SelectFieldUIStates.alert;
  } else {
    output.selectFieldState = SelectFieldUIStates.error;
  }

  output.messageProps = error;

  return output;
}

/**
 *  Maps the labels to their 'optionId' and localize them if 'i18n'
 * is set to true.
 */
export const getLocalizedLabelsByOptionId = (
  options: ListboxOption[],
  i18n: boolean,
  translate: any,
) => {
  return Object.fromEntries(
    options.map(({ optionId, label }: ListboxOption) => [
      optionId,
      i18n ? (translate(label) as string) : label,
    ]),
  );
};

/**
 * Outputs a mapping between the 'optionId' and the corresponding click
 * event handler.
 */
export const createOptionClickHandlerByOptionId = (
  options: ListboxOption[],
  handleItemClick: onListboxitemClick,
) => {
  const createOnListItemClick =
    (optionId: OptionId) => (domEvent: MouseEvent) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
      const isPrimarybutton = domEvent.button === 0;

      if (isPrimarybutton) {
        handleItemClick(optionId);
      }
    };

  return Object.fromEntries(
    options.map(({ optionId }) => [optionId, createOnListItemClick(optionId)]),
  );
};

/**

/**
 * Generates an event listener that manages keyDown events.
 */
export const createHandleKeyDown =
  <T = HTMLElement>(
    isExpanded: boolean,
    setIsExpanded: Dispatch<SetStateAction<boolean>>,
    options: ListboxOption[],
    selectedOptionIndex: number,
    onChange: onListboxChangeHandler,
    trigger: UseFormTrigger<FieldValues>,
    inputNameKey: string,
  ) =>
  (domEvent: KeyboardEvent<T>) => {
    let isKnownKey = true;

    switch (domEvent.key) {
      case 'Escape':
        if (isExpanded) {
          setIsExpanded(false);
        }
        break;
      case 'Enter':
        setIsExpanded(toggle);
        break;
      case '\u0020': // Spacebar
        setIsExpanded(toggle);
        break;
      case 'ArrowUp':
        if (selectedOptionIndex > 0) {
          onChange(inputNameKey, options[selectedOptionIndex - 1].optionId, {
            shouldTouch: true,
          });
          trigger(inputNameKey);
        }
        break;
      case 'ArrowDown':
        if (selectedOptionIndex < options.length - 1) {
          onChange(inputNameKey, options[selectedOptionIndex + 1].optionId, {
            shouldTouch: true,
          });
          trigger(inputNameKey);
        }
        break;
      default:
        isKnownKey = false;
    }

    if (isKnownKey) {
      domEvent.preventDefault();
    }
  };

export const generateModalName = (id: string) => `${id}-select-modal`;

export const modifyTheme: (globalTheme: AppTheme) => AppTheme = memoize(
  (globalTheme) =>
    produce(globalTheme, (draft) => {
      //   draft.modal.content.mobile.top = '21%';
      //   draft.modal.content.mobile.bottom = 'unset';
      //   draft.modal.content.mobile.width = '94%';
      //   draft.modal.content.mobile.padding = '0';
      //   draft.modal.content.tablet.top = '21%';
      //   draft.modal.content.tablet.bottom = 'unset';
      //   draft.modal.content.tablet.width = '94%';
      //   draft.modal.content.tablet.padding = '2rem 0';
      // }),
    }),
);

/**
 * Renders a 'DOM' id of '<option />'.
 * @param rootId 'DOM' id of the root element
 * @param listItemIndex index of '<option />'
 */
export const renderListItemId = (rootId: string, listItemIndex: number) =>
  `${rootId}-option-${listItemIndex}`;
