import React from 'react';
import Select from 'src/components/Select/Select.component';
import { SelectProps } from 'src/components/Select/Select.types';
import { useController, useFormContext } from 'react-hook-form';

type SelectFieldProps = Omit<
  SelectProps,
  'value' | 'onChange' | 'onBlur' | 'ref'
> & {
  name: string;
  component?: React.FC<any>;
};

const SelectField: React.FC<SelectFieldProps> = React.memo(function SelectField(
  props: SelectFieldProps,
) {
  const { control, setValue } = useFormContext();
  const { component: Component, options, name, ...otherProps } = props;
  const {
    field: { onChange, ...rest },
  } = useController({
    name,
    control,
  });
  const handleChange = setValue as onListboxChangeHandler;

  if (Component) {
    return (
      <Component
        {...otherProps}
        {...rest}
        ref={null}
        options={options}
        onChange={handleChange}
      />
    );
  }

  return (
    <Select
      data-component="select-field"
      {...otherProps}
      {...rest}
      ref={null}
      name={name}
      options={options}
      onChange={handleChange}
    />
  );
});

export default SelectField;
