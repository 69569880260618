import { ReactComponent as Created } from 'src/images/advPlanRequestsStatus/created.svg';
import { ReactComponent as Approved } from 'src/images/advPlanRequestsStatus/approved.svg';
import { ReactComponent as Unsuitable } from 'src/images/advPlanRequestsStatus/unsuitable.svg';

export enum CollaboratorStatus {
  active = 'active',
  inactive = 'inactive',
  waiting = 'waiting',
}

export const CollaboratorStatusMap = {
  [CollaboratorStatus.active]: {
    label: 'lbl.active',
    icon: Approved,
    popupTitle: 'text.wannaDeactivateCollaboratorTitle',
    popupSubTitle: 'text.wannaDeactivateCollaboratorSubTitle',
    buttonLabel: 'lbl.deactivateCollaborator',
    isActive: true,
    style: {
      bg: '#009E6B',
      color: '#fff',
      colorBorder: '#009E6B',
    },
  },
  [CollaboratorStatus.inactive]: {
    label: 'lbl.inactive',
    icon: Unsuitable,
    popupTitle: 'text.wannaActivateCollaboratorTitle',
    popupSubTitle: 'text.wannaActivateCollaboratorSubTitle',
    buttonLabel: 'lbl.activateCollaborator',
    isActive: false,
    style: {
      bg: '#C9CDD6',
      color: '#fff',
      colorBorder: '#C9CDD6',
    },
  },
  [CollaboratorStatus.waiting]: {
    label: 'lbl.pending',
    icon: Created,
    popupTitle: 'text.wannaDeactivateCollaboratorTitle',
    popupSubTitle: 'text.wannaDeactivateCollaboratorSubTitle',
    buttonLabel: 'lbl.deactivateCollaborator',
    isActive: true,
    style: {
      bg: '#636E82',
      color: '#fff',
      colorBorder: '#636E82',
    },
  },
};
