import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { GenericErrorFormProps } from './GenericErrorForm.types';
import {
  ContainerMessageGeneric,
  MessageErrorGeneric,
} from './style/GenericErrorForm.style';

export const GenericErrorForm: React.FC<GenericErrorFormProps> = ({
  isPresentMessage,
  errorMessageID,
  textAlign = 'center',
}) => {
  return (
    <ContainerMessageGeneric
      data-component="generic-error-form"
      textAlign={textAlign}
      isPresentMessage={isPresentMessage}
    >
      <MessageErrorGeneric>
        {errorMessageID !== '' && <Translate id={errorMessageID} />}
      </MessageErrorGeneric>
    </ContainerMessageGeneric>
  );
};
