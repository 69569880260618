export const humanChatPath: string[] = [
  '/support',
  '/oops',
  '/thankyou-page-error/create-link-error',
  '/thankyou-page-error/expired-link',
  '/thankyou-page-error/canceled-link',
  '/thankyou-page-error/login-buyer-error',
  '/thankyou-page-error/uw-buyer-error',
  '/thankyou-page-error/payment-error',
  '/thankyou-page-error/not-my-company',
  '/thankyou-page-error/fiscal-code-match-error',
  '/thankyou-page-error/buyer-fiscal-code-error',
  '/thankyou-page-error/internal-error',
  '/thankyou-page-error/sign-generic-error',
  '/thankyou-page-error/wrong-user-error',
  '/thankyou-page-error/link-already-paid',
  '/thankyou-page-error/payment-failed',
];

export enum RoutePath {
  root = '/',
  login = '/login',
  advancePaymentInfo = '/advance-payment-info',
  ecommerceInfo = '/ecommerce-info',
  remodulationInfo = '/remodulation-info',
  collaboratorInfo = '/collaborator-info',
  advancePlanRequests = '/advance-plan-requests',
  remodulationRequests = '/remodulation-requests',
  collaborators = '/collaborators',
  collaborator = '/collaborator',
  addCollaborator = '/add-collaborator',
  successAddCollaborator = '/success-add-collaborator',
  registration = '/registration',
  registrationMerchantLandingPage = '/registration-opyn-pay-later',
  registrationCollaboratorLandingPage = '/registration-collaborator-opyn-pay-later',
  registrationBuyer = '/registration-buyer/',
  registrationMerchant = '/registration-merchant/',
  registrationGuest = '/registration-buyer-guest/',
  registrationLight = '/registration-buyer-light/',
  registrationCollaborator = '/registration-collaborator/',
  successRegistrationCollaborator = '/success-registration-collaborator/',
  emailStep = 'email-step',
  checkDataStep = 'check-data-step',
  passwordStep = 'password-step',
  otpConfirmEmailStep = 'otp-confirm-email-step',
  phoneStep = 'phone-step',
  otpConfirmPhoneStep = 'otp-confirm-phone-step',
  flagStep = 'flag-step',
  fiscalCodeStep = 'fiscal-code-step',
  checkdataStep = 'check-data-step',
  registrationStep = '/registration-step/',
  recoveryData = '/recovery-data/',
  recoveryEmail = 'recovery-email',
  recoveryPassword = 'recovery-password',
  insertOtp = 'insert-otp',
  showEmail = 'show-email',
  insertPassword = 'insert-password',
  authOtp = 'auth-otp',
  performOtp = 'perform-otp',
  changePhone = 'change-phone',
  changeEmail = 'change-mail',
  changePassword = 'change-password',
  purchase = '/purchase',
  purchaseLogged = '/purchase-logged',
  splat = '*',
  oops = '/oops',
  oopsPlugin = '/oops/plugin',
  pluginInfo = '/plugin-info',
  ThankyouPage = '/ThankyouPage/',
  successRegistration = 'success-registration',
  successChangePassword = 'success-change-password',
  successRecoveryPassword = 'success-recovery-password',
  successChangeNumber = 'success-change-number',
  successChangeEmail = 'success-change-email',
  welcomePage = '/welcome-page',
  welcomePageLogged = '/welcome-page-logged',
  ecommerce = '/ecommerce-checkout/',
  ecommerceCheckData = 'check-data',
  ecommerceCheckout = 'checkout',
  monitoring = '/monitoring',
  monitoringDetail = '/monitoring-detail/',
  isYourCompany = 'is-your-company',
  ThankyouPageError = '/thankyou-page-error/',
  createLinkError = 'create-link-error',
  expiredLink = 'expired-link',
  canceledLink = 'canceled-link',
  expiredCart = 'expired-cart',
  ecommerceWrongData = 'ecommerce-wrong-data',
  loginBuyerError = 'login-buyer-error',
  uwBuyerError = 'uw-buyer-error',
  uwBuyerErrorPlugin = 'uw-buyer-error/plugin',
  paymentError = 'payment-error',
  linkAlreadyPaid = 'link-already-paid',
  notMyCompany = 'not-my-company',
  fiscalCodeMatchError = 'fiscal-code-match-error',
  buyerFiscalCodeError = 'buyer-fiscal-code-error',
  internalError = 'internal-error',
  signGenericError = 'sign-generic-error',
  wrongUserError = 'wrong-user-error',
  paymentFailed = 'payment-failed',
  createLink = '/create-link/',
  chooseCategory = 'choose-category',
  chooseProduct = 'choose-product',
  chooseCompany = 'choose-company',
  stepCustomer = 'step-customer',
  stepCountry = 'step-country',
  stepNamePostalCode = 'step-name-and-postal-code',
  stepName = 'step-name',
  stepCf = 'step-cf/',
  stepCheckData = 'step-check-data',
  stepImport = 'step-import',
  stepVAT = 'step-vat-number',
  privateArea = '/private-area',
  payByLinkFlow = '',
  initialPage = 'initial-page',
  startPageTaking = 'start-page-taking',
  startPageWithoutTaking = 'start-page-without-taking',
  stepInstallmentPlan = 'step-installment-plan/',
  stepInstallmentDelay = 'step-installment-delay/',
  stepInstallmentNumber = 'step-installment-number/',
  stepSelectTemplates = 'step-select-template/',
  stepInstallmentAmount = 'step-installment-amount/',
  stepCustomInstallmentForm = 'step-custom-installment-form/',
  stepSetInstallments = 'step-set-installments/',
  stepDescription = 'step-description/',
  stepEmail = 'step-email/',
  stepResumeQr = 'step-resume-qr/',
  stepCustomerValidation = 'step-customer-validation/',
  stepResume = 'step-resume/',
  stepCreditResume = 'step-credit-resume/',
  stepCollectionMethod = 'step-collection-method/',
  stepInvoiceDate = 'step-invoice-date/',
  startInstallmentPlan = 'start-installment-plan/',
  dateInstallmentPlan = 'date-installment-plan/',
  stepSendLink = 'step-send-link/',
  successSendLink = 'success-send-link',
  sendLinkNow = 'send-link-now',
  showQrCode = 'show-qr-code',
  advancePaymentRequest = '/advance-payment-request/',
  chooseLink = 'choose-link',
  linkDetail = 'link-detail',
  installments = '/installments',
  methods = '/methods/',
  paymentMethods = 'payment-methods',
  addBackupMethod = 'add-backup-method',
  addMethodStripe = 'add-method-stripe',
  orders = '/orders',
  orderDetail = '/order-detail/',
  linkInvoiceDate = 'link-invoice-date',
  AdvPayResultPage = '/adv-pay-result-page',
  requestsResults = 'requests-results',
  linkDiPagamento = '/link-di-pagamento',
  linkOnline = '/link-online',
  balanceMovements = '/balance-movements',
  marketingChoice = '/marketing-choice',
  accessData = '/access-data',
  viewAllAvailability = '/viewAllAvailability',
  access = '/access',
  changeAccessData = '/change-access-data/',
  financialTransaction = '/financial-transaction',
  fundTransfer = '/fund-transfer',
  loginStripe = '/login-stripe',
  documents = '/documents',
  personalData = '/personal-data',
  paymentSummary = '/payment-summary',
  account = '/account',
  usageSetting = '/usage-setting',
  usageSettingOptions = '/usage-setting/options',
  usageSettingSavedTemplates = '/usage-setting/saved-templates',
  usageSettingTemplate = '/usage-setting/template/',
  usageSettingOptionsLiquidity = '/usage-setting/options/liquidity',
  usageSettingOptionsRequirements = '/usage-setting/options/usage-requirements',
  support = '/support',
  paymentDetail = '/payment-detail/',
  linkDiPagamentoDetail = '/link-di-pagamento/detail/',
  linkOnlineDetail = '/link-online/detail/',
  help = 'help',
  beforeEditPlan = 'before-edit-plan',
  editPlanShippingDate = 'edit-plan-shipping-date',
  successRemodulation = 'success-remodulation',
  actionsSuccessCheckCreditWorthiness = 'actions/success-check-credit-worthiness',
  info = 'info',
  actionsSuccessResend = 'actions/success-resend',
  actionsResendLink = 'actions/resend-link',
  actionsLinkChoice = 'actions/link-choice',
  actionsShowQrcode = 'actions/show-qrcode',
  actionsSendLinkNow = 'actions/send-link-now',
  actionsSuccessCanceled = 'actions/success-canceled',
  actionsSupport = 'actions/support',
  payment = 'payment',
  paymentFlow = '/payment-flow/',
  chooseMethod = 'choose-method',
  payWithOpyn = 'pay-with-opyn',
  selectInstallments = 'select-installments',
  selectExpiredInstallments = 'select-expired-installments',
  paymentSuccess = 'payment-success',
  multiPaymentSuccess = 'multi-payment-success',
  setupSuccess = 'setup-success',
  setMethods = 'set-methods',
  setBackup = 'set-backup',
  paymentSuccessPlugin = 'payment-success/plugin',
  alreadyPaidPlugin = 'already-paid/plugin',
  setupSuccessPlugin = 'setup-success/plugin',
  saveTemplate = '/save-template',
  templateSaved = '/template-saved',
  createLinkExit = '/create-link/exit',
  loadingNamirial = 'loading-namirial',
  continueRegistration = '/continue-registration',
  onboarding = '/onboarding/',
  readContract = 'read-contract',
  signWithSpid = 'sign-with-spid',
  successNamirial = 'success-namirial',
  signStripe = 'sign-stripe',
  errorKoAdmin = 'error-ko-admin',
  success = 'success',
  assistance = 'assistance',
  uploadedContract = 'uploaded-contract',
  devOnlyTestPage = '/dev_only/test_page',
}

interface linkObject {
  label: string;
  url?: string;
  viewState?: Array<string>;
  hideByCantGiveCredit?: boolean;
  hasPluginProduct?: boolean;
  child?: linkObject[];
}

const viewStateArrayMerchant = ['2', '3', '4', '30', '31', '32', '33', '34'];

export const merchantMenu: linkObject[] = [
  {
    label: 'menu.links',
    url: RoutePath.linkDiPagamento,
    viewState: viewStateArrayMerchant,
  },
  {
    label: 'menu.onlineShop',
    viewState: viewStateArrayMerchant,
    child: [
      {
        label: 'menu.advPaymentInfo',
        url: RoutePath.ecommerceInfo,
      },
      {
        label: 'menu.onlineShopOrders',
        hasPluginProduct: true,
        url: RoutePath.linkOnline,
      },
    ],
  },
  {
    label: 'lbl.monitoring',
    viewState: viewStateArrayMerchant,
    url: RoutePath.monitoring,
  },
  {
    label: 'menu.services',
    viewState: viewStateArrayMerchant,
    child: [
      {
        label: 'menu.advPayment',
        hideByCantGiveCredit: true,
        child: [
          {
            label: 'menu.advPaymentInfo',
            url: RoutePath.advancePaymentInfo,
          },
          {
            label: 'menu.advPaymentRequest',
            url: `${RoutePath.advancePaymentRequest}${RoutePath.chooseLink}`,
          },
          {
            label: 'menu.advPaymentAll',
            url: RoutePath.advancePlanRequests,
          },
        ],
      },
      {
        label: 'menu.managementCollaborators',
        child: [
          {
            label: 'menu.advPaymentInfo',
            url: RoutePath.collaboratorInfo,
          },
          {
            label: 'menu.addCollaborator',
            url: RoutePath.addCollaborator,
          },
          {
            label: 'menu.yourCollaborators',
            url: RoutePath.collaborators,
          },
        ],
      },
      {
        label: 'menu.changePlan',
        child: [
          {
            label: 'menu.changePlanInfo',
            url: RoutePath.remodulationInfo,
          },
          {
            label: 'menu.changePlan',
            url: RoutePath.linkDiPagamento,
          },
          {
            label: 'menu.changePlanAll',
            url: RoutePath.remodulationRequests,
          },
        ],
      },
    ],
  },
  {
    label: 'menu.movements',
    viewState: viewStateArrayMerchant,
    url: RoutePath.balanceMovements,
  },
  {
    label: 'menu.profile',
    viewState: viewStateArrayMerchant,
    url: RoutePath.account,
  },
  {
    label: 'menu.help',
    viewState: viewStateArrayMerchant,
    url: RoutePath.support,
  },
];
export const collaboratorMenu: linkObject[] = [
  {
    label: 'menu.links',
    url: RoutePath.linkDiPagamento,
    viewState: viewStateArrayMerchant,
  },
  {
    label: 'menu.services',
    viewState: viewStateArrayMerchant,
    child: [
      {
        label: 'menu.advPayment',
        hideByCantGiveCredit: true,
        child: [
          {
            label: 'menu.advPaymentInfo',
            url: RoutePath.advancePaymentInfo,
          },
          {
            label: 'menu.advPaymentRequest',
            url: `${RoutePath.advancePaymentRequest}${RoutePath.chooseLink}`,
          },
          {
            label: 'menu.advPaymentAll',
            url: RoutePath.advancePlanRequests,
          },
        ],
      },
      {
        label: 'menu.changePlan',
        child: [
          {
            label: 'menu.changePlanInfo',
            url: RoutePath.remodulationInfo,
          },
          {
            label: 'menu.changePlan',
            url: RoutePath.linkDiPagamento,
          },
          {
            label: 'menu.changePlanAll',
            url: RoutePath.remodulationRequests,
          },
        ],
      },
    ],
  },
  {
    label: 'menu.onlineShop',
    viewState: viewStateArrayMerchant,
    child: [
      {
        label: 'menu.advPaymentInfo',
        url: RoutePath.ecommerceInfo,
      },
      {
        label: 'menu.onlineShopOrders',
        hasPluginProduct: true,
        url: RoutePath.linkOnline,
      },
    ],
  },
  {
    label: 'menu.profile',
    viewState: viewStateArrayMerchant,
    url: RoutePath.account,
  },
  {
    label: 'menu.help',
    viewState: viewStateArrayMerchant,
    url: RoutePath.support,
  },
];

export const buyerMenu: linkObject[] = [
  {
    label: 'lbl.orders',
    url: RoutePath.orders,
    viewState: ['2', '3', '4'],
  },
  {
    label: 'menu.installments',
    url: RoutePath.installments,
    viewState: ['2', '3', '4'],
  },
  {
    label: 'menu.paymentmethods',
    url: RoutePath.methods + RoutePath.paymentMethods,
  },
  {
    label: 'menu.profile',
    url: RoutePath.account,
  },
  {
    label: 'menu.help',
    url: RoutePath.support,
  },
];
