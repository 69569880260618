import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import React from 'react';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StepperProps } from './Stepper.types';
import { CircularProgress, FlexWrapper } from './style/stepper.style';

const StepperPercentage: React.FC<StepperProps> = ({
  dataPercentageStepper,
}) => {
  return (
    <>
      {dataPercentageStepper?.map((item, i) => {
        return (
          <FlexWrapper
            gap="1.6rem"
            align="center"
            flexDirection="row"
            key={i}
            length={i}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <CircularProgress outlineColor={colors.primaryGreen}>
                {i + 1}
              </CircularProgress>
            </div>
            <div
              style={{
                flexGrow: item.Interval !== undefined ? '1' : 'unset',
              }}
            >
              <H4
                light={i !== 0}
                style={{
                  textAlign: item.Interval !== undefined ? 'left' : 'center',
                }}
              >
                {item.Rate + '%'}
              </H4>
              {item.Interval !== undefined ? (
                <P
                  style={{
                    color: `${colors.textColorGrey}`,
                  }}
                >
                  <Translate
                    id={
                      i === 0
                        ? 'text.stepperTemplateFirst'
                        : 'text.stepperTemplate'
                    }
                    data={{ interval: item.Interval }}
                  />
                </P>
              ) : null}
            </div>
            {item.Expiration && (
              <div>
                <P bold={i === 0} style={{ color: colors.textColorGrey }}>
                  {moment().isSame(item.Expiration, 'day') ? (
                    <Translate id="text.today" />
                  ) : (
                    refactorFormatDate(item.Expiration, 'DD MMMM YYYY')
                  )}
                </P>
              </div>
            )}
          </FlexWrapper>
        );
      })}
    </>
  );
};
export default StepperPercentage;
