import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import React, { useEffect, useState } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { generateQrCodeMethodApi } from 'src/services/orderLink/orderLink.request';
import { PayByLinkIconQR } from './style/PayByLinkActionsQR.style';
import { ContainerPadding } from 'src/style/Container.style';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';

const PayByLinkActionsQR: React.FC = () => {
  const navigate = useNavigate();
  const { OrderId, path } = useOutletContext<PayByLinkDetailsContext>();
  const [qrCode, setqrCode] = useState('');
  const [isLoadingState, setIsLoadingState] = useState(true);

  useEffect(() => {
    mutateQrCode({ idOrdine: OrderId });
  }, []);

  const { mutate: mutateQrCode, isLoading: isLoadingQrCode } = useMutation(
    (values: any) => generateQrCodeMethodApi(values),
    {
      onSuccess: async (res) => {
        setqrCode(res.data.ResultSet);
        setIsLoadingState(false);
      },
      onError: () => {
        navigate('/error-page');
      },
    },
  );

  if (isLoadingQrCode || isLoadingState) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <PayByLinkHeader stepBack title="text.QRcode" idLink={OrderId} />
      <ContainerPadding>
        <TextDefaultPage title="lbl.showQrCode" />
        <PayByLinkIconQR>
          <Icon
            iconSize="27.9rem"
            iconHeight="27.9rem"
            iconSrc={'data:image/png;base64, ' + qrCode}
          />
        </PayByLinkIconQR>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            onClick={() => navigate(path)}
          >
            <Translate id="text.seeAllPayByLink" />
          </Button>
        </ButtonContainer>
      </ContainerPadding>
    </>
  );
};

export default PayByLinkActionsQR;
