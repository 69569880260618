import { Resolver } from 'react-hook-form';
import { installmentNumber } from 'src/services/orderLink/orderLink.config';
import numberInRange from 'src/utils/validation/numberInRange';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const InstallmentConfigForm = () => {
  const initialValues = {
    [installmentNumber]: 1,
  };

  const validate = validateFormSync({
    [installmentNumber]: validateFieldSync(
      required,
      numberInRange({ min: 1, max: 50 }),
    ),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { initialValues, resolver };
};
