import breakpoints from 'src/style-utils/breakpoints';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import styled from 'styled-components';

// https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
const screenReaderOnly = `
position: absolute !important;
width: 1px !important;
height: 1px !important;
padding: 0 !important;
margin: -1px !important;
overflow: hidden !important;
clip: rect(0, 0, 0, 0) !important;
white-space: nowrap !important;
border: 0 !important;
`.replace(/\r?\n/g, '');

export const FocusSentinel = styled.div`
  ${screenReaderOnly};
`;

export const ModalRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(53, 48, 47, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1300;
  ${({ theme: { modal } }) => `
    padding-top:${modal.content.mobile.paddingTop};
  `};
  @media (min-width: ${breakpoints.md}px) {
    ${({ theme: { modal } }) => `
         padding-top:${modal.content.tablet.paddingTop};
       `};
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${({ theme: { modal } }) => `
      padding-top:${modal.content.desktop.paddingTop};
    `};
  }
`;

ModalRoot.displayName = 'ModalRoot';

export const ModalContentRoot = styled.div`
  overflow: hidden;
  background: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  ${({ theme: { modal } }) => `
    max-width: ${modal.content.mobile.maxWidth};
    top: ${modal.content.mobile.top};
    bottom: ${modal.content.mobile.bottom};
    left: ${modal.content.mobile.left};
    right: ${modal.content.mobile.right};
    border-radius ${modal.content.mobile.borderRadius};
    max-height: calc(${modal.content.mobile.maxHeight} - ${modal.content.mobile.paddingTop});
  `};

  @media (min-width: ${breakpoints.md}px) {
    ${({ theme: { modal } }) => `
    top: ${modal.content.tablet.top};
    bottom: ${modal.content.tablet.bottom};
    left: ${modal.content.tablet.left};
    right: ${modal.content.tablet.right};
    max-width: ${modal.content.tablet.maxWidth};
    border-radius ${modal.content.tablet.borderRadius};
    max-height: calc(${modal.content.tablet.maxHeight} - ${modal.content.tablet.paddingTop});
    `};
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${({ theme: { modal } }) => `
    top: ${modal.content.desktop.top};
    bottom: ${modal.content.desktop.bottom};
    left: ${modal.content.desktop.left};
    right: ${modal.content.desktop.right};
    max-width: ${modal.content.desktop.maxWidth};
    border-radius ${modal.content.desktop.borderRadius};
    max-height: calc(${modal.content.desktop.maxHeight} - ${modal.content.desktop.paddingTop});
    `};
  }
`;

ModalContentRoot.displayName = 'ModalContentRoot';

export const ModalBody = styled.div`
  ${({ theme: { modal } }) => `
    padding: ${modal.content.mobile.padding};
  `};

  @media (min-width: ${breakpoints.md}px) {
    ${({ theme: { modal } }) => `
    padding: ${modal.content.tablet.padding};
    `};
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${({ theme: { modal } }) => `
    padding: ${modal.content.desktop.padding};
    `};
  }
  ${P} {
    color: ${colors.textColorBlack};
    &.text-gray,
    &.text-pgrey {
      color: ${colors.textColorGrey};
    }
  }
  overflow: auto;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
ModalBody.displayName = 'ModalBody';

export const CloseButtonRoot = styled.button.attrs(() => ({
  type: 'button',
  'aria-label': 'close',
  title: 'close',
}))`
  color: ${colors.white};
  background: transparent;
  border: none;
  margin-left: auto;
`;

CloseButtonRoot.displayName = 'CloseButtonRoot';
