import { css } from 'styled-components';
import createExtractCssFromProps from './createExtractCssFromProps';
import media from './media-queries';

// The order of the properties is intentional:
// rules are rendered according to the standard iteration order of js objects.
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
const mapPaddingPropNamesToCssRules = {
  padding: 'padding',
  paddingTop: 'padding-top',
  paddingRight: 'padding-right',
  paddingBottom: 'padding-bottom',
  paddingLeft: 'padding-left',
};

export type WithPaddingProps = Partial<
  Record<keyof typeof mapPaddingPropNamesToCssRules, string>
>;

/**
 * Adds padding props to a 'styled-component'.
 * @example
 * ```javascript
 *  const H3 = styled.h3`
 *   ${withPadding};
 *  `;
 *
 *  const h3Elem = <H3 paddingTop="10px" />
 * ```
 */
export const withPadding = css`
  ${createExtractCssFromProps(mapPaddingPropNamesToCssRules)};
`;

const mapMarginPropNamesToCssRules = {
  margin: 'margin',
  marginTop: 'margin-top',
  marginRight: 'margin-right',
  marginBottom: 'margin-bottom',
  marginLeft: 'margin-left',
};

const mapDesktopPaddingPropNamesToCssRules = {
  desktopPadding: 'padding',
  desktopPaddingTop: 'padding-top',
  desktopPaddingRight: 'padding-right',
  desktopPaddingBottom: 'padding-bottom',
  desktopPaddingLeft: 'padding-left',
};

export type WithDesktopPaddingProps = Partial<
  Record<
    | 'desktopPadding'
    | 'desktopPaddingTop'
    | 'desktopPaddingRight'
    | 'desktopPaddingBottom'
    | 'desktopPaddingLeft',
    string
  >
>;

export const withDesktopPadding = css`
  ${createExtractCssFromProps(mapDesktopPaddingPropNamesToCssRules, {
    media: 'desktop',
  })};
`;

export type WithMarginProps = Partial<
  Record<keyof typeof mapMarginPropNamesToCssRules, string>
>;

/**
 * Adds margin props to a 'styled-component'.
 * @example
 * ```javascript
 *  const H3 = styled.h3`
 *   ${withMargin};
 *  `;
 *
 *  const h3Elem = <H3 marginLeft="10px" />
 * ```
 */
export const withMargin = css`
  ${createExtractCssFromProps(mapMarginPropNamesToCssRules)};
`;

type MaxWidthConfig = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
};

/**
 * Helpers that the 'max-width' given a MaxWidthConfig config object.
 *
 * @example
 * ```javascript
 *
 * ```
 */
export const setMaxWidthPerBreakpointLabel = ({
  mobile,
  tablet,
  desktop,
}: MaxWidthConfig) => css`
  ${mobile && `max-width: ${mobile};`};
  ${tablet && media.tablet`max-width: ${tablet};`};
  ${desktop && media.desktop`max-width: ${desktop};`};
`;
