import { IconRoot } from 'src/components/Icon/style/Icon.style';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const StyledModalHeader = styled(Flex)`
  width: 100%;
  align-items: center;
  padding: 1.6rem 2.4rem;
  color: ${colors.textColorGrey};
  border-bottom: 1px solid ${colors.textColorLightGrey};
  ${IconRoot} {
    cursor: pointer;
  }
  ${media.tablet`
  padding: 1.6rem 4rem;
  `}
`;
