import { authTokenKey, isLocalhost } from './session.config';
import { acquireEndcallbackUrlFromQueryParams } from './session.helpers';
import { Homepage, SessionReducerShape, OpynSiteInfo } from './session.types';

export async function getSessionInfo(): Promise<Partial<SessionReducerShape>> {
  const searchParams = new URLSearchParams(window.location.search);

  const homepage: Homepage = {
    pathname: '',
    search: '',
    host: '',
    protocol: '',
    href: '',
  };

  if (process.env.PUBLIC_URL) {
    try {
      let publicURL;

      publicURL = new URL(process.env.PUBLIC_URL);

      homepage.pathname = publicURL.pathname;
      homepage.search = publicURL.host;
      homepage.host = publicURL.host;
      homepage.protocol = publicURL.protocol;
      homepage.href = publicURL.href;
    } catch (parseError) {
      throw new Error(`Invalid PUBLIC_URL provided ${parseError}`);
    }
  }

  const response = await Promise.all([
    getOpynSiteInfo(searchParams, window.navigator),
    getAuthToken(searchParams),
  ]).then(([opynSiteInfo, auth]) => ({
    info: {
      homepage,
      isLocalhost,
      lastAddress: document.referrer,
      // camelCase key, use 'endCallbackUrlKey' to get the query params key.
      endCallbackUrl: acquireEndcallbackUrlFromQueryParams(searchParams),
      ...opynSiteInfo,
    },
    auth: {
      ...auth,
    },
  }));

  return response;
}

export const getOpynSiteInfo = (
  searchParams: URLSearchParams,
  navigator: Navigator,
) => {
  const opynSiteInfo: OpynSiteInfo = {
    channelInfo: navigator.userAgent,
  };

  return Promise.resolve(opynSiteInfo);
};

export async function getAuthToken(searchParams: URLSearchParams) {
  let authToken: string | null = searchParams.get(authTokenKey);

  if (typeof authToken === 'string') {
    // Remove the previous auth serialized if it was present.
    window.localStorage.removeItem(authTokenKey);
    return {
      authToken,
      from: 'url',
    };
  }

  authToken = window.localStorage.getItem(authTokenKey);

  return {
    authToken,
    from: 'local-storage',
  };
}
// export async function getTypeAccount(searchParams: URLSearchParams) {
//   let typeAccount: string | null = searchParams.get(typeAccountKey);

//   if (typeof typeAccount === 'string') {
//     // Remove the previous auth serialized if it was present.
//     window.localStorage.removeItem(typeAccountKey);
//     window.localStorage.setItem(typeAccountKey, typeAccount);
//     return typeAccount as 'MERCHANT' | 'BUYER';
//   }

//   typeAccount = window.localStorage.getItem(typeAccountKey);

//   return typeAccount === null
//     ? 'MERCHANT'
//     : (typeAccount as 'MERCHANT' | 'BUYER');
// }
