import { cva } from 'class-variance-authority';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H5, PSmall } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { OrderStatus } from '../StatusPayByLink/StatusPayByLink.config';

type ResumePaymentCardProps = {
  name: string;
  date: string;
  amount: number;
  status?: 'paid' | 'expired' | 'processing' | 'default' | 'interrupted';
  arrowStatus?: 'paid' | 'expired' | 'processing' | 'default';
  NumRata: number;
  shadows?: boolean;
  itemStatus?: number;
  orderStatus?: OrderStatus;
  onClickHandler?: (event: React.MouseEvent<any>) => void;
};

const cardVariants = cva(
  'group relative z-0 flex items-center gap-2 rounded-xl bg-white p-4 outline outline-1 transition-shadow duration-500 before:absolute before:left-0 before:top-1/2 before:h-9 before:w-[.7rem] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full lg:before:h-[4.6rem]',
  {
    variants: {
      status: {
        paid: 'outline-success before:bg-success',
        expired: 'outline-danger before:bg-danger',
        processing:
          'outline-pgreyoutlined before:border before:border-success before:bg-white',
        default: 'outline-pgreyoutlined before:bg-pgreyoutlined',
        interrupted: 'outline-pwarningoutlined before:bg-pwarningoutlined',
      },
      clickable: {
        true: 'cursor-pointer',
      },
      shadows: {
        true: 'shadow-md hover:shadow-lg',
      },
    },
    defaultVariants: {
      status: 'default',
      clickable: false,
      shadows: false,
    },
  },
);

const arrowVariant = cva('block h-8 w-8 shrink-0 rounded-full', {
  variants: {
    status: {
      paid: 'shadow-md lg:h-11 lg:w-11',
      expired: 'bg-danger text-white',
      processing: 'bg-white lg:h-11 lg:w-11',
      default: 'bg-white shadow-md lg:h-11 lg:w-11',
    },
  },
  defaultVariants: {
    status: 'default',
  },
});

const InstallmentCard: React.FC<ResumePaymentCardProps> = ({
  amount,
  date,
  status,
  arrowStatus,
  name,
  NumRata,
  shadows,
  itemStatus,
  orderStatus,
  onClickHandler,
}) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const clickable = onClickHandler !== undefined;
  if (
    orderStatus &&
    orderStatus === OrderStatus.interrupted &&
    itemStatus === 0
  ) {
    status = 'interrupted';
  }
  if (orderStatus === OrderStatus.regularIncoming && itemStatus === 4) {
    status = 'processing';
    arrowStatus = 'processing';
  }

  return (
    <div
      data-component="installment-card"
      className={cardVariants({ status: status, clickable, shadows })}
      onClick={onClickHandler}
    >
      <div className="flex w-full items-center gap-2 lg:gap-4">
        <div className="flex w-[65%] items-center gap-2 lg:w-[70%]">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full border-[0.5rem] border-pgreen-400 bg-white lg:h-14 lg:w-14">
            <H5>{NumRata}</H5>
          </div>
          <div className="flex flex-col gap-1">
            <PSmall>{refactorFormatDate(date, 'DD MMMM YYYY')}</PSmall>
            <H5 className="line-clamp-1 break-all">{name}</H5>
          </div>
        </div>
        <div className="shrink-0">
          <H5>{formatCurrency(amount)}</H5>
        </div>
      </div>
      {!arrowStatus && (
        <div className="pointer-events-none absolute inset-0 -z-10 overflow-hidden rounded-xl after:absolute after:right-0 after:top-1/2 after:h-40 after:w-40 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-bblue after:transition-all after:duration-500 after:ease-in-out group-hover:after:w-[210%]" />
      )}
      {onClickHandler && (
        <ArrowRight className={arrowVariant({ status: arrowStatus })} />
      )}
    </div>
  );
};

export default InstallmentCard;
