import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlreadyPaid } from 'src/images/pageStatus/already-paid.svg';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';

const LinkAlreadyPaid: React.FC = () => {
  return (
    <>
      <Icon
        svgIconComponent={AlreadyPaid}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.linkAlreadyPaid" />
      </H4>
      <ButtonContainer>
        <NeedHelpButton />
      </ButtonContainer>
    </>
  );
};

export default LinkAlreadyPaid;
