import StatsCard from 'src/components/StatsCard/StatsCard.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import { useState } from 'react';
import PendingBalanceInfoModalLoadable from 'src/components/PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';

type Props = {
  buyerStats?: GetBuyerStatsDataResponse;
};
const StatsCardWrapper: React.FC<Props> = ({ buyerStats }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const TotalAmount = buyerStats?.ResultSet.TotalAmount ?? 0;
  const InstallmentsCreated = buyerStats?.ResultSet.InstallmentsCreated ?? 0;
  const InstallmentsOverdue = buyerStats?.ResultSet.InstallmentsOverdue ?? 0;
  const InstallmentsPaid = buyerStats?.ResultSet.InstallmentsPaid ?? 0;
  const InstallmentsProcessing =
    buyerStats?.ResultSet.InstallmentsProcessing ?? 0;
  const OrdersCount = buyerStats?.ResultSet.OrdersCount;
  const {
    formatters: { formatCurrency },
  } = useI18n();
  return (
    <section>
      <Flex gap="0.8rem" responsive marginBottom="0.8rem">
        <StatsCard
          label="lbl.totalSales"
          content={formatCurrency(TotalAmount)}
          tooltipText="text.totalAmountTooltip"
        />
        <StatsCard
          label="lbl.orders"
          content={OrdersCount}
          tooltipText="text.ordersTooltip"
        />
      </Flex>
      <Flex gap="0.8rem" responsive>
        <StatsCard
          label="lbl.installmentsPaid"
          content={formatCurrency(InstallmentsPaid)}
          tooltipText="text.installmentsAmountTooltip"
        />
        <StatsCard
          label="lbl.installmentsToBePaid"
          content={
            InstallmentsProcessing <= 0
              ? formatCurrency(InstallmentsCreated)
              : formatCurrency(InstallmentsCreated + InstallmentsProcessing)
          }
          tooltipText="text.installmentsToBePaidTooltip"
          installmentsProcessing={InstallmentsProcessing}
          onLblClick={() => setModalOpen(true)}
        />
        <StatsCard
          label="lbl.overdueInstallments"
          content={formatCurrency(InstallmentsOverdue)}
          contentColor={colors.redStatus}
          tooltipText="text.overdueInstallmentsTooltip"
        />
      </Flex>
      {modalOpen && (
        <PendingBalanceInfoModalLoadable
          modalTitle="lbl.PBModalTitle"
          modalBodyType="merchant"
          i18n
          handleClose={() => setModalOpen(false)}
        />
      )}
    </section>
  );
};

export default StatsCardWrapper;
