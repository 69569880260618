import flagIT from 'src/images/icons/nationalflags/IT.svg?url';
import flagGE from 'src/images/icons/nationalflags/GB.svg?url';

export const optionsLanguage: ListboxOption[] = [
  {
    label: 'ITA',
    optionId: 'it-IT',
    iconSrc: flagIT,
  },
  {
    label: 'ENG',
    optionId: 'en-GB',
    iconSrc: flagGE,
  },
];
