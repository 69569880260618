import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ChangedMethodSvg } from 'src/images/icons/changed_method_icon.svg';
import { ReactComponent as CreditCard } from 'src/images/icons/credit-card.svg';
import { ReactComponent as RoundStepper } from 'src/images/icons/jump-stepper-round.svg';
import { ReactComponent as Phone } from 'src/images/icons/phone.svg';
import HowDoesItWorkCard from 'src/pages/PurchaseFlow/subcomponents/HowDoesItWorkCard';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { useLocation, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { useSession } from 'src/services/session/session.context';
import { useProfileStore } from 'src/store/store';
import { H4, H5, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

function PluginSetupSuccessPage() {
  const { successData } = useOutletContext<EcommerceContext>();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const location = useLocation();
  const guidParam = location?.state?.guidParam;

  const { onLogout } = useSession();

  const {
    formatters: { formatCurrency },
  } = useI18n();

  const singleInstallment = successData?.NextAmount === null;

  const formattedAmount = formatCurrency(successData?.NextAmount ?? 0);
  const formatteDueDate = refactorFormatDate(
    successData?.NextDueDate ?? '',
    'DD MMMM YYYY',
  );
  const userGuest =
    currentOrder?.BuyerRegistrationStatus &&
    currentOrder?.BuyerRegistrationStatus !== 'registered';
  const labels = {
    title: singleInstallment
      ? 'text.setupPaymentSingleInstallment'
      : 'text.setupPaymentInstallment',
    subtitle: singleInstallment
      ? 'text.setupSuccessSingleInstallmentSubtitle'
      : 'text.setupSuccessSubtitle',
  };
  const buttonLabel =
    guidParam || userGuest ? 'lbl.completeProfile' : 'lbl.backToEcommerce';

  return (
    <>
      <div className="container my-10 grid gap-6 text-center">
        <div className="relative flex items-center justify-between">
          <ChangedMethodSvg className="mx-auto w-66 lg:w-96" />
        </div>
        <div className="mx-auto grid max-w-olg gap-4">
          <H4>
            <Translate
              id={labels.title}
              data={{ amount: formattedAmount, dueDate: formatteDueDate }}
            />
          </H4>
          <P>
            <Translate id={labels.subtitle} />
          </P>
        </div>
        {userGuest && (
          <>
            <H5 textAlign="left" light margin="4.8rem 0 1.6rem">
              <Translate id="lbl.whyRegister" />
            </H5>
            <div className="mb-14 grid gap-4 text-left lg:grid-cols-2">
              <HowDoesItWorkCard title="text.keepTrack" icon={RoundStepper} />
              <HowDoesItWorkCard
                title="text.associatePayment"
                icon={CreditCard}
              />
              <HowDoesItWorkCard title="text.accessAnywhere" icon={Phone} />
            </div>
          </>
        )}
      </div>
      <ButtonContainer marginTop="0.8rem">
        <Button
          onClick={() => {
            if (guidParam || userGuest)
              (window as any).open(
                `${RoutePath.registrationGuest}${RoutePath.emailStep}?guid=${guidParam}`,
                '_blank',
              );
            (window as any).parent.postMessage(
              JSON.stringify({ status: 'close' }),
              '*',
            );
          }}
          variant="Primary"
          height="5.4rem"
          type="submit"
          minWidthMobile="100%"
        >
          <Translate id={buttonLabel} />
        </Button>
        {guidParam && (
          <Button
            margin="1.6rem 0 0 "
            onClick={() => {
              (window as any).parent.postMessage(
                JSON.stringify({ status: 'close' }),
                '*',
              );
              onLogout();
            }}
            variant="Tertiary"
            height="5.4rem"
            type="submit"
            minWidthMobile="100%"
          >
            <Translate id="lbl.backToEcommerce" />
          </Button>
        )}
      </ButtonContainer>
    </>
  );
}

export default PluginSetupSuccessPage;
