import { Resolver } from 'react-hook-form';
import { validateEndDate } from 'src/utils/validation/date';

import { DataFormBalanceMovements } from 'src/pages/balanceMovementsPage/BalanceMovementsPage.types';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';
import {
  clientList,
  endDate,
  flagCessioneCredito,
  flagNonCessioneCredito,
  skipField,
  startDate,
  takeField,
  takeMovements,
  typesField,
} from './SearchCriteriaForm.config';

export const searchCriteriaFormConfig = () => {
  const initialValues: DataFormBalanceMovements = {
    [startDate]: '',
    [endDate]: '',
    [typesField]: [],
    [clientList]: [],
    [flagCessioneCredito]: false,
    [flagNonCessioneCredito]: false,
    [skipField]: 0,
    [takeField]: takeMovements,
  };
  const validate = (values: any) => {
    return validateFormSync({
      [endDate]: validateFieldSync(validateEndDate(values[startDate])),
    })(values);
  };

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };

  return { initialValues, resolver };
};
