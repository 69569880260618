import { FC, useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { Flex } from 'src/style/flexbox.style';
import { AmountPillContainer } from './AmountsFormElement.style';
import Translate from 'src/components/Translate/Translate.component';

interface AmountProps {
  From: number;
  To: number;
  fieldName?: string;
}
type AmountsInputProps = {
  amounts: { From: number; To: number }[];
  fieldName?: string;
};

const AmountPill: FC<AmountProps> = ({ From, To, fieldName }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const { append, remove } = useFieldArray({
    name: fieldName ?? 'Amounts',
  });
  const amountsArray: { From: number; To: number }[] =
    useWatch({ name: fieldName ?? 'Amounts' }) ?? [];
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    checked
      ? remove(amountsArray.findIndex((i) => i.From === From))
      : append({ From: From, To: To });
    setChecked(!checked);
  };
  const label =
    To < 999999 ? (
      <Translate
        id="lbl.fromXtoY"
        data={{
          x: formatCurrency(From ?? 0, { hideCents: true }),
          y: formatCurrency(To ?? 0, { hideCents: true }),
        }}
      />
    ) : (
      <Translate
        id="lbl.moreThanX"
        data={{
          x: formatCurrency(From ?? 0, { hideCents: true }),
        }}
      />
    );

  useEffect(() => {
    setChecked(amountsArray.some((i) => i.From === From && i.To === To));
  }, [amountsArray]);

  useEffect(() => {
    setChecked(amountsArray.some((i) => i.From === From && i.To === To));
  }, [amountsArray]);

  return (
    <AmountPillContainer onClick={handleClick} checked={checked}>
      <span>{label}</span>
    </AmountPillContainer>
  );
};

const AmountsFormEelement: FC<AmountsInputProps> = ({ amounts, fieldName }) => {
  return (
    <Flex
      data-component="amounts-form-element"
      gap="1.2rem"
      overflow="auto"
      flexWrap="wrap"
    >
      {amounts.map(({ From, To }, i) => (
        <AmountPill key={i} {...{ From, To, fieldName }} />
      ))}
    </Flex>
  );
};

export default AmountsFormEelement;
